import { useState, useContext, useEffect, useReducer } from 'react';
import { ActionIcon, Alert, Center, Group, Text, Loader, Table, TextInput, Switch, ScrollArea, LoadingOverlay } from '@mantine/core';
import { IconSearch, IconAlertCircle, IconRefresh } from '@tabler/icons';
import { TitleContainer } from '../../shared/TitleContainer';
import AppContext from '../../shared/AppContext';

interface RoleInterface {
    role_id: number,
    role_label: string,
    is_granted: boolean,
}
interface PermissionInterface {
    api_id: number,
    api_code: string,
    api_label: string,
    roles: RoleInterface[],
};
interface FamilyInterface {
    family: string,
    data: PermissionInterface[],
}

const permissionsReducer = (state: any, action: any) => {
    switch(action.type) {
        case 'LOAD':
            return {
                ...state,
                isLoading: false,
                isUpdating: false,
                loaded: action.data,
                filtered: JSON.parse(JSON.stringify(action.data)),
            }
        case 'START_UPDATE':
            return {
                ...state,
                isUpdating: true,
            }
        case 'UPDATE':
            const ai = action.data.api_id;
            const ri = action.data.role_id;
            const ll = JSON.parse(JSON.stringify(state.loaded));
            for(let f=0; f<ll.length; f++) {
                for(let a=0; a<ll[f].data.length; a++) {
                    for(let r=0; r<ll[f].data[a].roles.length;r++){
                        if (ai === ll[f].data[a].api_id && ri === ll[f].data[a].roles[r].role_id) {
                            ll[f].data[a].roles[r].is_granted = action.data.value;
                        }
                    }
                }
            }
            const ff = JSON.parse(JSON.stringify(state.filtered));
            for(let f=0; f<ff.length; f++) {
                for(let a=0; a<ff[f].data.length; a++) {
                    for(let r=0; r<ff[f].data[a].roles.length;r++){
                        if (ai === ff[f].data[a].api_id && ri === ff[f].data[a].roles[r].role_id) {
                            ff[f].data[a].roles[r].is_granted = action.data.value;
                        }
                    }
                }
            }
            return {
                ...state,
                isUpdating: false,
                loaded: ll,
                filtered: ff,
            }
        case 'FILTER':
            const w = action.term;
            const ss = JSON.parse(JSON.stringify(state.loaded));
            if (w.length === 0) {
                return {
                    ...state,
                    filtered: ss,
                }
            }
            for(let f=0; f<ss.length; f++) {
                for(let a=ss[f].data.length-1; a>=0; a--) {
                    if (ss[f].data[a].api_code.search(w) === -1 && ss[f].data[a].api_label.search(w) === -1) {
                        ss[f].data.splice(a, 1);
                    }
                }
            }
            return {
                ...state,
                filtered: ss,
            }
        case 'ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.message || 'The API says no.',
            }
        case 'RESET':
            return {
                isLoading: true,
                isUpdating: false,
                error: null,
                loaded: [],
                filtered: [],
            }
        default:
            return {
                ...state,
                isLoading: false,
                error: 'Oops, unknown local error.',
            }
    };
};

const ManagePermissions = () => {

    const myContext = useContext(AppContext);
    const [search, setSearch] = useState('');
    const apiUrlGet: string = myContext.apiAddress + '/get_repository_permission';
    const apiUrlUpdate: string = myContext.apiAddress + '/update_permission';
    
    const [ permissions, dispatchPermissions ] = useReducer(
        permissionsReducer, {
            isLoading: true,
            isUpdating: false,
            error: null,
            loaded: [],
            filtered: [],
        }
    );

    const handleChange = (api_id: number, role_id: number, value: boolean) => {
        const myForm = new FormData();
        myForm.append('api_id', api_id + '');
        myForm.append('role_id', role_id + '');
        myForm.append('value', value ? '1' : '0');
        dispatchPermissions({type: 'START_UPDATE'});
        myContext.httpClient.post(apiUrlUpdate, myForm).then((result: any) => {
            if (result.data.status === true) {
                dispatchPermissions({type: 'UPDATE', data: { api_id: api_id, role_id: role_id, value: value} });
            } else if (result.data.status === false) {
                dispatchPermissions({type: 'ERROR', message: result.data.message});
            } else {
                dispatchPermissions({type: 'ERROR', message: 'Strange, the servor returned a bad answer.'});
            }
        });
    }

    const handleSearch = (v: string) => {
        setSearch(v);
        dispatchPermissions({type: 'FILTER', term: v.trim().toLocaleLowerCase()});
    }

    useEffect(() => {
        if (!permissions.isLoading) return;
        if (apiUrlGet.startsWith('null')) return;
        myContext.httpClient.get(apiUrlGet).then((result: any) => {
            if (result.data.status === true) {
                dispatchPermissions({type: 'LOAD', data: result.data.data});
            } else if (result.data.status === false) {
                dispatchPermissions({type: 'ERROR', message: result.data.message});
            } else {
                dispatchPermissions({type: 'ERROR', message: 'Strange, the servor returned a bad answer.'});
            }
        });
    }, [permissions.isLoading]); // eslint-disable-line

    if (permissions.isLoading) {
        return (
            <Center>
                <Group>
                    <Loader color="blue" size="md" />
                    <Text color="blue">Récupération des permissions en cours&hellip;</Text>
                </Group>
            </Center>
        );
    }

    if (permissions.error !== null) {
        return (
            <Center>
                <Alert icon={<IconAlertCircle size={16} />} title="Gestion des permissions" color="red" radius="md">
                    {permissions.error}
                </Alert>
            </Center>
        )
    }

    if (permissions.filtered.length === 0) {
        return (
            <Center>
                <Alert icon={<IconAlertCircle size={16} />} title="Gestion des permissions" color="red" radius="md">
                    Aucune permission à afficher.
                </Alert>
            </Center>
        )
    }

    return (
        <>
        <Group position='apart'>
            <TitleContainer>Gestion des permissions</TitleContainer>
            <ActionIcon color='blue' variant='outline' onClick={() => dispatchPermissions({type: 'RESET'})}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        <TextInput
            placeholder="Rechercher une API"
            mb="md"
            icon={<IconSearch size={14} stroke={1.5} />}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
        />
        <ScrollArea style={{height: 'calc(100vh - 160px)'}}>
            <LoadingOverlay visible={permissions.isUpdating} />
            {permissions.filtered.map((f: FamilyInterface) => (
            <Table sx={{ minWidth: 1000 }} key={'p_family_' + f.family} highlightOnHover>
                <tbody>        
                    {(f.data.length === 0) ? (<></>) : (
                        <>
                        <tr>
                            <td colSpan={1 + f.data[0].roles.length}><strong>{f.family}</strong></td>
                        </tr>
                        {f.data.map((p: PermissionInterface) => (
                            <tr key={'p_api_' + p.api_id}>
                                <td style={{width: '250px'}}>
                                    <Text size='xs' color="dimmed">{p.api_code}</Text>
                                    <Text>{p.api_label}</Text>
                                </td>
                                {p.roles.map((r) => (
                                    <td key={'p_' + p.api_id + '_' + r.role_id}>
                                        <Group spacing='xs'>
                                            <Switch size="xs" color="blue" checked={r.is_granted} onChange={(event) => handleChange(p.api_id, r.role_id, event.currentTarget.checked)} />
                                            <Text size="xs">{r.role_label}</Text>
                                        </Group>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </>
                    )}
                </tbody>
            </Table>
            ))}
        </ScrollArea>
        </>
    )
}

export { ManagePermissions }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, createStyles, Group, Loader, Paper, Text, TextInput } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import AppContext from '../AppContext';
import { JobSelector } from '../JobSelector';
import { SectorSelector } from '../SectorSelector';
import { AlertNotification } from '../../shared/AlertNotification';

interface InputInterface {
    code: string,
    label: string,
    listCode: string,
    defaultItems: string[],
    api: string,
    isEdition: boolean,
    handle: any,
}

interface ItemListInterface {
    id: number,
    value: string,
}

const useStyles = createStyles((theme) => ({
    root: {
        marginTop: '5px',
    },
    
}))

const ChipsContainer = ({code, label, listCode, defaultItems, api, isEdition, handle}: InputInterface) => {

    const myContext = useContext(AppContext);
    const { classes } = useStyles();
    const [ oldItems, setOldItems ] = useState([...defaultItems]);
    const [ newItems, setNewItems ] = useState([...defaultItems]);
    const [ value, setValue ] = useState('');
    const [ isChange, setIsChange ] = useState([false, false]);
    const [ isUpdating, setIsUpdating ] = useState(false);
    
    const remove = (l: string) => {
        if (isUpdating) return;
        setNewItems(newItems.filter((v) => v !== l));
        setIsChange([true, isChange[1]]);
    }

    const reset = () => {
        setValue('');
        setIsChange([false, false]);
        setNewItems([...oldItems]);
    }

    const update = () => {
        if (!isChange[0] && !isChange[1]) return;
        if (api === '') return;
        setIsUpdating(true);
        const F = new FormData();
        F.append('code', code);
        if (value.trim() === '') {
            F.append('value', [...newItems].join(';'));
        } else {
            F.append('value', [...newItems, value.trim()].join(';'));
        }
        myContext.httpClient.post(api, F).then((result: any) => {
            if (result.data.status === true) {
                const v: string = result.data.message;
                setOldItems(v === '' ? [] : v.split(';'));
                setNewItems(v === '' ? [] : v.split(';'));
                setIsChange([false, false]);
                setValue('');
                if (handle !== null) {
                    handle({code: code, value: v});
                }
            } else {
                setNewItems([...oldItems]);
                AlertNotification({message: result.data.message || 'unknown error from servor'});
            }
            setIsUpdating(false);
        });
    }

    const addListItems = (items: ItemListInterface[]) => {
        const news: string[] = [];
        for (let item of items) {
            if (newItems.includes(item.value)) {
                continue;
            } else {
                news.push(item.value);
            }
        }
        if (news.length === 0) return;
        setNewItems([...newItems, ...news]);
        setIsChange([isChange[0], true]);
    }

    const addTag = () => {
        const v = value.trim();
        if (v === '' || newItems.includes(v)) return;
        setNewItems([...newItems, v]);
        setValue('');
        setIsChange([isChange[0], true]);
    }

    useEffect(() => {
        if (handle === null) return;
        if (api !== '') return;
        handle({code: code, value: newItems.join(';')});
    }, [api, code, newItems, handle]);

    return (
        <>
        <Paper p="xs" className={classes.root} withBorder>
            <Text color="dimmed" size="xs">{label}</Text>
            {newItems.map((v,idx) => (
                <Group key={`${code}-${idx}`} noWrap>
                    {isEdition && 
                    <ActionIcon radius="xl" variant="filled" size="xs" 
                        onClick={() => remove(v)}
                    >
                        <IconX/>
                    </ActionIcon>
                    }
                    <Text pb="xs" style={{lineHeight: '1.2em'}}>{v}</Text>
                </Group>
            ))}
            {(listCode === '' && isEdition) && 
            <TextInput
                value={value} 
                placeholder="nouvel élément"
                onChange={(event) => { 
                    const v= event.currentTarget.value.replace(';',','); 
                    setValue(v); 
                    setIsChange([isChange[0], v.trim() === '' ? false : true ]);
                } }
                disabled={isUpdating}
                onKeyDown={(e) => { if (e.key === 'Enter') { addTag() }}}
            />
            }
            {(isEdition && listCode === 'jobs') && <JobSelector handle={addListItems} /> }
            {(isEdition && listCode === 'sectors') && <SectorSelector handle={addListItems} /> }
        </Paper>
        {(api !== '' && (isChange[0] || isChange[1])) && 
        <Group position="center">
            {(!isUpdating) && 
            <ActionIcon color="blue" radius="xl" variant="outline" onClick={() => reset()}>
                <IconX />
            </ActionIcon>}
            {(!isUpdating) && 
            <ActionIcon color="blue" radius="xl" variant="filled" onClick={update}>
                <IconCheck />
            </ActionIcon>}
            {isUpdating && <Loader />}
        </Group>}
        </>
    )
}

export { ChipsContainer }
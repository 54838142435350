import { useContext, useState } from 'react';
import { ActionIcon, Box, Button, CopyButton, Group, Text, Textarea, TextInput } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TitleContainer } from '../../shared/TitleContainer';

import { HuntInterface } from '../interfaces';

const AddHunt = () => {

    const myContext = useContext(AppContext);
    const [working, setWorking] = useState(false);
    const [inputLabel, setInputLabel] = useState('');
    const [inputDescription, setInputDescription] = useState('');
    const [done, setDone] = useState(false);
    const [hunt, setHunt] = useState<null | HuntInterface>(null);

    const reset = () => {
        setInputLabel('');
        setInputDescription('');
        setDone(false);
        setHunt(null);
    }

    const submit = () => {
        const form = new FormData();
        form.append('action', 'add');
        form.append('label', inputLabel);
        form.append('description', inputDescription);
        const api = `${myContext.apiAddress}/update_hunt`;
        setWorking(true);
        myContext.httpClient.post(api, form).then((res:any) => {
            if (res.data.status === true) {
                setDone(true);
                setHunt(res.data.data);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setWorking(false);
        });
    }
    
    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Créer une nouvelle chasse
            </TitleContainer>
            <ActionIcon color='blue' variant='outline' disabled={working} onClick={() => reset()}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        {done ?
        <Box maw={300} mx="auto">
            <Text>{inputLabel}</Text>
            {inputDescription.length > 0 && <Text color='sm'>{inputDescription}</Text>}
            {hunt !== null &&
            <Group>
                <Text>{hunt.token}</Text>
                <CopyButton value={hunt.token}>
                    {({ copied, copy }) => (
                        <Button compact color={copied ? 'teal' : 'blue'} onClick={copy}>
                        {copied ? 'Copied token' : 'Copy token'}
                        </Button>
                    )}
                </CopyButton>
            </Group>
            }
        </Box>
        :
        <Box maw={300} mx="auto">
            <Text color="dimmed">
                Après création d'une chasse, un token est généré pour permettre le déversement de données.
            </Text>
            <TextInput
                withAsterisk
                label="Libellé"
                placeholder=""
                value={inputLabel}
                onChange={(event) => setInputLabel(event.currentTarget.value)}
                disabled={working}
            />
            <Textarea
                withAsterisk
                label="Description"
                placeholder=""
                value={inputDescription}
                onChange={(event) => setInputDescription(event.currentTarget.value)}
                disabled={working}
            />
            <Group position="right" mt="md">
                <Button 
                    variant="outline" color="blue"
                    onClick={() => submit()}
                    disabled={(inputDescription.length > 500 || inputLabel.length === 0 || inputLabel.length > 100)}
                    loading={working}
                >
                    Soumettre
                </Button>
            </Group>
        </Box>
        }
        </>
    )
}

export { AddHunt }
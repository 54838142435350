import { useContext, useEffect, useReducer, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { ActionIcon, Alert, Card, Center, Divider, Grid, Group, Indicator, Loader, LoadingOverlay, Modal, Progress, ScrollArea, Text } from '@mantine/core';
import { IconAlertCircle, IconCheck, IconEdit, IconEditOff, IconFileExport, IconNotes } from '@tabler/icons';
import AppContext from "../../shared/AppContext";
import { TextInputContainer } from '../../shared/TextInputContainer';
import { TextareaContainer } from '../../shared/TextareaContainer';
import { ChipsContainer } from '../../shared/ChipsContainer';
import { UserSelector } from '../../shared/UserSelector';
import { HistoryButton } from '../../shared/HistoryButton';
import { AlertNotification } from '../../shared/AlertNotification';
import { TrashButton } from "../../shared/TrashButton";
import { ToggleContainer } from "../../shared/ToggleContainer";
import { Attachements } from "./attachments";
import { DisplayPDF } from "../../shared/DisplayPDF";

const RichTextContainer = lazy(() => import('../../shared/RichTextComponent/rte_container').then(({RichTextContainer}) => ({default: RichTextContainer})));

interface UserInterface {
    id: number,
    label: string,
}

interface CvDocInterface {
    id: number,
    genuineFileId: number,
    pdfFileId: number,
    filename: string,
    status: string,
    name: string,
    email: string,
    is_blocked: boolean,
    phone: string,
    address: string,
    zipcode: string,
    jobs: string[],
    sectors: string[],
    tags: string[],
    content: string,
    notebook: string,
    nAttachments: number,
}

const DocumentReducer = (state: CvDocInterface, payload: any) => {
    switch (payload.type) {
        case 'SET':
            return {
                ...payload.data
            }
        case 'UPDATE_NAME':
            return {
                ...state,
                name: payload.value,
            }
        case 'UPDATE_NOTEBOOK':
            return {
                ...state,
                notebook: payload.value,
            }
    }
}

interface PropsInterface {
    documentId: number,
    isEdition: boolean,
}

const DocumentContainer = ({documentId, isEdition}: PropsInterface) => {

    // STATUS
    // inserted
    // affected
    // waiting-for-control
    // waiting-for-validation
    // validated
    
    const [ document, dispatchDocument ] = useReducer(DocumentReducer, null);
    const [ isRemoved, setIsRemoved ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isNotebookOpened, setIsNotebookOpened ] = useState(false);
    const [ isContentOpened, setIsContentOpened ] = useState(false);
    const [ isChangingStatus, setIsChangingStatus ] = useState(false);
    const [ isModeEdition, setIsModeEdition ] = useState(isEdition);
    const myContext = useContext(AppContext);
    const apiUpdate = `${myContext.apiAddress}/update_cv?id=${documentId}`;
    const navigate = useNavigate();
    
    useEffect(() => {
        if (isLoaded) return;
        if (documentId === undefined || /^[1-9][0-9]{0,5}$/.test(documentId + '') !== true) return;
        const f = new FormData();
        f.append('id', documentId + '');
        myContext.httpClient.post(`${myContext.apiAddress}/get_cv`, f).then((result: any) => {
            setIsLoaded(true);
            if (result.data.status === true) {
                dispatchDocument({type: 'SET', data: result.data.data});
            }
        });
    }, [isLoaded]); // eslint-disable-line

    const changeStatus = ({id, label}: UserInterface) => {
        let api: string | null = null;
        if (document.status === 'affected') {
            api = 'submit_cv_for_control';
        } else if (document.status === 'waiting-for-control') {
            api = 'submit_cv_for_validation';
        } else if (document.status === 'waiting-for-validation') {
            api = 'validate_cv';
        }
        if (api === '') return;
        api = `${myContext.apiAddress}/${api}?id=${document.id}`;
        if (api !== 'validate_cv') {
            api = `${api}&user=${id}`;
        }
        setIsChangingStatus(true);
        myContext.httpClient.get(api).then((result: any) => {
            setIsChangingStatus(false);
            if (result.data.status === true) {
                navigate(`/cvtheque/list`, { replace: true });
            } else {
                AlertNotification({message: result.data.message || 'unknown error from servor'});
            }
        })
    }

    const transferActionTo = ({id, label}: UserInterface) => {
        const api = `${myContext.apiAddress}/change_action_cv_to?id=${document.id}&user=${id}`;
        setIsChangingStatus(true);
        myContext.httpClient.get(api).then((result: any) => {
            setIsChangingStatus(false);
            if (result.data.status === true) {
                navigate(`/cvtheque/list`, { replace: true })
            } else {
                AlertNotification({message: result.data.message || 'unknown error from servor'});
            }
        })
    }

    if (!isLoaded) {
        return (
            <Card>
                <Center>
                    <Group>
                        <Loader />
                        <Text>
                            {!isLoaded && "Chargement du document..."}
                        </Text>
                    </Group>
                </Center>
            </Card>
        )
    }

    if (document.id === -1) {
        return (
            <Card>
                <Center>
                    <Alert icon={<IconAlertCircle size={16} />} title={`cv.toLocaleUpperCase()`} color="red" radius="md">
                        Document {document.id} non chargé
                    </Alert>
                </Center>
            </Card>
        )
    }

    if (isRemoved) {
        return (
            <Card>
                <Center>
                    <Alert icon={<IconAlertCircle size={16} />} title={document.name} color="red">
                        Vous venez de mettre ce document à la poubelle.
                    </Alert>
                </Center>
            </Card>
        )
    }

    return (
        <>
        <Modal
            opened={isNotebookOpened}
            onClose={() => { setIsNotebookOpened(false) }}
            title={`Notes sur ${document.name}`}
            size="calc(100vw - 200px)"
            fullScreen
        >
            <Suspense fallback={<Group><Loader/><Text>Loading component&hellip;</Text></Group>}>
                <RichTextContainer 
                    code='notebook'
                    value={document.notebook}
                    api={apiUpdate}
                    handle={(v:string) => dispatchDocument({type:'UPDATE_NOTEBOOK', value: v})}
                />
            </Suspense>
        </Modal>

        <Modal
            opened={isContentOpened}
            onClose={() => { setIsContentOpened(false)}}
            title={`Contenu textuel extrait du document de ${document.name}`}
            size="xl"
        >
            {document.content.split('\n').map((t: string, idx: number) => (
                <Text key={`text-${idx}`}>{t}</Text>
            ))}
        </Modal>
        
        <Grid>
            <Grid.Col sm={12} md={8} lg={9} style={{ height: 'calc(100vh - 65px)' }}>
                <DisplayPDF fileUrl={`${myContext.apiAddress}/get_document_cv?id=${document.id}&document=${document.pdfFileId}`} />
            </Grid.Col>
            <Grid.Col sm={12} md={4} lg={3} style={{ height: 'calc(100vh - 65px)' }}>
                <ScrollArea style={{ height: '100%' }}>
                    <Group position="right" style={{paddingRight: '20px'}}>
                        {isModeEdition ? 
                        <>
                        <TrashButton
                            label={`Curriculum vitae de ${document.name}`}
                            api='remove_cv'
                            params={`id=${document.id}`}
                            handle={() => setIsRemoved(true)}
                        />
                        <ActionIcon color="blue" variant="outline" 
                            onClick={() => setIsModeEdition(false)}
                        >
                            <IconEditOff size={16} />
                        </ActionIcon>
                        </>
                        :
                        <>
                        {document.notebook.length ===  0 ?
                        <ActionIcon variant="outline" color='blue' onClick={() => setIsNotebookOpened(true)}>
                            <IconNotes size={16} />
                        </ActionIcon>
                        :
                        <Indicator position='middle-end' color='green'>
                            <ActionIcon variant="outline" color='blue' onClick={() => setIsNotebookOpened(true)}>
                                <IconNotes size={16} />
                            </ActionIcon>
                        </Indicator>
                        }
                        <Attachements 
                            documentId={documentId} 
                            name={document.name}
                            nAttachments={document.nAttachments}
                        />
                        <HistoryButton
                            family="cv"
                            id={documentId}
                            label={document.name}
                        />
                        <ActionIcon variant="outline" color="blue" onClick={() => setIsContentOpened(true)}>
                            <Text size="xs">txt</Text>
                        </ActionIcon>
                        <ActionIcon color="blue" variant="outline"
                            component="a" target="_blank" rel="noopener noreferrer"
                            href={`${myContext.apiAddress}/get_document_cv?id=${document.id}&document=${document.pdfFileId}`}
                        >
                            <IconFileExport size={16} />
                        </ActionIcon>
                        <ActionIcon color="blue" variant="outline" 
                            onClick={() => setIsModeEdition(true)}
                        >
                            <IconEdit size={16} />
                        </ActionIcon>
                        </>
                        }
                    </Group>
                    <Group position="center" pt="xs">
                        <ToggleContainer
                            action='block'
                            defaultValue={!document.is_blocked}
                            label='Utilisable'
                            isEdition={isModeEdition}
                            api={`${myContext.apiAddress}/block_cv?id=${documentId}`}
                            handle={null}
                        />                      
                    </Group>
                    {/* ****************************
                        CV section starts...
                    **************************** */}
                    <Progress
                        mt="md"
                        size="xl"
                        radius="xl"
                        sections={[
                            { value: 20, color: document.status === 'inserted' ? 'green' : 'gray', label: 'Inséré' },
                            { value: 20, color: document.status === 'affected' ? 'green' : 'gray', label: 'Affecté' },
                            { value: 20, color: document.status === 'waiting-for-control' ? 'green' : 'gray', label: 'Soumis' },
                            { value: 20, color: document.status === 'waiting-for-validation' ? 'green' : 'gray', label: 'Contrôlé' },
                            { value: 20, color: document.status === 'validated' ? 'green' : 'gray', label: 'Vallidé' },
                        ]}
                    />
                    <TextInputContainer 
                        code="filename"
                        label="Nom du fichier (avec extension)"
                        defaultValue={document.filename}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={null}
                        withAsterisk={true}
                    />
                    <TextInputContainer 
                        code="name"
                        label="Nom complet"
                        defaultValue={document.name}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={(value: string) => dispatchDocument({type:'UPDATE_NAME', value: value}) }
                        withAsterisk={true}
                    />
                    <TextInputContainer 
                        code="email"
                        label="Email"
                        defaultValue={document.email}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={null}
                        withAsterisk={true}
                    />
                    <TextInputContainer 
                        code="phone"
                        label="Téléphone"
                        defaultValue={document.phone}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={null}
                        withAsterisk={false}
                    />
                    <TextareaContainer
                        code="address"
                        label="Adresse (rue, code postal, ville)"
                        defaultValue={document.address}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={null}
                    />
                    <TextInputContainer 
                        code="zipcode"
                        label="Code postal"
                        defaultValue={document.zipcode}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={null}
                        withAsterisk={false}
                    />
                    <ChipsContainer
                        code="jobs"
                        label="Métiers"
                        listCode="jobs"
                        defaultItems={document.jobs}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={null}
                    />
                    <ChipsContainer
                        code="sectors"
                        label="Secteurs"
                        listCode="sectors"
                        defaultItems={document.sectors}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={null}
                    />
                    <ChipsContainer
                        code="tags"
                        label="Mots clés"
                        listCode=""
                        defaultItems={document.tags}
                        api={apiUpdate}
                        isEdition={isModeEdition}
                        handle={null}
                    />
                    {(isModeEdition && ['affected','waiting-for-control','waiting-for-validation'].includes(document.status)) &&
                    <Card>
                        <LoadingOverlay visible={isChangingStatus} />
                        <Center>
                            <Text>
                                {document.status === 'affected' && "Demander le contrôle du CV à"}
                                {document.status === 'waiting-for-control' && "Demander la validation du CV à"}
                                {document.status === 'waiting-for-validation' && "Valider le CV"}
                            </Text>
                        </Center>
                        <Center>
                            {['affected','waiting-for-control'].includes(document.status) && 
                            <UserSelector
                                handle={changeStatus}
                            />
                            }
                            {document.status === 'waiting-for-validation' && 
                            <ActionIcon color="blue" variant="outline" radius="xl"
                                onClick={() => changeStatus({id: 0, label: ''})}
                            >
                                <IconCheck/>
                            </ActionIcon>
                            }
                        </Center>
                        <Divider mt='xs' mb='xs' />
                        <Group position="center">
                            <Text>Transférer l'action à</Text>
                            <UserSelector
                                handle={transferActionTo}
                            />
                        </Group>
                    </Card>
                    }
                    {/* ****************************
                        CV section ends.
                    **************************** */}
                </ScrollArea>
            </Grid.Col>
        </Grid>
        </>
    )
}

export { DocumentContainer }
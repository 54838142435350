import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Badge, Button, Card, Divider, Group, LoadingOverlay, Stack, Table, Text } from '@mantine/core';
import { IconRefresh, IconSquareToggleHorizontal } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { toFrenchDate } from '../../../services/functions';
import { InfoNotification } from '../../shared/InfoNotification';

interface RowInterface {
    id: number,
    fullname: string,
    label: string,
    start_date: string,
    end_date: string,
    created_date: string,
    am: boolean,
    pm: boolean,
    total: number,
}

const rowsReducer = (state: RowInterface[], payload: any) => {
    switch(payload.type){
        case 'SET':
            return payload.data;
        case 'DEL':
            return state.filter((row: RowInterface) => row.id !== payload.id);
        default:
            return [];
    }

}

const LeaveValidateList = () => {

    const myContext = useContext(AppContext);
    const [opened, setOpened] = useState(false);
    const [working, setWorking] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [rows, dispatchRows] = useReducer(rowsReducer, []);

    useEffect(() => {
        if (loaded) return;
        setWorking(true);
        myContext.httpClient.get(`${myContext.apiAddress}/hr_request_account?action=list_day_off_to_validate`).then((res:any) => {
            setLoaded(true);
            setWorking(false);
            if (res.data.status === true) {
                dispatchRows({type: 'SET', data: res.data.data || []});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });

    }, [loaded]); // eslint-disable-line

    const convertToSquares = (am: boolean, pm: boolean, total: number) => {
        const c = (am && pm) ? '◼' : (am ? '◧' : '◨');
        let s = '';
        if (total <= 0) {
            return '?';
        }
        for (let i=0;i<total;i++) {
            s += c;
            if (i === 15) {
                s += '...';
                break;
            }
        }
        return s;
    }

    const validate = (id: number) => {
        setWorking(true);
        const api = `${myContext.apiAddress}/hr_request_account?id=${id}&action=validate_day_off`;
        myContext.httpClient.get(api).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                dispatchRows({type: 'DEL', id: id});
                InfoNotification({message: 'demande de congé validée'});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <Card withBorder>
            <Group position='apart'>
                <Text>Demandes de congé à valider</Text>
                <ActionIcon color='blue' variant='outline' onClick={() => setOpened(!opened)}>
                    <IconSquareToggleHorizontal size={16} />
                </ActionIcon>
            </Group>
            {opened && 
            <>
            <LoadingOverlay visible={working} />
            <Divider mt='sm' mb='sm' />
            <Group position='right'>
                <Badge>{rows.length}</Badge>
                <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
            <Table highlightOnHover><tbody>
            {rows.map((row: RowInterface) => 
                <tr key={`request-${row.id}`}>
                    <td>
                        <Stack spacing={0}>
                            <Text>{row.fullname}</Text>
                            <Group><Text>Du {toFrenchDate(row.start_date)} au {toFrenchDate(row.end_date)}</Text><Text color='teal'>{convertToSquares(row.am, row.pm, row.total)}</Text></Group>
                            <Text>{row.label}</Text>
                            <Text color='dimmed' size='sm'>Demande créée le {toFrenchDate(row.created_date.substring(0,10))}</Text>
                        </Stack>
                    </td>
                    <td>
                    <Stack>
                        <Button color='blue' variant='outline' onClick={() => validate(row.id)}>
                            valider
                        </Button>
                    </Stack>
                    </td>
                </tr>)}
            </tbody></Table>
            </>}
        </Card>
    )
}

export { LeaveValidateList }
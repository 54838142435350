import { useContext, useState } from "react"
import { ActionIcon, Center, SegmentedControl, Modal, Button } from "@mantine/core"
import { IconStackPop } from "@tabler/icons"
import { InfoNotification } from "../InfoNotification";
import { AlertNotification } from "../AlertNotification";
import AppContext from "../AppContext";

interface StatusItem {
    label: string,
    value: string,
}
interface PropsInterface {
    api: string,
    items: StatusItem[],
    handle: any,
}

const StatusButton = ({items, handle, api}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [display, setDisplay] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [status, setStatus] = useState('');
    
    const save = () => {
        setIsWorking(true);
        const F = new FormData();
        F.append('code', 'status');
        F.append('value', status);
        const url = `${myContext.apiAddress}/${api}`;
        myContext.httpClient.post(url, F).then((res: any) => {
            setIsWorking(false);
            setDisplay(false);
            if (res.data.status === true) {
                InfoNotification({ message: 'Status changed to ' + status });
                handle(status);
            } else {
                AlertNotification({ message: res.data.message || 'unknown error' });
            }
        });
    }

    return (
        <>
        <Modal
            opened={display}
            onClose={() => setDisplay(false)}
            title="Sélectionner un statut"
        >
            <Center>
                <SegmentedControl
                    size="md"
                    data={items}
                    orientation="vertical"
                    value={status}
                    onChange={setStatus}
                    color="blue"
                    disabled={isWorking}
                />
            </Center>
            <Center mt="lg">
                <Button disabled={status === ''} loading={isWorking} onClick={() => save() }>
                    Sauvegarder
                </Button>
            </Center>
        </Modal>
        <ActionIcon color="blue" variant="outline" onClick={() => setDisplay(true)}>
            <IconStackPop size={16} />
        </ActionIcon>
        </>
    )
}

export { StatusButton }
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Badge, Center, ScrollArea, Tabs } from '@mantine/core';
import { IconUser, IconSearch, IconBasket, IconQuestionMark, IconReport, IconFiles, IconMessages } from '@tabler/icons';

import { Header } from './header';
import { Brief } from './brief';
import { Search } from './search';
import { Basket } from './basket';
import { FormCreator } from '../../shared/FormCreator';
import { Process } from './process';
import { Docs } from './docs';
import { Messages } from '../../shared/MessagesContainer/messages';

type ParamInterface = {
    paramNeedId: string,
}

const ProcessNeed = () => {

    const { paramNeedId } = useParams<ParamInterface>();
    const needId: number = parseInt(paramNeedId === undefined ? '0' : paramNeedId);
    const [isOpen, setIsOpen] = useState(true);

    const [activeTab, setActiveTab] = useState<string | null>('brief');
    const [isBriefOpen, setIsBriefOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isBasketOpen, setIsBasketOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isProcessOpen, setIsProcessOpen] = useState(false);
    const [isDocsOpen, setIsDocsOpen] = useState(false);
    const [isMessagesOpen, setIsMessagesOpen] = useState(false);
    const [label, setLabel ] = useState('...');

    useEffect(() => {
        switch(activeTab) {
            case 'brief':
                if (!isBriefOpen) setIsBriefOpen(true);
                break;
            case 'search':
                if (!isSearchOpen) setIsSearchOpen(true);
                break;
            case 'basket':
                if (!isBasketOpen) setIsBasketOpen(true);
                break;
            case 'form':
                if (!isFormOpen) setIsFormOpen(true);
                break;
            case 'process':
                if (!isProcessOpen) setIsProcessOpen(true);
                break;
            case 'docs':
                if (!isDocsOpen) setIsDocsOpen(true);
                break;
            case 'messages':
                if (!isMessagesOpen) setIsMessagesOpen(true);
                break;
        }
    }, [activeTab]); // eslint-disable-line

    return (
        <>
        <Header needId={needId} handle={(b: boolean) => setIsOpen(b)}/>
        <Center>
            <Badge color='orange' style={{ textTransform: 'none' }}>{label}</Badge>
        </Center>
        <Tabs radius="xs" value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List>
                <Tabs.Tab value="brief" icon={<IconUser size={14} />}>Brief</Tabs.Tab>
                <Tabs.Tab value="search" icon={<IconSearch size={14} />}>Recherche</Tabs.Tab>
                <Tabs.Tab value="basket" icon={<IconBasket size={14} />}>Panier</Tabs.Tab>
                <Tabs.Tab value="form" icon={<IconQuestionMark size={14} />}>Questionnaire</Tabs.Tab>
                <Tabs.Tab value="process" icon={<IconReport size={14} />}>Analyse</Tabs.Tab>
                <Tabs.Tab value="docs" icon={<IconFiles size={14} />}>Bibliothèque</Tabs.Tab>
                <Tabs.Tab value="messages" icon={<IconMessages size={14} />}>Journal</Tabs.Tab>
            </Tabs.List>

            <ScrollArea style={{width: '100%', height: 'calc(100vh - 170px)'}}>
        
                <Tabs.Panel value="brief">
                    {isBriefOpen &&
                    <Brief needId={needId} isOpen={isOpen} handleLabel={(s: string) => setLabel(s) }/>
                    }
                </Tabs.Panel>

                <Tabs.Panel value="search">
                    {isSearchOpen &&
                    <Search needId={needId} isOpen={isOpen} />
                    }
                </Tabs.Panel>

                <Tabs.Panel value="basket">
                    {isBasketOpen &&
                    <Basket needId={needId} isOpen={isOpen} />
                    }
                </Tabs.Panel>

                <Tabs.Panel value="form">
                    {isFormOpen &&
                    <FormCreator
                        nature='need'
                        id={needId}
                        isOpen={isOpen}
                        initialQuestions={[
                            { position: 1, nature: 'T', required: true , code: '_title', label: 'Mission de ...', param: null },
                            { position: 2, nature: 'D', required: true , code: '_date', label: 'Vous êtes disponible à partir du', param: null },
                            { position: 3, nature: 'R', required: true , code: '_move', label: 'Vous êtes mobile sur ...', param: null },
                            { position: 4, nature: 'N', required: true , code: '_rate', label: 'Votre TJM hors frais, ou salaire annuel brut, est de', param: null },
                            { position: 5, nature: 'Z', required: false, code: '_town', label: 'Votre code postal (mettre 99999 si hors de France)', param: null },
                        ]}
                    />
                    }
                </Tabs.Panel>

                <Tabs.Panel value="process">
                    {isProcessOpen &&
                    <Process needId={needId} isOpen={isOpen} />
                    }
                </Tabs.Panel>

                <Tabs.Panel value="docs">
                    {isDocsOpen &&
                    <Docs needId={needId} isOpen={isOpen} />
                    }
                </Tabs.Panel>

                <Tabs.Panel value="messages">
                    {isMessagesOpen &&
                    <Messages objectCode='need' objectId={needId} isOpen={isOpen} 
                        getUrl={`get_process_need?need_id=${needId}&what=messages`}
                        updateUrl={`update_process_need?need_id=${needId}&what=message`}
                        height='calc(100vh - 250px)'
                    />
                    }
                </Tabs.Panel>

            </ScrollArea>
        </Tabs>
        </>
    )
}

export { ProcessNeed }
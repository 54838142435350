import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Badge, Button, Center, ColorSwatch, CopyButton, Divider, Group, LoadingOverlay, Modal, ScrollArea, Spoiler, Stack, Switch, Table, Text } from '@mantine/core';
import { IconExternalLink, IconFileDescription, IconRefresh, IconSortDescending, IconUsers, IconWriting } from '@tabler/icons';
import AppContext from '../../shared/AppContext';

import { InfoNotification } from '../../shared/InfoNotification';
import { AlertNotification } from '../../shared/AlertNotification';
import { DocumentContainer } from '../../cvtheque/DocumentContainer';
import { File } from './file';
import { Choice } from './choice';
import { Comment } from './comment';
import { Document } from './document';
import { ValueDate } from './value_date';
import { ValueBoolean } from './value_boolean';
import { ValueNumber } from './value_number';
import { ValueTown } from './value_town';
import { ValuePortage } from './value_portage';
import { Contract } from './contract';
import { toFrenchDate } from '../../../services/functions';
import { MBTI } from './mbti';

interface TownInterface {
    id: number,
    zipcode: string,
    label: string,
}
interface QuestionInterface {
    label: string,
    value: string | number | null,
}
interface RowInterface {
    "header": {
        "cv_id": number,
        "name": string,
        "email": string,
        "phone": string | null,
        "inserted_date": string | null,
        "mbti": string | null,
    },
    "form": {
        "created_date": string | null,
        "filled_date": string | null,
        "token": string | null,
        "date": string | null,
        "move": boolean | null,
        "rate": number | null,
        "town": TownInterface | null,
        "full": QuestionInterface[],
        "fees": number | null,
        "travel_time": number | null,
        "portage": string | null,
    },
    "nda": {
        "is_sent": boolean | null,
        "file_id": number | null,
        "created_date": string | null
    },
    "bm": {
        "score": -2 | -1 | 0 | 1 | null,
        "comment": string | null,
        "created_date": string | null,
        "modified_date": string | null
    },
    "ccv": {
        "file_id": number | null,
        "created_date": string | null,
        "modified_date": string | null,
        "is_sent": boolean | null,
    },
    "client": {
        "rate": number | null,
        "score": -2 | -1 | 0 | 1 | null,
        "comment": string | null,
        "created_date": string | null,
        "modified_date": string | null
    },
    "end": {
        "is_winner": boolean | null,
        "end_date": string | null,
    },
}
interface ProcessInterface {
    nTotal: number,
    nWithForm: number,
    nWithNDA: number,
    rows: RowInterface[],
}
const processReducer = (state: ProcessInterface, payload: any) => {
    switch(payload.type) {
        case 'set':
            let n1: number = 0;
            let n2: number = 0;
            for(let row of payload.data) {
                if (row.form.filled_date !== null) {
                    n1 += 1;
                }
                if (row.nda.file_id !== null) {
                    n2 += 1;
                }
            }
            return {
                nTotal: payload.data.length,
                nWithForm: n1,
                nWithNDA: n2,
                rows: payload.data,
            }
        case 'remove-ccv':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        return {...r, ccv: {...r.ccv, file_id: null, created_sdate: null}}
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'remove-nda':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        return {...r, nda: { ...r.nda, file_id: null, created_sdate: null}}
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'update-form':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        if (payload.what === 'rate') {
                            return {...r, form: { ...r.form, rate: payload.value}}
                        } else if (payload.what === 'date') {
                            return {...r, form: { ...r.form, date: payload.value}}
                        } else if (payload.what === 'move') {
                            return {...r, form: { ...r.form, move: payload.value}}
                        } else if (payload.what === 'fees') {
                            return {...r, form: { ...r.form, fees: payload.value}}
                        } else if (payload.what === 'travel_time') {
                            return {...r, form: { ...r.form, travel_time: payload.value}}
                        } else if (payload.what === 'portage') {
                            return {...r, form: { ...r.form, portage: payload.value}}
                        } else {
                            return {...r}
                        }
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'update-ccv':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        return {...r, ccv: { ...r.ccv, file_id: payload.id, created_date: payload.date}}
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'update-client':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        if (payload.what === 'rate') {
                            return {...r, client: { ...r.client, rate: payload.value}}
                        } else {
                            return {...r};    
                        }
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'update-nda':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        return {...r, nda: { ...r.nda, file_id: payload.id, created_sdate: payload.date}}
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'update-score':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        if (payload.nature === 'bm') {
                            return {...r, bm: { ...r.bm, score: payload.value}}
                        } else if (payload.nature === 'client') {
                            return {...r, client: { ...r.client, score: payload.value}}
                        } else {
                            return {...r}    
                        }
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'update-comment':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        if (payload.nature === 'bm') {
                            return {...r, bm: { ...r.bm, comment: payload.value}}
                        } else if (payload.nature === 'client') {
                            return {...r, client: { ...r.client, comment: payload.value}}
                        } else {
                            return {...r}    
                        }
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'update-town':
            return {
                ...state,
                rows: state.rows.map((r: RowInterface) => {
                    if (payload.cv_id === r.header.cv_id) {
                        return {...r, form: { ...r.form, town: {...payload.value} }}
                    } else {
                        return {...r}
                    }
                }),
            }
        case 'reset':
        default:
            return { nTotal: 0, nWithForm: 0, nWithNDA: 0, rows: [] };
    }
}

interface PropsInterface {
    needId: number,
    isOpen: boolean,
}

const Process = ({needId, isOpen}: PropsInterface) => {

    const myContext = useContext(AppContext);
    
    const [lightTheme, setLightTheme] = useState(myContext.colorScheme === 'dark' ? false : true);
    useEffect(() => {
        setLightTheme(myContext.colorScheme === 'dark' ? false : true);
    }, [myContext.colorScheme]);

    const [process, dispatchProcess] =  useReducer(processReducer, { nTotal: 0, nWithForm: 0, nWithNDA: 0, rows: [] });
    const [filteredRows, setFilteredRows] = useState<RowInterface[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [withForm, setWithForm] = useState(true);
    const [withNDA, setWithNDA] = useState(false);

    const [docId,setDocId] = useState(-1);
    const [displayDoc, setDisplayDoc] = useState(false);

    const [cvId, setCvId] = useState(-1);
    const [displayForm, setDisplayForm] = useState(false);
    
    const colors: string[] = ['gray', 'red', 'yellow', 'green'];
    const mois: string[] = ['jan', 'fév', 'mar', 'avr', 'mai', 'jui', 'juil', 'aoû', 'sep', 'oct', 'nov', 'déc'];

    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (loaded) return;
        dispatchProcess({type: 'reset'});
        setWorking(true);
        const api = `${myContext.apiAddress}/get_process_need?need_id=${needId}&what=process`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                if (res.data.data !== null) {
                    dispatchProcess({type: 'set', data: res.data.data});
                    setFilteredRows(sort(res.data.data));
                }
            } else {
                setError(res.data.messsage || 'unknown error');
            }
            setLoaded(true);
            setWorking(false);
        });
    }, [loaded]); // eslint-disable-line

    if (error !== null) {
        <Center pt="lg" pb="lg">
            <Text color="red">{error}</Text>
        </Center>
    }

    useEffect(() => {
        if (process.nTotal === 0) return;
        setFilteredRows(sort(process.rows));
    }, [refresh]); // eslint-disable-line

    useEffect(() => {
        setRefresh(refresh + 1);
    }, [withForm]); // eslint-disable-line

    useEffect(() => {
        setRefresh(refresh + 1);
    }, [withNDA]); // eslint-disable-line

    const sort = (rows: RowInterface[]) => {
        const sorted: RowInterface[] = rows.filter((row: RowInterface) => {
            if ( ( (withForm && (row.form.filled_date !== null || row.bm.comment !== null)) || (!withForm) )
            && ( (withNDA && row.nda.file_id !== null) || (!withNDA) ) ) {
                return true;
            } else {
                return false;
            }
        });
        // on classe dans l'ordre suivant :
        // 1) score du client
        // 2) score du BM
        // 3) TJM du MT
        sorted.sort((a,b) => {
            if (b.client.score === a.client.score) {
                if (b.bm.score === a.bm.score) {
                    if (a.form.rate === b.form.rate) {
                        return 0;
                    } else {
                        return (a.form.rate || 99999) - (b.form.rate || 99999);
                    }
                } else {
                    return (b.bm.score === null ? -3 : b.bm.score) - (a.bm.score === null ? -3 : a.bm.score);
                }
            } else {
                return (b.client.score === null ? -3 : b.client.score) - (a.client.score === null ? -3 : a.client.score);
            }
        });
        return sorted.map((r) => { return {...r}});
    }

    const exportForDocuSign = () => {
        setWorking(true);
        const api = `${myContext.apiAddress}/get_process_need?need_id=${needId}&what=export_ndas`;
        myContext.httpClient.get(api).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                    navigator.clipboard.writeText(res.data.data);
                    InfoNotification({message: 'Données copiées dans le presse papier.'});
                } else {
                    AlertNotification({message: 'The clipboard API is not available'});
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    const formatDate = (s: string) => {
        const t = s.substring(0,10).split('-');
        return parseInt(t[2]) + ' ' + mois[parseInt(t[1])-1] + ' ' + t[0];
    }

    const exportForClipBoard = () => {
        let s: string = '';
        s += '<table><thead><tr>'
            + '<th>Prénom NOM</th>'
            + '<th>MT, TJM €/j HT</th>'
            + '<th>MT, frais €/j HT</th>'
            + '<th>MT, dispo ?</th>'
            + '<th>MT, mobile ?</th>'
            + '<th>MT, maison</th>'
            + '<th>Temps aller (min)</th>'
            + '<th>NDA signé ?</th>'
            + '<th>BM position</th>'
            + '<th>BM commentaire</th>'
            + '<th>CV client inséré</th>'
            + '<th>CV client transmis ?</th>'
            + '<th>Client, TJM €/j HT, hors frais</th>'
            + '<th>Client position</th>'
            + '<th>Client commentaire</th>'
            + '</tr></thead><tbody>';
        for (const row of filteredRows) {
            s +=  '<tr>'
                + '<td>' + (row.header.name) + '</td>'
                + '<td>' + (row.form.rate === null ? '' : row.form.rate) + '</td>'
                + '<td>' + (row.form.fees === null ? '' : row.form.fees) + '</td>'
                + '<td>' + (row.form.date === null ? '' : row.form.date) + '</td>'
                + '<td>' + (row.form.move === null ? '' : (row.form.move ? 'oui' : 'non')) + '</td>'
                + '<td>' + (row.form.town === null ? '' : row.form.town.zipcode + ' ' + row.form.town.label) + '</td>'
                + '<td>' + (row.form.travel_time === null ? '' : row.form.travel_time) + '</td>'
                + '<td>' + (row.nda.file_id === null ? 'non' : 'oui') + '</td>'
                + '<td>' + (row.bm.score === null ? '' : (row.bm.score === 1 ? 'retenu' : (row.bm.score === 0 ? 'peut-être' : (row.bm.score === -1 ? 'non' : '?'))) ) + '</td>'
                + '<td>' + (row.bm.comment === null ? '' : row.bm.comment) + '</td>'
                + '<td>' + (row.ccv.file_id === null ? 'non' : 'oui') + '</td>'
                + '<td>' + (row.ccv.is_sent === null ? '' : (row.ccv.is_sent ? 'oui' : 'non')) + '</td>'
                + '<td>' + (row.client.rate === null ? '' : row.client.rate) + '</td>'
                + '<td>' + (row.client.score === null ? '' : (row.client.score === 1 ? 'retenu' : (row.client.score === 0 ? 'peut-être' : (row.client.score === -1 ? 'non' : '?'))) ) + '</td>'
                + '<td>' + (row.client.comment === null ? '' : row.client.comment) + '</td>'
                + '</tr>';
        }    
        s += '</tbody></table>';
        return s;
    }

    const fnProviderRateToClientRate = (v: number | null) => {
        if (v === null) return '';
        const r1: number = Math.ceil(v / 0.7);
        const r2: number = Math.ceil(v + 400);
        return r1 > r2 ? r2 : r1
    }

    return (
        <>
        <Modal
            opened={displayDoc}
            onClose={() => { setDisplayDoc(false); setDocId(-1)}}
            title={`Curriculum vitae ${docId}`}
            fullScreen
        >
            <DocumentContainer
                documentId={docId}
                isEdition={false}
            />
        </Modal>
        <Modal
            opened={displayForm}
            onClose={() => { setDisplayForm(false); setCvId(-1)}}
            size='lg'
        >
            {cvId !== -1 &&
            <>
            <Group pb="lg">
                <Text>Lien vers le questionnaire</Text>
                <ActionIcon component='a' target='_blank' color='blue' variant='outline'
                    href={`https://snr-digital.com/form/${filteredRows[cvId].form.token}`}
                >
                    <IconExternalLink size={16} />
                </ActionIcon>
            </Group>
            <Text pb="sm" color="dimmed">Questionnaire généré le {filteredRows[cvId].form.created_date}.</Text>
            <Text pb="sm" color="dimmed">
                {filteredRows[cvId].form.filled_date === null ?
                <>Questionnaire non encore rempli.</>
                :
                <>Questionnaire rempli le {filteredRows[cvId].form.filled_date}.</>
                }
            </Text>
            <Text pb="lg">Réponses de {filteredRows[cvId].header.name} :</Text>
            {filteredRows[cvId].form.full !== null && 
            <>
            {filteredRows[cvId].form.full.map((q: QuestionInterface, i: number) => (
                <Stack key={`form-q-${i}`} spacing={0} mt='xs'>
                    <Text color="dimmed">{q.label}</Text>
                    {typeof(q.value) === 'string' ?
                    <>
                    {q.value.split('\n').map((s:string, j: number) => 
                    <Text key={`cv-${cvId}-form-${i}-${j}`}>{s}</Text>
                    )}
                    </>
                    :
                    <Text>{q.value}</Text>
                    }
                </Stack>
            ))}
            </>
            }
            </>
            }
        </Modal>
        <Stack>
            <LoadingOverlay visible={working} />
            <Group position="apart" pt="xs">
                <Group>
                    <Badge>{process.nTotal} <IconUsers size={12} /> </Badge>
                    <Badge>{process.nWithForm} <IconWriting size={12} /> </Badge>
                    <Badge>{process.nWithNDA} NDA </Badge>
                </Group>
                <Group>
                    <Switch checked={withForm} size='xs'
                        onChange={(event) => setWithForm(event.currentTarget.checked)} 
                        label='questionnaire rempli ou avis' 
                    />
                    <Divider orientation="vertical" />
                    <Switch checked={withNDA} size='xs'
                        onChange={(event) => setWithNDA(event.currentTarget.checked)} 
                        label='NDA reçu' 
                    />
                    <Divider orientation="vertical" />
                    <ActionIcon color="blue" variant="outline" onClick={() => setLoaded(false)}>
                        <IconRefresh size={16} />
                    </ActionIcon>
                    <Divider orientation="vertical" />
                    <ActionIcon color="blue" variant="outline" onClick={() => setFilteredRows(sort(process.rows))}>
                        <IconSortDescending size={16} />
                    </ActionIcon>
                    <Divider orientation="vertical" />
                    <Button color="blue" variant="outline" size='xs' onClick={() => exportForDocuSign()}>
                        Données DocuSign
                    </Button>
                    <CopyButton value={exportForClipBoard()}>
                        {({ copied, copy }) => (
                        <Button size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                            {copied ? 'Données copiées' : 'Copier'}
                        </Button>
                        )}
                    </CopyButton>
                </Group>
            </Group>
            {loaded &&
            <ScrollArea style={{width: 'calc(100vw - 100px)', height: 'calc(100vh - 230px)'}}>
                <Table highlightOnHover style={{width: '2270px'}}>
                    <thead>
                        <tr style={{
                                position: 'sticky', top: '0',
                                zIndex: '10',
                                backgroundColor: lightTheme ? 'white' : '#1A1B1E'  
                            }}
                        >
                            <th style={{width: '110px'}}>Prénom NOM</th>
                            <th style={{width: '90px'}}>CV</th>
                            <th style={{width: '80px', textAlign: 'right'}}>€/j HT</th>
                            <th style={{width: '80px', textAlign: 'right'}}>frais</th>
                            <th style={{width: '80px', textAlign: 'center'}}>Dispo ?</th>
                            <th style={{width: '80px', textAlign: 'center'}}>Mobile ?</th>
                            <th style={{width: '100px', textAlign: 'left'}}>Maison</th>
                            <th style={{width: '80px', textAlign: 'right'}}>Tps aller</th>
                            <th style={{width: '120px', textAlign: 'center'}}>Portage</th>
                            <th style={{width: '90px'}}>NDA</th>
                            <th style={{width: '70px'}}>Test psycho</th>
                            <th style={{width: '70px'}}>BM</th>
                            <th style={{width: '400px'}}>BM comment</th>
                            <th style={{width: '90px'}}>CV client</th>
                            <th style={{width: '80px', textAlign: 'center'}}>CV sent</th>
                            <th style={{width: '80px', textAlign: 'right'}}>€/j HT</th>
                            <th style={{width: '70px'}}>Client</th>
                            <th style={{width: '300px'}}>Client comment</th>
                            <th style={{width: '80px', textAlign: 'center'}}>contrat</th>
                            <th style={{width: '110px'}}>Prénom NOM</th>
                        </tr>
                    </thead>
                    {isOpen ?
                    <tbody>
                        {filteredRows.map((row: RowInterface, i: number) => (
                        <tr key={`row-cv-${row.header.cv_id}`}>
                            <td style={{verticalAlign: 'top'}}>
                                <Text>{row.header.name}</Text>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <Group spacing={5}>
                                    <ActionIcon color="blue" variant="outline" onClick={() => { setDocId(row.header.cv_id); setDisplayDoc(true) }}>
                                        <IconFileDescription size={16} />
                                    </ActionIcon>
                                    <ActionIcon color="blue" variant="outline" onClick={() => { setCvId(i); setDisplayForm(true)}}>
                                        <IconExternalLink size={16} />
                                    </ActionIcon>
                                </Group>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.filled_date !== null &&
                                <ValueNumber
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    field='form_rate'
                                    value={row.form.rate}
                                    handleChange={(v: number) => dispatchProcess({type: 'update-form', cv_id: row.header.cv_id, what: 'rate', value: v}) }
                                    fnOnValue={fnProviderRateToClientRate}
                                />
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <ValueNumber
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    field='form_fees'
                                    value={row.form.fees}
                                    handleChange={(v: number) => dispatchProcess({type: 'update-form', cv_id: row.header.cv_id, what: 'fees', value: v}) }
                                    fnOnValue={(v: number) => { return v; }}
                                />
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.filled_date !== null && 
                                <ValueDate
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    value={row.form.date}
                                    handleChange={(v: string) => dispatchProcess({type: 'update-form', cv_id: row.header.cv_id, what: 'date', value: v}) }
                                />
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.move !== null &&
                                <ValueBoolean
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    field='form_move'
                                    value={row.form.move}
                                    handleChange={(v: boolean) => dispatchProcess({type: 'update-form', cv_id: row.header.cv_id, what: 'move', value: v}) }
                                />
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <ValueTown
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    field='form_town_id'
                                    value={row.form.town}
                                    handleChange={(v: TownInterface) => dispatchProcess({type: 'update-town', cv_id: row.header.cv_id, value: v}) }
                                />
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <ValueNumber
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    field='form_travel_time'
                                    value={row.form.travel_time}
                                    handleChange={(v: number) => dispatchProcess({type: 'update-form', cv_id: row.header.cv_id, what: 'travel_time', value: v}) }
                                    fnOnValue={(v: number) => {
                                        const h = Math.floor(v / 60);
                                        const m = v - 60 * h;
                                        return h + 'h' + (m < 10 ? '0' + m : m);
                                    }}
                                />
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <ValuePortage
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    name={row.header.name}
                                    value={row.form.portage}
                                    handleChange={(v: number) => dispatchProcess({type: 'update-form', cv_id: row.header.cv_id, what: 'portage', value: v}) }
                                />
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {(row.form.filled_date !== null || (row.bm.score !== null && [1,0].includes(row.bm.score))) &&
                                <Stack spacing={0}>
                                    <File 
                                        needId={needId} 
                                        cvId={row.header.cv_id}
                                        name={row.header.name}
                                        nature='nda'
                                        fileId={row.nda.file_id}
                                        handleAdd={(fid: number, date: string) => dispatchProcess({type: 'update-nda', cv_id: row.header.cv_id, what: 'nda', id: fid, date: date})}
                                        handleRemove={() => dispatchProcess({type: 'remove-nda', cv_id: row.header.cv_id})}
                                    />
                                    <Text size='xs'>{toFrenchDate(row.nda.created_date?.substring(0,10))}</Text>
                                </Stack>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {(row.form.filled_date !== null || (row.bm.score !== null && [1,0].includes(row.bm.score))) &&
                                <MBTI cv_id={row.header.cv_id} value={row.header.mbti} />
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <Choice 
                                    needId={needId} 
                                    cvId={row.header.cv_id} 
                                    nature='bm' 
                                    value={row.bm.score}
                                    handle={(v: number) => dispatchProcess({type: 'update-score', cv_id: row.header.cv_id, nature: 'bm', value: v})}
                                />
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <Comment
                                    needId={needId} 
                                    cvId={row.header.cv_id}
                                    name={row.header.name}
                                    nature='bm'
                                    value={row.bm.comment}
                                    handle={(s: string) => dispatchProcess({type: 'update-comment', cv_id: row.header.cv_id, nature: 'bm', value: s})}
                                />
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {(row.form.filled_date !== null || (row.bm.score !== null && [1].includes(row.bm.score))) &&
                                <Stack spacing={0}>
                                    <File 
                                        needId={needId} 
                                        cvId={row.header.cv_id}
                                        name={row.header.name}
                                        nature='ccv'
                                        fileId={row.ccv.file_id}
                                        handleAdd={(fid: number, date: string) => { dispatchProcess({type: 'update-ccv', cv_id: row.header.cv_id, what: 'ccv', id: fid, date: date}); }}
                                        handleRemove={() => { dispatchProcess({type: 'remove-ccv', cv_id: row.header.cv_id}) }}
                                    />
                                    <Text size='xs'>{toFrenchDate(row.ccv.created_date?.substring(0,10))}</Text>
                                </Stack>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {(row.form.filled_date !== null || (row.bm.score !== null && [1].includes(row.bm.score))) &&
                                <ValueBoolean
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    field='ccv_is_sent'
                                    value={row.ccv.is_sent}
                                    handleChange={(v: boolean) => dispatchProcess({type: 'update-ccv', cv_id: row.header.cv_id, what: 'is_sent', value: v}) }
                                />
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {(row.form.filled_date !== null || (row.bm.score !== null && [1].includes(row.bm.score))) &&
                                <ValueNumber
                                    needId={needId}
                                    cvId={row.header.cv_id}
                                    field='client_rate'
                                    value={row.client.rate || 0}
                                    handleChange={(v: number) => dispatchProcess({type: 'update-client', cv_id: row.header.cv_id, what: 'rate', value: v}) }
                                    fnOnValue={(v: number | null) => {
                                        if (v === null) return '';
                                        const m = Math.ceil(Math.min(v * 0.3, 400));
                                        return v - m;
                                    }}
                                />
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {(row.ccv.is_sent || row.client.score !== null) &&
                                <Choice 
                                    needId={needId} 
                                    cvId={row.header.cv_id} 
                                    nature='client' 
                                    value={row.client.score} 
                                    handle={(v: number) => dispatchProcess({type: 'update-score', cv_id: row.header.cv_id, nature: 'client', value: v})}
                                />
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {(row.ccv.is_sent || row.client.comment !== null) &&
                                <Comment
                                    needId={needId} 
                                    cvId={row.header.cv_id}
                                    name={row.header.name}
                                    nature='client'
                                    value={row.client.comment}
                                    handle={(v: string) => dispatchProcess({type: 'update-comment', cv_id: row.header.cv_id, nature: 'client', value: v})}
                                />
                                }
                            </td>
                            <td>
                                {(row.client.score === 1) &&
                                <Contract 
                                    need_id={needId}
                                    cv_id={row.header.cv_id}
                                />
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <Text>{row.header.name}</Text>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    :
                    <tbody>
                        {filteredRows.map((row: RowInterface, i: number) => (
                        <tr key={`row-cv-${row.header.cv_id}`}>
                            <td style={{verticalAlign: 'top'}}>
                                <Text>{row.header.name}</Text>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <Group spacing={5}>
                                    <ActionIcon color="blue" variant="outline" onClick={() => { setDocId(row.header.cv_id); setDisplayDoc(true) }}>
                                        <IconFileDescription size={16} />
                                    </ActionIcon>
                                    <ActionIcon color="blue" variant="outline" onClick={() => { setCvId(i); setDisplayForm(true)}}>
                                        <IconExternalLink size={16} />
                                    </ActionIcon>
                                </Group>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.rate !== null && 
                                <>
                                <Text align="right">{row.form.rate.toLocaleString()}</Text>
                                <Text align="right" color="dimmed">{(row.form.rate * 1.3).toLocaleString()}</Text>
                                </>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.fees !== null && 
                                <>
                                <Text align="right">{row.form.fees.toLocaleString()}</Text>
                                </>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.date !== null && 
                                <Center>
                                    <Text align='center'>{formatDate(row.form.date)}</Text>
                                </Center>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.move !== null &&
                                <Center><ColorSwatch color={row.form.move ? 'green' : 'red'} size={12}/></Center>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.town !== null && 
                                <Stack>
                                    <Text color="dimmed" size="xs">{row.form.town.zipcode}</Text>
                                    <Text>{row.form.town.label}</Text>
                                </Stack>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.travel_time !== null && 
                                <>
                                <Text align="right">{row.form.travel_time.toLocaleString()}</Text>
                                </>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.form.portage !== null && 
                                <>
                                <Text align="center">{row.form.portage}</Text>
                                </>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.nda.file_id !== null &&
                                <Stack>
                                    <Document 
                                        needId={needId} 
                                        fileId={row.nda.file_id}
                                    />
                                    <Text size='xs'>{toFrenchDate(row.nda.created_date?.substring(0,10))}</Text>
                                </Stack>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <MBTI cv_id={row.header.cv_id} value={row.header.mbti} />
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.bm.score !== null &&
                                <Center>
                                    <ColorSwatch color={colors[row.bm.score + 2]} size={12} />
                                </Center>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.bm.comment !== null &&
                                <Spoiler maxHeight={100} showLabel="show more" hideLabel="hide">
                                    <Text>{row.bm.comment}</Text>
                                </Spoiler>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.ccv.file_id !== null &&
                                <Stack>
                                    <Document 
                                        needId={needId} 
                                        fileId={row.ccv.file_id}
                                    />
                                    <Text size='xs'>{toFrenchDate(row.ccv.created_date?.substring(0,10))}</Text>
                                </Stack>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.ccv.is_sent !== null &&
                                <Center><ColorSwatch color={row.ccv.is_sent ? 'green' : 'red'} size={12}/></Center>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.client.rate !== null && 
                                <>
                                <Text align="right">{row.client.rate.toLocaleString()}</Text>
                                <Text align="right" color="dimmed">{(row.client.rate * 0.7).toLocaleString()}</Text>
                                </>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.client.score !== null &&
                                <Center>
                                    <ColorSwatch color={colors[row.client.score + 2]} size={12} />
                                </Center>
                                }
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {row.client.comment !== null &&
                                <Spoiler maxHeight={100} showLabel="show more" hideLabel="hide">
                                    <Text>{row.client.comment}</Text>
                                </Spoiler>
                                }
                            </td>
                            <td></td>
                            <td style={{verticalAlign: 'top'}}>
                                <Text>{row.header.name}</Text>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    }
                </Table>
            </ScrollArea>
            }
        </Stack>
        </>
    )
}

export { Process }
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { ActionIcon, Button, Card, Center, Checkbox, Divider, Drawer, Group, LoadingOverlay, Modal, NumberInput, ScrollArea, Stack, Table, Text, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { IconAdjustmentsAlt, IconBuildingFactory2, IconBuildingBank, IconArrowsHorizontal, IconArrowUp, IconAdjustments, IconLasso, IconRefresh, IconUserCircle } from "@tabler/icons";
import { TitleContainer } from "../shared/TitleContainer";
import { AlertNotification } from "../shared/AlertNotification";
import { toFrenchDate } from "../../services/functions";
import AppContext from "../shared/AppContext"
import { DocumentButton } from "../mission/ManageMission/document_button";
import { LetteringBadge } from "./lettering_badge";

interface DeltaInterface {
    debit: string,
    credit: string,
    comment: string,
}

interface RowInterface {
    id: number,
    index: number,
    date: number,
    label: string,
    debit: number,
    credit: number,
    currency: string,
    lettering: number | null,
    category: 'bank' | 'bill' | 'invoice',
    mission_id: number,
}

// liste des flux financiers relatifs aux factures (fournisseurs et clients)
const rowsReducerA = (state: RowInterface[], payload: any) => {
    switch(payload.type){
        case 'SET':
            return payload.data;
        case 'SET_LETTER':
            const letteringId: number = payload.id;
            const ids: number[] = payload.ids;
            return state.map((row: RowInterface, i: number) => {
                return {...row, lettering: ids.includes(i) ? letteringId : row.lettering}
            });
        default:
            return [];
    }
}

// liste des flux financiers en provenance de la banque
const rowsReducerB = (state: RowInterface[], payload: any) => {
    switch(payload.type){
        case 'SET':
            return payload.data;
        case 'SET_LETTER':
            const letteringId: number = payload.id;
            const ids: number[] = payload.ids;
            return state.map((row: RowInterface, i: number) => {
                return {...row, lettering: ids.includes(i) ? letteringId : row.lettering}
            });
        default:
            return [];
    }
}

const BankLettering = () => {

    const myContext = useContext(AppContext);
    
    const defaultDateEnd: Date = new Date();
    const defaultDateStart: Date = new Date(new Date().setDate(new Date().getDate()-90));

    const [workingA, setWorkingA] = useState(true);
    const [rowsA, dispatchRowsA] = useReducer(rowsReducerA, []);
    const [dateStartA, setDateStartA] = useState<Date>(new Date( defaultDateStart.getTime() ));
    const [dateEndA, setDateEndA] = useState<Date>(new Date( defaultDateEnd.getTime() ));
    const [seuilMinA, setSeuilMinA] = useState(1);
    const [seuilMaxA, setSeuilMaxA] = useState(200000);
    const [searchA, setSearchA] = useState('');
    const [indexesA, setIndexesA] = useState<number[]>([]);

    const [workingB, setWorkingB] = useState(true);
    const [rowsB, dispatchRowsB] = useReducer(rowsReducerB, []);
    const [dateStartB, setDateStartB] = useState<Date>(new Date( defaultDateStart.getTime() ));
    const [dateEndB, setDateEndB] = useState<Date>(new Date( defaultDateEnd.getTime() ));
    const [seuilMinB, setSeuilMinB] = useState(1);
    const [seuilMaxB, setSeuilMaxB] = useState(200000);
    const [searchB, setSearchB] = useState('');
    const [indexesB, setIndexesB] = useState<number[]>([]);

    const [synchronized, setSynchronized] = useState(true);

    const [totalA, setTotalA] = useState(0);
    const [totalB, setTotalB] = useState(0);
    const [matching, setMatching] = useState(false);
    const matchingStartIndex = useRef(-1);

    const [opened, setOpened] = useState(false);

    const [deltaRow, setDeltaRow] = useState<null | DeltaInterface>(null);
    const [deltaOpened, setDeltaOpened] = useState(false);

    // load A
    const loadRows = (nature: 'A' | 'B') => {
        const api = `${myContext.apiAddress}/bank_lettering`;
        const F = new FormData();
        if (nature === 'A') {
            setWorkingA(true);
            F.append('action', 'list_not_bank');
            F.append('date_min', dateStartA.toISOString().split('T')[0]);
            F.append('date_max', dateEndA.toISOString().split('T')[0]);
            F.append('seuil_min', seuilMinA + '');
            F.append('seuil_max', seuilMaxA + '');
        } else {
            setWorkingB(true);
            F.append('action', 'list_bank');
            F.append('date_min', dateStartB.toISOString().split('T')[0]);
            F.append('date_max', dateEndB.toISOString().split('T')[0]);
            F.append('seuil_min', seuilMinB + '');
            F.append('seuil_max', seuilMaxB + '');
        }
        myContext.httpClient.post(api, F).then((res: any) => {
            setWorkingA(false);
            setWorkingB(false);
            if (res.data.status === true) {
                if (nature === 'A') {
                    dispatchRowsA({type: 'SET', data: res.data.data || []});
                } else {
                    dispatchRowsB({type: 'SET', data: res.data.data || []});
                }
            } else {
                AlertNotification({ message: res.data.message || 'unknown error' });
            }
        });
    }

    // on sauvegarde une association
    const saveLettering = () => {
        if (rowsA.length === 0 || rowsB.length === 0) return;
        setWorkingA(true);
        setWorkingB(true);
        const api = `${myContext.apiAddress}/bank_lettering`;
        const F = new FormData();
        const a: number[] = [];
        const b: number[] = [];
        for (const r of indexesA) {
            a.push(rowsA[r].id);
        }
        for (const r of indexesB) {
            b.push(rowsB[r].id);
        }
        F.append('ids_a', a.join(','));
        F.append('ids_b', b.join(','));
        F.append('total_a', Math.round(totalA) + '');
        F.append('total_b', Math.round(totalB) + '');
        if (deltaRow !== null) {
            F.append('delta_debit', Math.round(100*parseFloat(deltaRow.debit)).toFixed(0));
            F.append('delta_credit', Math.round(100*parseFloat(deltaRow.credit)).toFixed(0));
            F.append('delta_comment', deltaRow.comment);
        }
        F.append('action', 'do_lettering');
        myContext.httpClient.post(api, F).then((res:any) => {
            setWorkingA(false);
            setWorkingB(false);
            if (res.data.status === true) {
                dispatchRowsA({type: 'SET_LETTER', id: res.data.data.id, ids: indexesA});
                dispatchRowsB({type: 'SET_LETTER', id: res.data.data.id, ids: indexesB});
                setIndexesA([]);
                setIndexesB([]);
                setTotalA(0);
                setTotalB(0);
                setDeltaRow(null);
                setMatching(false);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    // on charge les données au démarrage
    useEffect(() => {
        loadRows('A');
        loadRows('B');
    }, []); // eslint-disable-line

    // on enregistre une sélection
    const handleCheckbox = (nature: 'A' | 'B', index: number, value: boolean) => {
        let a: number[] = [...indexesA];
        let b: number[] = [...indexesB];
        if (nature === 'A') {
            if (value) {
                a = a.concat([index]);
                setIndexesA([...a]);
            } else {
                a = a.filter((i) => i !== index);
                setIndexesA([...a]);
            }
        } else {
            if (value) {
                b = b.concat([index]);
                setIndexesB([...b]);
            } else {
                b = b.filter((i) => i !== index);
                setIndexesB([...b]);
            }
        }
        let t: boolean = false;
        let ad: number = 0;
        let ac: number = 0;
        let bd: number = 0;
        let bc: number = 0;    
        if (a.length > 0) {
            for (const i of a) {
                ad += rowsA[i].debit;
                ac += rowsA[i].credit;
            }
        }
        if (b.length > 0) {
            for (const i of b) {
                bd += rowsB[i].debit;
                bc += rowsB[i].credit;
            }
        }
        ad = Math.round(ad * 1000) / 1000;
        ac = Math.round(ac * 1000) / 1000;
        bd = Math.round(bd * 1000) / 1000;
        bc = Math.round(bc * 1000) / 1000;
        if ((ad < 0 && ac === 0 && ad === bd + bc) || (ad === 0 && ac > 0 && ac === bd + bc)) {
            t = true;
        } else {
            console.log(`ad= ${ad}, ac= ${ac} AND bd + bc = ${bd} + ${bc} = ${bd+bc}`);
        }
        setTotalA(ad + ac);
        setTotalB(bd + bc);
        setMatching(t);
    }

    // on part des factures
    // des plus anciennes aux plus récentes
    // en écartant les factures lettrées
    // et on essaye de trouver un match
    const findMatch = () => {
        let i0: number = matchingStartIndex.current + 1;
        matchingStartIndex.current = i0;
        if (i0 >= rowsA.length) {
            i0 = 0;
            matchingStartIndex.current = 0;
        }
        for (let i=i0;i<rowsA.length;i++) {
            const a = rowsA[i];
            if (a.lettering !== null) {
                continue;
            }
            const c: boolean = a.category === 'bill' ? false : true;
            let m: number = c ? a.credit : a.debit;
            if (m === 0) {
                continue;
            }
            for (let j=0;j<rowsB.length;j++) {
                const b = rowsB[j];
                if (b.lettering !== null) {
                    continue;
                }
                if ((c && b.credit === m && b.debit === 0) || (!c && b.debit === m && b.credit === 0)) {
                    let ok: boolean = false;
                    // on transforme en une suite de mots
                    const sa: string[] = a.label.toUpperCase().replace(/[^a-z0-9:_-]/gmi, " ").replace(/\s+/g, " ").split(' ');
                    const sb: string[] = b.label.toUpperCase().replace(/[^a-z0-9:_-]/gmi, " ").replace(/\s+/g, " ").split(' ');
                    // on cherche la référence dans la facture
                    let ref: string = '';
                    for (let ia=0;ia<sa.length;ia++){
                        if (sa[ia] === 'REF:' && ia+1 < sa.length) {
                            if (sa[ia+1].length > 4) {
                                ref = sa[ia+1];
                                break;
                            }
                        }
                    }
                    if (ref !== '') {
                        for (const s of sb) {
                            if (s === ref) {
                                ok = true;
                                break;
                            }
                        }
                    }
                    if (ok) {
                        matchingStartIndex.current = i;
                        m = Math.abs(m);
                        setIndexesA([i]);
                        setIndexesB([j]);
                        setTotalA(m);
                        setTotalB(m);
                        setMatching(true);
                        document.getElementById(`row-a-${rowsA[i].id}`)?.scrollIntoView();
                        document.getElementById(`row-b-${rowsB[j].id}`)?.scrollIntoView();
                        return;
                    }
                }
            }
        }
        matchingStartIndex.current = 0;
    }

    useEffect(() => {
        if (deltaOpened === true) return;
        if (deltaRow === null) return;
        if (Math.round(100*(totalA + parseFloat(deltaRow.debit) - totalB - parseFloat(deltaRow.credit))) === 0) {
            setMatching(true);
        } else {
            setMatching(false);
        }
    }, [deltaOpened]); // eslint-disable-line

    return (
        <>
        <Drawer
            opened={opened}
            onClose={() => setOpened(false)}
            title="Réglages"
            position='right'
            padding='xs'
        >
            <ScrollArea style={{height: 'calc(100vh - 60px)'}}>
                <Divider mt='sm' mb='xs' label='Factures' />
                <NumberInput
                    label="Seuil minimal"
                    value={seuilMinA}
                    onChange={(v) => { if (v !== undefined) {setSeuilMinA(v)}}}
                    min={0} max={999999}
                    withAsterisk
                />
                <NumberInput
                    label="Seuil maximal"
                    value={seuilMaxA}
                    onChange={(v) => { if (v !== undefined) {setSeuilMaxA(v)}}}
                    min={0} max={999999}
                    withAsterisk
                />
                <DatePicker 
                    label="Date de création de début"
                    value={dateStartA} 
                    onChange={(v) => { if (v !== null) { setDateStartA(v) } }}
                    withAsterisk 
                />
                <DatePicker 
                    label="Date de création de fin"
                    value={dateEndA} 
                    onChange={(v) => { if (v !== null) { setDateEndA(v) } }}
                    withAsterisk 
                />
                <Divider mt='sm' mb='xs' label='Banque' />
                <NumberInput
                    label="Seuil minimal"
                    value={seuilMinB}
                    onChange={(v) => { if (v !== undefined) {setSeuilMinB(v)}}}
                    min={0} max={999999}
                    withAsterisk
                />
                <NumberInput
                    label="Seuil maximal"
                    value={seuilMaxB}
                    onChange={(v) => { if (v !== undefined) {setSeuilMaxB(v)}}}
                    min={0} max={999999}
                    withAsterisk
                />
                <DatePicker 
                    label="Date de valeur de début"
                    value={dateStartB} 
                    onChange={(v) => { if (v !== null) { setDateStartB(v) } }}
                    withAsterisk 
                />
                <DatePicker 
                    label="Date de valeur de fin"
                    value={dateEndB} 
                    onChange={(v) => { if (v !== null) { setDateEndB(v) } }}
                    withAsterisk 
                />
            </ScrollArea>
        </Drawer>

        <Modal
            opened={deltaOpened}
            onClose={() => {
                setDeltaOpened(false);
                setDeltaRow(null);
            }}
        >
            <Text>Enregistrer une écriture afin d'équilibrer les montants sélectionnés :</Text>
            <TextInput 
                label='Montant à ajouter au débit (si trop facturé par exemple)'
                value={deltaRow === null ? '' : deltaRow.debit} 
                onChange={(event) => {
                    let v = event.currentTarget.value.replace(',','.').trim();
                    setDeltaRow(deltaRow === null ? {
                            debit: v,
                            credit: '',
                            comment: '',
                        } : {
                            ...deltaRow,
                            debit: v,
                        }
                    );
                }}
                withAsterisk
            />
            <TextInput 
                label='Montant à ajouter au crédit (si pas assez facturé par exemple)'
                value={deltaRow === null ? '' : deltaRow.credit.toString()} 
                onChange={(event) => {
                    let v = event.currentTarget.value.replace(',','.').trim();
                    setDeltaRow(deltaRow === null ? {
                            debit: '',
                            credit: v,
                            comment: '',
                        } : {
                            ...deltaRow,
                            credit: v,
                        }
                    );
                }}
                withAsterisk
            />
            <TextInput 
                label='Commentaire'
                value={deltaRow === null ? '' : deltaRow.comment} 
                onChange={(event) => {
                    const v = event.currentTarget.value;
                    setDeltaRow(deltaRow === null ? {
                            debit: '',
                            credit: '',
                            comment: v,
                        } : {
                            ...deltaRow,
                            comment: v,
                        }
                    );
                }}
                withAsterisk
            />
            <Center p='xs'>
                <Button color='blue' variant='outline'
                    disabled={
                        deltaRow === null || 
                        /^[0-9]{1,5}(\.[0-9]{0,2}){0,1}$/.test(deltaRow.debit) === false ||
                        /^[0-9]{1,5}(\.[0-9]{0,2}){0,1}$/.test(deltaRow.credit) === false ||
                        deltaRow.comment.trim().length < 5 ||
                        (parseFloat(deltaRow.debit) !== 0 && parseFloat(deltaRow.credit) !== 0)
                    }
                    onClick={() => setDeltaOpened(false)}
                >
                    Valider cet ajout
                </Button>
            </Center>
        </Modal>

        <Group position="apart">
            <TitleContainer>
                Editeur pour le rapprochement bancaire
            </TitleContainer>
            <Group>
                <TextInput 
                    label='Rechercher dans les factures'
                    size='xs'
                    value={searchA}
                    onChange={(event) => {
                        const s: string = event.currentTarget.value.trim();
                        setSearchA(s);
                        if (synchronized) {
                            setSearchB(s);
                        }
                    }}
                />
                <ActionIcon 
                    color='blue'
                    variant='outline'
                    onClick={() => {
                        if (synchronized) {
                            setSynchronized(false);
                        } else {
                            setSynchronized(true);
                            setSearchB(searchA);
                        }
                    }}
                >
                    {synchronized ?
                    <IconArrowsHorizontal size={16} />
                    :
                    <IconArrowUp size={16} /> 
                    }
                </ActionIcon>
                <TextInput 
                    label='Rechercher dans la banque'
                    size='xs'
                    value={searchB}
                    onChange={(event) => {
                        const s: string = event.currentTarget.value.trim();
                        setSearchB(s);
                        if (synchronized) {
                            setSearchA(s);
                        }
                    }}
                />
                <ActionIcon color='blue' variant='outline' onClick={() => setOpened(true)}>
                    <IconAdjustments size={16} />
                </ActionIcon>
                <ActionIcon color='blue' variant='outline' onClick={() => { loadRows('A'); loadRows('B'); setIndexesA([]); setIndexesB([]); setMatching(false); setTotalA(0); setTotalB(0); }}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
        </Group>

        <ScrollArea style={{height: 'calc(50vh - 100px)'}}>
            <LoadingOverlay visible={workingA} />
            <Table highlightOnHover withBorder verticalSpacing={0} horizontalSpacing={0}>
                <thead>
                    <tr>
                        <th style={{width: '20px'}}></th>
                        <th style={{width: '70px'}}>Date</th>
                        <th>Description</th>
                        <th style={{width: '90px'}}>Débit</th>
                        <th style={{width: '90px'}}>Crédit</th>
                        <th style={{width: '110px'}}>Lettrage</th>
                    </tr>
                </thead>
                <tbody>
                    {rowsA.filter((item:RowInterface) => searchA.length < 3 || item.label.includes(searchA)).map((item: RowInterface) => 
                    <tr id={`row-a-${item.id}`} key={`row-a-${item.id}`}>
                        <td>
                            {item.category === 'bill' ?
                            <IconUserCircle size={12} />
                            :
                            <IconBuildingFactory2 size={12} />
                            }
                        </td>
                        <td>
                            <Text size='xs'>{toFrenchDate(item.date)}</Text>
                        </td>
                        <td>
                            <Text size='xs'>{item.label}</Text>
                        </td>
                        <td>
                            <Text size='xs' align='right'>{item.debit === 0 ? '' : item.debit.toLocaleString('fr-FR', { style: 'currency', currency: item.currency })}</Text>
                        </td>
                        <td>
                            <Text size='xs' align='right'>{item.credit === 0 ? '' : item.credit.toLocaleString('fr-FR', { style: 'currency', currency: item.currency })}</Text>
                        </td>
                        <td>
                            <Group position='apart'>
                                {item.lettering === null ?
                                <Text pl='sm'>
                                    <Checkbox checked={indexesA.includes(item.index)} 
                                        onChange={(event) => {
                                            handleCheckbox('A', item.index, event.currentTarget.checked);
                                        }}
                                    />
                                </Text>
                                :
                                <Text pl='xs'><LetteringBadge letter={`L${item.lettering}`} /></Text>
                                }
                                <DocumentButton 
                                    title=''
                                    category={item.category}
                                    missionId={item.mission_id}
                                    fileId={item.id}
                                    handleChange={null}
                                    missionOpened={item.lettering === null ? true : false}
                                />
                            </Group>
                        </td>
                    </tr>
                    )}
                </tbody>
            </Table>
        </ScrollArea>

        <ScrollArea style={{height: 'calc(50vh - 100px)'}}>
            <LoadingOverlay visible={workingB} />
            <Table highlightOnHover withBorder verticalSpacing={0} horizontalSpacing={0}>
                <thead>
                    <tr>
                        <th style={{width: '20px'}}></th>
                        <th style={{width: '70px'}}>Date</th>
                        <th>Description</th>
                        <th style={{width: '90px'}}>Débit</th>
                        <th style={{width: '90px'}}>Crédit</th>
                        <th style={{width: '110px'}}>Lettrage</th>
                    </tr>
                </thead>
                <tbody>
                    {rowsB.filter((item:RowInterface) => searchB.length < 3 || item.label.includes(searchB)).map((item: RowInterface) => 
                    <tr id={`row-b-${item.id}`} key={`row-b-${item.id}`}>
                        <td>
                            <IconBuildingBank size={12} />
                        </td>
                        <td>
                            <Text size='xs'>{toFrenchDate(item.date)}</Text>
                        </td>
                        <td>
                            <Text size='xs'>{item.label}</Text>
                        </td>
                        <td>
                            <Text size='xs' align='right'>{item.debit === 0 ? '' : item.debit.toLocaleString('fr-FR', { style: 'currency', currency: item.currency })}</Text>
                        </td>
                        <td>
                            <Text size='xs' align='right'>{item.credit === 0 ? '' : item.credit.toLocaleString('fr-FR', { style: 'currency', currency: item.currency })}</Text>
                        </td>
                        <td>
                            {item.lettering === null ?
                            <Text pl='sm'>
                                <Checkbox checked={indexesB.includes(item.index)} 
                                    onChange={(event) => {
                                        handleCheckbox('B', item.index, event.currentTarget.checked);
                                    }}
                                />
                            </Text>
                            :
                            <Text align='left' pl='xs'><LetteringBadge letter={`L${item.lettering}`} /></Text>
                            }
                        </td>
                    </tr>
                    )}
                </tbody>
            </Table>
        </ScrollArea>
        <Group position='apart' m={5}>
            <LoadingOverlay visible={workingA || workingB} />
            <Group style={{width: 'calc(100% - 80px)'}}>
                {indexesA.map((i) => 
                <Card key={`match-a-${i}`} style={{width: 'min(100%,350px)'}} p={3}>
                    <Group>
                        {rowsA[i].category === 'bill' ?
                        <IconUserCircle size={12} />
                        :
                        <IconBuildingFactory2 size={12} />
                        }
                        <Text size='xs'>{(rowsA[i].debit + rowsA[i].credit).toLocaleString('fr-FR', { style: 'currency', currency: rowsA[i].currency })}</Text>
                    </Group>
                    <ScrollArea style={{height: '35px'}}>
                        <Text size='xs'>{rowsA[i].label}</Text>
                    </ScrollArea>
                </Card>
                )}
                {indexesB.map((i) => 
                <Card key={`match-b-${i}`} style={{width: 'min(100%,350px)'}} p={3}>
                    <Group>
                        <IconBuildingBank size={12} />
                        <Text size='xs'>{(rowsB[i].debit + rowsB[i].credit).toLocaleString('fr-FR', { style: 'currency', currency: rowsB[i].currency })}</Text>
                    </Group>
                    <ScrollArea style={{height: '35px'}}>
                        <Text size='xs'>{rowsB[i].label}</Text>
                    </ScrollArea>
                </Card>
                )}
                {((totalA !== 0 || totalB !== 0) && Math.ceil(1000 * (totalA-totalB)) !== 0) &&
                <Card p={3}>
                    <ActionIcon color="blue" variant="outline" onClick={() => setDeltaOpened(true)}>
                        <IconAdjustmentsAlt size={16} />
                    </ActionIcon>
                </Card>
                }
                <Stack align="center" spacing={0}>
                    {deltaRow === null ?
                    <Text size='xs'>
                        {totalA.toFixed(2)} - {totalB.toFixed(2)} = {(totalA-totalB).toFixed(2)}
                    </Text>
                    :
                    <Text size='xs'>
                        ({totalA.toFixed(2)}+{deltaRow.debit}) - ({totalB.toFixed(2)}+{deltaRow.credit}) = {(totalA+parseFloat(deltaRow.debit)-totalB-parseFloat(deltaRow.credit)).toFixed(2)}
                    </Text>
                    }
                    <Button color='blue' variant='outline' disabled={!matching} onClick={() => saveLettering()}>
                        Lettrer les écritures
                    </Button>
                </Stack>
            </Group>
            <Group style={{width: '40px'}}>
                <ActionIcon color='blue' variant='outline' onClick={() => findMatch()}>
                    <IconLasso size={16} />
                </ActionIcon>
            </Group>
        </Group>
        </>
    )
}

export { BankLettering }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Badge, Card, Center, CloseButton, Divider, Group, Input, LoadingOverlay, Modal, ScrollArea, Table, Text } from '@mantine/core';
import { IconMinus, IconPlus, IconRefresh, IconReportSearch, IconDashboard } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { ProfileButton } from './profile_button';
import { TrashButton } from '../../shared/TrashButton';
import { Candidate } from './candidate';

interface ItemInterface {
    cv_id: number,
    fullname: string,
    title: string,
    inserted_date: string,
}

interface QueryInterface {
    entries: number,
    query: string,
    created_date: string,
    created_by: string,
}

interface PropsInterface {
    huntId: number,
    isOpen: boolean,
}

const Basket = ({huntId, isOpen}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [items, setItems] = useState<ItemInterface[]>([]);
    const [displayedItems, setDisplayedItems] = useState<ItemInterface[]>([]);
    const [queries, setQueries] = useState<QueryInterface[]>([]);
    const [working, setWorking] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(0);
    const itemsPerPage = 25;
    const [search, setSearch] = useState('');
    const [candidateId, setCandidateId] = useState(0);
    const [candidateFullname, setCandidateFullname] = useState('');
    const [displayQueries, setDisplayQueries] = useState(false);
    
    // Chargement
    useEffect(() => {
        if (loaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_process_hunt?hunt_id=${huntId}&what=basket_items`).then((res:any) => {
            setWorking(false);
            setLoaded(true);
            if (res.data.status === true) {
                setItems(res.data.data || []);
                if (res.data.data.length === 0) {
                    setDisplayedItems([]);
                } else {
                    setDisplayedItems(res.data.data.map((i:ItemInterface) => {return {...i}}));
                }
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
        myContext.httpClient.get(`${myContext.apiAddress}/get_process_hunt?hunt_id=${huntId}&what=basket_queries`).then((res:any) => {
            if (res.data.status === true) {
                setQueries(res.data.data || []);
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
    }, [loaded]); // eslint-disable-line

    // recherche
    useEffect(() => {
        setPage(0);
        if (search.length === 0) {
            setDisplayedItems(items.map((i:ItemInterface) => { return {...i}}));
        } else {
            setDisplayedItems(items.filter((i:ItemInterface) => i.fullname.toLocaleLowerCase().indexOf(search) !== -1).map((i:ItemInterface) => { return {...i}}));
        }
    }, [search]); // eslint-disable-line

    if (error !== null) {
        return (
            <Group>
                <Text color="red">{error}</Text>
            </Group>
        )
    }

    return (
        <>
        <Modal
            opened={displayQueries}
            onClose={() => setDisplayQueries(false)}
            title='Requêtes utilisées pour alimenter ce panier'
        >
            <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
            {queries.map((q: QueryInterface, i: number) => 
            <Card key={`query-${i}`}>
                <Badge>{q.entries}</Badge>
                {q.query.split('|').map((s:string, j:number) => 
                <Text id={`query-${i}-row-${j}`} size='sm'>{s}</Text>)
                }
                <Group position='apart'>
                    <Text color="dimmed" size='sm'>{q.created_by}</Text>
                    <Text color="dimmed" size='sm'>{q.created_date}</Text>
                </Group>
            </Card>
            )}
            </ScrollArea>
        </Modal>
        <Modal
            opened={candidateId !== 0}
            onClose={() => {
                setCandidateId(0)
                setCandidateFullname('')
            }}
            title={`Fiche suivi de ${candidateFullname}`}
            fullScreen
        >
            <Candidate 
                hunt_id={huntId}
                cv_id={candidateId} 
                handle={null}
            />
        </Modal>
        <Group position="apart" pt="xs">
            <Badge>{items.length} personnes</Badge>
            <Group>
                <Input 
                    size='xs'
                    value={search}
                    placeholder='search in fullname'
                    onChange={(event) => setSearch(event.currentTarget.value.trim().toLocaleLowerCase())}
                    rightSection={
                        <CloseButton
                          aria-label="Clear input"
                          onClick={() => setSearch('')}
                          style={{ display: search ? undefined : 'none' }}
                        />}
                />
            </Group>
            <Group>
                {page > 0 &&
                <ActionIcon color='blue' variant='outline' onClick={() => setPage(page - 1)} size='sm'>
                    <IconMinus size={12} />
                </ActionIcon>
                }
                <Text color="dimmed" size="sm">page {page+1}</Text>
                {(page+1) * itemsPerPage < displayedItems.length && 
                <ActionIcon color='blue' variant='outline' onClick={() => setPage(page + 1)} size='sm'>
                    <IconPlus size={12} />
                </ActionIcon>
                }
            </Group>
            <Group>
                <ActionIcon color="blue" variant="outline" onClick={() => setDisplayQueries(true)}>
                    <IconReportSearch size={16} />
                </ActionIcon>
                <ActionIcon color="blue" variant="outline" onClick={() => { setItems([]); setLoaded(false)} }>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
        </Group>
        {(!working && items.length === 0) ?
        <Group pt="lg">
            <Text>Aucun CV rattaché à cette chasse.</Text>
        </Group>
        :
        <Card>
            <LoadingOverlay visible={working} />
            <ScrollArea style={{height: 'calc(100vh - 250px)'}}>
                <Table highlightOnHover>
                    <tbody>
                        {displayedItems.slice(page*itemsPerPage, (page+1)*itemsPerPage).map((item, idx: number) => (
                            <tr key={`page-${page}-item-${item.cv_id}`}>
                                <td>
                                    <Text lineClamp={1}>{item.fullname}</Text>
                                </td>
                                <td>
                                    <Text lineClamp={2} color="dimmed">{item.title.replaceAll('\\n', ' • ')}</Text>
                                </td>
                                <td>
                                    <ActionIcon color="blue" variant="outline" onClick={() => {
                                        setCandidateId(item.cv_id)
                                        setCandidateFullname(item.fullname)
                                    }}>
                                        <IconDashboard size={16} />
                                    </ActionIcon>
                                </td>
                                <td>
                                    <ProfileButton 
                                        hunt_id={huntId}
                                        cv_id={item.cv_id} 
                                    />
                                </td>
                                {isOpen && 
                                <td>
                                    <TrashButton 
                                        label={`Retirer ${item.fullname} de ce panier ?`}
                                        api='update_process_hunt'
                                        params={`hunt_id=${huntId}&cv_id=${item.cv_id}&what=detach&fullname=${item.fullname}`}
                                        handle={() => setItems(items.filter((i) => i.cv_id !== item.cv_id))}
                                    />
                                </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {isOpen && 
                <>
                <Divider label='Suppression du contenu du panier' labelPosition='center' mt='lg' mb='lg' />
                <Center>
                    <TrashButton 
                        label={`Vider ce panier ?`}
                        api='update_process_hunt'
                        params={`hunt_id=${huntId}&what=empty_basket`}
                        handle={() => setItems([])}
                    />
                </Center>
                </>
                }
            </ScrollArea>
        </Card>
        }
        </>
    )
}

export { Basket };
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Button, Card, Center, Grid, Group, LoadingOverlay, TextInput, Text, Textarea } from '@mantine/core';
import { IconX } from '@tabler/icons';

import { TitleContainer } from "../../shared/TitleContainer"
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { InfoNotification } from '../../shared/InfoNotification';
import { NatureSelector } from './nature_selector';

interface NatureInterface {
    id: number,
    label: string,
}
interface RowInterface {
    category: string,
    company_id: number,
    contact_id: number,
    company_label: string,
    contact_label: string,
}

const CreateMessageBook = () => {

    const myContext = useContext(AppContext);
    const [ word, setWord ] = useState('');
    const [ rows, setRows ] = useState<RowInterface[]>([]);
    const [ loading, setLoading ] = useState(false);
    const [ contact, setContact ] = useState<RowInterface | null>(null);
    const [ message, setMessage ] = useState('');
    const [ natures, setNatures ] = useState<NatureInterface[] | null>(null);
    const [ nature, setNature ] = useState<string | null>(null);
    
    useEffect(() => {
        if (word.length < 3) {
            setRows([]);
            return;
        }
        setLoading(true);
        const api = `${myContext.apiAddress}/search_contact_in_book?word=${word}`;
        myContext.httpClient.get(api).then((res:any) => {
            setLoading(false);
            if (res.data.status === true) {
                setRows(res.data.data || []);
            } else {
                setRows([]);
            }
        })
    }, [word]); // eslint-disable-line

    useEffect(() => {
        if (natures !== null) return;
        const api = `${myContext.apiAddress}/list_message_nature_book`;
        myContext.httpClient.get(api).then((res:any) => {
            setLoading(false);
            if (res.data.status === true) {
                setNatures(res.data.data || []);
            } else {
                setNatures([]);
            }
        })
    }, [natures]); // eslint-disable-line

    const categoryToLabel = (c: string) => {
        switch(c) {
            case 'c':
                return 'client';
            case 'p':
                return 'prospect';
            case 'r':
                return 'prise de référence';
            default:
                return 'undefined';
        }
    }

    // on sauvegarde le message
    const save = () => {
        if (nature === null) return;
        if (contact === null) return;
        setLoading(true);
        const api = `${myContext.apiAddress}/update_message_book`;
        const F = new FormData();
        F.append('action', 'add');
        F.append('nature_id', nature);
        F.append('company_id', contact?.company_id + '');
        F.append('contact_id', contact?.contact_id + '');
        F.append('message', message.trim());
        myContext.httpClient.post(api, F).then((res:any) => {
            setLoading(false);
            if (res.data.status === true) {
                InfoNotification({message: 'message saved'});
                setNature(null);
                setMessage('');
                setContact(null);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <>
        <TitleContainer>
            Ajouter un évènement lié à un contact d'une société
        </TitleContainer>
        {contact === null ?
        <Card>
            <Grid>
                <Grid.Col sm={12} md={6} lg={3}>
                    <TextInput
                        label="Contact à chercher"
                        value={word}
                        onChange={(event) => setWord(event.target.value.trim())}
                    />
                </Grid.Col>
                <Grid.Col sm={12} md={6} lg={3}>
                    {rows === null ?
                    <LoadingOverlay visible={loading} />
                    :
                    <>
                    {rows.map((row: RowInterface) => 
                    <Button key={`suggestion-${row.company_id}-${row.contact_id}`} variant="outline" compact
                        onClick={() => setContact({...row})}
                    >
                        {row.contact_label} @ {row.company_label} [ {categoryToLabel(row.category)} ]
                    </Button>
                    )}
                    </>
                    }
                </Grid.Col>
            </Grid>
        </Card>
        :
        <Center>
            <Card style={{width: 'min(100%, 700px)'}}>
                <LoadingOverlay visible={loading} />
                <Group pb='lg'>
                    <Text>Contact sélectionné :</Text>
                    <Text><strong>{contact.contact_label} @ {contact.company_label}</strong></Text>
                    <ActionIcon onClick={() => setContact(null)} color='blue' variant='outline'>
                        <IconX size={16} />
                    </ActionIcon>
                </Group>
                {natures !== null && 
                <NatureSelector 
                    onChange={setNature}
                />
                }
                <Textarea
                    pb='lg'
                    label="Message :"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    minRows={5}
                    autosize
                    withAsterisk
                />
                <Center>
                    <Button
                        onClick={() => save()}
                        disabled={message.length === 0 || nature === null}
                    >
                        submit
                    </Button>
                </Center>
            </Card>
        </Center>
        }
        </>
    )

}

export { CreateMessageBook }
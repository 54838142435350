import { useState } from 'react';
import { ActionIcon, Badge, Center, Divider, Modal, ScrollArea, Stack, Table, TextInput } from '@mantine/core';
import { IconCheck, IconPlaylistAdd, IconX } from '@tabler/icons';

interface ItemInterface {
    id: number,
    value: string,
}

interface PropsInterface {
    multi: boolean,
    items: ItemInterface[],
    handleSelection: any,
    title: string,
}

const ListItemSelector = ({items, handleSelection, title, multi}: PropsInterface) => {

    const [isOpened, setIsOpened] = useState(false);
    const [selection, setSelection] = useState<ItemInterface[]>([]);
    const [search, setSearch] = useState('');
    const [regexp, setRegexp] = useState<RegExp | null>(null);

    const add = (item: ItemInterface) => {
        if (multi) {
            const items: ItemInterface[] = selection.map((item) => { return {...item}});
            let t: boolean = true;
            for (let i of items) {
                if (i.id === item.id) {
                    t = false;
                    break;
                }
            }
            if (t) {
                items.push({...item});
            }
            setSelection(items);
        } else {
            setSelection([{...item}]);
        }
    }

    const remove = (item: ItemInterface) => {
        if (multi) {
            setSelection(selection.filter((i: ItemInterface) => i.id !== item.id));
        } else {
            setSelection([]);
        }
    }

    return (
    <>
    <Modal
        opened={isOpened}
        onClose={() => { 
            setSearch('');
            setRegexp(null);
            setIsOpened(false); 
        }}
        title={title}
    >   
        <Divider my="xs" label={`Sélection : ${selection.length}`} labelPosition="center" />
        {selection.length > 0 && 
        <>
        <ScrollArea style={{height: '150px'}}>
            <Table verticalSpacing="xs" withBorder highlightOnHover>
                <tbody>
                    {selection.map((item: ItemInterface) =>
                    <tr key={`selection-${item.id}`}>
                        <td style={{padding: '0', margin: '0'}}>
                            <Badge color="teal">{item.value}</Badge>
                        </td>
                        <td style={{padding: '0', margin: '0'}}>
                            <ActionIcon color="orange" variant="light" onClick={() => remove({...item})}>
                                <IconX />
                            </ActionIcon>
                        </td>
                    </tr>
                    )}
                </tbody>
            </Table>
        </ScrollArea>
        <Center pt="xs">
            <ActionIcon color="blue" variant="outline" onClick={() => {
                handleSelection(multi ? selection.map((item) => { return {...item}}) : {...selection[0]});
                setSearch('');
                setRegexp(null);
                setIsOpened(false); 
            }}>
                <IconCheck />
            </ActionIcon>
        </Center>
        </>
        }
        <Divider my="xs" label={`Liste : ${items.length}`} labelPosition="center" />
        <TextInput 
            placeholder="mot à rerchercher"
            radius="md"
            size="xs"
            value={search} 
            onChange={(event) => {
                const s = event.currentTarget.value.trim().toLocaleLowerCase();
                setSearch(s);
                setRegexp(s === '' ? null : new RegExp(s.replace('é', 'é|e').replace('è','è|e'), 'i'));
            }}
        />
        <ScrollArea pt={5} style={{height: '200px'}}>
            <Stack align="flex-start" spacing="xs">
                {items.filter((item) => regexp === null || regexp.test(item.value.toLocaleLowerCase())).map((item) => (
                    <Badge key={`item-${item.id}`}
                        style={{cursor: 'pointer'}}
                        onClick={() => add({...item})}
                    >{item.value}</Badge>
                ))}
            </Stack>
        </ScrollArea>
    </Modal>
    <Center>
        <ActionIcon color="blue" variant="outline" onClick={() => setIsOpened(true)}>
            <IconPlaylistAdd size={16} />
        </ActionIcon>
    </Center>
    </>
    )
}

export { ListItemSelector }

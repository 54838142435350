import { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { ActionIcon, Button, Card, Center, ColorSwatch, CopyButton, Group, Loader, LoadingOverlay, ScrollArea, Stack, Table, Tabs, Text } from '@mantine/core';
import { AlertNotification } from '../../shared/AlertNotification';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from "../../shared/TitleContainer"
import { IconRefresh } from '@tabler/icons';
import { DocumentButton } from '../ManageMission/document_button';
const ChartContainer = lazy(() => import('../../shared/ChartContainer').then(({ChartContainer}) => ({default: ChartContainer})));

interface RowInterface {
    mission_id: number,
    file_id: number,
    date_created: string,
    date_min: string,
    date_max: string,
    days: number,
    client_name: string,
    manager_name: string,
    category: string,
    reference: string | null,
    filename: string,
    amount_ht: number,
    amount_ttc: number,
    client_contact_activity_email: string | null,
    client_contact_invoice_email: string | null,
    supplier_contact_activity_email: string | null,
    supplier_contact_bill_email: string | null
}

const NotPaidMission = () => {

    const myContext = useContext(AppContext);
    const [loaded, setLoaded] = useState(false);
    const [bills, setBills] = useState<RowInterface[]>([]);
    const [invoices, setInvoices] = useState<RowInterface[]>([]);
    const [billsAmount, setBillsAmount] = useState(0);
    const [invoicesAmount, setInvoicesAmount] = useState(0);
    const [activeTab, setActiveTab] = useState<string | null>('overview');
    const [option, setOption] = useState<any | null>(null);

    useEffect(() => {
        if (loaded) return;
        setBills([]);
        setInvoices([]);
        setBillsAmount(0);
        setInvoicesAmount(0);
        myContext.httpClient.get(`${myContext.apiAddress}/audit_mission?report=not_paid`).then((res: any) => {
            if (res.data.status === true) {
                setBills(res.data.data.filter((row: RowInterface) => row.category === 'bill'));
                setInvoices(res.data.data.filter((row: RowInterface) => row.category === 'invoice'));
                let t1: number = 0;
                let t2: number = 0;
                let tInvoices: number[] = [0, 0, 0, 0]; // < 30 jours, < 60 jours, < 90 jours, au-delà
                let tBills: number[] = [0, 0, 0, 0];
                for (const row of res.data.data) {
                    const d: number = (row.days < 30) ? 0 : ((row.days < 60) ? 1 : ((row.days < 90) ? 2 : 3));
                    if (row.category === 'bill') {
                        t1 += row.amount_ttc;
                        tBills[d] += row.amount_ttc;
                    } else if (row.category === 'invoice') {
                        t2 += row.amount_ttc;
                        tInvoices[d] += row.amount_ttc;
                    }
                }
                setBillsAmount(t1);
                setInvoicesAmount(t2);
                setOption({
                    legend: {
                        data: ['Clients', 'Fournisseurs'],
                    },
                    tooltip: {},
                    xAxis: {
                        data: ['< 30j', '< 60j', '< 90j', '+ 90j'],
                        name: 'Période',
                        axisLine: { onZero: true },
                        splitLine: { show: false },
                        splitArea: { show: true }
                    },
                    yAxis: {},
                    grid: {
                        bottom: 100
                    },
                    series: [
                        {
                            name: 'Clients',
                            data: [...tInvoices],
                            type: 'bar',
                        },{
                            name: 'Fournisseurs',
                            data: [...tBills],
                            type: 'bar',
                        }
                    ]
                });
            } else {
                AlertNotification({message: res.data.message || 'unknown error from servor'});
            }
            setLoaded(true);
        });
    }, [loaded]); // eslint-disable-line

    const exportForClipBoard = () => {
        let s: string = '';
        if (activeTab === 'invoices') {
            s += '<table><thead><tr>'
                + '<th>Client</th>'
                + '<th>Manager de transition</th>'
                + '<th>Référence</th>'
                + '<th>Date de création</th>'
                + '<th>Date de début</th>'
                + '<th>Date de fin</th>'
                + '<th>Jours</th>'
                + '<th>Montant TTC</th>'
                + '<th>Email compte rendu d\'activité</th>'
                + '<th>Email facturation</th>'
                + '<th>URL</th>'
                + '</tr></thead><tbody>';
            for (const i of invoices) {
                s += '<tr>'
                    + '<td>' + i.client_name + '</td>'
                    + '<td>' + i.manager_name + '</td>'
                    + '<td>' + i.reference + '</td>'
                    + '<td>' + i.date_created + '</td>'
                    + '<td>' + i.date_min + '</td>'
                    + '<td>' + i.date_max + '</td>'
                    + '<td>' + i.days + '</td>'
                    + '<td>' + i.amount_ttc.toLocaleString() + '</td>'
                    + '<td>' + i.client_contact_activity_email + '</td>'
                    + '<td>' + i.client_contact_invoice_email + '</td>'
                    + '<td>https://www.snr-digital.com/document/invoice/' + i.mission_id + '-' + i.file_id + '-' + i.reference + '</td>'
                    + '</tr>';
            }
            s += '</tbody></table>';
        } else if (activeTab === 'bills') {
            s += '<table><thead><tr>'
                + '<th>Client</th>'
                + '<th>Manager de transition</th>'
                + '<th>Référence</th>'
                + '<th>Date de création</th>'
                + '<th>Date de début</th>'
                + '<th>Date de fin</th>'
                + '<th>Jours</th>'
                + '<th>Montant TTC</th>'
                + '<th>Email CRA</th>'
                + '<th>Email facturation</th>'
                + '</tr></thead><tbody>';
            for (const i of bills) {
                s += '<tr>'
                    + '<td>' + i.client_name + '</td>'
                    + '<td>' + i.manager_name + '</td>'
                    + '<td>' + i.reference + '</td>'
                    + '<td>' + i.date_created + '</td>'
                    + '<td>' + i.date_min + '</td>'
                    + '<td>' + i.date_max + '</td>'
                    + '<td>' + i.days + '</td>'
                    + '<td>' + i.amount_ttc.toLocaleString() + '</td>'
                    + '<td>' + i.client_contact_activity_email + '</td>'
                    + '<td>' + i.client_contact_invoice_email + '</td>'
                    + '</tr>';
            }
            s += '</tbody></table>';
        }
        return s;
    }

    return (
    <>
    <Group position='apart'>
        <TitleContainer>Factures clients et fournisseurs non encore lettrées</TitleContainer>
        <Group>
            {((activeTab === 'invoices' || activeTab === 'bills') && loaded) &&
            <CopyButton value={exportForClipBoard()}>
                {({ copied, copy }) => (
                <Button size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                    {copied ? 'Données copiées' : 'Copier'}
                </Button>
                )}
            </CopyButton>
            }
            <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
    </Group>
    <ScrollArea style={{ height: 'calc(100vh - 120px)' }}>
        <LoadingOverlay visible={!loaded}/>
        <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List>
                <Tabs.Tab value="overview">Synthèse</Tabs.Tab>
                <Tabs.Tab value="invoices">Facturé client</Tabs.Tab>
                <Tabs.Tab value="bills">Facturé fournisseur</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="overview" pt="xs">
                <Group position='apart'>
                    <Stack>
                        {invoicesAmount > 0 &&
                        <Card withBorder>
                            <Group position='apart'>
                                <Text size='xs'>Facturé client non lettré</Text>
                                <Text size='lg' align='right'>{invoicesAmount.toLocaleString('fr-FR', {maximumFractionDigits: 0})} €</Text>
                            </Group>
                        </Card>
                        }
                        {billsAmount > 0 &&
                        <Card withBorder>
                            <Group position='apart'>
                                <Text size='xs'>Facturé fournisseur non lettré</Text>
                                <Text size='lg' align='right'>{billsAmount.toLocaleString('fr-FR', {maximumFractionDigits: 0})} €</Text>
                            </Group>
                        </Card>
                        }
                    </Stack>
                    {option !== null &&
                    <Card withBorder style={{height: '100%', width: '100%'}}>
                        <Suspense fallback={<Loader/>}>
                            <ChartContainer
                                option={option}
                            />
                        </Suspense>
                    </Card>
                    }
                </Group>
            </Tabs.Panel>
            <Tabs.Panel value="invoices" pt="xs">
                <Table highlightOnHover>
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Manager de transition</th>
                            <th>Fichier</th>
                            <th>Référence</th>
                            <th>Période</th>
                            <th>Montant TTC</th>
                            <th>Retard</th>
                            <th>Lien</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((row: RowInterface) =>
                        <tr key={`row-${row.file_id}`}>
                            <td>
                                <Text>{row.client_name}</Text>
                            </td>
                            <td>
                                <Text>{row.manager_name}</Text>
                            </td>
                            <td>
                                <Text>{row.filename}</Text>
                            </td>
                            <td>
                                <Text>{row.reference}</Text>
                            </td>
                            <td>
                                <Stack spacing={0}>
                                    <Text size='xs'>{row.date_min.substring(0,7)}</Text>
                                    <Text size='xs'>{row.date_max.substring(0,7)}</Text>
                                </Stack>
                            </td>
                            <td>
                                <Text align='right'>{row.amount_ttc.toLocaleString('fr-FR', {maximumFractionDigits: 0})}</Text>
                            </td>
                            <td>
                                <Center>
                                    <ColorSwatch
                                        size={16}
                                        color={row.days < 30 ? 'green' : (row.days < 60 ? 'yellow' : 'red')}
                                    />
                                </Center>
                            </td>
                            <td>
                                <DocumentButton 
                                    title=''
                                    category={row.category}
                                    missionId={row.mission_id}
                                    fileId={row.file_id}
                                    handleChange={null}
                                    missionOpened={true}
                                />
                            </td>
                        </tr>
                        )}
                    </tbody>
                </Table>
            </Tabs.Panel>
            <Tabs.Panel value="bills" pt="xs">
                <Table highlightOnHover>
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Manager de transition</th>
                            <th>Fichier</th>
                            <th>Référence</th>
                            <th>Période</th>
                            <th>Montant TTC</th>
                            <th>Retard</th>
                            <th>Lien</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bills.map((row: RowInterface) =>
                        <tr key={`row-${row.file_id}`}>
                            <td>
                                <Text>{row.client_name}</Text>
                            </td>
                            <td>
                                <Text>{row.manager_name}</Text>
                            </td>
                            <td>
                                <Text>{row.filename}</Text>
                            </td>
                            <td>
                                <Text>{row.reference}</Text>
                            </td>
                            <td>
                                <Stack spacing={0}>
                                    <Text size='xs'>{row.date_min.substring(0,7)}</Text>
                                    <Text size='xs'>{row.date_max.substring(0,7)}</Text>
                                </Stack>
                            </td>
                            <td>
                                <Text align='right'>{row.amount_ttc.toLocaleString('fr-FR', {maximumFractionDigits: 0})}</Text>
                            </td>
                            <td>
                                <Center>
                                    <ColorSwatch
                                        size={16}
                                        color={row.days < 30 ? 'green' : (row.days < 60 ? 'yellow' : 'red')}
                                    />
                                </Center>
                            </td>
                            <td>
                                <DocumentButton 
                                    title=''
                                    category={row.category}
                                    missionId={row.mission_id}
                                    fileId={row.file_id}
                                    handleChange={null}
                                    missionOpened={true}
                                />
                            </td>
                        </tr>
                        )}
                    </tbody>
                </Table>
            </Tabs.Panel>
        </Tabs>
    </ScrollArea>
    </>
    )
}

export { NotPaidMission }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Group, LoadingOverlay, Stack, Text, TextInput } from '@mantine/core';
import { IconAlertTriangle, IconArrowBack, IconCheck, IconPencil, IconTrash, IconX } from '@tabler/icons';
import { isDate, isNumber } from '../../../services/functions';
import { AlertNotification } from '../AlertNotification';
import AppContext from '../AppContext';

interface RowInterface {
    id: number | null,
    label: string | null,
    date: string | null,
    quantity: number | null,
    price: number | null,
    amount: number | null,
    vat_rate: number | null,
}
interface PropsInterface {
    api: string,
    row: RowInterface,
    idx: number,
    remove: any,
    push: any,
    isEditing: boolean,
    isEditable: boolean,
}

const FinancialRowEditor = ({api, row, idx, remove, push, isEditing, isEditable}: PropsInterface) => {
    
    const myContext = useContext(AppContext);
    const [ isEdition, setIsEdition ] = useState(isEditing);
    const [ isSaving, setIsSaving ] = useState(false);

    const [ id, setId ] = useState<number | null>(row.id);
    const rl = row.label || '';
    const [ label, setLabel ] = useState<string>(rl);
    const [ oldLabel, setOldLabel ] = useState<string>(rl);
    const rdd: string[] = (row.date || '').split('-');
    const rd = (rdd.length === 3) ? rdd[2] + '/' + rdd[1] + '/' + rdd[0] : '';
    const [ date, setDate ] = useState<string>(rd);
    const [ oldDate, setOldDate ] = useState<string>(rd);
    const rq = row.quantity?.toLocaleString(undefined, {minimumFractionDigits: 2}) || '';
    const [ quantity, setQuantity ] = useState<string>(rq);
    const [ oldQuantity, setOldQuantity ] = useState<string>(rq);
    const rp = row.price?.toLocaleString(undefined, {minimumFractionDigits: 2}) || '';
    const [ price, setPrice ] = useState<string>(rp);
    const [ oldPrice, setOldPrice ] = useState<string>(rp);
    const ra = row.amount?.toLocaleString(undefined, {minimumFractionDigits: 2}) || '';
    const [ amount, setAmount ] = useState<string>(ra);
    const [ oldAmount, setOldAmount ] = useState<string>(ra);
    const rv = row.vat_rate?.toLocaleString(undefined, {minimumFractionDigits: 4}) || '';
    const [ vatRate, setVatRate ] = useState<string>(rv);
    const [ oldVatRate, setOldVatRate ] = useState<string>(rv);
    const rt = (row.amount === null || row.vat_rate === null) ? '' : (row.amount * (1.0+row.vat_rate)).toLocaleString(undefined, {maximumFractionDigits: 2});
    const [ total, setTotal ] = useState<string>(rt);
    const [ oldTotal, setOldTotal ] = useState<string>(rt);

    useEffect(() => {
        if (push === null) return;
        if (label.trim().length < 1) return;
        if (!isDate(date)) return;
        const da: string[] = date.split('/');
        const d = (da.length === 3) ? da[2]+'-'+da[1]+'-'+da[0] : '';
        // Si l'on peut construire une ligne valide, on la pousse au composant parent.
        let a: number | null = isNumber(amount) ? parseFloat(formatStringNumber(amount)) : null;
        const q: number | null = isNumber(quantity) ? parseFloat(formatStringNumber(quantity)) : null;
        const p: number | null = isNumber(price) ? parseFloat(formatStringNumber(price)) : null;
        if (q !== null && p !== null) {
            a = q * p;
        }
        const v: number | null = isNumber(vatRate) ? parseFloat(formatStringNumber(vatRate)) : null;
        if (a === null) return;
        setAmount(a.toLocaleString(undefined,{maximumFractionDigits: 2}));
        setTotal((a === null || v === null) ? '' : (a*(1+v)).toLocaleString(undefined,{maximumFractionDigits: 2}));
        // Tout est bon, on peut pousser la ligne au composant parent.
        push({id: id, label: label, date: d, quantity: q, price: p, amount: a, vat_rate: v});
    }, [id, label, date, quantity, price, amount, vatRate]); // eslint-disable-line

    useEffect(() => {
        if (row.id === null || row.id < 1) return;
        if (row.amount === null) return;
        setAmount(row.amount.toLocaleString(undefined,{maximumFractionDigits: 2}));
        setVatRate(row.vat_rate?.toLocaleString(undefined,{maximumFractionDigits: 4}) || '');
        setTotal((row.amount * (1 + (row.vat_rate || 0))).toLocaleString(undefined, {'maximumFractionDigits': 2}));
    }, [row]); // eslint-disable-line

    const removeRow = () => {
        if (row.id === null) {
            remove(idx);
            return;
        }
        if (api === '') return;
        const F = new FormData();
        F.append('flow_id', row.id + '');
        F.append('action', 'flow');
        setIsSaving(true);
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsSaving(false);
            if (res.data.status === true) {
                remove(idx);
            } else {
                AlertNotification({message: res.data.message || 'internal error'});
            }
        });
    }

    const resetRow = () => {
        setLabel(oldLabel);
        setDate(oldDate);
        setQuantity(oldQuantity);
        setPrice(oldPrice);
        setAmount(oldAmount);
        setVatRate(oldVatRate);
        setTotal(oldTotal);
    }

    const formatStringNumber = (s:string) => {
        return s.trim().replaceAll(' ','').replaceAll(' ','').replace(',','.');
    }

    const saveRow = () => {
        // on actualise les valeurs de la ligne
        let a: number | null = isNumber(amount) ? parseFloat(formatStringNumber(amount)) : null;
        const q: number | null = isNumber(quantity) ? parseFloat(formatStringNumber(quantity)) : null;
        const p: number | null = isNumber(price) ? parseFloat(formatStringNumber(price)) : null;
        let t: number | null = null;
        if (q !== null && p !== null) {
            a = q * p;
        }
        const v: number | null = isNumber(vatRate) ? parseFloat(formatStringNumber(vatRate)) : null;
        if (a !== null && v !== null) {
            t = a * (1.0 + v);
        }
        setAmount(a === null ? '' : a.toLocaleString(undefined, {minimumFractionDigits: 2}));
        setTotal(t === null ? '' : t.toLocaleString(undefined, {minimumFractionDigits: 2}));
        // avant de poursuivre, on s'assure que la ligne est correcte
        if (label.trim().length < 1 || !isDate(date) || a === null) {
            return;
        }
        // on quitte le mode édition
        // et on lance la sauvegarde
        setIsEdition(false);
        setIsSaving(true);
        const F = new FormData();
        let s: string = (id === null || id < 1) ? '' : id + '';
        F.append('flow_id', s);
        s = label.trim();
        F.append('label', s);
        s = date.trim();
        const sa: string[] = s.split('/');
        s = (sa.length === 3) ? sa[2]+'-'+sa[1]+'-'+sa[0] : '';
        F.append('date', s);
        F.append('quantity', (q === null) ? '' : q + '');
        F.append('price', (p === null) ? '' : p + '');
        F.append('amount', (a === null) ? '' : a + '');
        F.append('vat_rate', (v === null) ? '' : v + '');
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsSaving(false);
            if (res.data.status === true) {
                setOldLabel(label);
                setOldDate(date);
                setOldQuantity(quantity);
                setOldPrice(price);
                setOldAmount(amount);
                setOldVatRate(vatRate);
                setOldTotal(total);
                if (id === null) {
                    setId(res.data.data.id);
                }
            } else {
                setLabel(oldLabel);
                setDate(oldDate);
                setQuantity(oldQuantity);
                setPrice(oldPrice);
                setAmount(oldAmount);
                setVatRate(oldVatRate);
                setTotal(oldTotal);
                setIsEdition(true);
                AlertNotification({message: res.data.message || 'internal error'});
            }
        });
    }

    if (isEdition) {
        return (
            <tr>
                <td>
                    <Text color="dimmed">{idx}</Text>
                </td>
                <td>
                    <TextInput 
                        placeholder='Libellé'
                        value={label} 
                        onChange={(event) => setLabel(event.target.value) } 
                        rightSection={(label !== '' && label.trim().length < 3) && <IconAlertTriangle size={16} color="red" />}
                    />
                </td>
                <td>
                    <TextInput 
                        placeholder='JJ/MM/AAAA'
                        value={date}
                        onChange={(event) => setDate(event.target.value) }
                        rightSection={(date !== '' && !isDate(date)) && <IconAlertTriangle size={16} color="red" />}
                    />
                </td>
                <td>
                    <TextInput 
                        placeholder='quantité'
                        value={quantity}
                        onChange={(event) => setQuantity(event.target.value)}
                        rightSection={(quantity !== '' && !isNumber(quantity)) && <IconAlertTriangle size={16} color="red" />}
                    />
                </td>
                <td>
                    <TextInput 
                        placeholder='prix unitaire'
                        value={price}
                        onChange={(event) => setPrice(event.target.value)}
                        rightSection={(price !== '' && !isNumber(price)) && <IconAlertTriangle size={16} color="red" />}
                    />
                </td>
                <td>
                    <TextInput 
                        placeholder='montant'
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)}
                        rightSection={(amount !== '' && !isNumber(amount)) && <IconAlertTriangle size={16} color="red" />}
                    />
                </td>
                <td>
                    <TextInput 
                        placeholder='taux de TVA'
                        value={vatRate}
                        onChange={(event) => setVatRate(event.target.value)}
                        rightSection={(vatRate !== '' && (!isNumber(vatRate) || parseFloat(vatRate.replaceAll(' ','').replace(',','.')) < 0 || parseFloat(vatRate.replaceAll(' ','').replace(',','.')) > 0.5)) && <IconAlertTriangle size={16} color="red" />}
                    />
                </td>
                <td>
                    <Text>{total}{total === '' ? '' : ' €'}</Text>
                </td>
                <td>
                    <Stack>
                        <Group>
                            <ActionIcon color="blue" variant="outline" onClick={() => setIsEdition(false)}>
                                <IconX size={16} />
                            </ActionIcon>
                            {api !== '' &&
                            <ActionIcon color="blue" variant="outline" onClick={() => saveRow()}>
                                <IconCheck size={16} />
                            </ActionIcon>
                            }
                        </Group>
                        <Group>
                            <ActionIcon color="blue" variant="outline" onClick={() => resetRow()}>
                                <IconArrowBack size={16} />
                            </ActionIcon>
                            <ActionIcon color="blue" variant="outline" onClick={() => removeRow()}>
                                <IconTrash size={16} />
                            </ActionIcon>
                        </Group>
                    </Stack>
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
                <td>
                    <Text color="dimmed">{idx === -1 ? '' : idx}</Text>
                </td>
                <td>
                    <Text>{label}</Text>
                </td>
                <td>
                    <Text align="right">{date}</Text>
                </td>
                <td>
                    <Text align="right">{quantity}</Text>
                </td>
                <td>
                    <Text align="right">{price}</Text>
                </td>
                <td>
                    <Text align="right">{amount}{amount === '' ? '' : ' €'}</Text>
                </td>
                <td>
                    <Text align="right">{vatRate}</Text>
                </td>
                <td>
                    <Text align="right">{total}{total === '' ? '' : ' €'}</Text>
                </td>
                <td>
                    {(isEditable && idx !== -1) && <>
                    <LoadingOverlay visible={isSaving}/>
                    <ActionIcon color="blue" variant="outline" onClick={() => setIsEdition(true)}>
                        <IconPencil size={16} />
                    </ActionIcon>
                    </>}
                </td>
            </tr>
        );
    }

}

export { FinancialRowEditor }
import { useContext, useEffect, useReducer, useState } from 'react';
import { Badge, Box, Button, Card, Center, ColorSwatch, Group, LoadingOverlay, ScrollArea, Stack, Tabs, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { ProfileBox } from './profile_box';
import { DatePickerContainer } from '../../shared/DatePickerContainer';
import { TextareaContainer } from '../../shared/TextareaContainer';
import { ToggleContainer } from '../../shared/ToggleContainer';
import { CVInterface } from '../interfaces';
import { constructCvFromJson } from '../functions';
import { AlertNotification } from '../../shared/AlertNotification';
import { TextInputContainer } from '../../shared/TextInputContainer';
import { RichTextContainer } from '../../shared/RichTextComponent/rte_container';
import { Choice } from './choice';
import { FileUploaderButton } from '../../shared/FileUploaderButton';
import { Document } from './document';
import { TrashButton } from '../../shared/TrashButton';

interface DocumentInterface {
    id: number,
    category: string | null,
    filename: string,
    extension: string,
    created_date: string,
    created_by: string,
    blocked: boolean,
}
interface OptionInterface {
    code: string,
    label: string,
}
interface MetadataInterface {
    code: string,
    type: 'number' | 'text' | 'list' | 'date',
    label: string,
    options: OptionInterface[],
}
const documentsReducer = (state: DocumentInterface[], payload: any) => {
    switch(payload.type) {
        case 'add':
            return state.concat([{...payload.data}]);
        case 'remove':
            return state.filter((d) => d.id !== payload.id);
        case 'rename':
            return state.map((d) => {
                if (d.id === payload.id) {
                    return {...d, filename: payload.value};
                } else {
                    return {...d};
                }
            });
        case 'set':
            return payload.data || [];
        default:
            return [];
    }
}

interface FormRowInterface {
    code: string,
    label: string,
    value: string,
    nature: string,
}

interface RequestInterface {
    'status': boolean,
    'date': string | null,
    'comment': string | null,
}
interface DeclinedInterface {
    'status': boolean,
    'date': string | null,
    'comment': string | null,
}
interface FormInterface {
    'created_date': string | null,
    'opened_date': string | null,
    'filled_date': string | null,
    'content': FormRowInterface[],
}
interface ScoreInterface {
    'value': -2 | -1 | 0 | 1 | null,
    'comment': string,
}

interface PropsInterface {
    hunt_id: number,
    cv_id: number,
    handle: any,
}

const Candidate = ({hunt_id, cv_id, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [loaded,setLoaded] = useState(false);
    const [profile,setProfile] = useState<CVInterface | null>(null);
    const [request,setRequest] = useState<RequestInterface | null>(null);
    const [declined,setDeclined] = useState<DeclinedInterface | null>(null);
    const [form,setForm] = useState<FormInterface | null>(null);
    const [score,setScore] = useState<ScoreInterface | null>(null);
    const [activeTab, setActiveTab] = useState<string | null>('profile');
    const [documents, dispatchDocuments] = useReducer(documentsReducer, []);

    const updateMetadataApi = `${myContext.apiAddress}/update_process_hunt?hunt_id=${hunt_id}&what=metadata&cv_id=${cv_id}`;
    
    const isEdition: boolean = true;

    const scoreColors: string[] = ['gray', 'red', 'yellow', 'green'];

    const metadataFilename: MetadataInterface = { code: "filename", type: "text", label: "Nom du document", options: [] };

    useEffect(() => {
        if (loaded) return;
        const api = `${myContext.apiAddress}/get_process_hunt?hunt_id=${hunt_id}&what=candidate&cv_id=${cv_id}`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                setProfile(constructCvFromJson(res.data.data.profile));
                setRequest(res.data.data.request);
                setDeclined(res.data.data.declined);
                setForm(res.data.data.form);
                setScore(res.data.data.score);
                dispatchDocuments({type:'set', data: res.data.data.files === null ? [] : res.data.data.files });
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoaded(true);
        });
    }, []); // eslint-disable-line

    return (
        <>
        <LoadingOverlay visible={!loaded} />
        {loaded ?
        <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List>
                <Tabs.Tab value="profile">
                    <Text>Profil</Text>
                </Tabs.Tab>
                <Tabs.Tab value="request">
                    <Group>
                        <Text>Requête ?</Text>
                        {request?.status && <Badge color='teal'><IconCheck size={16} /></Badge>}
                    </Group>
                </Tabs.Tab>
                <Tabs.Tab value="declined">
                    <Group>
                        <Text>Refus candidat ?</Text>
                        {declined?.status && <Badge color='red'><IconX size={16} /></Badge>}
                    </Group>
                </Tabs.Tab>
                <Tabs.Tab value="form">
                    <Group>
                        <Text>Formulaire ?</Text>
                        {(form !== null && form.content.length !== 0) && <Badge color='teal'><IconCheck size={16} /></Badge>}
                    </Group>
                </Tabs.Tab>
                <Tabs.Tab value="files">
                    <Group>
                        <Text>Fichiers</Text>
                        {documents.length !== 0 && 
                        <Badge color="teal">{documents.length}</Badge>}
                    </Group>
                </Tabs.Tab>
                <Tabs.Tab value="score">
                    <Group>
                        <Text>Analyse ?</Text>
                        <ColorSwatch
                            size={12}
                            color={(score === null || score.value === null) ? '' : scoreColors[score.value + 2]}
                        />
                    </Group>
                </Tabs.Tab>
                <Tabs.Tab value="export">
                    <Group>
                        <Text>Exporter</Text>
                    </Group>
                </Tabs.Tab>
            </Tabs.List>
            <ScrollArea style={{height: 'calc(100vh - 130px)'}}>
                <Tabs.Panel value="profile">
                    {profile !== null && 
                        <Card>
                            <ProfileBox profile={profile} search={null} />
                        </Card>
                    }
                </Tabs.Panel>
                <Tabs.Panel value="request">
                    <Card style={{overflow: 'visible'}}>
                        <ToggleContainer 
                            action='has_request'
                            defaultValue={request?.status ? true : false}
                            label="Le candidat a-t-il émis une demande en amont du formulaire ?"
                            isEdition={isEdition}
                            api={updateMetadataApi}
                            handle={(b: boolean) => setRequest({
                                status: b,
                                date: request?.date || null,
                                comment: request?.comment || null,
                            })}
                        />
                        <DatePickerContainer 
                            code='has_request_date'
                            label='Date de la première demande'
                            defaultValue={request?.date || ''}
                            api={updateMetadataApi}
                            isEdition={isEdition}
                            handle={(s:string) => {
                                if (handle !== null) {
                                    handle(cv_id,'has_request_date',s);
                                }
                            }}
                            withAsterisk={false}
                        />
                        <TextareaContainer
                            code="has_request_comment"
                            label="Commentaire sur la requête formulée"
                            defaultValue={request?.comment || ''}
                            api={updateMetadataApi}
                            isEdition={isEdition}
                            handle={null}
                        />
                    </Card>
                </Tabs.Panel>
                <Tabs.Panel value="declined">
                    <Card style={{overflow: 'visible'}}>
                        <ToggleContainer 
                            action='has_declined'
                            defaultValue={declined?.status ? true : false}
                            label="Le candidat a-t-il émis un refus à la proposition ?"
                            isEdition={isEdition}
                            api={updateMetadataApi}
                            handle={(b: boolean) => setDeclined({
                                status: b,
                                date: declined?.date || null,
                                comment: declined?.comment || null,
                            })}
                        />
                        <DatePickerContainer 
                            code='has_declined_date'
                            label='Date du refus'
                            defaultValue={declined?.date || ''}
                            api={updateMetadataApi}
                            isEdition={isEdition}
                            handle={(s:string) => {
                                if (handle !== null) {
                                    handle(cv_id,'has_declined_date',s);
                                }
                            }}
                            withAsterisk={false}
                        />
                        <TextareaContainer
                            code="has_declined_comment"
                            label="Commentaire sur le refus du candidat"
                            defaultValue={declined?.comment || ''}
                            api={updateMetadataApi}
                            isEdition={isEdition}
                            handle={null}
                        />
                    </Card>
                </Tabs.Panel>
                <Tabs.Panel value="form">
                    {form?.content.map((row: FormRowInterface) => 
                    <Card key={`${cv_id}-q-${row.code}`}>
                        {['D','E','I','N','P','R','S'].indexOf(row.nature) === -1 ?
                        <>
                        <Text color="dimmed">{row.label}</Text>
                        <Text>{row.value}</Text>
                        </>
                        :
                        <TextInputContainer
                            code={row.code}
                            label={row.label}
                            defaultValue={row.value === null ? '' : row.value + ''}
                            api={updateMetadataApi}
                            isEdition={isEdition}
                            handle={null}
                            withAsterisk={false}
                        />
                        }
                    </Card>
                    )}
                </Tabs.Panel>
                <Tabs.Panel value="files">
                    <Center>
                        <Stack style={{width: 'min(100%, 600px)'}}>
                            <Group position='apart' pt='xs'>
                                <Text>Fichiers rattachés au candidat</Text>
                                <FileUploaderButton 
                                    title="Ajouter un nouveau d'un document"
                                    api={`update_process_hunt?hunt_id=${hunt_id}&cv_id=${cv_id}&&what=add_file`}
                                    metadata={[metadataFilename]}
                                    handle={(d:DocumentInterface) => dispatchDocuments({type:'add',data:d})}
                                />
                            </Group>
                            {documents.map((d:DocumentInterface) => 
                            <Group position='apart' key={`cv-${cv_id}-doc-${d.id}`}>
                                <Box style={{width: 'calc(100% - 100px)'}}>
                                    <TextInputContainer
                                        code=''
                                        label='Nom du fichier'
                                        defaultValue={d.filename}
                                        api={`${myContext.apiAddress}/update_process_hunt?hunt_id=${hunt_id}&cv_id=${cv_id}&file_id=${d.id}&what=rename_file`}
                                        isEdition={isEdition}
                                        handle={(s: string) => dispatchDocuments({type: 'rename', id: d.id, value: s})}
                                        withAsterisk={false}
                                    />
                                </Box>
                                <Group>
                                    <Document 
                                        huntId={hunt_id}
                                        cvId={cv_id}
                                        fileId={d.id}
                                    />
                                    {isEdition &&
                                    <TrashButton 
                                        label={`Fichier "${d.filename}"`}
                                        api='update_process_hunt'
                                        params={`hunt_id=${hunt_id}&cv_id=${cv_id}&file_id=${d.id}&what=remove_file`}
                                        handle={() => dispatchDocuments({type: 'remove', id: d.id})}
                                    />
                                    }
                                </Group>
                            </Group>
                            )}
                        </Stack>
                    </Center>
                </Tabs.Panel>
                <Tabs.Panel value="score">
                    <Center>
                        <Card style={{width: 'min(100%, 600px)'}}>
                            <Choice 
                                hunt_id={hunt_id}
                                cv_id={cv_id}
                                value={(score === null) ? null : (score.value === null ? null : score.value)}
                                handle={(v: -2 | -1 | 0 | 1) => {
                                    setScore({
                                        value: v,
                                        comment: score?.comment || '',
                                    });
                                    if (handle !== null) {
                                        handle(cv_id, 'bm_score', v);
                                    }
                                }}
                            />
                            <Text>Commentaire :</Text>
                            <RichTextContainer 
                                code='bm_comment'
                                value={score?.comment || ''}
                                api={updateMetadataApi}
                                handle={null}
                            />
                        </Card>
                    </Center>
                </Tabs.Panel>
                <Tabs.Panel value="export">
                    <Center p='lg'>
                        <Button color='blue' variant='outline' component='a'
                            target='_blank' href={`${myContext.apiAddress}/get_process_hunt?hunt_id=${hunt_id}&what=candidate_file&cv_id=${cv_id}`}
                        >
                            Export le dossier candidat dans un PDF
                        </Button>
                    </Center>
                </Tabs.Panel>
            </ScrollArea>
        </Tabs>
        :
        <Text>
            En cours de chargement...
        </Text>
        }
        </>
    );

}

export { Candidate }
import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Button, Center, Divider, LoadingOverlay, Modal, SegmentedControl, Stack, Switch, Table, Text, Textarea, TextInput } from '@mantine/core';
import { IconFilePencil } from '@tabler/icons';
import { FinancialFlowEditor } from '../../shared/FinancialFlowEditor';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { DisplayPDF } from "../../shared/DisplayPDF";

interface OptionInterface {
    code: string,
    value: string,
}
interface RowInterface {
    id: number,
    label: string,
    date: string,
    quantity: number | null,
    price: number | null,
    amount: number,
    vat_rate: number | null,
}
interface HeaderInterface {
    client_name: string,
    client_address_row_1: string,
    client_address_row_2: string,
    client_address_row_3: string,
    client_address_zipcode: string,
    client_address_town: string,
    client_address_country: string,
    finance_client_daily_rate: number,
    finance_supplier_daily_rate: number,
    manager_name: string,
}
interface InvoiceInterface {
    header: HeaderInterface | null,
    options: OptionInterface[],
    rows: RowInterface[],
}

const invoiceReducer = (state: InvoiceInterface, payload: any) => {
    switch (payload.type) {
        case 'define_header':
            return {
                header: {...payload.header},
                rows: [ {...payload.row} ],
                options: [
                    {code: 'chrono', value: 'AUTO'},
                    {code: 'extra', value: ''},
                    {code: 'payment', value: 'Paiement à réception'},
                    {code: 'is_manager_hidden', value: 'false'},
                    {code: 'is_credit_note', value: 'false'},
                    {code: 'language', value: 'french'},
                ],
            }
        case 'update_option':
            return {
                ...state,
                options: state.options.map((o) => {
                    if (o.code === payload.code) {
                        return {...o, value: payload.value};
                    } else {
                        return {...o};
                    }
                })
            }
        case 'update_rows':
            return {
                ...state,
                rows: payload.data.map((r: RowInterface) => { return { ...r}}),
            }
        default:
            return {
                header: null,
                rows: [],
                options: [],
            }
    }
}

const computeEndOfPreviousMonth = () => {
    const d = new Date();
    const m = d.getMonth();
    while (d.getMonth() === m) {
        d.setDate(d.getDate() - 1);
    }
    return d;
}

const computeEndOfCurrentMonth = () => {
    const d = new Date();
    const m = d.getMonth();
    while (d.getMonth() === m) {
        d.setDate(d.getDate() + 1);
    }
    d.setDate(d.getDate() - 1)
    return d;
}

const computeOpeningDaysInMonth = (dn: Date) => {
    // premier jour du mois
    const month = dn.getMonth() === 0 ? 11 : dn.getMonth() - 1;
    const d0 = new Date(
        dn.getFullYear(),
        month,
        1
    );
    let n: number = 0;
    while(d0.getMonth() === month){
        const d: number = d0.getDay();
        if (d !== 0 && d !== 6) {
            n += 1;
        }
        d0.setDate(d0.getDate() + 1);
    }
    return n;
}

interface PropsInterface {
    missionId: number,
    missionLabel: string,
    handle: any,
}

const InvoiceMakerButton = ({missionId, missionLabel, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [isOpened, setIsOpened ] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [invoice, dispatchInvoice] = useReducer(invoiceReducer, {
        header: null, rows: [], options: []
    });
    const [isPDF, setIsPDF] = useState(false);

    useEffect(() => {
        if (!isOpened) return;
        dispatchInvoice({type: 'reset'});
        setIsPDF(false);
        setIsWorking(true);
        const api = `${myContext.apiAddress}/create_invoice_mission?id=${missionId}&mode=get`;
        myContext.httpClient.get(api).then((res:any) => {
            setIsWorking(false);
            if (res.data.status === true) {
                const j: number = (new Date()).getDate();
                const d: Date = (j < 15) ? computeEndOfPreviousMonth() : computeEndOfCurrentMonth();
                const p: number | null = res.data.data.finance_client_daily_rate;
                const q: number = computeOpeningDaysInMonth(d);
                dispatchInvoice({type: 'define_header', header: res.data.data, row: {
                    id: null, 
                    label: 'Jours sur ' + d.toLocaleString('default', { month: 'long' }) + ' ' + d.getFullYear(), 
                    date: d.toISOString().substring(0,10), 
                    quantity: q, 
                    price: p, 
                    amount: p === null ? null : p * q, 
                    vat_rate: 0.2
                }});
            } else {
                AlertNotification({message: res.data.message || 'unknown internal error'});
            }
        });
    }, [isOpened]); // eslint-disable-line

    const isHeaderCorrect = () => {
        if (invoice.header === null) return false;
        if (invoice.header.manager_name === null) return false;
        if (invoice.header.client_name === null) return false;
        if (invoice.header.client_address_row_1 === null) return false;
        if (invoice.header.client_address_zipcode === null) return false;
        if (invoice.header.client_address_town === null) return false;
        return true;
    }

    const updateRows = (rows: RowInterface[]) => {
        dispatchInvoice({type: 'update_rows', data: rows});
    }

    const getOption = (s: string) => {
        for (const kv of invoice.options){
            if (kv.code === s) {
                return kv.value;
            }
        }
        return '';
    }

    const generatePDF = () => {
        setIsWorking(true);
        setIsPDF(false);
        const F = new FormData();
        F.append('id', missionId + '');
        F.append('rows', JSON.stringify(invoice.rows));
        for (const kv of invoice.options) {
            F.append(kv.code, kv.value);
        }
        const api = `${myContext.apiAddress}/create_invoice_mission?id=${missionId}&mode=produce`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsWorking(false);
            if (res.data.status === true) {
                setIsPDF(true);
            } else {
                AlertNotification({message: res.data.message || 'unknown internal error'});
            }
        });
    }

    const savePDF = () => {
        if (!isPDF) return;
        setIsWorking(true);
        const api = `${myContext.apiAddress}/create_invoice_mission?id=${missionId}&mode=save`;
        myContext.httpClient.get(api).then((res:any) => {
            setIsWorking(false);
            if (res.data.status === true) {
                setIsOpened(false);
                handle({...res.data.data});
            } else {
                AlertNotification({message: res.data.message || 'unknown internal error'});
            }
        });
    }

    return (
        <>
        <ActionIcon color="blue" variant="outline" onClick={() => setIsOpened(true)} >
            <IconFilePencil size={16} />
        </ActionIcon>
        <Modal
            title={`Création d'une facture client pour "${missionLabel}" [${missionId}]`}
            opened={isOpened}
            onClose={() => setIsOpened(false)}
            fullScreen
        >
            <>
                <LoadingOverlay visible={isWorking} />
                <Divider label="Eléments d'en tête de la facture client" labelPosition='center' />
                {invoice.header !== null &&
                <Table style={{maxWidth: 'min(100vw, 700px)'}} highlightOnHover><tbody>
                    <tr>
                        <td><Text color="dimmed" size="sm">Nom du manager de transition</Text></td>
                        <td><Text>{invoice.header.manager_name || '-- à compléter sur la page précédente --'}</Text></td>
                    </tr>
                    <tr>
                        <td><Text color="dimmed" size="sm">Adresse (nom du client)</Text></td>
                        <td><Text>{invoice.header.client_name || '-- à compléter sur la page précédente --'}</Text></td>
                    </tr>
                    <tr>
                        <td><Text color="dimmed" size="sm">Adresse (rue, ligne 1)</Text></td>
                        <td><Text>{invoice.header.client_address_row_1 || '-- à compléter sur la page précédente --'}</Text></td>
                    </tr>
                    <tr>
                        <td><Text color="dimmed" size="sm">Adresse (rue, ligne 2)</Text></td>
                        <td><Text>{invoice.header.client_address_row_2}</Text></td>
                    </tr>
                    <tr>
                        <td><Text color="dimmed" size="sm">Adresse (rue, ligne 3)</Text></td>
                        <td><Text>{invoice.header.client_address_row_3}</Text></td>
                    </tr>
                    <tr>
                        <td><Text color="dimmed" size="sm">Code postal</Text></td>
                        <td><Text>{invoice.header.client_address_zipcode || '-- à compléter sur la page précédente --'}</Text></td>
                    </tr>
                    <tr>
                        <td><Text color="dimmed" size="sm">Ville</Text></td>
                        <td><Text>{invoice.header.client_address_town || '-- à compléter sur la page précédente --'}</Text></td>
                    </tr>
                    <tr>
                        <td><Text color="dimmed" size="sm">Pays</Text></td>
                        <td><Text>{invoice.header.client_address_country}</Text></td>
                    </tr>
                    <tr>
                        <td><Text>Mode de paiement</Text></td>
                        <td>
                            <TextInput 
                                value={getOption('payment')}
                                onChange={(event) => dispatchInvoice({type: 'update_option', code: 'payment', value: event.currentTarget.value})}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Stack spacing={0}>
                                <Text>N° chrono</Text>
                                <Text size='xs'>Laisser AUTO pour une génération automatique.</Text>
                            </Stack>
                        </td>
                        <td>
                            <TextInput 
                                value={getOption('chrono')} 
                                onChange={(event) => dispatchInvoice({type: 'update_option', code: 'chrono', value: event.currentTarget.value})} 
                                disabled={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><Text>Masquer le nom du manager de transition ?</Text></td>
                        <td>
                            <Switch 
                                checked={getOption('is_manager_hidden') === 'true' ? true : false} 
                                onChange={(event) => dispatchInvoice({type: 'update_option', code: 'is_manager_hidden', value: event.currentTarget.checked + ''})} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><Text>S'agit-il d'un avoir ?</Text></td>
                        <td>
                            <Switch 
                                checked={getOption('is_credit_note') === 'true' ? true : false} 
                                onChange={(event) => dispatchInvoice({type: 'update_option', code: 'is_credit_note', value: event.currentTarget.checked + ''})} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><Text>Langue ?</Text></td>
                        <td>
                            <SegmentedControl
                                data={[
                                    { label: 'français', value: 'french' },
                                    { label: 'english', value: 'english' },
                                ]}
                                onChange={(v: string) => dispatchInvoice({type: 'update_option', code: 'language', value: v})}
                            />
                        </td>
                    </tr>
                </tbody></Table>
                }
                <Divider label="Lignes de facturation" labelPosition='center' />
                {(isHeaderCorrect()) &&
                <FinancialFlowEditor
                    updateApi='' data={invoice.rows} push={updateRows} isEditable={true}
                />
                }
                <Divider label="Complément d'information" labelPosition='center' />
                <Textarea 
                    value={getOption('extra')}
                    onChange={(event: any) => dispatchInvoice({type: 'update_option', code: 'extra', value: event.currentTarget.value})}
                    minRows={3}
                    maxRows={3}
                    maxLength={400}
                />
                <Divider label="Génération" labelPosition='center' />
                <Center style={{padding: '1em'}}>
                    <Button 
                        disabled={!isHeaderCorrect() || invoice.rows.length === 0}
                        onClick={() => generatePDF()}
                    >
                        Générer le document{invoice.rows.length > 0 ? ` avec ${invoice.rows.length} ligne${invoice.rows.length > 1 ? 's' : ''}${getOption('extra') === '' ? '' : ' et un commentaire.'}` : ''}
                    </Button>
                </Center>
            </>
            {isPDF && 
            <>
                <Divider label="Fichier généré" labelPosition='center' />
                <DisplayPDF fileUrl={`${myContext.apiAddress}/create_invoice_mission?id=${missionId}&mode=send`} />
                <Center style={{padding: '1em'}}>
                    <Button onClick={() => savePDF()}>
                        Rattacher cette facture à la mission
                    </Button>
                </Center>
            </>
            }
        </Modal>
        </>
    )
}

export { InvoiceMakerButton }
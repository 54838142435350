import { useState } from 'react';
import { ActionIcon, Center, Button, Modal, Text } from '@mantine/core';
import { IconCalendar } from '@tabler/icons';
import { DateInputContainer } from '../DateInputContainer';

interface PropsInterface {
    label1: string | null,
    label2: string | null,
    handle: any,
}

const DateInputButton = ({label1, label2, handle}: PropsInterface) => {

    const [display, setDisplay] = useState(false);
    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('');

    return (
        <>
        <Modal
            opened={display}
            onClose={() => setDisplay(false)}
        >
            {label1 !== null && <div>
                <Text>{label1}</Text>
                <DateInputContainer handle={setValue1} />
            </div>}
            {label2 !== null && <div>
                <Text>{label2}</Text>
                <DateInputContainer handle={setValue2} />
            </div>}
            <Center pt="md">
                <Button disabled={(label1 !== null && value1 === '') || (label2 !== null && value2 === '') || (label1 === null && label2 === null)}
                    onClick={() => { if (handle !== null) { 
                        handle(value1, value2)}; 
                        setDisplay(false);
                    } }
                >
                    Valider
                </Button>
            </Center>
        </Modal>
        <ActionIcon color="blue" variant="outline" onClick={() => setDisplay(true)}>
            <IconCalendar size={16}/>
        </ActionIcon>
        </>
    )
}

export { DateInputButton }
import { useContext, useState } from 'react';
import { Group, Stack } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconFileCertificate } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TrashButton } from '../../shared/TrashButton';
import { DocumentButton } from '../ManageMission/document_button';

interface FileInterface {
    id: number,
    label: string,
}
interface PropsInterface {
    missionId: number,
    files: FileInterface[],
    year: string,
    month: string,
}

const Files = ({missionId, year, month, files}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [working, setWorking] = useState(false);
    const [myFiles, setMyFiles] = useState<FileInterface[]>(files.map((f) => { return {...f}}));

    const removeFile = (id: number) => {
        setMyFiles(myFiles.filter((f) => f.id !== id));
    }

    const saveFile = (inputFiles: File[]) => {
        setWorking(true);
        const F = new FormData();
        F.append('id', missionId + '');
        F.append('action', 'add');
        F.append('reference', 'CRA-'+missionId+'-'+year+'-'+month);
        F.append('category', 'report');
        F.append('status', 'signed');
        let n = inputFiles[0].name.normalize();
        F.append('document', inputFiles[0], n);
        F.append('label', n);
        const api = `${myContext.apiAddress}/update_report_mission`;
        myContext.httpClient.post(api, F, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                setMyFiles((myFiles.map((f) => {return {...f}})).concat({id: res.data.data.id, label: res.data.data.filename}));
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        })
    }

    return (
        <Stack>
            {myFiles.length !== 0 && <Group>
                {myFiles.map((f) => 
                <Group key={`file-${f.id}`}>
                    <DocumentButton
                        title={f.label}
                        category='report'
                        missionId={missionId}
                        missionOpened={true}
                        fileId={f.id}
                        handleChange={null}
                    />
                    <TrashButton
                        label={`Supprimer "${f.label}" ?`}
                        api={`update_report_mission`}
                        params={`id=${missionId}&file_id=${f.id}&action=remove`}
                        handle={() => removeFile(f.id)}
                    />
                </Group>
                )}
            </Group>}
            <Dropzone
                onDrop={(files) => saveFile(files)}
                onReject={(files) => console.log('rejected files', files)}
                maxSize={1 * 1024 ** 2}
                maxFiles={1}
                accept={[ 'application/pdf' ]}
                loading={working}
                padding="xs"
            >
                <Dropzone.Idle>
                    <Group position="center" spacing={0} style={{ height: '1em', pointerEvents: 'none' }}>
                        <IconFileCertificate size={16} />
                    </Group>
                </Dropzone.Idle>
            </Dropzone>
        </Stack>
    )

}

export { Files }
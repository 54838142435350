import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Badge, Box, Button, Card, Center, Divider, Group, LoadingOverlay, Mark, Modal, Text, Textarea } from '@mantine/core';
import { IconMessagePlus, IconMessages } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface MessageInterface {
    id: number,
    message: string,
    nature: string | null,
    contact_id: number,
    contact_name: string,
    created_date: string,
    created_name: string,
}
const messagesReducer = (state: MessageInterface[], payload: any) => {
    switch(payload.type){
        case 'set':
            return payload.data;
        case 'add':
            return [{...payload.data}].concat(state);
        default:
            return [];
    }
}

interface PropsInterface {
    companyId: number,
    companyName: string,
    contactId: number,
    contactName: string,
}

const MessageButton = ({companyId, companyName, contactId, contactName}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [isLoaded,setIsLoaded] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const [messages, dispatchMessages] = useReducer(messagesReducer, []);
    const [isWorking, setIsWorking] = useState(false);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        setIsLoaded(false);
    }, [contactId]);

    useEffect(() => {
        if (!isOpened) return;
        if (isLoaded) return;
        const api = `${myContext.apiAddress}/list_message_book?id=${companyId}`;
        myContext.httpClient.get(api).then((res:any) => {
            setIsLoaded(true);
            if (res.data.status === true) {
                if (res.data.data !== null) {
                    dispatchMessages({type: 'set', data: res.data.data});
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [isOpened]) // eslint-disable-line

    const saveMessage = () => {
        const s = newMessage.trim();
        if (s.length < 10) return;
        const api = `${myContext.apiAddress}/update_message_book`;
        const F = new FormData();
        F.append('action', 'add');
        F.append('company_id', companyId + '');
        F.append('contact_id', contactId + '');
        F.append('message', s);
        setIsWorking(true);
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsWorking(false);
            if (res.data.status === true) {
                dispatchMessages({type: 'add', data: {
                    id: res.data.data.id,
                    message: newMessage,
                    contact_id: contactId,
                    contact_name: contactName,
                    created_name: 'moi',
                    created_date: "Aujourd'hui à " + (new Date()).getHours() + "h" + (new Date()).getMinutes(),
                }});
                setNewMessage('');
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <>
        <Modal
            opened={isOpened}
            onClose={() => setIsOpened(false)}
            title={`Messages liés à ${companyName}`}
            fullScreen
        >
            <Box>
                <LoadingOverlay visible={!isLoaded}/>
                {contactId > 0 &&
                <>
                <Textarea
                    label={<Text>Notes prises suite à un échange avec <Mark>{contactName}</Mark> ce jour</Text>}
                    value={newMessage}
                    onChange={(event) => setNewMessage(event.target.value)}
                    autosize
                    minRows={3}
                    maxRows={5}
                />
                <Center>
                    <Button loading={isWorking} disabled={newMessage.trim().length < 10} onClick={() => saveMessage()}>
                        Transférer le message
                    </Button>
                </Center>
                </>}
                <Divider pt="xs" label={`${messages.length} message${messages.length > 1 ? 's' : ''}, du plus récent au plus ancien`} labelPosition='center'/>
                <Center>
                    <Box style={{width: 'min(100%, 800px'}}>
                        {messages.map((m: MessageInterface,i: number) => 
                        <Card key={`message-${i}`} m="xs">
                            <Group position='apart'>
                                {m.contact_id === contactId ? 
                                <Text><Mark>{m.contact_name}</Mark></Text> 
                                : 
                                <Text weight={700}>{m.contact_name}</Text>
                                }
                                {m.nature !== null && <Badge>{m.nature}</Badge> }
                            </Group>
                            <Text color="dimmed" size="sm">Le {m.created_date} par {m.created_name}</Text>
                            {m.message.split("\n").map((l,j) => 
                            <Text key={`message-${i}-row-${j}`}>{l}</Text>
                            )}
                        </Card>
                        )}
                    </Box>
                </Center>
            </Box>
        </Modal>
        <ActionIcon color="blue" variant="outline" onClick={() => setIsOpened(true)}>
            {contactId === 0 ? <IconMessages size={16} /> : <IconMessagePlus size={16} />}
        </ActionIcon>
        </>
    )
}

export { MessageButton }
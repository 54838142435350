import { useContext, useState } from 'react';
import { ActionIcon, Badge, Button, Group, Loader, Modal, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import { IconCheck } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../AlertNotification';

interface ItemInterface {
    code: string,
    value: string,
}

interface PropsInterface {
    shortTitle: string,
    longTitle: string,
    items: ItemInterface[],
    currentValue: string,
    api: string,
}

const ShortListItemSelector = ({shortTitle, longTitle, items, currentValue, api}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ opened, setOpened ] = useState(false);
    const [ value, setValue ] = useState(currentValue);
    const [ newValue, setNewValue ] = useState('');
    const [ working, setWorking ] = useState(false);

    const changeTo = (v: string) => {
        setOpened(false);
        const F = new FormData();
        F.append('value', v);
        setWorking(true);
        myContext.httpClient.post(`${myContext.apiAddress}/${api}`, F).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                setValue(v);
            } else {
                AlertNotification(res.data.message || 'unknown error');
            }
        });
    }

    return (<>
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title={longTitle}
        >
            <ScrollArea style={{height: 'calc(100vh - 200px)'}} p='xs'>
                <Stack>
                    {items.map((item: ItemInterface) => 
                    <Button color="blue" variant="outline" key={`source-${item.code}`} onClick={() => changeTo(item.value)}>{item.value}</Button>
                    )}
                    <Group style={{ alignItems: 'end' }}>
                        <TextInput style={{width: 'calc(100% - 50px)'}} label="Autre valeur :" value={newValue} onChange={(event) => setNewValue(event.currentTarget.value)} />
                        <ActionIcon mb={5} color="blue" variant="outline" onClick={() => changeTo(newValue)}
                            disabled={value.trim().length < 2}
                        >
                            <IconCheck size={16} />
                        </ActionIcon>
                    </Group>
                </Stack>
            </ScrollArea>
        </Modal>
        <Group>
            <Text color="dimmed" size="xs">{shortTitle}</Text>
            {working ?
            <Loader size='sm' />
            :
            <Badge style={{ textTransform: 'none', cursor: 'pointer' }} onClick={() => setOpened(true)}>{value}</Badge>
            }
        </Group>
    </>)

}

export { ShortListItemSelector }
import { useContext, useReducer, useEffect, useState } from 'react';
import { ActionIcon, Alert, Center, Drawer, Group, Text, Loader, ScrollArea, Table } from '@mantine/core';
import { IconAlertCircle, IconUserCheck, IconEdit } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';
import { EditAccount } from '../EditAccount';
import { AvatarIcon } from '../../shared/AvatarIcon';
import { ToggleContainer } from '../../shared/ToggleContainer';

const usersReducer = (state: any, payload: any) => {
    switch (payload.type) {
        case 'LOAD':
            return {
                ...state,
                isLoading: false,
                data: payload.data,
            }
        case 'UPDATE':
            const n = state.data.map((u: UserInterface) => { return {...u}});
            const id = payload.user.id;
            for (let i=0;i<n.length;i++) {
                if (n[i].id === id) {
                    if (payload.user.nickname !== undefined) {
                        n[i].nickname = payload.user.nickname;
                    }
                    if (payload.user.firstname !== undefined) {
                        n[i].firstname = payload.user.firstname;
                    }
                    if (payload.user.lastname !== undefined) {
                        n[i].lastname = payload.user.lastname;
                    }
                    if (payload.user.email !== undefined) {
                        n[i].email = payload.user.email;
                    }
                    if (payload.user.is_frozen !== undefined) {
                        n[i].is_frozen = payload.user.is_frozen;
                    }
                    if (payload.user.avatar !== undefined) {
                        n[i].avatar = payload.user.avatar;
                    }
                    break;
                }
            }
            return {
                ...state,
                data: n,
            }
        case 'ERROR':
            return {
                ...state,
                isLoading: false,
                isUpdating: false,
                error: payload.message,
            }
        default:
            return {
                ...state,
                error: 'Oops, unknown local error.',
            }
    }
}

interface UserInterface {
    id: number,
    nickname: string,
    firstname: string,
    lastname: string,
    email: string,
    avatar: string,
    is_frozen: boolean,
    last_connected_date: string,
}

const ManageAccounts = () => {

    const myContext = useContext(AppContext);
    const apiUrlGet: string = myContext.apiAddress + '/get_repository_account';
    const noData: UserInterface[] = [];
    const [users, dispatchUsers] = useReducer(
        usersReducer, {
            isLoading: true,
            isUpdating: false,
            error: null,
            data: noData,
        }
    );
    const noUser: UserInterface = {
        id: -1,
        nickname: '',
        firstname: '',
        lastname: '',
        email: '',
        avatar: '',
        is_frozen: false,
        last_connected_date: '',
    }
    const [user, setUser] = useState(noUser);
    const [filterOnActif, setFilterOnActif] = useState(true);

    useEffect(() => {
        if (!users.isLoading) return;
        if (apiUrlGet.startsWith('null')) return;
        myContext.httpClient.get(apiUrlGet).then((result: any) => {
            if (result.data.status === true) {
                dispatchUsers({type: 'LOAD', data: result.data.data});
            } else if (result.data.status === false) {
                dispatchUsers({type: 'ERROR', message: result.data.message});
            } else {
                dispatchUsers({type: 'ERROR', message: 'Strange, the servor returned a bad answer.'});
            }
        });
    }, [users.isLoading, apiUrlGet, myContext.httpClient]);

    if (users.isLoading) {
        return (
            <Center>
                <Group>
                    <Loader color="blue" size="md" />
                    <Text color="blue">Récupération des comptes des utilisateurs en cours&hellip;</Text>
                </Group>
            </Center>
        );
    }

    if (users.error !== null) {
        return (
            <Center>
                <Alert icon={<IconAlertCircle size={16} />} title="Gestion des comptes des utilisateurs" color="red" radius="md">
                    {users.error}
                </Alert>
            </Center>
        )
    }

    const updateUser = (user: UserInterface) => {
        dispatchUsers({type: 'UPDATE', user: {...user}});
        setUser({...noUser});
    }

    return (
        <>
        <Drawer
            opened={user.id >= 0}
            onClose={() => setUser({...noUser})}
            title={`Modifier l'utilisateur ${user.id}`}
            padding="xl"
            size="xl"
            position="right"
        >
            <EditAccount 
                id={user.id} 
                nickname={user.nickname} 
                firstname={user.firstname}
                lastname={user.lastname}
                email={user.email}
                is_frozen={user.is_frozen}
                handleChange={updateUser}
            />
        </Drawer>
        <Group position="apart">
            <TitleContainer>Gérer les comptes utilisateurs</TitleContainer>
            <ToggleContainer 
                defaultValue={filterOnActif}
                label='Actifs ?'
                isEdition={true}
                handle={(b: boolean) => setFilterOnActif(b)}
                api=''
                action=''
            />
        </Group>
        <ScrollArea style={{height: 'calc(100vh - 120px)'}}>
            <Center><Text color="blue">{users.data.filter((u: UserInterface) => u.is_frozen !== filterOnActif).length} utilisateurs</Text></Center>
            <Table sx={{ minWidth: 1000 }} highlightOnHover>
                <thead>
                    <tr>
                        <th>Est actif ?</th>
                        <th>Avatar</th>
                        <th>Prénom</th>
                        <th>Nom de famille</th>
                        <th>Email</th>
                        <th>Login</th>
                        <th>Dernière connexion le</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.data.filter((u: UserInterface) => u.is_frozen !== filterOnActif).map((u: UserInterface) => (
                        <tr key={'user-' + u.id}>
                            <td>
                                {!u.is_frozen &&
                                <Center>
                                    <IconUserCheck size={16} color="green" />
                                </Center>
                                }
                            </td>
                            <td>
                                <AvatarIcon 
                                    avatar={u.avatar}
                                    firstname={u.firstname}
                                    lastname={u.lastname}
                                    isFull={false}
                                />
                            </td>
                            <td>{u.firstname}</td>
                            <td>{u.lastname}</td>
                            <td>{u.email}</td>
                            <td>{u.nickname}</td>
                            <td>{u.last_connected_date}</td>
                            <td>
                                <ActionIcon variant="outline" onClick={() => { setUser({...u}) }}>
                                    <IconEdit size={16} />
                                </ActionIcon>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </ScrollArea>
        </>
    )
}

export { ManageAccounts }
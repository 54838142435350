import { useContext, useEffect, useReducer, useState } from 'react';
import AppContext from '../../shared/AppContext';
import { ActionIcon, Button, Card, Center, Group, LoadingOverlay, Modal, ScrollArea, Stack, Text } from '@mantine/core';
import { IconCertificate } from '@tabler/icons';

import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';

interface FieldInterface {
    code: string,
    value: string,
    nature: 'text' | 'textarea',
}
const fieldsReducer = (state: FieldInterface[], payload: any) => {
    switch (payload.type) {
        case 'fill':
            return payload.data.map((f:FieldInterface) => { return {...f}});
        case 'set':
            return state.map((f:FieldInterface) => {
                if (f.code === payload.code) {
                    return {...f, value: payload.value}
                } else {
                    return {...f};
                }
            })
        default:
            return [];
    }
}

interface PropsInterface {
    need_id: number,
    cv_id: number,
}

const Contract = ({need_id, cv_id}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [opened, setOpened] = useState(false);
    const [nature, setNature] = useState<string | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [fields, dispatchFields] = useReducer(fieldsReducer, []);

    const editorForContract = useEditor({
        extensions: [
          StarterKit, Underline, Superscript, SubScript, Highlight,
          TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content: '',
    });

    useEffect(() => {
        if (nature === null) return;
        setLoaded(false);
        const api = `${myContext.apiAddress}/create_contract_need?need_id=${need_id}&cv_id=${cv_id}&nature=${nature}`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                dispatchFields({type: 'fill', data: res.data.data.fields});
                let s: string = res.data.data.content;
                for (let f of res.data.data.fields) {
                    if (f.value !== null && (f.value + '').length > 1) {
                        s = s.replaceAll('{{' + f.code + '}}', f.value + '');
                    }
                }
                if (editorForContract !== null) {
                    editorForContract.commands.setContent(s);
                }
            }
            setLoaded(true);
        });
    }, [nature]); // eslint-disable-line

    const printDocument = () => {
        let myWindow = window.open('', 'PRINT');
        if (myWindow !== null) {
            myWindow.document.write(`<html><head><title>SNR PARTNERS</title></head><body>${editorForContract?.getHTML()}</body></html>`);
            myWindow.print();
            myWindow.close();
        }
    }

    return (
        <>
        <Modal
            opened={opened}
            onClose={() => { setOpened(false); setLoaded(false); setNature(null); }}
            fullScreen
        >
            {nature === null ? 
            <Center>
                <Stack>
                    <Button color='blue' variant='outline' onClick={() => setNature('client')}>
                        Contrat client
                    </Button>
                    <Button color='blue' variant='outline' onClick={() => setNature('portage')}>
                        Contrat si MT avec portage
                    </Button>
                    <Button color='blue' variant='outline' onClick={() => setNature('supplier')}>
                        Contrat si MT a sa société
                    </Button>
                </Stack>
            </Center>
            :
            <div>
                <LoadingOverlay visible={!loaded} />
                {loaded &&
                <Center>
                    <Stack>
                        <Group position="right">
                            <Button color='blue' variant='outline' onClick={() => printDocument()}>
                                Imprimer
                            </Button>
                        </Group>
                        <ScrollArea style={{height: 'calc(100vh - 150px)', width: '210mm'}}>
                            {fields.filter((f:FieldInterface) => (f.value === null || f.value.length < 1)).length > 0 &&
                            <Card>
                                <Text>Champs à renseigner dans le brief :</Text>
                                {fields.filter((f:FieldInterface) => (f.value === null || f.value.length < 1)).map((f:FieldInterface) => 
                                <Text color="red" key={`field-empty-for-${f.code}`}>{f.code}</Text>
                                )}
                            </Card>
                            }
                            <RichTextEditor editor={editorForContract}>
                                <RichTextEditor.Toolbar sticky stickyOffset={0}>
                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.Bold />
                                    <RichTextEditor.Italic />
                                    <RichTextEditor.Underline />
                                    <RichTextEditor.Strikethrough />
                                    <RichTextEditor.ClearFormatting />
                                    <RichTextEditor.Highlight />
                                    <RichTextEditor.Code />
                                </RichTextEditor.ControlsGroup>

                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.H1 />
                                    <RichTextEditor.H2 />
                                    <RichTextEditor.H3 />
                                </RichTextEditor.ControlsGroup>

                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.Blockquote />
                                    <RichTextEditor.Hr />
                                    <RichTextEditor.BulletList />
                                    <RichTextEditor.OrderedList />
                                    <RichTextEditor.Subscript />
                                    <RichTextEditor.Superscript />
                                </RichTextEditor.ControlsGroup>

                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.AlignLeft />
                                    <RichTextEditor.AlignCenter />
                                    <RichTextEditor.AlignJustify />
                                    <RichTextEditor.AlignRight />
                                </RichTextEditor.ControlsGroup>
                                </RichTextEditor.Toolbar>

                                <RichTextEditor.Content />
                            </RichTextEditor>
                        </ScrollArea>
                    </Stack>
                </Center>
                }
            </div>
            }
        </Modal>
        <ActionIcon color="blue" variant="outline" onClick={() => setOpened(true)}>
            <IconCertificate size={16} />
        </ActionIcon>
        </>
    );
}

export { Contract }
import { useEffect } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

type ParamInterface = {
    paramUrl: string,
}

const JumpTo = () => {

    const { paramUrl } = useParams<ParamInterface>();
    const url: string | null = (paramUrl === undefined) ? null : paramUrl;
    const navigate = useNavigate();

    useEffect(() => {
        if (url === null) {
            console.log(url);
        } else {
            navigate(`${decodeURIComponent(url)}`, { replace: true });
        }
    }, []); // eslint-disable-line

    return (<>
        <LoadingOverlay visible={true} />
    </>)
}

export { JumpTo }
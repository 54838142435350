import { useContext, useState } from "react";
import { Alert, Button, Group, Loader, PasswordInput, Text, TextInput } from "@mantine/core";
import { IconAlertCircle } from '@tabler/icons';
import AppContext from "../../shared/AppContext";
import { AlertNotification } from '../../shared/AlertNotification';
import { toEmail } from '../../../services/functions';

const RecoveryContainer = () => {

    const [isWaiting,setIsWaiting] = useState(false);
    const myContext = useContext(AppContext);
    const [step, setStep] = useState(0);
    const [ domain, setDomain ] = useState('');
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [pwd1,setPwd1] = useState('');
    const [pwd2,setPwd2] = useState('');

    const reset = () => {
        setIsWaiting(false);
        setStep(0);
        setDomain('');
        setNickname('');
        setEmail('');
        setCode('');
    }

    const submitStep0 = () => {
        setIsWaiting(true);
        const f = new FormData();
        f.append('dom', domain);
        f.append('step', '0');
        f.append('nickname', nickname);
        f.append('email', email);
        myContext.httpClient.post(`${myContext.apiAddress}/recover`, f).then((res: any) => {
            if (res.data.status === true) {
                setStep(1);
            } else {
                setNickname('');
                setEmail('');
                AlertNotification({message: 'Je ne suis pas en mesure de donner suite à votre demande.'});
            }
            setIsWaiting(false);
        });
    }

    const submitStep1 = () => {
        setIsWaiting(true);
        const f = new FormData();
        f.append('dom', domain);
        f.append('step', '1');
        f.append('code', code);
        myContext.httpClient.post(`${myContext.apiAddress}/recover`, f).then((res: any) => {
            if (res.data.status === true) {
                setStep(2);
            } else {
                setNickname('');
                setEmail('');
                AlertNotification({message: 'Je ne suis pas en mesure de donner suite à votre demande.'})
            }
            setIsWaiting(false);
        });
    }

    const submitStep2 = () => {
        setIsWaiting(true);
        const f = new FormData();
        f.append('dom', domain);
        f.append('step', '2');
        f.append('pwd', pwd1);
        myContext.httpClient.post(`${myContext.apiAddress}/recover`, f).then((res: any) => {
            if (res.data.status === true) {
                setStep(3);
            } else {
                setNickname('');
                setEmail('');
                setCode('');
                AlertNotification({message: 'Je ne suis pas en mesure de donner suite à votre demande.'});
            }
            setIsWaiting(false);
        });
    }

    return (
        <>
        { step > -1 && 
            <>
            <Text weight="bold">Etape 1</Text>
            <TextInput
                label="Votre domaine"
                value={domain}
                onChange={(event) => { setDomain(event.target.value.trim().toLocaleUpperCase())}}
                disabled={step === 0 ? false : true}
                required
            />
            {(domain !== '' && !(/^[A-Z]+$/.test(domain))) && 
            <Text color='red'>Ce domaine ne semble pas correct.</Text>
            }
            <TextInput
                label="Votre identifiant de connexion"
                value={nickname}
                onChange={(event) => { setNickname(event.target.value)}}
                disabled={step === 0 ? false : true}
                required
            />
            {(nickname !== '' && !(/^[a-z]+$/.test(nickname))) && 
            <Text color='red'>Cet identifiant ne semble pas correct.</Text>
            }
            <TextInput
                label="Votre adresse mail"
                value={email}
                onChange={(event) => { setEmail(event.target.value)}}
                disabled={step === 0 ? false : true}
                required
            />
            {(email.length > 0 && toEmail(email) === null) &&
            <Text color='red'>Cet email ne semble pas correct.</Text>
            }
            { step === 0 && <Group><Button 
                    style={{marginTop: '1em'}} 
                    onClick={() => submitStep0()}
                    disabled={!(/^[a-z]+$/.test(nickname) && email !== '' && toEmail(email) !== null)}
                >Soumettre</Button></Group>}
        </>
        }
        { step > 0 && 
            <>
            <Text weight="bold">Etape 2</Text>
            <Text>Vous venez de recevoir un code par email, merci de le renseigner ci-après :</Text>
            <TextInput
                label="Code de vérification"
                value={code}
                onChange={(event) => { setCode(event.target.value)}}
                disabled={step === 1 ? false : true}
                required
            />
            { step === 1 && <Group><Button 
                    style={{marginTop: '1em'}} 
                    onClick={() => submitStep1()}
                    disabled={!(/^[0-9]+$/.test(code))}
                >Soumettre</Button></Group>}
            </>
        }
        { step > 1 &&
            <>
            <Text weight="bold">Etape 3</Text>
            <PasswordInput
                required
                label="Nouveau mot de passe"
                value={pwd1}
                onChange={(event) => setPwd1(event.currentTarget.value)}
            />
            { /^[a-zA-Z0-9]+$/.test(pwd1) === false && <Text color="red" size="sm">Que des lettres et des chiffres.</Text> }
            { pwd1.length < 8 && <Text color="red" size="sm">Au moins 8 caractères.</Text> }
            { (pwd1.length - pwd1.replaceAll(/[a-zA-Z]/g,'').length < 2) && <Text color="red" size="sm">Au moins 2 lettres.</Text> }
            { (pwd1.length - pwd1.replaceAll(/[0-9]/g,'').length < 2) && <Text color="red" size="sm">Au moins 2 chiffres.</Text> }
            <PasswordInput
                required
                label="Resaisie du nouveau mot de passe"
                value={pwd2}
                onChange={(event) => setPwd2(event.currentTarget.value)}
            />
            { (pwd2.length > 0 && pwd1 !== pwd2) && <Text color="red" size="sm">La resaisie n'est pas identique au nouveau mot de passe.</Text> }
            { step === 2 && 
                <Group><Button
                    disabled={/^[a-zA-Z0-9]+$/.test(pwd1) === false || pwd1.length < 8 || pwd1.length - pwd1.replaceAll(/[a-zA-Z]/g,'').length < 2 || pwd1.length - pwd1.replaceAll(/[0-9]/g,'').length < 2}
                    onClick={() => submitStep2()}
                    style={{marginTop: '1em'}}
                >
                    Soumettre
                </Button></Group>
            }
            </>
        }
        { step > 2 &&
            <>
            <Text weight="bold">Fin</Text>
            <Alert icon={<IconAlertCircle size={16} />} title="Bien joué" color="green">
                Votre mot de passe a été changé avec succès.
            </Alert>
            </>
        }
        { (step > 0 && !isWaiting) && 
            <>
            <Group><Button
                onClick={() => reset()}
                variant='default'
                style={{marginTop: '1em'}}
            >
                Recommencer
            </Button></Group>
            </>
        }
        { isWaiting && <Loader />}
        </>
    );

}

export { RecoveryContainer }

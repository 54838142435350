import { Center, ScrollArea, Stack, Text } from '@mantine/core';
import { useParams } from "react-router-dom";
import { ContactBook } from "../ContactBook";
import { TitleContainer } from "../../shared/TitleContainer";

type ParamInterface = {
    paramCompanyId: string,
    paramCompanyName: string,
}

const CompanyFiche = () => {

    const { paramCompanyId, paramCompanyName } = useParams<ParamInterface>();

    return (
        <Center>
            <Stack style={{ width: 'min(100%, 600px)' }}>
                <TitleContainer>
                    {paramCompanyName}    
                </TitleContainer>
                {(/^[1-9][0-9]{0,3}$/.test(paramCompanyId || 'x') && (paramCompanyName || '').length > 1) ?
                <ScrollArea style={{height: 'calc(100vh - 130px)'}}>
                    <ContactBook
                        id={parseInt(paramCompanyId || '0')}
                        letter={(paramCompanyName || '_').substring(0,1).toLocaleUpperCase()}
                        label={paramCompanyName || 'undefined'}
                    />
                </ScrollArea>
                :
                <Text color='red'>paramètres incorrects</Text>
                }
            </Stack>
        </Center>
    )

}

export { CompanyFiche }
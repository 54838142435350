import { useState } from 'react';
import { SearchQueryCV } from "./query";
import { SearchResultCV } from "./result";

interface SearchInterface {
    searchString: string,
    isValidated: boolean,
}

interface PropsInterface {
    handleSelection: any,
}

const SearchCV = ({handleSelection}: PropsInterface) => {

    const [search, setSearch] = useState<SearchInterface>({searchString: '', isValidated: true});
    const [display, setDisplay] = useState(false);

    const runSearch = (param: SearchInterface) => {
        setSearch({...param});
        setDisplay(true);
    }
    
    return (
        <>
            {display === false && 
            <SearchQueryCV search={search} handleFunction={runSearch} />
            }
            {display === true &&
            <SearchResultCV search={search} handleBack={(res: SearchInterface) => { 
                setDisplay(false);
                setSearch({...res});
            }} handleSelection={handleSelection} />
            }
        </>
    );

}

export { SearchCV }
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Group, LoadingOverlay, ScrollArea, Switch } from '@mantine/core';
import AppContext from "../AppContext";
import { AlertNotification } from '../AlertNotification';

import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';

interface PropsInterface {
    code: string,
    value: string,
    api: string,
    handle: any,
}

const RichTextContainer = ({code, value, api, handle}: PropsInterface) => {

    const [ isRTE, setIsRTE ] = useState(false);
    const oldValue = useRef<string>(value);
    const newValue = useRef<string>(value);
    const [ isUpdating, setIsUpdating ] = useState(false);
    const [ hasChanged, setHasChanged ] = useState(false);
    const myContext = useContext(AppContext);

    const update = useCallback(() => {
        const D = new FormData();
        D.append('code', code);
        D.append('value', newValue.current);
        setIsUpdating(true);
        myContext.httpClient.post(api, D).then((result:any) => {
            setIsUpdating(false);
            if (result.data.status === true) {
                oldValue.current = newValue.current;
                setHasChanged(false);
                if (handle !== null) {
                    handle(newValue.current);
                }
            } else {
                AlertNotification({message: result.data.message || 'unknown error from servor'});
            }
        })
    }, []); // eslint-disable-line

    // --------------------------------------------------------
    // On met en place un mécanisme de sauvegarde automatique
    // après 10 secondes de non saisie suite à une modification.
    const unsavedSeconds = 10;
    const startAutoSaveTimer = useRef<any>(null);
    const lastSavingTime = useRef<number>(0);
    const testAutoSaveTimer = useCallback(() => {
        clearTimeout(startAutoSaveTimer.current);
        startAutoSaveTimer.current = setTimeout(() => {
            const t = (new Date()).getTime();
            if (t - lastSavingTime.current > (unsavedSeconds + 1)*1000) {
                lastSavingTime.current = t;
                if (oldValue.current !== newValue.current) {
                    update();
                }
            }
            testAutoSaveTimer();
        }, unsavedSeconds * 1000);
    }, [update]);
    // --------------------------------------------------------

    const editor = useEditor({
        extensions: [
          StarterKit,
          Underline,
          Superscript,
          SubScript,
          Highlight,
          TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content: value,
        onUpdate({ editor }) {
            newValue.current = editor.getHTML();
            if (oldValue.current === newValue.current) {
                setHasChanged(false);
            } else {
                testAutoSaveTimer();
                setHasChanged(true);                
            }
        },
    });

    useEffect(() => {
        return () => clearTimeout(startAutoSaveTimer.current);
    }, []);

    return (
        <Card>
            <LoadingOverlay visible={isUpdating} />
            <Group position="right">
                <Switch 
                    label="Mode édition"
                    checked={isRTE} 
                    onChange={(event) => {
                        if (event.currentTarget.checked) {
                            testAutoSaveTimer();
                            setIsRTE(true);
                        } else {
                            clearTimeout(startAutoSaveTimer.current);
                            setIsRTE(false);
                        }
                    }} 
                />
                {(isRTE && hasChanged) &&
                <>
                <Button color='blue' variant='outline' compact onClick={() => { editor?.commands.setContent(oldValue); setHasChanged(false); setIsRTE(false);}}>
                    Annuler les modifications ?
                </Button>
                <Button color='blue' variant='outline' compact onClick={() => update()}>
                    Sauvegarder les modifications ?
                </Button>
                </>}
            </Group>
            {isRTE ?
            <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                <RichTextEditor editor={editor}>
                    <RichTextEditor.Toolbar sticky stickyOffset={0}>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                    </RichTextEditor.ControlsGroup>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>
                    <RichTextEditor.Content />
                </RichTextEditor>
            </ScrollArea>
            :
            <div dangerouslySetInnerHTML={{__html: oldValue.current}} />
            }
        </Card>
    )
}

export { RichTextContainer }
interface PropsInterface {
    fileUrl: string
}

const DisplayPDF = ({fileUrl}: PropsInterface) => {

    return (
        <embed 
            src={`${fileUrl}`}
            type="application/pdf" 
            style={{width: '100%', height: '100%'}}
        />
    )

}

export { DisplayPDF }
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { ActionIcon, Center, Group, Loader, LoadingOverlay } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import { TitleContainer } from '../../shared/TitleContainer';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

const ChartContainer = lazy(() => import('../../shared/ChartContainer').then(({ChartContainer}) => ({default: ChartContainer})));

// composant
const OrgaTeam = () => {

    const myContext = useContext(AppContext);
    const [loaded,setLoaded] = useState(false);
    const [tree, setTree] = useState<any>(null);

    useEffect(() => {
        if (loaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_repository_organisation`).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                setTree(res.data.data);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [loaded]); // eslint-disable-line

    return (
        <>
        <Group position='apart'>
            <TitleContainer>Organigramme</TitleContainer>
            <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        <Center>
            {loaded ?
            <Suspense fallback={<Loader/>}>
                <ChartContainer 
                    option={{
                        style: {
                            width: '100vw',
                            height: 'calc(100vh - 120px)',
                        },
                        tooltip: {
                            trigger: 'item',
                            triggerOn: 'mousemove'
                        },
                        series: {
                            type: 'tree',
                            data: [tree],
                            symbolSize: 10,
                            //orient: 'vertical',
                            edgeShape: 'polyline',
                            label: {
                                position: 'left',
                                verticalAlign: 'middle',
                                align: 'right',
                                backgroundColor: 'red',
                                color: 'white',
                                padding: 5,
                                margin: 10,
                                fontSize: 15,
                            },
                            emphasis: {
                                focus: 'descendant'
                            },
                            expandAndCollapse: true,
                            animationDuration: 550,
                            animationDurationUpdate: 750
                        }
                    }}
                />
            </Suspense>
            :
            <LoadingOverlay visible={true}/>
            }
        </Center>
        </>
    )
}

export { OrgaTeam }
import { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Badge, Box, Group, SegmentedControl, ScrollArea, Table, Text, TextInput } from '@mantine/core';
import { IconRefresh, IconLockOpen, IconLock } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TitleContainer } from '../../shared/TitleContainer';

import { HuntInterface } from '../interfaces';

const HuntsReducer = (state: HuntInterface[], payload: any) => {
    switch(payload.type){
        case 'archive':
            return state.filter((p) => p.token !== payload.token);
        case 'set':
            return payload.data;
        case 'msg_subject':
            return state.map((p) => {
                if (p.token === payload.token) {
                    return {...p, msg_subject: payload.value }
                } else {
                    return {...p}
                }
            })
        case 'msg_body':
            return state.map((p) => {
                if (p.token === payload.token) {
                    return {...p, msg_body: payload.value }
                } else {
                    return {...p}
                }
            })
        case 'rename_label':
            return state.map((p) => {
                if (p.token === payload.token) {
                    return {...p, label: payload.value }
                } else {
                    return {...p}
                }
            })
        case 'rename_description':
            return state.map((p) => {
                if (p.token === payload.token) {
                    return {...p, description: payload.value }
                } else {
                    return {...p}
                }
            })
        case 'toggle':
            return state.map((p) => {
                if (p.token === payload.token) {
                    return {...p, is_open: !p.is_open }
                } else {
                    return {...p}
                }
            })
        default:
            return [];
    }
}

const ListHunts = () => {

    const myContext = useContext(AppContext);
    const [loaded, setLoaded] = useState(false);
    const [status, setStatus] = useState('1');
    const [hunts, dispatchHunts] = useReducer(HuntsReducer, []);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (loaded) return;
        const api = `${myContext.apiAddress}/list_hunt?status=${status}`;
        myContext.httpClient.get(api).then((res: any) => {
            if (res.data.status === true) {
                dispatchHunts({type: 'set', data: res.data.data || []});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoaded(true);
        });
    }, [loaded]); // eslint-disable-line

    useEffect(() => {
        setLoaded(false);
    }, [status]);

    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Liste des chasses
            </TitleContainer>
            <Group>
                {loaded && <Badge>{hunts.length}</Badge>}
                <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
        </Group>
        <Box maw={1000} mx="auto" mt='xs'>
            <Group position='apart'>
                <Group>
                    <Text>Rechercher :</Text>
                    <TextInput 
                        value={search}
                        onChange={(event) => setSearch(event.currentTarget.value.trim().toLocaleLowerCase())}
                        size='xs'
                    />
                </Group>
                <SegmentedControl
                    data={[
                        {label: 'ouverts à la collecte', value: '1'},
                        {label: 'fermés à la collecte', value: '0'},
                        {label: 'archivés', value: '-1'},
                    ]}
                    value={status}
                    onChange={setStatus}
                    color="blue"
                    size="xs"
                />    
            </Group>
            <ScrollArea style={{height: 'calc(100vh - 150px)'}}>
                <Table highlightOnHover><tbody>
                {hunts.filter((p:HuntInterface) => search === '' || p.label.toLocaleLowerCase().includes(search)).map((p:HuntInterface) => 
                <tr key={`project-${p.id}`}>
                    <td style={{maxWidth: '300px'}}>
                        <Box component={Link} to={`/hunt/manage/${p.id}`}>
                            <Text>{p.label}</Text>
                        </Box>
                    </td>
                    <td style={{maxWidth: '300px'}}>
                        {p.description !== null && <Text size='sm'>{p.description}</Text>}
                    </td>
                    <td>
                        {p.is_open ?
                        <Text color='teal'><IconLockOpen size={16} />ouvert</Text>
                        :
                        <Text color='red'><IconLock size={16} />fermé</Text>
                        }
                    </td>
                    <td style={{textAlign: 'right'}}>
                        <Badge>{p.n_short_profiles.toLocaleString()}</Badge>
                    </td>
                    <td style={{textAlign: 'right'}}>
                        <Badge>{p.n_long_profiles.toLocaleString()}</Badge>
                    </td>
                </tr>
                )}
                </tbody></Table>
            </ScrollArea>
        </Box>
        </>
    )
}

export { ListHunts }
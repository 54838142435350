import { useContext, useEffect, useState } from 'react';
import AppContext from '../../shared/AppContext';
import { ActionIcon, Alert, Button, Center, CopyButton, Divider, Group, LoadingOverlay, ScrollArea, Table, Text } from '@mantine/core';
import { IconInfoCircle, IconRefresh } from '@tabler/icons';
import { AlertNotification } from '../../shared/AlertNotification';
import { InfoNotification } from '../../shared/InfoNotification';

interface RowInterface {
    t: string, // date
    c: number, // nombre de formulaires créés
    s: number, // nombre de formulaires envoyés
    r: number, // nombre de demandes d'informations complémentaires
    d: number, // nombre de personnes ayant décliné
    o: number, // nombre de formulaires ouverts
    f: number, // nombre de formulaires remplis
}

interface PropsInterface {
    id: number,
}

const Statistics = ({id}: PropsInterface) => {

    const myContext = useContext(AppContext);

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [rows, setRows] = useState<RowInterface[]>([]);
    const [total, setTotal] = useState<RowInterface>({ t: '', c: 0, s: 0, r: 0, d: 0, o: 0, f: 0 });
    const [isUploadingAnswers, setIsUploadingAnswers] = useState(false);

    // chargement des données
    useEffect(() => {
        if (loaded) return;
        const api = `${myContext.apiAddress}/get_process_hunt?hunt_id=${id}&what=get_statistics`;
        myContext.httpClient.get(api).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                if (res.data.data.length === 0) {
                    setError('no data');
                } else {
                    setError(null);
                    setRows(res.data.data.map((a:RowInterface) => { return {...a}}));
                    const t = [0,0,0,0,0,0];
                    for(const row of res.data.data) {
                        t[0] += parseInt(row.c);
                        t[1] += parseInt(row.s);
                        t[2] += parseInt(row.r);
                        t[3] += parseInt(row.d);
                        t[4] += parseInt(row.o);
                        t[5] += parseInt(row.f);
                    }
                    setTotal({t: 'total', c: t[0], s: t[1], r: t[2], d: t[3], o: t[4], f: t[5]});
                }
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
    }, [loaded]); // eslint-disable-line

    const copyStats = () => {
        let s = '<table><tr><td>Date</td><td>Form created</td><td>Form sent</td><td>Has request</td><td>Has declined</td><td>Has just open</td><td>Form filled</td></tr>';
        for (const r of rows) {
            s += `<tr><td>${r.t}</td><td>${r.c}</td><td>${r.s}</td><td>${r.r}</td><td>${r.d}</td><td>${r.o}</td><td>${r.f}</td></tr>`;
        }
        s += '</table>';
        return s;
    }

    // téléchargement des réponses brutes
    const uploadFormAnswers = () => {
        if (isUploadingAnswers) return;
        setIsUploadingAnswers(true);
        const api = `${myContext.apiAddress}/get_process_hunt?hunt_id=${id}&what=get_answers`;
        myContext.httpClient.get(api).then((res:any) => {
            setIsUploadingAnswers(false);
            if (res.data.status === true) {
                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                    let s = '<table>';
                    s += '<tr><td>fullname</td><td>token</td><td>code</td><td>label</td><td>value</td></tr>';
                    for(const f of res.data.data){
                        let color: string;
                        if (f['score']['color'] === -1) {
                            color = 'red';
                        } else if (f['score']['color'] === 0) {
                            color = 'yellow';
                        } else if (f['score']['color'] === 1) {
                            color = 'green';
                        } else {
                            color = 'undefined';
                        }
                        s += `<tr><td>${f['fullname']}</td><td>${f['token']}</td><td>bm_color</td><td>BM - Code couleur</td><td>${color}</td></tr>`;
                        //s += `<tr><td>${f['fullname']}</td><td>${f['token']}</td><td>bm_comment</td><td>BM - Commentaire</td><td>${f['score']['comment'] === null ? '' : f['score']['comment']}</td></tr>`;
                        for(const v of f['values']) {
                            s += `<tr><td>${f['fullname']}</td><td>${f['token']}</td><td>${v['code']}</td><td>${v['label']}</td><td>${v['value'] === null ? '' : v['value']}</td></tr>`;
                        }
                    }
                    s += '</table>'
                    navigator.clipboard.writeText(s);
                    InfoNotification({message: 'Données copiées dans le presse papier.'});
                } else {
                    AlertNotification({message: 'The clipboard API is not available'});
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    // affichage du composant
    return (
        <Center>
            <ScrollArea style={{height: 'calc(100vh - 150px)'}} p='xs'>
                <LoadingOverlay visible={!loaded} />
                <Divider labelPosition='center' label="Section 1/2" />
                <Group position='apart' mt='xs'>
                    {error === null
                    ?
                    <Text>Statistiques journalières</Text>
                    :
                    <Alert variant="light" color="red" title="oops" icon={<IconInfoCircle />}>
                        {error}
                    </Alert>
                    }
                    <Group>
                        <CopyButton value={copyStats()}>
                        {({ copied, copy }) => (
                            <Button color={copied ? 'teal' : 'blue'} onClick={copy} variant='outline' compact>
                            {copied ? 'Copied data' : 'Copy data'}
                            </Button>
                        )}
                        </CopyButton>
                        <ActionIcon color='blue' variant='outline' onClick={() => { setLoaded(false); setRows([])} } >
                            <IconRefresh size={16} />
                        </ActionIcon>
                    </Group>
                </Group>
                <Table style={{width: 'min(100vw, 700px)', textAlign: 'right'}} 
                    highlightOnHover
                    withColumnBorders
                >
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Form created</th>
                            <th>Form sent</th>
                            <th>Has request</th>
                            <th>Has declined</th>
                            <th>Has just open</th>
                            <th>Form filled</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row:RowInterface) => 
                        <tr key={`stats-on-${row.t}`}>
                            <td><Text>{row.t}</Text></td>
                            <td><Text>{row.c}</Text></td>
                            <td><Text>{row.s}</Text></td>
                            <td><Text>{row.r}</Text></td>
                            <td><Text>{row.d}</Text></td>
                            <td><Text>{row.o}</Text></td>
                            <td><Text>{row.f}</Text></td>
                        </tr>
                        )}
                        <tr>
                            <td>TOTAL</td>
                            <td><Text>{total.c}</Text></td>
                            <td><Text>{total.s}</Text></td>
                            <td><Text>{total.r}</Text></td>
                            <td><Text>{total.d}</Text></td>
                            <td><Text>{total.o}</Text></td>
                            <td><Text>{total.f}</Text></td>
                        </tr>
                        {total.c > 0 &&
                        <tr>
                            <td>%</td>
                            <td><Text size="xs">100.0</Text></td>
                            <td><Text size="xs">{total.s > 0 ? (100*total.s/total.c).toFixed(1) : ''}</Text></td>
                            <td><Text size="xs">{total.r > 0 ? (100*total.r/total.c).toFixed(1) : ''}</Text></td>
                            <td><Text size="xs">{total.d > 0 ? (100*total.d/total.c).toFixed(1) : ''}</Text></td>
                            <td><Text size="xs">{total.o > 0 ? (100*total.o/total.c).toFixed(1) : ''}</Text></td>
                            <td><Text size="xs">{total.f > 0 ? (100*total.f/total.c).toFixed(1) : ''}</Text></td>
                        </tr>
                        }
                    </tbody>
                </Table>
                <Divider labelPosition='center' label="Section 2/2" />
                <Center pb="xs" pt="xs">
                    <Button loading={isUploadingAnswers}
                        color='blue' variant='outline'
                        onClick={() => uploadFormAnswers()}
                    >
                        Copier les réponses aux questionnaires et les scores associés
                    </Button>
                </Center>
            </ScrollArea>
        </Center>
        );

}

export { Statistics }
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Badge, Divider, Group, Loader, Progress, ScrollArea, Stack, Switch, Table, Text, TextInput } from '@mantine/core';
import { IconArrowBigRight, IconUserOff, IconX } from '@tabler/icons';
import { TitleContainer } from '../../shared/TitleContainer';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { UserSelector } from '../../shared/UserSelector';
import { toFrenchDate } from '../../../services/functions';

interface UserInterface {
    id: number,
    label: string
}
interface StatInterface {
    label: string,
    value: number,
    percent: number,
}
interface MissionInterface {
    id: number,
    client_name: string,
    manager_name: string,
    label: string,
    created_date: string,
    affected_id: number,
    affected_at: string | null,
    is_finished: boolean,
    is_removed: boolean,
    date_start: string,
    date_end: string,
}

const ListMission = () => {

    const myContext = useContext(AppContext);
    const [missions, setMissions] = useState<MissionInterface[]>([]);
    const [missionsFiltered, setMissionsFiltered] = useState<MissionInterface[]>([]);
    const [stats, setStats] = useState<StatInterface[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [search, setSearch] = useState('');
    const [user, setUser] = useState<UserInterface | null>(null);

    useEffect(() => {
        if (isLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/list_mission`).then((res: any) => {
            setIsLoaded(true);
            if (res.data.status === true) {
                if (res.data.data !== null) {
                    // liste des missions non filtrées
                    setMissions(res.data.data);
                    // liste des missions filtrées
                    setMissionsFiltered(res.data.data.filter((m: MissionInterface) => m.is_finished === isFinished && m.is_removed === isRemoved));
                    // statistiques sur les missions en cours
                    let users: string[] = [];
                    let values: number[] = [];
                    for (const m of res.data.data) {
                        if (m.is_finished === false && m.is_removed === false) {
                            let i = users.indexOf(m.affected_at);
                            if (i === -1) {
                                i = users.length;
                                users.push(m.affected_at);
                                values.push(0);
                            }
                            values[i] += 1;
                        }
                    }
                    let m = 0;
                    for (let i=0;i<values.length;i++){
                        m += values[i];
                    }
                    const s: StatInterface[] = [];
                    for (let i=0;i<users.length;i++){
                        s.push({
                            label: users[i] || 'non affecté',
                            percent: Math.ceil(100 * values[i] / m),
                            value: values[i],
                        })
                    }
                    setStats(s.sort((a,b) => { return b.value - a.value }));
                }
            } else {
                AlertNotification({message: res.data.message || 'internal error'});
            }
            //setUser({id: myContext.account.id, label: 'moi'}); // définir par défaut l'utilisateur
        });
    }, [isLoaded]); // eslint-disable-line

    useEffect(() => {
        const s = search.trim().toLocaleLowerCase();
        setMissionsFiltered(missions.filter((m: MissionInterface) => (
            m.is_finished === isFinished && 
            m.is_removed === isRemoved && 
            (s.length === 0 || (m.client_name + ' ' + m.manager_name + ' ' + m.label + ' ' + m.affected_at).toLocaleLowerCase().indexOf(s) !== -1) && 
            (user === null || user.id === m.affected_id)
        )));
    }, [search, user]); // eslint-disable-line

    useEffect(() => {
        setMissionsFiltered(missions.filter((m: MissionInterface) => m.is_finished === isFinished && m.is_removed === isRemoved));
    }, [isFinished, isRemoved]); // eslint-disable-line

    return (
        <>
        <Group position="apart">
            <TitleContainer>
                Liste des missions
            </TitleContainer>
            <Group>
                {user === null ?
                <IconUserOff size={16} />
                : 
                <>
                <Badge>{user.label}</Badge>
                <ActionIcon color="blue" variant="outline" onClick={() => setUser(null)}>
                    <IconX size={16} />
                </ActionIcon>
                </>
                }
                <UserSelector
                    handle={(u: UserInterface) => setUser({...u})}
                />
                <Divider orientation='vertical' />
                <Switch 
                    checked={isRemoved} onChange={(event) => setIsRemoved(event.currentTarget.checked)}
                    label="mises à la poubelle"
                />
                <Divider orientation='vertical' />
                <Switch 
                    checked={isFinished} onChange={(event) => setIsFinished(event.currentTarget.checked)}
                    label="terminées"
                />
            </Group>
        </Group>
        <ScrollArea style={{height: 'calc(100vh - 130px)'}}>
            <Group>
                <Text>Répartition des missions en cours</Text>
            {stats.map((s,i) => 
                <Group key={`progress-${i}`}>
                    <Stack spacing={0} style={{width: '100px'}}>
                        <Text color="dimmed" size="sm" truncate>{s.value} {s.label}</Text>
                        <Progress color="teal" value={s.percent} />
                    </Stack>
                </Group>
            )}
            </Group>
            {!isLoaded && 
                <Group>
                    <Loader />
                    <Text>Récupération des missions en cours&hellip;</Text>
                </Group>
            }
            {(isLoaded && missions.length === 0) && 
                <Text>aucune mission</Text>
            }
            {(isLoaded && missions.length > 0) && 
                <Stack>
                    <Group position="apart">
                        <TextInput 
                            placeholder='Texte à chercher'
                            value={search}
                            onChange={(event) => setSearch(event.target.value)} 
                        />
                        <Badge>{missionsFiltered.length}</Badge>
                    </Group>
                    <Table highlightOnHover>
                        <thead>
                            <tr>
                                <th>Nom du client</th>
                                <th>Manager de transition</th>
                                <th>Poste occupé</th>
                                <th>Planning</th>
                                <th>Affecté à</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {missionsFiltered.map((m: MissionInterface, idx: number) => (
                            <tr key={`mission-${idx}`}>
                                <td>{m.client_name}</td>
                                <td>{m.manager_name}</td>
                                <td>{m.label}</td>
                                <td>
                                    <Stack spacing={0}>
                                        <Text size='xs'>{toFrenchDate(m.date_start)}</Text>
                                        <Text size='xs'>{toFrenchDate(m.date_end)}</Text>
                                    </Stack>
                                </td>
                                <td>
                                    {m.affected_at === null && <Badge color="orange">non affectée</Badge>}
                                    {m.affected_at !== null && <Badge color="green">{m.affected_at}</Badge>}
                                </td>
                                <td>
                                    <ActionIcon color="blue" variant="outline"
                                        component={Link} to={`/mission/manage/${m.id}`}
                                    >
                                        <IconArrowBigRight size={16} />
                                    </ActionIcon>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Stack>
            }
        </ScrollArea>
        </>
    )

}

export { ListMission }
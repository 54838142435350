import { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Card, Center, Checkbox, ColorSwatch, createStyles, Group, Loader, Mark, ScrollArea, Table, Tabs, Text, useMantineTheme } from '@mantine/core';
import { IconArrowBigRight, IconFileDescription, IconX, IconRefresh } from '@tabler/icons';

import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';
import { UserSelector } from '../../shared/UserSelector';
import { InfoNotification } from '../../shared/InfoNotification';
import { AlertNotification } from '../../shared/AlertNotification';
import { DuplicateButton } from './duplicate';

interface FileInterface {
    id: number,
    filename: string,
    status: string,
    change_by: number,
    change_for: number | null,
    days: number,
    is_duplicate: boolean,
}

interface UserInterface {
    id: number,
    label: string,
}

const filesReducer = (state: any, payload: any) => {
    const user: number = payload.userId || -1;
    switch (payload.type) {
        case 'FILTER':
            return {
                ...state,
                insertedFiltered: state.inserted.filter((f: FileInterface) => f.change_for === user),
                affectedFiltered: state.affected.filter((f: FileInterface) => f.change_for === user),
                waitingForControlFiltered: state.waitingForControl.filter((f: FileInterface) => f.change_for === user),
                waitingForValidationFiltered: state.waitingForValidation.filter((f: FileInterface) => f.change_for === user),
            }
        case 'REMOVE':
            const id: number = payload.id;
            const k: string = payload.status;
            return {
                ...state,
                [k]: state[k].filter((f: FileInterface) => f.id !== id),
                [k + 'Filtered']: state[k + 'Filtered'].filter((f: FileInterface) => f.id !== id),
            }
        case 'RESET_FILTER':
            return {
                ...state,
                insertedFiltered: state.inserted.map((f: FileInterface) => {return {...f}}),
                affectedFiltered: state.affected.map((f: FileInterface) => {return {...f}}),
                waitingForControlFiltered: state.waitingForControl.map((f: FileInterface) => {return {...f}}),
                waitingForValidationFiltered: state.waitingForValidation.map((f: FileInterface) => {return {...f}}),
            }
        case 'SET':
            return {
                inserted: payload.data.filter((f: FileInterface) => f.status === 'inserted'),
                affected: payload.data.filter((f: FileInterface) => f.status === 'affected'),
                waitingForControl: payload.data.filter((f: FileInterface) => f.status === 'waiting-for-control'),
                waitingForValidation: payload.data.filter((f: FileInterface) => f.status === 'waiting-for-validation'),
                insertedFiltered: payload.data.filter((f: FileInterface) => f.status === 'inserted'),
                affectedFiltered: payload.data.filter((f: FileInterface) => f.status === 'affected'),
                waitingForControlFiltered: payload.data.filter((f: FileInterface) => f.status === 'waiting-for-control'),
                waitingForValidationFiltered: payload.data.filter((f: FileInterface) => f.status === 'waiting-for-validation'),
            }
    }
}

const useStyles = createStyles((theme) => ({
    rowSelected: {
        backgroundColor:
            theme.colorScheme === 'dark'
            ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
            : theme.colors[theme.primaryColor][0],
    },
}));

const ListCV = () => {

    const { classes, cx } = useStyles();
    const myContext = useContext(AppContext);
    const theme = useMantineTheme();
    const [isUpdating,setIsUpdating] = useState(false);
    const [isFilesLoaded,setIsFilesLoaded] = useState(false);
    const [isUsersLoaded,setIsUsersLoaded] = useState(false);
    const [isUsersLoadedError, setIsUsersLoadedError] = useState(false);
    const [files,dispatchFiles] = useReducer(filesReducer, {
        inserted: [],
        affected: [],
        waitingForControl: [],
        waitingForValidation: [],
        insertedFiltered: [],
        affectedFiltered: [],
        waitingForControlFiltered: [],
        waitingForValidationFiltered: [],
    });
    const [selectionInserted, setSelectionInserted] = useState<number[]>([]);
    const [selectionAffected, setSelectionAffected] = useState<number[]>([]);
    const [userFilter, setUserFilter] = useState('');
    const [activeTab, setActiveTab] = useState<string | null>('inserted');

    const handleSearch = (event: any) => {
        setSelectionInserted([]);
        setSelectionAffected([]);
        dispatchFiles({type: 'FILTER', userId: event.id});
        setUserFilter(event.label);
    }
    const resetSearch = () => {
        setSelectionInserted([]);
        setSelectionAffected([]);
        dispatchFiles({type: 'RESET_FILTER'});
        setUserFilter('');
    }

    const toggleRowInserted = (id: number) => setSelectionInserted((current) =>
        current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );
    const toggleAllInserted = () => setSelectionInserted(
        (current) => (current.length === files.insertedFiltered.length ? [] : files.insertedFiltered.map((item: FileInterface) => item.id))
    );

    const toggleRowAffected = (id: number) => setSelectionAffected((current) =>
        current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );
    const toggleAllAffected = () => setSelectionAffected(
        (current) => (current.length === files.affectedFiltered.length ? [] : files.affectedFiltered.map((item: FileInterface) => item.id))
    );

    useEffect(() => {
        if (isUsersLoaded) return;
        if (isUsersLoadedError) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_repository_user`).then((res: any) => {
            if (res.data.status === true) {
                myContext.setUsers({
                    label: 'Utilisateurs',
                    date: (new Date()).getTime(),
                    items: res.data.data,
                });
                setIsUsersLoaded(true);
            } else {
                setIsUsersLoadedError(true);
            }
        });
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!isUsersLoaded) return;
        if (isFilesLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_not_validated_cv`).then((result:any) => {
            setIsFilesLoaded(true);
            if (result.data.status === true) {
                dispatchFiles({type: 'SET', data: result.data.data || [], userId: myContext.account.id});
            }
            const stats = [
                0, // inserted
                0, // affected
                0, // waiting-for-control
                0, // waiting-for-validation
            ];
            for (let row of result.data.data) {
                if (row.status === 'inserted') {
                    stats[0] += 1;
                } else if (row.status === 'affected') {
                    stats[1] += 1;
                } else if (row.status === 'waiting-for-control') {
                    stats[2] += 1;
                } else if (row.status === 'waiting-for-validation') {
                    stats[3] += 1;
                }
            }
            let i = 0;
            for(let j=0;j<4;j++){
                if (stats[i] < stats[j]) {
                    i = j;
                }
            }
            if (i === 0) {
                setActiveTab('inserted');
            } else if (i === 1) {
                setActiveTab('affected');
            } else if (i === 2) {
                setActiveTab('waitingForControl');
            } else if (i === 3) {
                setActiveTab('waitingForValidation');
            }
        });
    }, [isUsersLoaded, isFilesLoaded]); // eslint-disable-line

    const affectSelectedUser = (userSelected: UserInterface) => {
        const formData = new FormData();
        formData.append('user', userSelected.id + '');
        if (activeTab === 'inserted') {
            if (selectionInserted.length === 0) return;
            formData.append('files', selectionInserted.join(','));
            setSelectionInserted([]);
        } else if (activeTab === 'affected') {
            if (selectionAffected.length === 0) return;
            formData.append('files', selectionAffected.join(','));
            setSelectionAffected([]);
        }
        setIsUpdating(true);
        myContext.httpClient.post(`${myContext.apiAddress}/affect_cv`, formData).then((result:any) => {
            if (result.data.status === true) {
                // on provoque un rechargement des données
                setIsFilesLoaded(false);
                InfoNotification({message: 'Curriculum vitae affecté.'});
            } else {
                AlertNotification({message: result.data.message || 'Internal error.'});
            }
            setIsUpdating(false);
        });
    }

    interface AlertContainerInterface {
        days: number,
    }
    const AlertContainer = ({days}: AlertContainerInterface) => {
        let k: string = '';
        if (days < 7) {
            k = 'green';
        } else if (days < 14) {
            k = 'yellow';
        } else {
            k = 'red';
        }
        return (
            <ColorSwatch key={k} color={theme.colors[k][4]} >
                <Text size="xs" color="white">{days}</Text>
            </ColorSwatch>
        );
    }

    return (
        <>
        <Group position="apart">
            <TitleContainer>
                Stock des curriculum vitae à traiter
            </TitleContainer>
            {(isFilesLoaded && isUsersLoaded && !isUpdating) && 
                <Group mb="md">
                    <Text>Filter sur</Text>
                    <UserSelector handle={handleSearch} />
                    {userFilter !== '' && <>
                    <Text>{userFilter}</Text>
                    <ActionIcon onClick={resetSearch} >
                        <IconX />
                    </ActionIcon>
                    </>}
                    <ActionIcon color='blue' variant='outline' onClick={() => setIsFilesLoaded(false)}>
                        <IconRefresh size={16} />
                    </ActionIcon>
                </Group>
            }
        </Group>
        {isUpdating && <Group position="center" align="center">
            <Loader/>
            <Text>Mise à jour en cours...</Text>
        </Group>}
        {(!isFilesLoaded || !isUsersLoaded) && <Group position="center" align="center">
            <Loader/>
            <Text>Chargement en cours...</Text>
        </Group>}
        {(isFilesLoaded && isUsersLoaded && !isUpdating) && 
        <>
        <Center>
            <Card radius="lg" withBorder>
                <Tabs value={activeTab} onTabChange={setActiveTab}>
                    
                    <Tabs.List>
                        <Tabs.Tab value="inserted" icon={<IconArrowBigRight size={14} />}>
                            {files.insertedFiltered.length === 0 && <>0</>}{files.insertedFiltered.length > 0 && <Mark>{files.insertedFiltered.length}</Mark>} CV à affecter</Tabs.Tab>
                        <Tabs.Tab value="affected" icon={<IconArrowBigRight size={14} />}>
                            {files.affectedFiltered.length === 0 && <>0</>}{files.affectedFiltered.length > 0 && <Mark>{files.affectedFiltered.length}</Mark>} CV à traiter</Tabs.Tab>
                        <Tabs.Tab value="waitingForControl" icon={<IconArrowBigRight size={14} />}>
                            {files.waitingForControlFiltered.length === 0 && <>0</>}{files.waitingForControlFiltered.length > 0 && <Mark>{files.waitingForControlFiltered.length}</Mark>} CV à contrôler</Tabs.Tab>
                        <Tabs.Tab value="waitingForValidation" icon={<IconArrowBigRight size={14} />}>
                            {files.waitingForValidationFiltered.length === 0 && <>0</>}{files.waitingForValidationFiltered.length > 0 && <Mark>{files.waitingForValidationFiltered.length}</Mark>} CV à valider</Tabs.Tab>
                    </Tabs.List>

                    {/* ------------------   INSERTED   ------------------ */}
                    <Tabs.Panel value="inserted" pt="xs">
                        {files.inserted.length === 0 && 
                        <Text>Aucun CV inséré à affecter.</Text>
                        }
                        {files.inserted.length > 0 &&
                        <ScrollArea style={{height: 'calc(100vh - 220px)'}}>
                            <Group>
                                <Text>Sélectionner un ou plusieurs fichiers pour les affecter</Text>
                                {selectionInserted.length !== 0 &&
                                <>
                                <Text>, puis affecter à</Text>
                                <UserSelector handle={(s: UserInterface) => affectSelectedUser(s) }/>
                                </>
                                }
                            </Group>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: 40 }}>
                                            <Checkbox
                                                onChange={toggleAllInserted}
                                                checked={selectionInserted.length === files.insertedFiltered.length}
                                                indeterminate={selectionInserted.length > 0 && selectionInserted.length !== files.insertedFiltered.length}
                                                transitionDuration={0}
                                            />
                                        </th>
                                        <th>Curriculum vitae</th>
                                        <th>Inséré par</th>
                                        <th>Inactivité</th>
                                        <th>Doublon</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.insertedFiltered.map((item: FileInterface) => (
                                        <tr key={'item'+item.id} className={cx({ [classes.rowSelected]: selectionInserted.includes(item.id) })}>
                                            <td>
                                                <Checkbox
                                                    checked={selectionInserted.includes(item.id)}
                                                    onChange={() => toggleRowInserted(item.id)}
                                                    transitionDuration={0}
                                                />
                                            </td>
                                            <td>{item.filename}</td>
                                            <td>
                                                {myContext.returnUsername(item.change_by)}
                                            </td>
                                            <td>
                                                <AlertContainer days={item.days} />
                                            </td>
                                            <td>
                                                {item.is_duplicate && <DuplicateButton id={item.id} handle={() => dispatchFiles({ type: 'REMOVE', id: item.id, status: 'inserted' })}/>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </ScrollArea>
                        }
                    </Tabs.Panel>
                    
                    {/* ------------------   AFFECTED   ------------------ */}
                    <Tabs.Panel value="affected" pt="xs">
                        {files.affected.length === 0 && 
                        <Text>Aucun CV affecté à traiter.</Text>
                        }
                        {files.affected.length > 0 &&
                        <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                            <Group>
                                <Text>Sélectionner un ou plusieurs fichiers pour les ré affecter</Text>
                                {selectionAffected.length !== 0 &&
                                <>
                                <Text>, puis ré affecter à</Text>
                                <UserSelector handle={(s: UserInterface) => affectSelectedUser(s) }/>
                                </>
                                }
                            </Group>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: 40 }}>
                                            <Checkbox
                                                onChange={toggleAllAffected}
                                                checked={selectionAffected.length === files.affectedFiltered.length}
                                                indeterminate={selectionAffected.length > 0 && selectionAffected.length !== files.affectedFiltered.length}
                                                transitionDuration={0}
                                            />
                                        </th>
                                        <th>Curriculum vitae</th>
                                        <th>Affecté à</th>
                                        <th>Inactivité</th>
                                        <th>Doublon</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.affectedFiltered.map((item: FileInterface) => (
                                        <tr key={'item'+item.id} className={cx({ [classes.rowSelected]: selectionAffected.includes(item.id) })}>
                                            <td>
                                                <Checkbox
                                                    checked={selectionAffected.includes(item.id)}
                                                    onChange={() => toggleRowAffected(item.id)}
                                                    transitionDuration={0}
                                                />
                                            </td>
                                            <td>{item.filename}</td>
                                            <td>
                                                {myContext.returnUsername(item.change_for)}
                                            </td>
                                            <td>
                                                <AlertContainer days={item.days} />
                                            </td>
                                            <td>
                                                {item.is_duplicate && <DuplicateButton id={item.id} handle={() => dispatchFiles({ type: 'REMOVE', id: item.id, status: 'affected' })}/>}
                                            </td>
                                            <td>
                                                <ActionIcon variant="outline" color="blue" mr="md"
                                                    component={Link} to={`/cvtheque/document/${item.id}`}
                                                >
                                                    <IconFileDescription size={16}/>
                                                </ActionIcon>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </ScrollArea>
                        }
                    </Tabs.Panel>
                    
                    {/* ------------------   WAITING-FOR-CONTROL   ------------------ */}
                    <Tabs.Panel value="waitingForControl" pt="xs">
                        {files.waitingForControl.length === 0 && 
                        <Text>Aucun CV traité à contrôler.</Text>
                        }
                        {files.waitingForControl.length > 0 &&
                        <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Curriculum vitae</th>
                                        <th>Demande émanant de</th>
                                        <th>A contrôler par</th>
                                        <th>Inactvité</th>
                                        <th>Doublon</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.waitingForControlFiltered.map((item: FileInterface) => (
                                        <tr key={'item'+item.id}>
                                            <td>{item.filename}</td>
                                            <td>
                                                {myContext.returnUsername(item.change_by)}
                                            </td>
                                            <td>
                                                {myContext.returnUsername(item.change_for)}
                                            </td>
                                            <td>
                                                <AlertContainer days={item.days} />
                                            </td>
                                            <td>
                                                {item.is_duplicate && <DuplicateButton id={item.id} handle={() => dispatchFiles({ type: 'REMOVE', id: item.id, status: 'waitingForControl' })}/>}
                                            </td>
                                            <td>
                                                <ActionIcon variant="outline" color="blue" mr="md"
                                                    component={Link} to={`/cvtheque/document/${item.id}`}
                                                >
                                                    <IconFileDescription size={16}/>
                                                </ActionIcon>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </ScrollArea>
                        }
                    </Tabs.Panel>
                    
                    {/* ------------------   WAITING-FOR-VALIDATION   ------------------ */}
                    <Tabs.Panel value="waitingForValidation" pt="xs">
                    {files.waitingForValidation.length === 0 && 
                        <Text>Aucun CV contrôlé à valider.</Text>
                        }
                        {files.waitingForValidation.length > 0 &&
                        <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Curriculum vitae</th>
                                        <th>Demande émanant de</th>
                                        <th>A valider par</th>
                                        <th>Inactivité</th>
                                        <th>Doublon</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.waitingForValidationFiltered.map((item: FileInterface) => (
                                        <tr key={'item'+item.id}>
                                            <td>{item.filename}</td>
                                            <td>
                                                {myContext.returnUsername(item.change_by)}
                                            </td>
                                            <td>
                                                {myContext.returnUsername(item.change_for)}
                                            </td>
                                            <td>
                                                <AlertContainer days={item.days} />
                                            </td>
                                            <td>
                                                {item.is_duplicate && <DuplicateButton id={item.id} handle={() => dispatchFiles({ type: 'REMOVE', id: item.id, status: 'waitingForValidation' })}/>}
                                            </td>
                                            <td>
                                                <ActionIcon variant="outline" color="blue" mr="md"
                                                    component={Link} to={`/cvtheque/document/${item.id}`}
                                                >
                                                    <IconFileDescription size={16}/>
                                                </ActionIcon>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </ScrollArea>
                        }
                    </Tabs.Panel>

                </Tabs>
            </Card>
        </Center>
        </>
        }
        </>
    )
}

export { ListCV }
import { Center, ScrollArea } from '@mantine/core';
import { DocumentsContainer } from '../../shared/DocumentsContainer';

interface OptionInterface {
    code: string,
    label: string,
}
interface MetadataInterface {
    code: string,
    type: 'number' | 'text' | 'list' | 'date',
    label: string,
    options: OptionInterface[],
}

interface PropsInterface {
    needId: number,
    isOpen: boolean,
}

const Docs = ({needId, isOpen}: PropsInterface) => {

    const metadataFilename: MetadataInterface = { code: "filename", type: "text", label: "Nom du document", options: [] };
    
    return (
        <Center>
            <ScrollArea style={{height: 'calc(100vh - 250px)', minWidth: '300px', maxWidth: '700px'}}>
                <DocumentsContainer
                    list_api='list_document_need'
                    get_api='get_document_need'
                    update_api={isOpen ? 'update_document_need' : null}
                    object_id={needId}
                    metadata={[metadataFilename]}
                />
            </ScrollArea>
        </Center>
    )
}

export { Docs }
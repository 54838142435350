import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Alert, Badge, Box, Button, Card, ColorSwatch, CopyButton, Divider, Drawer, Grid, Group, Loader, LoadingOverlay, Modal, Progress, ScrollArea, Stack, Text, Textarea } from '@mantine/core';
import { IconRefresh, IconAlertCircle, IconPlus } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TermInputBox } from './term_input_box';
import { TermBox } from './term_box';
import { ProfileBox } from './profile_box';
import { Pagination } from './pagination';
import { AnalyzeFields } from './analyze_fields';
import { RepoSearches } from './repo_searches';
import { slugify } from '../../../services/functions';
import { BuyButton } from './buy_button';

import { CVInterface, SearchInterface, TermInterface, ResultSearch, RowHistoryInterface } from '../interfaces';

import { constructRexpExp, constructCvFromJson, constructSearchTermFromString, stringifySearch } from '../functions';

const profilesReducer = (state: CVInterface[], payload:any) => {
    switch(payload.type) {
        case 'set':
            return payload.data.map((p: any) => { return constructCvFromJson(p) });
        case 'add':
            return state.concat(payload.data.map((p: any) => { return constructCvFromJson(p) }));
        default:
            return [];
    }
}

const searchReducer = (state: SearchInterface, payload: any) => {
    switch (payload.type) {
        case 'add':
            const s = payload.term.value.trim();
            if (s === '') return state;
            for (let i=0;i<state.terms.length;i++){
                if (s === state.terms[i].value) {
                    return state;
                }
            }
            return {
                id: state.id,
                refreshed: true,
                terms: [{
                    included: payload.included,
                    value: s, 
                    'Aa': payload.term.caseSensitive, 
                    'A+': payload.term.expand,
                    '!': payload.term.compulsory,
                    regexp: constructRexpExp(s, payload.term.expand, payload.term.caseSensitive),
                    weight: 0,
                    fields: {...payload.term.fields},
                }, ].concat(state.terms),
            }
        case 'remove':
            return {
                id: state.id,
                refreshed: true,
                terms: state.terms.filter((t: TermInterface) => !(t.included === payload.included && t.value === payload.value)),
            }
        case 'search_done':
            return {
                ...state,
                refreshed: false,
            }
        case 'set':
            return {
                ...payload.search,
                refreshed: true,
            }
        case 'toggle':
            const w = payload.what;
            return {
                id: state.id,
                refreshed: true,
                terms: state.terms.map((t: TermInterface) => {
                    if (t.included === payload.included && t.value === payload.value) {
                        if (w === 'caseSensitive') {
                            return {...t, 'Aa': !t['Aa'], regexp: constructRexpExp(t['value'], t['A+'], t['Aa']) }
                        } else if (w === 'expand') {
                            return {...t, 'A+': !t['A+'], regexp: constructRexpExp(t['value'], t['A+'], t['Aa']) }
                        } else if (w === 'compulsory') {
                            return {...t, '!': !t['!'] }
                        } else if (['n','h','j','e','f','d','s','l'].indexOf(w) !== -1) {
                            return {...t, fields: { ...t.fields, [w]: !t.fields[w] }};
                        } else {
                            return {...t}
                        }
                    } else {
                        return t;
                    }
                }),
            }
        case 'update':
            const v = payload.new_value.trim();
            if (v === '') return state;
            for (let i=0;i<state.terms.length;i++){
                if (v === state.terms[i].value) {
                    return state;
                }
            }
            return {
                id: state.id,
                refreshed: true,
                terms: state.terms.map((t: TermInterface) => {
                    if (t.included === payload.included && t.value === payload.value) {
                        return {...t, value: v, regexp: constructRexpExp(v, t['A+'], t['Aa'])};
                    } else {
                        return t;
                    }
                }),
            }
        case 'weights':
            return {
                ...state,
                terms: state.terms.map((t,i) => { return {...t, weight: payload.data[i]}}),
            }
        case 'reset':
            return {
                id: null,
                terms: [],
                refreshed: true,
            }
        default:
            return {
                id: null,
                terms: [],
                refreshed: true,
            };
    }
}

interface PropsInterface {
    huntId: number,
}

const AnalyzeCVs = ({huntId}: PropsInterface) => {

    const itemsPerPage = 10;
    const myContext = useContext(AppContext);
    const [ profiles, dispatchProfiles ] = useReducer(profilesReducer, []);
    const [ loaded, setLoaded ] = useState(false);
    const [ warning, setWarning ] = useState<null | string>(null);
    const [ search, dispatchSearch ] = useReducer(searchReducer, {id: null, terms: [], refreshed: false});
    const [ matchesIn, setMatchesIn ] = useState<ResultSearch[]>([]);
    const [ matchesOut, setMatchesOut ] = useState<ResultSearch[]>([]);
    const [ matchesUndefined, setMatchesUndefined ] = useState<ResultSearch[]>([]);
    const [ pageIn, setPageIn ] = useState(0);
    const [ pageOut, setPageOut ] = useState(0);
    const [ pageUndefined, setPageUndefined ] = useState(0);
    const [ display, setDisplay ] = useState<'in' | 'out' | 'undefined'>('in');
    const [ expertMode, setExpertMode ] = useState(false);
    const [ expertTerms, setExpertTerms ] = useState('');
    const [ isTooLongToBeSaved, setIsTooLongToBeSaved ] = useState(false);
    const [ isDrawerOpened, setIsDrawerOpened ] = useState(false);
    const [ saveSearchCounter, setSaveSearchCounter ] = useState(0);

    // Fonction asynchrone pour charger les profils
    const loadPage = (page: number) => {
        return new Promise((resolve) => {
            const api = `${myContext.apiAddress}/analyze_hunt?id=${huntId}&page=${page}`;
            myContext.httpClient.get(api).then((res:any) => {
                let b: boolean;
                if (res.data.status === true) {
                    dispatchProfiles({type: page === 0 ? 'set' : 'add', data: res.data.data.profiles });
                    b = (res.data.data.profiles.length === res.data.data.max_per_page) ? true : false;
                } else {
                    AlertNotification({message: res.data.message || 'unknown error'});
                    b = false;
                }
                resolve(b);
            });
        })
    }
    const loadPages = async () => {
        let p = 0;
        let more = await loadPage(p);
        while (more === true) {
            p += 1;
            more = await loadPage(p);
        }
        setLoaded(true);
    }

    // On charge les profils.
    useEffect(() => {
        if (loaded) return;
        setWarning(null);
        dispatchSearch({type: 'reset'});
        setMatchesIn([]);
        setMatchesOut([]);
        setMatchesUndefined([]);
        loadPages();
    }, [loaded]); // eslint-disable-line

    // On applique les filtres.
    useEffect(() => {
        if (!loaded) return;
        if (profiles.length === 0) return;
        if (!search.refreshed) return;
        dispatchSearch({type: 'search_done'});
        const resultsOut: ResultSearch[] = [];
        const resultsIn: ResultSearch[] = [];
        const resultsUndefined: ResultSearch[] = [];
        const nTerms = search.terms.length;
        const weights = Array(nTerms).fill(0);
        const fieldToLetter: { [key: string]: string } = { 
            'fullname': 'n',
            'headline': 'h',
            'jobs': 'j',
            'experiences': 'e',
            'formations': 'f',
            'decorations': 'd',
            'sector': 's',
            'location': 'l',
        };
        for (let i=0;i<profiles.length;i++) {
            // profil
            const p = profiles[i];
            // initialisation
            let score: {'in': number, 'out': number, [key: string]: number } = { 'in': 0, 'out': 0 };
            let shouldBe: {'in': boolean, 'out': boolean, [key: string]: boolean } = { 'in': false, 'out': false };
            const poids = Array(nTerms).fill(0);
            // on applique chaque terme de la recherche
            for (let j=0;j<nTerms;j++) {
                const term = search.terms[j];
                // analyse des champs
                for (const f of ['fullname','headline','sector','location']) {
                    const s: string = p[f];
                    if (s === '') continue;
                    if (!term.fields[fieldToLetter[f]]) continue;
                    let mm = [...s.matchAll(term.regexp)];
                    let n = 0;
                    if (mm.length !== 0){
                        mm.forEach((m) => {
                            if (m.index !== undefined) {
                                n += 100 - Math.floor(100 * m.index / s.length);
                            }
                        });
                    }
                    if (n !== 0) {
                        score[(term.included) ? 'in' : 'out'] += n;
                        poids[j] += 1;
                    }
                }
                for (const f of ['decorations',]) {
                    const nRows = p[f].length;
                    if (nRows === 0) continue;
                        if (!term.fields[fieldToLetter[f]]) continue;
                        let n = 0;
                        for (let r=0;r<nRows;r++){
                            const s: string = p[f][r];
                            if (s === '') continue;
                            let mm = [...s.matchAll(term.regexp)];
                            if (mm.length !== 0){
                                for (let o=0;o < mm.length;o++){
                                    const m = mm[o];
                                    if (m.index !== undefined) {
                                        n += Math.floor(100 * (1 - m.index / s.length) * (1 - r / nRows));
                                    }
                                }
                            }
                        }
                        if (n !== 0) {
                            score[(term.included) ? 'in' : 'out'] += n;
                            poids[j] += 1;
                        }
                }
                for (const f of ['jobs','experiences','formations',]) {
                    const nRows = p[f].length;
                    if (nRows === 0) continue;
                    if (!term.fields[fieldToLetter[f]]) continue;
                    let n = 0;
                    for (let r=0;r<nRows;r++){
                        const s: string = p[f][r]['la'];
                        if (s === '') continue;
                        let mm = [...s.matchAll(term.regexp)];
                        if (mm.length !== 0){
                            for (let o=0;o < mm.length;o++){
                                const m = mm[o];
                                if (m.index !== undefined) {
                                    n += Math.floor(100 * (1 - m.index / s.length) * (1 - r / nRows));
                                }
                            }
                        }
                    }
                    if (n !== 0) {
                        score[term.included ? 'in' : 'out'] += n;
                        poids[j] += 1;
                    }
                }
            }
            for (let j=0;j<nTerms;j++) {
                if (poids[j] !== 0) {
                    weights[j] += 1;
                }
                if (search.terms[j].included) {
                    if (search.terms[j]['!'] && poids[j] === 0) {
                        shouldBe.in = true;
                    }
                } else {
                    if (search.terms[j]['!'] && poids[j] !== 0) {
                        shouldBe.out = true;
                    }
                }
            }
            // on décide la classification "in", "out", "undefined"
            let c = 'undefined';
            if (shouldBe['in'] || shouldBe['out']) {
                c = 'out';
            } else if (score['in'] !== 0 || score['out'] !== 0) {
                if (score['in'] >= score['out']) {
                    c = 'in';
                } else {
                    c = 'out';
                }
            }
            // on affecte le profil
            if (c === 'out') {
                resultsOut.push({
                    id: p.id,
                    index: i,
                    scoreIn: score['in'],
                    scoreOut: score['out'],
                });
            } else if (c === 'in') {
                resultsIn.push({
                    id: p.id,
                    index: i,
                    scoreIn: score['in'],
                    scoreOut: score['out'],
                });
            } else {
                resultsUndefined.push({
                    id: p.id,
                    index: i,
                    scoreIn: score['in'],
                    scoreOut: score['out'],
                });
            }
        }
        dispatchSearch({type: 'weights', data: weights});
        setMatchesIn(resultsIn.sort((a, b) => b.scoreIn - a.scoreIn));
        setMatchesOut(resultsOut.sort((a, b) => (b.scoreIn !== 0 ? b.scoreIn : - b.scoreOut) - (a.scoreIn !== 0 ? a.scoreIn : - a.scoreOut) ));
        setMatchesUndefined(resultsUndefined);
        setSaveSearchCounter(saveSearchCounter + 1);
    }, [loaded, search.refreshed]); // eslint-disable-line

    // on formate une ligne
    const formatRowForExport = (p: CVInterface) => {
        return '<tr><td>https://www.linkedin.com/talent/profile/'+p.token+'</td><td>'
                +p.token+'</td><td>'
                +p.is_known+'</td><td>'
                +p.fullname+'</td><td>'
                +p.headline+'</td><td>'
                +p.location+'</td><td>'
                +p.sector+'</td><td>'
                +p.decorations.join('\n')+'</td><td>'
                +p.jobs.map((r: RowHistoryInterface) => { return `${r.y0} - ${r.y1} : ${r.la}`}).join('\n')+'</td><td>'
                +p.experiences.map((r: RowHistoryInterface) => { return `${r.y0} - ${r.y1} : ${r.la}`}).join('\n')+'</td><td>'
                +p.formations.map((r: RowHistoryInterface) => { return `${r.y0} - ${r.y1} : ${r.la}`}).join('\n')+'</td></tr>';
    }
    // on exporte les données pour Excel
    const exportForClipBoard = () => {
        let s = '<table><thead><tr><th>url</th><th>token</th><th>is_known</th><th>fullname</th><th>headline</th><th>location</th><th>sector</th><th>decorations</th><th>current experiences</th><th>past experiences</th><th>formations</th></thead><tbody>';
        if (display === 'in') {
            for(const m of matchesIn) {
                s += formatRowForExport(profiles[m.index]);
            }
        } else if (display === 'undefined') {
            for(const m of matchesUndefined) {
                s += formatRowForExport(profiles[m.index]);
            }
        } else if (display === 'out') {
            for(const m of matchesOut) {
                s += formatRowForExport(profiles[m.index]);
            }
        }
        s += '</tbody></table>';        
        return s;
    }
    const exportAllForClipboard = () => {
        let s = '<table><thead><tr><th>url</th><th>token</th><th>is_known</th><th>fullname</th><th>headline</th><th>location</th><th>sector</th><th>decorations</th><th>current experiences</th><th>past experiences</th><th>formations</th></thead><tbody>';
        for(const p of profiles) {
            s += formatRowForExport(p);
        }
        s += '</tbody></table>';        
        return s;
    }

    const exportProfileIds = () => {
        if (display !== 'in') return [];
        const ids: number[] = [];
        for (const m of matchesIn) {
            ids.push(m.id);
        }
        return ids;
    }

    // déclenchement d'actions suite à la mise à jour de la recherche
    useEffect(() => {
        const s = stringifySearch(search);
        setIsTooLongToBeSaved(s.length > 5000 ? true : false);
        setExpertTerms(s.replaceAll('|','\n'));
    }, [search]); // eslint-disable-line

    // On affiche le composant.
    return (
        <Box>
            <LoadingOverlay visible={!loaded} />
            <Drawer 
                opened={isDrawerOpened} 
                onClose={() => setIsDrawerOpened(false)} 
                title="Add a new term search"
                position='left'
                padding='xl'
                size="xl"
            >
                <ScrollArea style={{height: 'calc(100vh - 100px)'}}>
                    <Text size='sm' color='teal'>Terme à inclure :</Text>
                    <TermInputBox handle={(t: TermInterface) => { 
                        dispatchSearch({included: true, type: 'add', term: t});
                        setIsDrawerOpened(false);
                    }} />
                    <Text size='sm' color='red'>Terme à exclure :</Text>
                    <TermInputBox handle={(t: TermInterface) => { 
                        dispatchSearch({included: false, type: 'add', term: t});
                        setIsDrawerOpened(false);
                    }} />
                    <Text size='sm' color='dimmed'>Légende :</Text>
                    <Text><strong>A+</strong> autoriser préfixe et suffixe autour du terme</Text>
                    <Text><strong>Aa</strong> être sensible aux minuscules et aux majuscules</Text>
                    <Text><strong>!</strong> imposer le terme</Text>
                    <Text><strong>N</strong> rechercher le terme dans les noms complets</Text>
                    <Text><strong>H</strong> rechercher le terme dans les en-têtes</Text>
                    <Text><strong>J</strong> rechercher le terme dans les expériences professionnelles en cours</Text>
                    <Text><strong>E</strong> rechercher le terme dans les expériences professionnelles passées</Text>
                    <Text><strong>F</strong> rechercher le terme dans les formations</Text>
                    <Text><strong>D</strong> rechercher le terme dans les spotlights</Text>
                    <Text><strong>S</strong> rechercher le terme dans les secteurs professionnels</Text>
                    <Text><strong>L</strong> rechercher le terme dans les localisations géographiques</Text>
                </ScrollArea>
            </Drawer>
            <Modal
                opened={expertMode}
                onClose={() => setExpertMode(false)}
                size='lg'
            >
                <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                    <Text size="xs" color="dimmed">Exemple: <strong>manager;+;A+;Aa;!</strong> pour je veux (+) le mot manager, avec préfixe et suffixe (A+), en différenciant les minuscules des majuscules (Aa), et ce mot doit obligatoirement être là (!).</Text>
                    <Textarea 
                        value={expertTerms}
                        onChange={(event) => setExpertTerms(event.currentTarget.value)}
                        autosize
                    />
                </ScrollArea>
                <Group position="center">
                    <Button color="blue" variant="outline"
                        onClick={() => {
                            // on transforme les éléments en recherche
                            const rows: string[] = expertTerms.split('\n');
                            const S: SearchInterface = { id: search.id, terms: [], 'refreshed': true };
                            for (const row of rows) {
                                const T: null | TermInterface = constructSearchTermFromString(row);
                                if (T !== null) {
                                    S.terms.push(T);
                                }
                            }
                            setExpertMode(false);
                            dispatchSearch({type: 'set', search: S});
                        }}
                    >
                        Apply
                    </Button>
                </Group>
            </Modal>
            <Group position='apart'>
                <Group>
                    {loaded ? 
                    <Group>
                        <Badge>{profiles.length}</Badge>
                        <Text>profils chargés</Text>
                    </Group>
                    :
                    <Group>
                        <Loader size='sm'/>
                        <Text>Chargement des profils...</Text>
                    </Group>
                    }
                    {warning !== null && <Text color='red'>{warning}</Text>}
                    <RepoSearches huntId={huntId} 
                        getSearch={() => { 
                            return search
                        }}
                        setSearch={(s: SearchInterface) => 
                            dispatchSearch({type: 'set', search: s})
                        }
                        resetSearch={() => 
                            dispatchSearch({type: 'reset'})
                        }
                        runSave={saveSearchCounter}
                    />
                    {profiles.length !== 0 &&
                    <AnalyzeFields profiles={profiles} />
                    }
                </Group>
                {loaded && 
                <Group>    
                    <CopyButton value={exportAllForClipboard()}>
                        {({ copied, copy }) => (
                        <Button compact size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                            {copied ? 'Données copiées' : 'Copier toutes les données'}
                        </Button>
                        )}
                    </CopyButton>
                    <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                        <IconRefresh size={16} />
                    </ActionIcon>
                </Group>
                }
            </Group>
            <Grid m={0}>
                <Grid.Col xs={12} sm={6} lg={4}>
                    <Divider label="Termes de recherche" labelPosition='center' />
                    <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                        <Stack>
                            <Grid m={0}>
                                <Grid.Col span={2} offset={4}>
                                    <ActionIcon color='blue' variant='outline' onClick={() => setIsDrawerOpened(true)}>
                                        <IconPlus size={16} />
                                    </ActionIcon>
                                </Grid.Col>
                                <Grid.Col span={5}>
                                    <Button size="xs" color="blue" variant="outline" compact
                                        onClick={() => setExpertMode(true)}
                                    >
                                        Expert mode
                                    </Button>
                                </Grid.Col>
                            </Grid>
                            {isTooLongToBeSaved && 
                            <Alert icon={<IconAlertCircle size="1rem" />} title="Too long search to be saved" color='yellow'>
                                Reduce the number of terms to save this search
                            </Alert>
                            }
                            <Card p={5}>
                                <Stack align="flex-start">
                                    <Group spacing={5}>
                                        {search.terms.filter((t: TermInterface) => t.included).sort((a: TermInterface,b:TermInterface) => { return b.weight - a.weight } ).map((t: TermInterface, i: number) => 
                                        <TermBox key={`term-in-${slugify(t.value)}`} 
                                            term={{...t}} 
                                            color='teal'
                                            close={()          => { dispatchSearch({included: true, type: 'remove', value: t.value })} }
                                            toggle={(f:string) => { dispatchSearch({included: true, type: 'toggle', value: t.value, what: f })} }
                                            update={(v:string) => { dispatchSearch({included: true, type: 'update', value: t.value, new_value: v })} }
                                        />
                                        )}
                                    </Group>
                                </Stack>
                            </Card>
                            <Card p={5}>
                                <Stack align="flex-start">
                                    <Group spacing={5}>
                                        {search.terms.filter((t: TermInterface) => !t.included).sort((a: TermInterface,b:TermInterface) => { return b.weight - a.weight } ).map((t: TermInterface, i: number) => 
                                        <TermBox key={`term-out-${slugify(t.value)}`} 
                                            term={{...t}} 
                                            color='red'
                                            close={()          => { dispatchSearch({included: false, type: 'remove', value: t.value })} }
                                            toggle={(f:string) => { dispatchSearch({included: false, type: 'toggle', value: t.value, what: f })} }
                                            update={(v:string) => { dispatchSearch({included: false, type: 'update', value: t.value, new_value: v })} }
                                        />
                                        )}
                                    </Group>
                                </Stack>
                            </Card>
                        </Stack>
                    </ScrollArea>
                </Grid.Col>
                <Grid.Col span="auto">
                    <Divider label="Résultats" labelPosition='center' />
                    {profiles.length > 0 &&
                    <Group grow mb={5}>
                        <Group>
                            <ColorSwatch size={15} color="teal" onClick={() => setDisplay('in')} />
                            <ColorSwatch size={15} color="orange" onClick={() => setDisplay('undefined')} />
                            <ColorSwatch size={15} color="red" onClick={() => setDisplay('out')} />
                        </Group>
                        <Progress size='xl' style={{cursor: 'pointer'}} sections={[
                            { 
                                value: Math.round(100*matchesIn.length/profiles.length), 
                                color: 'teal', label: matchesIn.length + ' in',
                                onClick: () => setDisplay('in'),
                            },{
                                value: Math.round(100*matchesUndefined.length/profiles.length), 
                                color: 'orange', label: matchesUndefined.length + ' undefined',
                                onClick: () => setDisplay('undefined'),
                            },{
                                value: Math.round(100*matchesOut.length/profiles.length),
                                color: 'red', label: matchesOut.length + ' out',
                                onClick: () => setDisplay('out'),
                            },
                        ]}/>
                    </Group>
                    }
                    {display === 'in' &&
                    <Box
                        sx={(theme) => ({
                            border: '2px solid #12B886',
                            borderRadius: '5px',
                        })}
                    >
                        <Group position='apart'>
                            <CopyButton value={exportForClipBoard()}>
                                {({ copied, copy }) => (
                                <Button compact size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                                    {copied ? 'Données copiées' : 'Copier'}
                                </Button>
                                )}
                            </CopyButton>
                            {search.id !== null &&
                            <BuyButton 
                                hunt_id={huntId}
                                search_id={search.id}
                                get_ids={exportProfileIds}
                            />
                            }
                            <Pagination 
                                page={pageIn} 
                                pages={Math.ceil(matchesIn.length / itemsPerPage)}
                                change={(p: number) => setPageIn(p)} 
                            />
                        </Group>
                        <ScrollArea style={{height:'calc(100vh - 245px)'}}>
                            {matchesIn.slice(itemsPerPage*pageIn, itemsPerPage*(pageIn+1)).map((m) => 
                                <ProfileBox key={`result-in-${m.id}`} 
                                    profile={profiles[m.index]} search={search}
                                />
                            )}
                        </ScrollArea>
                    </Box>
                    }
                    {display === 'undefined' &&
                    <Box
                        sx={(theme) => ({
                            border: '2px solid #FD7E14',
                            borderRadius: '5px',
                        })}
                    >
                        <Group position='apart'>
                            <CopyButton value={exportForClipBoard()}>
                                {({ copied, copy }) => (
                                <Button compact size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                                    {copied ? 'Données copiées' : 'Copier'}
                                </Button>
                                )}
                            </CopyButton>
                            <Pagination 
                                page={pageUndefined} 
                                pages={Math.ceil(matchesUndefined.length / itemsPerPage)}
                                change={(p: number) => setPageUndefined(p)}
                            />
                        </Group>
                        <ScrollArea style={{height:'calc(100vh - 245px)'}}>
                            {matchesUndefined.slice(itemsPerPage*pageUndefined, itemsPerPage*(pageUndefined+1)).map((m) => 
                                <ProfileBox key={`result-undefined-${m.id}`} 
                                    profile={profiles[m.index]} search={null}
                                />
                            )}
                        </ScrollArea>
                    </Box>
                    }
                    {display === 'out' &&
                    <Box
                        sx={(theme) => ({
                            border: '2px solid #FA5252',
                            borderRadius: '5px',
                        })}
                    >
                        <Group position='apart'>
                            <CopyButton value={exportForClipBoard()}>
                                {({ copied, copy }) => (
                                <Button compact size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                                    {copied ? 'Données copiées' : 'Copier'}
                                </Button>
                                )}
                            </CopyButton>
                            <Pagination 
                                page={pageOut} 
                                pages={Math.ceil(matchesOut.length / itemsPerPage)}
                                change={(p: number) => setPageOut(p)}
                            />
                        </Group>
                        <ScrollArea style={{height:'calc(100vh - 245px)'}}>
                            {matchesOut.slice(itemsPerPage*pageOut, itemsPerPage*(pageOut+1)).map((m) => 
                                <ProfileBox key={`result-out-${m.id}`}
                                    profile={profiles[m.index]} search={search}
                                />
                            )}
                        </ScrollArea>
                    </Box>
                    }
                </Grid.Col>
            </Grid>
        </Box>
    )
}

export { AnalyzeCVs }
import { useContext, useEffect, useState } from 'react';
import { Select, Loader } from '@mantine/core';

import AppContext from '../../shared/AppContext';

interface NatureInterface {
    id: number,
    label: string,
}

interface PropsInterface {
    onChange: any,
}

const NatureSelector = ({onChange}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ natures, setNatures ] = useState<NatureInterface[] | null>(null);
    const [ nature, setNature ] = useState<string | null>(null);
    
    useEffect(() => {
        if (natures !== null) return;
        const api = `${myContext.apiAddress}/list_message_nature_book`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                setNatures(res.data.data || []);
            } else {
                setNatures([]);
            }
        })
    }, [natures]); // eslint-disable-line

    useEffect(() => {
        onChange(nature);
    }, [nature]); // eslint-disable-line

    return (
        <>
        {natures === null ?
        <Loader />
        :
        <Select
            pb='lg'
            label="Nature du message ou de l'évènement"
            placeholder="pick one"
            data={natures.map((n: NatureInterface) => { return { value: n.id +'', label: n.label } })}
            value={nature} onChange={setNature}
            withAsterisk
        />
        }
        </>
    )

}

export { NatureSelector }



import { useContext, useEffect, useRef, useState } from 'react';
import { ActionIcon, Badge, Center, Divider, Grid, Group, Highlight, LoadingOverlay, Modal, Progress, ScrollArea, Table, Text } from "@mantine/core";
import { IconFileDescription, IconFilter, IconX } from '@tabler/icons';
import { TitleContainer } from "../../shared/TitleContainer"
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { DocumentContainer } from '../DocumentContainer';

interface WordInterface {
    name: string,
    value: number,
}
interface ExampleInterface {
    id: number,
    field: string,
}

const AuditMetadataCV = () => {

    const myContext = useContext(AppContext);
    const previousField = useRef('');
    const [field, setField] = useState<string | null>(null);
    const [values, setValues ] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [words, setWords] = useState<WordInterface[]>([]);
    const [examples, setExamples] = useState<ExampleInterface[]>([]);
    const [docId,setDocId] = useState(-1);
    const [displayDoc, setDisplayDoc] = useState(false);

    useEffect(() => {
        load();
    }, [field]); // eslint-disable-line
    
    useEffect(() => {
        load();
    }, [values]); // eslint-disable-line

    const addFilter = (s: string) => {
        setValues([...values].concat([s]));
    }

    const removeFilter = (s: string) => {
        setValues(values.filter((v: string) => v !== s));
    }

    const load = () => {
        if (field === null) return;
        setIsLoading(true);
        const api = `${myContext.apiAddress}/analyze_metadata_cv`;
        const F = new FormData();
        F.append('field', field);
        if (previousField.current === field && values.length !== 0) {
            F.append('values', values.join(';'));
        } else {
            previousField.current = field;
        }
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsLoading(false);
            if (res.data.status === true) {
                setWords(res.data.data.series);
                setExamples(res.data.data.examples);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    const removeButton = (s: string) => (
        <ActionIcon size="xs" color="blue" radius="xl" variant="transparent">
            <IconX size={10} />
        </ActionIcon>
    );

    return (
        <>
        <Modal
            opened={displayDoc}
            onClose={() => { setDisplayDoc(false); setDocId(-1)}}
            title={`Curriculum vitae ${docId}`}
            fullScreen
        >
            <DocumentContainer
                documentId={docId}
                isEdition={false}
            />
        </Modal>
        <div>
            <LoadingOverlay visible={isLoading} />
            <TitleContainer>
                Analyser les méta données
            </TitleContainer>
            <Divider/>
            <Group>
                <Text>Métadonnée :</Text>
                {['jobs','sectors','tags'].map((s:string, i:number) =>
                <Badge key={`field-${i}`}
                    style={{cursor: 'pointer'}}
                    variant={s === field ? 'filled' : 'light'}
                    onClick={() => {
                        setField(s);
                        setValues([]);
                    }}
                >
                    {s}
                </Badge>
                )}
            </Group>
            <Divider/>
            <Group>
                <Text>Filtres :</Text>
                {values.map((s:string, i: number) => 
                <Badge key={`value-${i}`} variant="outline" color="yellow"
                    rightSection={removeButton(s)}
                    onClick={() => removeFilter(s)}
                >
                    {s}
                </Badge>
                )}
            </Group>
            <Divider/>
            <Grid>
                <Grid.Col span={5}>
                    {words.length !== 0 &&
                    <>
                    <Text>{words.length} valeurs :</Text>
                    <ScrollArea style={{height: 'calc(100vh - 220px)'}}>
                        <Table highlightOnHover>
                            <tbody>
                                {words.map((w:WordInterface, i: number) => 
                                <tr key={`word-${i}`}>
                                    <td style={{width: '100px'}}>
                                        <Progress 
                                            sections={[
                                                { value: 100 - Math.floor(100.0 * w.value / words[0].value), color: 'gray' },
                                                { value: Math.floor(100.0 * w.value / words[0].value), color: 'blue'}
                                            ]}
                                        />
                                    </td>
                                    <td>
                                        <Text align='right'>{w.value}</Text>
                                    </td>
                                    <td>
                                        {w.name}
                                    </td>
                                    <td>
                                        <ActionIcon color="blue" variant="outline">
                                            <IconFilter size={16} onClick={() => addFilter(w.name)} />
                                        </ActionIcon>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </Table>
                    </ScrollArea>
                    </>
                    }
                </Grid.Col>
                <Grid.Col span={1}>
                    <Center>
                        <Divider orientation="vertical" style={{height: 'calc(100vh - 200px)'}} />
                    </Center>
                </Grid.Col>
                <Grid.Col span={6}>
                    {examples.length !== 0 && 
                    <>
                    <Text>
                        {examples.length < 100 ? 'CV concernés' : 'Exemples'} :
                    </Text>
                    <ScrollArea style={{height: 'calc(100vh - 220px)'}}>
                        <Table highlightOnHover>
                            <tbody>
                                {examples.map((e:ExampleInterface, i: number) => 
                                <tr key={`exmaple-${i}`}>
                                    <td>
                                        <Highlight highlight={values}>
                                            {e.field}
                                        </Highlight>
                                    </td>
                                    <td>
                                        <ActionIcon variant="outline" color="blue" mr="md"
                                            onClick={() => { setDocId(e.id); setDisplayDoc(true)}}
                                        >
                                            <IconFileDescription size={16} />
                                        </ActionIcon>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </Table>
                    </ScrollArea>
                    </>
                    }
                </Grid.Col>
            </Grid>
        </div>
        </>
    )
}

export { AuditMetadataCV }
import { useEffect, useState } from 'react';
import { ScrollArea, Tabs, LoadingOverlay } from '@mantine/core';
import { TitleContainer } from '../../shared/TitleContainer';
import { DashboardContainer } from './dashboard';

const DashboardBook = () => {

    const [activeTab, setActiveTab] = useState<string | null>('prospects');
    const [prospectsClicked, setProspectsClicked] = useState(false);
    const [referencesClicked, setReferencesClicked] = useState(false);
    const [clientsClicked, setClientsClicked] = useState(false);

    useEffect(() => {
        if (activeTab === 'prospects' && !prospectsClicked) {
            setProspectsClicked(true);
        } else if (activeTab === 'references' && !referencesClicked) {
            setReferencesClicked(true);
        } else if (activeTab === 'clients' && !clientsClicked) {
            setClientsClicked(true);
        }
    }, [activeTab]); // eslint-disable-line

    return (
        <>
        <TitleContainer>
            Analyse de l'action commerciale
        </TitleContainer>
        <Tabs value={activeTab} onTabChange={setActiveTab}>

            <Tabs.List>
                <Tabs.Tab value="prospects">Prospects</Tabs.Tab>
                <Tabs.Tab value="references">Prises de référence</Tabs.Tab>
                <Tabs.Tab value="clients">Clients</Tabs.Tab>
            </Tabs.List>

            <ScrollArea style={{height: 'calc(100vh - 150px)'}}>
            <Tabs.Panel value="prospects">
                {prospectsClicked ?
                <DashboardContainer category='p' />
                :
                <LoadingOverlay visible={!prospectsClicked}/>
                }
            </Tabs.Panel>

            <Tabs.Panel value="references">
                {referencesClicked ?
                <DashboardContainer category='r' />
                :
                <LoadingOverlay visible={!referencesClicked} />
                }
            </Tabs.Panel>

            <Tabs.Panel value="clients">
                {clientsClicked ?
                <DashboardContainer category='c' />
                :
                <LoadingOverlay visible={!clientsClicked} />
                }
            </Tabs.Panel>

            </ScrollArea>

        </Tabs>
        </>
    )
}

export { DashboardBook }
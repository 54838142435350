import { useContext, useReducer, useState } from 'react';
import { ActionIcon, Badge, Button, Card, Group, List, Loader, Progress, Text, ThemeIcon } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconCircleDashed, IconCircleRectangle, IconCircleCheck, IconCloudUpload, IconTrash } from '@tabler/icons';
import { TitleContainer } from "../../shared/TitleContainer"
import { UserSelector } from '../../shared/UserSelector';
import AppContext from '../../shared/AppContext';

interface UserInterface {
    id: number,
    label: string,
}

interface FileInterface {
    document: File,
    metadata: File | null,
    status: number, // 0 waiting, 1 uploading, 2 error, 3 success
    message: string | null,
    auto_affectation: string | null,
}

const noData: FileInterface[] = [];

const filesReducer = (state: any, payload: any) => {
    switch (payload.type) {
        case 'LOAD':
            const IF = payload.rows.filter((f: File) => !f.name.endsWith('.xml'));
            const IM = payload.rows.filter((f: File) => f.name.endsWith('.xml'));
            const F: FileInterface[] = [];
            for (var k=0;k<IF.length;k++) {
                const x = IF[k].name.split('.').slice(0, -1).join('.') + '.xml';
                let t = -1;
                for (var i=0;i<IM.length;i++) {
                    if (IM[i].name === x) {
                        t = i;
                        break;
                    }
                }
                F.push({
                    document: IF[k],
                    metadata: t !== -1 ? IM[t] : null,
                    status: 0,
                    message: null,
                    auto_affectation: null,
                });
            }
            return {
                rows: F,
                nbr: F.length,
                ok: 0,
                ko: 0,
            };
        case 'RESET':
            return {
                rows: noData,
                nbr: 0,
                ko: 0,
                ok: 0
            };
        case 'START':
            return {
                rows: state.rows.map((row: FileInterface, idx: number) => {
                    if (idx === payload.idx) {
                        return {
                            document: row.document,
                            metadata: row.metadata,
                            status: 1,
                            message: null,
                            auto_affectation: null,
                        }
                    } else {
                        return { ...row };
                    }
                }),
                ...state
            };
        case 'END':
            return {
                rows: state.rows.map((row: FileInterface, idx: number) => {
                    if (idx === payload.idx) {
                        return {
                            document: row.document,
                            metadata: row.metadata,
                            status: payload.status ? 3 : 2,
                            message: payload.message,
                            auto_affectation: payload.auto_affectation,
                        }
                    } else {
                        return { ...row };
                    }
                }),
                ko: state.ko + (payload.status ? 0 : 1),
                ok: state.ok + (payload.status ? 1 : 0),
                nbr: state.nbr,
            }
    }
}

const InsertCV = () => {

    const myContext = useContext(AppContext);
    const api = `${myContext.apiAddress}/insert_cv`;
    const [isRunning,setIsRunning] = useState(false);
    const [isDone,setIsDone] = useState(false);
    const [files,dispatchFiles] = useReducer(
        filesReducer, {
            rows: noData,
            nbr: 0,
            ok: 0,
            ko: 0,
        }
    );
    const [user, setUser] = useState<UserInterface | null>(null);

    const setFiles = (inputFiles: File[]) => {
        dispatchFiles({type: 'LOAD', rows: inputFiles});
    }

    const reset = () => {
        dispatchFiles({type: 'RESET'})
        setIsRunning(false);
        setIsDone(false);
    }

    const uploadFiles = () => {
        if (files === undefined) return;
        if (isRunning) return;
        setIsRunning(true);
        setIsDone(false);
        const uploadPromises = files.rows.map(async (row: FileInterface, idx: number) => {
            dispatchFiles({type: 'START', idx: idx});
            const F = new FormData();
            let n = row.document.name.normalize();
            F.append('document', row.document, n);
            if (user !== null) {
                F.append('user', user.id + '');
            }
            if (row.metadata !== null) {
                F.append('metadata', row.metadata, n.substring(0, n.length - 3) + 'xml');
            }
            const result = await myContext.httpClient.post(api, F, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatchFiles({
                type: 'END', 
                idx: idx, 
                status: result.data.status === true ? true : false, 
                message: result.data.message,
                auto_affectation: (result.data.data === undefined || result.data.data === null) ? null : result.data.data.auto_affectation,
            });
        });
        Promise.all(uploadPromises).then(() => {
            setIsRunning(false);
            setIsDone(true);
        });
    }

    return (
        <>
        <TitleContainer>
            Importer un ou plusieurs curriculum vitae
        </TitleContainer>
        <Dropzone
            onDrop={(files) => setFiles(files)}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={8 * 1024 ** 2}
            maxFiles={100}
            accept={[
                'application/pdf',
                'application/xml',
                'text/xml',
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]}
            loading={isRunning}
        >
            <Group position="center" spacing="xl" style={{ minHeight: 50, pointerEvents: 'none' }}>
                <div>
                    <Group>
                        <Text>Déposer ici des fichiers</Text>
                        <Badge>PDF</Badge>
                        <Badge>PPT</Badge>
                        <Badge>PPTX</Badge>
                        <Badge>DOC</Badge>
                        <Badge>DOCX</Badge>
                        <Badge>XML</Badge>
                        <Text>ou bien cliquer sur cette zone pour sélectionner les fichiers à importer</Text>
                    </Group>
                    <Text size="sm" color="dimmed" inline mt={7}>
                        Attacher au maximum 100 fichiers, chacun de moins de 8 Mo.
                    </Text>
                </div>
            </Group>
        </Dropzone>
        <Group pt="xs" pb="xs">
            <Text>Affecter automatiquement à (optionnel)</Text>
            <UserSelector handle={(s: UserInterface) => setUser(s) }/>
            {user !== null && <>
                <Text>{user.label}</Text>
                <ActionIcon color="blue" variant="outline" onClick={() => setUser(null)}>
                    <IconTrash size={16} />
                </ActionIcon>
            </>
            }
        </Group>
        {(files?.nbr !== undefined && files.nbr > 0) && 
            <Group p="xl">
                <Progress
                    mt="md"
                    size="xl"
                    radius="xl"
                    sections={[
                        { value: Math.ceil(100*files.ok/files.nbr), color: 'green', label: 'succès' },
                        { value: Math.ceil(100*files.ko/files.nbr), color: 'red', label: 'échecs' },
                        { value: Math.ceil(100*(files.nbr-files.ko-files.ok)/files.nbr), color: 'blue', label: 'à charger' },
                    ]}
                />
                <Progress 
                    value={Math.ceil(100*(files.ok+files.ko)/files.nbr)} 
                    label={Math.ceil(100*(files.ok+files.ko)/files.nbr)+'%'}
                    mt="md"
                    size="xl" 
                    radius="xl"
                    animate={isRunning}
                />
                <Group>
                    <Badge color="blue">{files.nbr}</Badge>
                    <Text>&rArr;</Text>
                    <Badge color="teal">{files.ok}</Badge>
                    <Text>+</Text>
                    <Badge color="red">{files.ko}</Badge>    
                </Group>
            </Group>
        }
        <Card p="xl">
            <List
                spacing="xs"
                size="sm"
                center
            >
            {files?.rows.map((f: FileInterface, idx: number) => (
                <List.Item
                    key={'upload-' + idx}
                    icon={<>
                        {f.status === 0 &&
                        <ThemeIcon color="blue" size={20} radius="xl">
                            <IconCircleDashed size={14} />
                        </ThemeIcon>}
                        {f.status === 1 && 
                        <Loader variant="dots" />}
                        {f.status === 2 &&
                        <ThemeIcon color="red" size={20} radius="xl">
                            <IconCircleRectangle size={14} />
                        </ThemeIcon>}
                        {f.status === 3 &&
                        <ThemeIcon color="green" size={20} radius="xl">
                            <IconCircleCheck size={14} />
                        </ThemeIcon>}
                    </>}
                >
                    <Group>
                        <Text color="dimmed" style={{width: '70px', textAlign: 'right'}}>
                            {Math.round(f.document.size / 1024)} ko
                        </Text>
                        <Text color="dimmed" style={{width: '30px', textAlign: 'right'}}>
                            {f.metadata !== null && <>xml</>}
                        </Text>
                        <Text>{f.document.name}</Text>
                        {f.auto_affectation !== null &&
                        <Text>
                            Affectation {f.auto_affectation}
                        </Text>
                        }    
                    </Group>
                    {f.status === 2 && 
                    <Group>
                        <Text color="red" pl="xs">{f.message}</Text>
                    </Group>
                    }
                </List.Item>
            ))}
            </List>
        </Card>
        {(files?.nbr !== undefined && files.nbr > 0 && !isRunning) && 
        <Card>
            <Group spacing="xl" position="center">
                {!isDone && 
                <Button radius="xl" leftIcon={<IconCloudUpload />} variant="outline"
                    onClick={() => uploadFiles()}
                >
                    Charger
                </Button>
                }
                <Button radius="xl" leftIcon={<IconTrash />} variant="outline"
                    onClick={() => reset()}
                >
                    Effacer
                </Button>
            </Group>
        </Card>
        }
        </>
    )
}

export { InsertCV }
import { useContext } from 'react';
import AppContext from '../../shared/AppContext';

import { Group, Switch, createStyles } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    '& *': {
      cursor: 'pointer',
    },
  },

  icon: {
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: 1,
    top: 3,
  },

  iconLight: {
    left: 4,
    color: theme.white,
  },

  iconDark: {
    right: 4,
    color: theme.colors.gray[6],
  },
}));

export function ColorSchemeToggle() {
  
  const myContext = useContext(AppContext);

  const { classes, cx } = useStyles();

  const toggleColorScheme = () => {
    myContext.setColorScheme(myContext.colorScheme === 'dark' ? 'light' : 'dark');
  }

  return (
    <Group position="center">
      <div className={classes.root}>
        <IconSun className={cx(classes.icon, classes.iconLight)} size={18} stroke={1.5} />
        <IconMoonStars className={cx(classes.icon, classes.iconDark)} size={18} stroke={1.5} />
        <Switch checked={myContext.colorScheme === 'dark'} onChange={() => toggleColorScheme()} size="md" />
      </div>
    </Group>
  );
}
import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Group, Loader, Modal, ScrollArea, Text, Timeline } from '@mantine/core';
import { IconAsterisk, IconGitPullRequest, IconHistory } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface EventInterface {
    id: string,
    date: string,
    code: string,
    label: string,
    user: string,
    family: string,
}
interface HistoryInterface {
    isLoading: boolean,
    events: EventInterface[],
}
const HistoryReducer = (state: HistoryInterface, payload: any) => {
    switch (payload.type) {
        case 'SET':
            return {
                isLoading: false,
                events: payload.events.map((e: EventInterface) => { return { ...e}}),
            }
        case 'RESET':
            return {
                isLoading: true,
                events: [],
            }
        default:
            return {
                isLoading: true,
                events: [],
            }
    }
}

interface PropsInterface {
    family: string,
    id: number,
    label: string,
}

const HistoryButton = ({family, id, label}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [isOpened, setIsOpened] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [ history, dispatchHistory ] = useReducer(HistoryReducer, {
        isLoading: true, events: []
    });

    useEffect(() => {
        if (isLoaded) return;
        if (!isOpened) return;
        if (!history.isLoading) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_history_${family}?id=${id}`).then((result: any) => {
            setIsLoaded(true);
            if (result.data.status === true) {
                dispatchHistory({type: 'SET', events: result.data.data.events});
            } else {
                AlertNotification({message: result.data.message || 'unknown error from servor'});
            }
        });
    }, [isLoaded, isOpened, history.isLoading, myContext, family, id]);

    return  (
        <>
        <Modal
            opened={isOpened}
            onClose={() => { setIsOpened(false); setIsLoaded(false); dispatchHistory({type: 'RESET'})}}
            title={`Historique des évènements "${label}"`}
            size="xl"
        >
            <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                {history.isLoading && <Group position="center" align="center">
                    <Loader/>
                    <Text>Chargement de l'historique en cours...</Text>
                </Group>}
                {!history.isLoading && <Timeline>
                    {history.events.map((e: EventInterface) => (
                    <Timeline.Item
                        key={`event-${e.id}`}
                        title={<Text size="sm">{e.date}</Text>}
                        bullet={<>
                            {e.code !== 'status' && <IconAsterisk />}
                            {e.code === 'status' && <IconGitPullRequest />}
                        </>}
                    >
                        <Group>
                            <Text color="blue">{e.code}</Text>
                            <Text>{e.label}</Text>
                        </Group>
                        <Text color="dimmed" size="sm">par {e.user}</Text>
                    </Timeline.Item>
                    ))}
                </Timeline>}
            </ScrollArea>
        </Modal>
        <ActionIcon variant="outline" color="blue" onClick={() => setIsOpened(true)}>
            <IconHistory size={16}/>
        </ActionIcon>
        </>
    )
}

export { HistoryButton }
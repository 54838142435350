import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Alert, Badge, Button, Card, ColorSwatch, Group, LoadingOverlay, Modal, ScrollArea, Text } from '@mantine/core';
import { IconInfoCircle, IconRefresh } from '@tabler/icons';
import { Candidate } from './candidate';
import AppContext from '../../shared/AppContext';

interface ItemInterface {
    cv_id: number,
    talent_token: string,
    fullname: string,
    title: string,
    form_sent_date: string,
    form_opened_date: string | null,
    has_request_date: string | null,
    has_declined_date: string | null,
    form_filled_date: string | null,
    bm_score: null | number,
}
interface PropsInterface {
    hunt_id: number,
}

const Process = ({hunt_id}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [loaded, setLoaded] = useState(false);
    const [working, setWorking] = useState(true);
    const [items, setItems] = useState<ItemInterface[]>([]);
    const [displayedItems, setDisplayedItems] = useState<ItemInterface[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [candidateId, setCandidateId] = useState(0);
    const [candidateFullname, setCandidateFullname] = useState('');
    const [filterForm, setFilterForm] = useState<string | null>(null);
    const [filterChoice, setFilterChoice] = useState<number | null>(null);
    
    const scoreColors: string[] = ['gray', 'red', 'yellow', 'green'];

    useEffect(() => {
        if (loaded) return;
        setWorking(true);
        myContext.httpClient.get(`${myContext.apiAddress}/get_process_hunt?hunt_id=${hunt_id}&what=get_candidates`).then((res:any) => {
            setWorking(false);
            setLoaded(true);
            if (res.data.status === true) {
                setItems(res.data.data || []);
                setError(null);
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
    }, [loaded]); // eslint-disable-line

    const updateItem = (cv_id: number, field: string, value: any) => {
        setItems(items.map((i:ItemInterface) => {
            if (i.cv_id === cv_id) {
                if (field === 'bm_score') {
                    return {...i, 'bm_score': value};
                } else if (field === 'has_request_date') {
                    return {...i, 'has_request_date': value};
                } else if (field === 'has_declined_date') {
                    return {...i, 'has_declined_date': value};
                } else {
                    return {...i};
                }
            } else {
                return {...i};
            }
        }));
        setDisplayedItems(displayedItems.map((i:ItemInterface) => {
            if (i.cv_id === cv_id) {
                if (field === 'bm_score') {
                    return {...i, 'bm_score': value};
                } else if (field === 'has_request_date') {
                    return {...i, 'has_request_date': value};
                } else if (field === 'has_declined_date') {
                    return {...i, 'has_declined_date': value};
                } else {
                    return {...i};
                }
            } else {
                return {...i};
            }
        }));
    }

    useEffect(() => {
        if (!loaded) return;
        if (items.length === 0) {
            setDisplayedItems([]);
        } else {
            if (filterForm === null) {
                if (filterChoice === null) {
                    setDisplayedItems(items.map((i:ItemInterface) => {return {...i}}));
                } else {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.bm_score === filterChoice
                    ));
                }
            } else if (filterForm === 'info') {
                if (filterChoice === null) {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.has_request_date !== null
                    ).map((i:ItemInterface) => {return {...i}}));
                } else {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.has_request_date !== null && i.bm_score === filterChoice
                    ).map((i:ItemInterface) => {return {...i}}));
                }
            } else if (filterForm === 'refus') {
                if (filterChoice === null) {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.has_declined_date !== null
                    ).map((i:ItemInterface) => {return {...i}}));
                } else {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.has_declined_date !== null && i.bm_score === filterChoice
                    ).map((i:ItemInterface) => {return {...i}}));
                }
            }  else if (filterForm === 'ouvert') {
                if (filterChoice === null) {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.form_opened_date !== null && i.form_filled_date === null && i.has_declined_date === null
                    ).map((i:ItemInterface) => {return {...i}}));
                } else {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.form_opened_date !== null && i.form_filled_date === null && i.has_declined_date === null && i.bm_score === filterChoice
                    ).map((i:ItemInterface) => {return {...i}}));
                }
            } else if (filterForm === 'partant') {
                if (filterChoice === null) {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.form_filled_date !== null
                    ).map((i:ItemInterface) => {return {...i}}));
                } else {
                    setDisplayedItems(items.filter((i:ItemInterface) => 
                        i.form_filled_date !== null && i.bm_score === filterChoice
                    ).map((i:ItemInterface) => {return {...i}}));
                }
            }
        }
    }, [filterForm, filterChoice, loaded]); // eslint-disable-line

    // rendu
    return (
        <>
        <Modal
            opened={candidateId !== 0}
            onClose={() => {
                setCandidateId(0);
                setCandidateFullname('');
            }}
            title={`Fiche de suivi de ${candidateFullname}`}
            fullScreen
        >
            <Candidate 
                hunt_id={hunt_id}
                cv_id={candidateId} 
                handle={updateItem}
            />
        </Modal>
        <ScrollArea style={{height: 'calc(100vh - 150px)'}}>
            <LoadingOverlay visible={working} />
            <Group position="apart" pt="xs">
                <Text>Candidats ayant remplis le formulaire</Text>
                <Group>
                    <Text>Filter sur</Text>
                    <Button color="blue" variant={filterForm === 'info' ? 'filled' : 'outline'} compact
                        onClick={() => setFilterForm(filterForm === 'info' ? null : 'info')}
                    >
                        info
                    </Button>
                    <Button color="blue" variant={filterForm === 'refus' ? 'filled' : 'outline'} compact
                        onClick={() => setFilterForm(filterForm === 'refus' ? null : 'refus')}
                    >
                        refus
                    </Button>
                    <Button color="blue" variant={filterForm === 'ouvert' ? 'filled' : 'outline'} compact
                        onClick={() => setFilterForm(filterForm === 'ouvert' ? null : 'ouvert')}
                    >
                        ouvert
                    </Button>
                    <Button color="blue" variant={filterForm === 'partant' ? 'filled' : 'outline'} compact
                        onClick={() => setFilterForm(filterForm === 'partant' ? null : 'partant')}
                    >
                        partant
                    </Button>
                    <ActionIcon color='blue' variant={filterChoice === -2 ? 'filled' : 'outline'}
                        onClick={() => setFilterChoice(filterChoice === -2 ? null : -2)}
                    >
                        <ColorSwatch color={scoreColors[0]} size={12} />
                    </ActionIcon>
                    <ActionIcon color='blue' variant={filterChoice === -1 ? 'filled' : 'outline'}
                        onClick={() => setFilterChoice(filterChoice === -1 ? null : -1)}
                    >
                        <ColorSwatch color={scoreColors[1]} size={12} />
                    </ActionIcon>
                    <ActionIcon color='blue' variant={filterChoice === 0 ? 'filled' : 'outline'}
                        onClick={() => setFilterChoice(filterChoice === 0 ? null : 0)}
                    >
                        <ColorSwatch color={scoreColors[2]} size={12} />
                    </ActionIcon>
                    <ActionIcon color='blue' variant={filterChoice === 1 ? 'filled' : 'outline'}
                        onClick={() => setFilterChoice(filterChoice === 1 ? null : 1)}
                    >
                        <ColorSwatch color={scoreColors[3]} size={12} />
                    </ActionIcon>
                </Group>
                <ActionIcon color='blue' variant="outline" onClick={() => setLoaded(false)}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
            {error !== null &&
            <Alert variant="light" color="red" title="oops" icon={<IconInfoCircle />}>
                {error}
            </Alert>
            }
            {displayedItems.map((item: ItemInterface) => 
            <Card key={`candidate-${item.cv_id}`}>
                <Group position='apart'>
                    <Group>
                        <ColorSwatch
                            size={12}
                            color={(item.bm_score === null) ? '' : scoreColors[item.bm_score + 2]}
                        />
                        <Text>{item.fullname}</Text>
                        {item.has_request_date !== null && <Badge color="blue">info</Badge>}
                        {item.has_declined_date !== null && <Badge color="red">refus</Badge>}
                        {(item.form_opened_date !== null && item.form_filled_date === null && item.has_declined_date === null) && <Badge color="gray">indécis</Badge>}
                        {item.form_filled_date !== null && <Badge color="teal">partant</Badge>}
                    </Group>
                    <Group>
                        <Button color='blue' variant='outline' compact
                            onClick={() => {
                                setCandidateId(item.cv_id);
                                setCandidateFullname(item.fullname);
                            }}
                        >
                            voir sa fiche de suivi
                        </Button>
                        <Button component="a" 
                            href={`https://www.linkedin.com/talent/profile/${item.talent_token}`}
                            target="_blank"
                            color='blue' variant='outline'
                            compact
                        >
                            voir sa page LinkedIn
                        </Button>
                    </Group>
                </Group>
                <Text color="dimmed" size="sm">{item.title.replaceAll('\\n', ' - ')}</Text>
                <Text size="xs" color="dimmed">inmail le {item.form_sent_date}, formulaire rempli le {item.form_filled_date}</Text>
            </Card>
            )}
        </ScrollArea>
        </>
    )

}

export { Process }
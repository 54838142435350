import { Text } from '@mantine/core';

const TitleContainer = (props: any) => {

    return (
        <Text weight={700} underline mb="xs">
            {props.children}
        </Text>
    )
}

export { TitleContainer }
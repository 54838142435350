import { useParams } from "react-router-dom";
import { DocumentContainer } from '../DocumentContainer';

type ParamInterface = {
    paramDocumentId: string,
}

const DocumentCV = () => {

    const { paramDocumentId } = useParams<ParamInterface>();
    
    return (
        <>
        <DocumentContainer
            documentId={paramDocumentId === undefined ? -1 : parseInt(paramDocumentId)}
            isEdition={true}
        />
        </>
    )
}

export { DocumentCV }
import { useContext, useState } from 'react';
import { ActionIcon, Card, createStyles, Group, Loader, Text } from '@mantine/core';
import 'dayjs/locale/fr';
import { DatePicker } from '@mantine/dates';
import { IconCheck, IconX } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface PropsInterface {
    code: string,
    label: string,
    defaultValue: string,
    api: string,
    isEdition: boolean,
    handle: any,
    withAsterisk: boolean
}

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },
    
    input: {
        height: 'auto',
        paddingTop: 18,
        marginTop: 5,
    },
    
    label: {
        position: 'absolute',
        pointerEvents: 'none',
        color: theme.colors.gray[6],
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}))

const DatePickerContainer = ({code, label, defaultValue, api, isEdition, handle, withAsterisk}: PropsInterface) => {

    const { classes } = useStyles();
    const [ value, setValue ] = useState(defaultValue);
    const [ date, setDate ] = useState<Date | null>(defaultValue === '' ? null : new Date(defaultValue));
    const [ isUpdating, setIsUpdating ] = useState(false);
    const myContext = useContext(AppContext);

    const reset = () => {
        setDate(value === '' ? null : new Date(value));
    }

    const dateToString = (d: Date) => {
        return d.getFullYear() + '-' + (d.getMonth() < 9 ? '0' + (d.getMonth()+1) : (d.getMonth()+1)) + '-' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
    }

    const update = () => {
        const s = (date === null) ? '2900-01-01' : dateToString(date);
        setIsUpdating(true);
        const F = new FormData();
        F.append('action', code);
        F.append('code', code);
        F.append('value', s);
        myContext.httpClient.post(api, F).then((result: any) => {
            if (result.data.status === true) {
                setValue((s === '2900-01-01') ? '' : s);
                if (handle !== null) {
                    handle((s === '2900-01-01') ? '' : s);
                }
            } else {
                setDate(new Date(value));
                AlertNotification({message: result.data.message || "unknown error from servor"});
            }
            setIsUpdating(false);
        });
    }

    if (isEdition) {
        return (
            <>
            <DatePicker 
                locale='fr'
                label={label}
                placeholder="-"
                value={date}
                onChange={setDate}
                classNames={classes} 
                disabled={isUpdating}
                withAsterisk={withAsterisk}
            />
            <Group position="center">
                {(!isUpdating && ( (date !== null && value !== dateToString(date)) || (date === null  && (value !== null && value !== '')))) && 
                <>
                <ActionIcon color="blue" radius="xl" variant="outline" onClick={reset}>
                    <IconX />
                </ActionIcon>
                <ActionIcon color="blue" radius="xl" variant="filled" onClick={update}>
                    <IconCheck />
                </ActionIcon>
                </>}
                {isUpdating && <Loader />}
            </Group>
            </>
        );
    } else {
        return (
            <Card p="xs">
                <Text size="sm" color="dimmed">
                    {label}
                </Text>
                <Text>
                    {value || '-'}
                </Text>
            </Card>
        );
    }

}

export { DatePickerContainer }
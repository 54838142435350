import { showNotification } from '@mantine/notifications';

interface PropsInterface {
    message: string,
}

const InfoNotification = ({message}: PropsInterface) => {

    showNotification({
        message: message,
        color: "green",
        autoClose: 2000,
        styles: (theme) => ({
            root: {
                backgroundColor: theme.colors.green[6],
                borderColor: theme.colors.green[6],
                '&::before': { backgroundColor: theme.white },
            },
            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
                color: theme.white,
                '&:hover': { backgroundColor: theme.colors.green[7] },
            },
        }),
    });

}

export { InfoNotification }
import { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Badge, Center, Divider, Group, Loader, LoadingOverlay, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { IconArrowBigRight, IconCheck, IconUrgent, IconQuestionMark, IconRefresh, IconSum, IconUserSearch, IconX } from '@tabler/icons';
import { AlertNotification } from '../../shared/AlertNotification';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';
import { DateInputButton } from '../../shared/DateInputButton';
import { toFrenchDate } from '../../../services/functions';
import { UserSelector } from '../../shared/UserSelector';
const ChartContainer = lazy(() => import('../../shared/ChartContainer').then(({ChartContainer}) => ({default: ChartContainer})));

interface ItemInterface {
    id: number,
    label: string,
}
interface MissionInterface {
    id: number,
    mission_name: string,
    invoice: number,
    bill: number,
    margin: number,
    is_finished: boolean,
}
interface DataInterface {
    filters: {
        date_start: string,
        date_end: string,
    },
    categories: string[],
    series: {
        contract: number[],
        contract_lost: number[],
        invoice: number[],
        bill: number[],
        margin: number[],
    },
    clients: MissionInterface[],
    total: {
        invoice: number,
        bill: number,
        margin: number,
    }
}

const DashboardMission = () => {

    const myContext = useContext(AppContext);
    const [data, setData] = useState<DataInterface | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [dateStart, setDateStart] = useState<string | null>(null);
    const [dateEnd, setDateEnd] = useState<string | null>(null);
    const [user, setUser] = useState<ItemInterface | null>(null);

    const updateDates = (d1: string, d2: string) => {
        setDateStart(d1);
        setDateEnd(d2);
        setLoaded(false);
    }

    const changeYearBy = (v: number) => {
        if (dateStart === null) return;
        if (dateEnd === null) return;
        let d: number = parseInt(dateStart.substring(0,4)) + v;
        setDateStart(d + dateStart.substring(4));
        d = parseInt(dateEnd.substring(0,4)) + v;
        setDateEnd(d + dateEnd.substring(4));
        setLoaded(false);
    }

    const updateUser = (u: ItemInterface) => {
        setUser({...u});
        setLoaded(false);
    }

    const cumulateData = (tab: number[]) => {
        const n = tab.length;
        const a: number[] = [...tab];
        let i = n;
        while (a[i-1] === null) {
            i -= 1;
            if (i === 0) {
                break;
            }
        }
        for (let j=0;j<i;j++) {
            if (tab[j] !== null) {
                for (let k=j+1;k<i;k++) {
                    a[k] += tab[j];
                }
            }
        }
        for(let i=0;i<a.length;i++){
            a[i] = Math.floor(a[i]);
        }
        return [...a];
    }

    useEffect(() => {
        if (loaded) return;
        const F = new FormData();
        if (dateStart !== null && dateEnd !== null) {
            F.append('date_start', dateStart);
            F.append('date_end', dateEnd);
        }
        if (user !== null) {
            F.append('user_id', user.id + '');
        }
        myContext.httpClient.post(`${myContext.apiAddress}/get_finance_all_mission`, F).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                setData(res.data.data);
                setDateStart(res.data.data.filters.date_start);
                setDateEnd(res.data.data.filters.date_end);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [loaded]); // eslint-disable-line

    return (
        <>
        <LoadingOverlay visible={!loaded} />
        <TitleContainer>Tableau de bord global sur les missions (montants hors taxes)</TitleContainer>
        {data === null ?
        <>
        <Center>
            <Text>Aucune donnée</Text>
        </Center>
        </>
        :
        <>
        <Center>
            <Stack>
                <Group position="apart">
                    <Group>
                        <UserSelector
                            handle={updateUser}
                        />
                        <IconUserSearch size={16} />
                        {user !== null &&
                        <>
                        <Badge>{user.label}</Badge>
                        <ActionIcon color="blue" variant="outline" onClick={() => { setUser(null); setLoaded(false) }}>
                            <IconX size={16} />
                        </ActionIcon>
                        </>
                        }
                    </Group>
                    <Group>
                        <Badge>
                            {`${toFrenchDate(data.filters.date_start)} - ${toFrenchDate(data.filters.date_end)}`}
                        </Badge>
                        <ActionIcon color='blue' variant='outline'
                            onClick={() => changeYearBy(-1)}
                        >
                            <Text size='xs'>-1Y</Text>
                        </ActionIcon>
                        <DateInputButton
                            label1='Date de début (incluse)'
                            label2='Date de fin (incluse)'
                            handle={updateDates}
                        />
                        <ActionIcon color='blue' variant='outline'
                            onClick={() => changeYearBy(1)}
                        >
                            <Text size='xs'>+1Y</Text>
                        </ActionIcon>
                        <Divider orientation='vertical' />
                        <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                            <IconRefresh size={16} />
                        </ActionIcon>
                    </Group>
                </Group>
                <Divider label="Evolution mois par mois" labelPosition='center' />
                <Suspense fallback={<Loader/>}>
                    <ChartContainer option={{
                        style: {
                            width: '900px',
                            height: '500px',
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['prévisionnel client', 'prévisionnel client perdu', 'facturé client', 'facturé manager', 'marge brute']
                        },
                        xAxis: {
                            type: 'category',
                            data: data.categories,
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: '{value} k€'
                            }
                        },
                        series: [{
                            name: 'prévisionnel client',
                            type: 'line',
                            symbolSize: 10,
                            data: data.series.contract,
                            stack: 'total',
                        },{
                            name: 'prévisionnel client perdu',
                            type: 'line',
                            symbolSize: 10,
                            data: data.series.contract_lost,
                            stack: 'total',
                        },{
                            name: 'facturé client',
                            type: 'line',
                            symbolSize: 10,
                            data: data.series.invoice,
                        },{
                            name: 'facturé manager',
                            type: 'line',
                            symbolSize: 10,
                            data: data.series.bill,
                        },{
                            name: 'marge brute',
                            type: 'line',
                            symbolSize: 10,
                            data: data.series.margin,
                        }]
                    }} />
                </Suspense>
                <Divider label="Evolution en cumulé" labelPosition='center' />
                <Suspense fallback={<Loader/>}>
                    <ChartContainer option={{
                        style: {
                            width: '900px',
                            height: '500px',
                        },
                        tooltip: {
                            trigger: 'axis',
                            valueFormatter: (v:number) => `${v.toLocaleString()}`
                        },
                        legend: {
                            data: ['prévisionnel client', 'prévisionnel client perdu', 'facturé client', 'facturé manager', 'marge brute']
                        },
                        xAxis: {
                            type: 'category',
                            data: data.categories,
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: (v:number) => `${v.toLocaleString()}`
                            }
                        },
                        series: [{
                            name: 'prévisionnel client',
                            type: 'line',
                            symbolSize: 10,
                            data: cumulateData(data.series.contract),
                            stack: 'total',
                        },{
                            name: 'prévisionnel client perdu',
                            type: 'line',
                            symbolSize: 10,
                            data: cumulateData(data.series.contract_lost),
                            stack: 'total',
                        },{
                            name: 'facturé client',
                            type: 'line',
                            symbolSize: 10,
                            data: cumulateData(data.series.invoice),
                        },{
                            name: 'facturé manager',
                            type: 'line',
                            symbolSize: 10,
                            data: cumulateData(data.series.bill),
                        },{
                            name: 'marge brute',
                            type: 'line',
                            symbolSize: 10,
                            data: cumulateData(data.series.margin),
                        }]
                    }} />
                </Suspense>
                <Divider label="Détail par mission" labelPosition='center' />
                <ScrollArea style={{height: 'calc(100vh - 200px)', width: 'min(100vw, 900px)'}}>
                    <Table highlightOnHover
                        style={{width: '900px'}}
                    >
                        <thead>
                            <tr>
                                <th></th>
                                <th>Client</th>
                                <th>Facturé client</th>
                                <th>Facturé manager</th>
                                <th>Marge brute</th>
                                <th style={{width: '60px'}}>Taux</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.clients.map((c,i) => 
                            <tr key={`client-${i}`}>
                                <td>
                                    {c.is_finished ?
                                    <Badge color='orange'>clos</Badge>
                                    :
                                    <Badge color="blue">run</Badge>
                                    } 
                                </td>
                                <td>{c.mission_name}</td>
                                <td><Text align="center">{Math.floor(c.invoice).toLocaleString('fr-Fr')}</Text></td>
                                <td><Text align="center">{Math.floor(c.bill).toLocaleString('fr-Fr')}</Text></td>
                                <td><Text align="center">{Math.floor(c.margin).toLocaleString('fr-Fr')}</Text></td>
                                <td><Text align="center">{c.invoice > 0 ? Math.floor(100 * c.margin / c.invoice) : ''} %</Text></td>
                                <td>
                                    {c.margin / c.invoice < 0.2 ?
                                    <Text align="center" color="red"><IconUrgent size={16}/></Text>
                                    :
                                    <>
                                        {c.invoice > 0 ?
                                        <>
                                        {(Math.floor(100 * c.margin / c.invoice) >= 25 && Math.floor(100 * c.margin / c.invoice) <= 35) ?
                                            <Text align="center" color="green"><IconCheck size={16}/></Text>
                                        :
                                            <Text align="center" color="yellow"><IconQuestionMark size={16}/></Text>
                                        }
                                        </>
                                        :
                                        <Text>...</Text>
                                        }
                                    </>
                                    }
                                </td>
                                <td>
                                    <ActionIcon component={Link} to={`/mission/manage/${c.id}`}
                                        color='blue' variant='outline'
                                    >
                                        <IconArrowBigRight size={16} />
                                    </ActionIcon>
                                </td>
                            </tr>
                            )}
                            <tr>
                                <td><Text align="center"><IconSum size={16}/></Text></td>
                                <td><Text align="center">{Math.floor(data.total.invoice).toLocaleString('fr-Fr')}</Text></td>
                                <td><Text align="center">{Math.floor(data.total.bill).toLocaleString('fr-Fr')}</Text></td>
                                <td><Text align="center">{Math.floor(data.total.margin).toLocaleString('fr-Fr')}</Text></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </ScrollArea>
            </Stack>
        </Center>
        </>
        }
        </>
    )
}

export { DashboardMission }
import { useContext, useState } from 'react';
import { Card, Button, Group, Overlay, ScrollArea, Stack, Switch, Text, TextInput, useMantineTheme } from '@mantine/core';
import { Dropzone,  IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons';
import { useForm } from '@mantine/form';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface UserEditionInterface {
    id: number,
    nickname: string,
    firstname: string,
    lastname: string,
    email: string,
    is_frozen: boolean,
    handleChange: (params: any) => any,
}

const EditAccount = (user: UserEditionInterface) => {

    const appContext = useContext(AppContext);
    const theme = useMantineTheme();
    const [isUpdating,setIsUpdating] = useState(false);
    const apiAddressUpdate = `${appContext.apiAddress}/update_account`;

    const [file,setFile] = useState<File | null>(null);
    const form = useForm({
        initialValues: {
            nickname: user.nickname,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            is_active: !user.is_frozen,
        },
        validate: {
            nickname: (val: string) => (/^[a-z]{2,20}$/.test(val) ? null : 'Identifiant invalide'),
            firstname: (val: string) => (/^[A-Za-zéèêï]{2,15}([ -][A-Za-zéèêï]{2,15}){0,2}$/.test(val) ? null : 'Prénom invalide'),
            lastname: (val: string) => (/^[A-Z]{2,15}([ -][A-Z]{2,15}){0,2}$/.test(val) ? null : 'Nom de famille invalide'),
            email: (val: string) => (/^[a-z0-9-._]{1,40}@([a-z0-9-]{1,9}\.){0,1}[a-z0-9-]{1,20}\.[a-z]{2,5}$/.test(val) ? null : 'Email invalide'),
        },
    });

    const updateUser = () => {
        const f = new FormData();
        let c:number = 0;
        f.append('id', user.id + '');
        if (form.values.nickname !== user.nickname) {
            f.append('nickname', form.values.nickname);
            c++;
        }
        if (form.values.firstname !== user.firstname) {
            f.append('firstname', form.values.firstname);
            c++;
        }
        if (form.values.lastname !== user.lastname) {
            f.append('lastname', form.values.lastname);
            c++;
        }
        if (form.values.email !== user.email) {
            f.append('email', form.values.email);
            c++;
        }
        if (form.values.is_active === user.is_frozen) {
            f.append('is_frozen', (form.values.is_active) ? '0' : '1');
            c++;
        }
        if (c === 0) return;
        setIsUpdating(true);
        appContext.httpClient.post(apiAddressUpdate, f).then((result: any) => {
            if (result.data.status === true) {
                user.handleChange({
                    id: user.id,
                    nickname: form.values.nickname,
                    firstname: form.values.firstname,
                    lastname: form.values.lastname,
                    email: form.values.email,
                    is_frozen: !form.values.is_active,
                });
            } else {
                AlertNotification({message: result.data.message || 'Something wrong from the servor, sorry :('});
            }
            setIsUpdating(false);
        });
    }

    const updateAvatar = () => {
        if (file === null) return;
        const f = new FormData();
        f.append('id', user.id + '');
        f.append('avatar', file, file.name.normalize());
        setIsUpdating(true);
        appContext.httpClient.post(apiAddressUpdate, f).then((result: any) => {
            if (result.data.status === true) {
                user.handleChange({
                    id: user.id,
                    avatar: result.data.data.avatar,
                });
            } else {
                AlertNotification({message: result.data.message || 'Something wrong from the servor, sorry :('});
                setFile(null);
            }
            setIsUpdating(false);
        });
    }

    return (
        <>
        { isUpdating && <Overlay /> }
        <ScrollArea style={{ height: '90%' }}>
            <Card shadow="sm" p="lg" radius="md" withBorder>
                <form onSubmit={form.onSubmit(() => { updateUser() })}>
                    <Stack>
                        <TextInput
                            required
                            label="Identifiant de connexion"
                            placeholder="Identifianti de connexion"
                            value={form.values.nickname}
                            onChange={(event) => form.setFieldValue('nickname', event.currentTarget.value)}
                            error={form.errors.nickname && 'Identifiant invalide'}
                        />
                        <TextInput
                            required
                            label="Prénom"
                            placeholder="Prénom"
                            value={form.values.firstname}
                            onChange={(event) => form.setFieldValue('firstname', event.currentTarget.value)}
                            error={form.errors.firstname && 'Prénom invalide'}
                        />
                        <TextInput
                            required
                            label="Nom de famille"
                            placeholder="Nom de famille"
                            value={form.values.lastname}
                            onChange={(event) => form.setFieldValue('lastname', event.currentTarget.value)}
                            error={form.errors.lastname && 'Nom de famille invalide'}
                        />
                        <TextInput
                            required
                            label="Email"
                            placeholder="Email"
                            value={form.values.email}
                            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                            error={form.errors.email && 'Email invalide'}
                        />
                        <Switch
                            label="Est actif ?"
                            checked={form.values.is_active}
                            onChange={(event) => form.setFieldValue('is_active', event.currentTarget.checked)}
                        />
                    </Stack>
                    <Group position="right" mt="xl">
                        <Button type="submit">Mettre à jour</Button>
                    </Group>
                </form>
            </Card>
            <div>&nbsp;</div>
            <Card shadow="sm" p="lg" radius="md" withBorder>
                <div>
                    <Text>
                        Pour changer l'avatar, déposer un fichier image ci-dessous ou cliquer pour en sélectionner un :
                    </Text>
                </div>
                <Dropzone
                    onDrop={(files) => setFile(files[0])}
                    onReject={(files) => console.log('rejected files', files)}
                    maxSize={1024 * 1024}
                    accept={IMAGE_MIME_TYPE}
                >
                    <Group position="center" spacing="xl" style={{ minHeight: 50, pointerEvents: 'none' }}>
                        <Dropzone.Accept>
                            <IconUpload
                                size={30}
                                stroke={1.5}
                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                            />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <IconX
                                size={30}
                                stroke={1.5}
                                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                            />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <IconPhoto size={30} stroke={1.5} />
                        </Dropzone.Idle>
                    </Group>
                    <Group>
                        <Text>Fichier sélectionné : <strong>{file ? file.name : ''}</strong></Text>
                    </Group>
                </Dropzone>
                <Group position="right" mt="xl">
                    <Button onClick={() => updateAvatar()}>Mettre à jour</Button>
                </Group>
            </Card>
        </ScrollArea>
        </>
    );
}

export { EditAccount }

import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Alert, Badge, ColorSwatch, Divider, Group, LoadingOverlay, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { IconAlertCircle, IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { FileUploaderButton } from '../../shared/FileUploaderButton';
import { DocumentButton } from './document_button';
import { TrashButton } from '../../shared/TrashButton';
import { toFrenchDate } from '../../../services/functions';
import { InvoiceMakerButton } from './invoice_maker_button';
import { ReportMakerButton } from './report_maker_button';
import { LetteringBadge } from '../../bank/lettering_badge';

interface DocumentInterface {
    id: number,
    status: string,
    filename: string,
    extension: string,
    is_removed: boolean,
    created_date: string,
    lettering: string | null,
}

const DocumentsReducer = (state: DocumentInterface[], payload: any) => {
    switch(payload.type) {
        case 'set':
            return payload.data.filter((d: DocumentInterface) => !d.is_removed);
        case 'add':
            const n: DocumentInterface[] = state.map((d) => { return {...d}});
            n.unshift({...payload.data});
            return n;
        case 'update':
            return state.map((d: DocumentInterface) => {
                if (d.id === payload.id) {
                    return {
                        ...d,
                        filename: payload.field === 'filename' ? payload.value : d.filename,
                        status: payload.field === 'status' ? payload.value : d.status,
                    }
                } else {
                    return {...d};
                }
            });
        case 'remove':
            return state.filter((d) => d.id !== payload.id);
        default:
            return [];
    }
}

interface PropsInterface {
    missionId: number,
    missionLabel: string,
    category: 'contract' | 'bill' | 'invoice' | 'report',
    missionOpened: boolean,
}

const DocumentsContainer = ({missionId, missionLabel, category, missionOpened}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [error, setError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [documents, dispatchDocuments] = useReducer(DocumentsReducer, []);

    useEffect(() => {
        if (isLoaded) return;
        const F = new FormData();
        F.append('id', missionId + '');
        F.append('category', category);
        const api = `${myContext.apiAddress}/list_${category}_mission`;
        myContext.httpClient.post(api, F).then((res: any) => {
            setIsLoaded(true);
            if (res.data.status === true) {
                if (res.data.data !== null) {
                    dispatchDocuments({type: 'set', data: res.data.data});
                }
            } else {
                setError(res.data.message || 'internal error');
            }
        });
    }, [isLoaded, category, missionId]); // eslint-disable-line

    return (
        <div style={{height: '400px'}}>
            <LoadingOverlay visible={!isLoaded} overlayBlur={2} />
            <Group position="apart">
                <Group spacing={0}>
                    {category === 'contract' && <><ColorSwatch color="orange" size={12}/><ColorSwatch color="yellow" size={12}/></>}
                    {category === 'invoice' && <ColorSwatch color="orange" size={12}/>}
                    {category === 'bill' && <ColorSwatch color="yellow" size={12}/>}
                    {category === 'report' && <ColorSwatch color="yellow" size={12}/>}
                    <Text style={{paddingLeft: '0.5em'}}>
                        {(category === 'contract') && <>Contrats et avenants</>}
                        {(category === 'invoice') && <>Factures client</>}
                        {(category === 'bill') && <>Factures fournisseur</>}
                        {(category === 'report') && <>Comptes rendus d'activité (CRA)</>}
                    </Text>
                </Group>
                <Group>
                    <Badge color={documents.length === 0 ? 'gray' : 'blue'}>{documents.length}</Badge>
                    {missionOpened && 
                    <ActionIcon color="blue" variant="outline"
                        onClick={() => setIsLoaded(false)}
                    >
                        <IconRefresh size={16} />
                    </ActionIcon>
                    }
                </Group>
            </Group>
            {error !== '' && 
            <Alert icon={<IconAlertCircle size={16} />} title="Erreur" color="red">
                {error}
            </Alert>
            }
            <Divider label="Liste des documents" labelPosition="center"/>
            {documents.length > 0 && 
            <ScrollArea style={{height: '300px'}}>
                <Table><tbody>
                {documents.map((d: DocumentInterface, idx: number) => (
                <tr key={`${category}-document-${idx}`}>
                    <td>
                        <Text>{d.filename}</Text>
                        <Text size="xs">le {toFrenchDate(d.created_date)}</Text>
                    </td>
                    <td>
                        <Stack spacing='xs'>
                            <Badge>{d.status}</Badge>
                            {d.lettering !== null && <LetteringBadge letter={d.lettering} /> }
                        </Stack>
                    </td>
                    <td>
                        <Stack spacing="xs">
                            <DocumentButton 
                                missionId={missionId}
                                missionOpened={missionOpened && d.lettering === null}
                                category={category}
                                fileId={d.id}
                                title={`${category} / ${d.filename}`}
                                handleChange={(res:any) => dispatchDocuments({type: 'update', field: res.field, value: res.value, id: res.id})}
                            />
                            {(missionOpened && d.lettering === null) &&
                            <TrashButton
                                label={`Supprimer "${d.filename}" ?`}
                                api={`update_${category}_mission`}
                                params={`id=${missionId}&file_id=${d.id}&action=remove`}
                                handle={() => {dispatchDocuments({type: 'remove', id: d.id})}}
                            />
                            }
                        </Stack>
                    </td>
                </tr>
                ))}
                </tbody></Table>
            </ScrollArea>
            }
            {missionOpened &&
            <>
            <Divider label="Ajouter un document" labelPosition="center" />
            <Group position="center" spacing="xl">
                <FileUploaderButton
                    title={
                        ((category === 'contract') ? "Contrats et avenants" : "")
                        + ((category === 'invoice') ? "Factures client" : "")
                        + ((category === 'bill') ? "Factures fournisseur" : "")
                        + ((category === 'report') ? "Comptes rendus d'activité" : "")
                    }
                    metadata={
                        category === 'contract' ?
                        [
                            {label: "Libellé du document", code: "label", type: "text", options: null},
                            {label: "Statut du document", code: "status", type: "list", options: null},
                        ]
                        :
                        [
                            {label: "Libellé du document", code: "label", type: "text", options: null},
                            {label: "Statut du document", code: "status", type: "list", options: null},
                            {label: "Référence du document", code: "reference", type: "text", options: null},
                        ]
                    }
                    api={`update_${category}_mission?id=${missionId}&action=add`}
                    handle={(d: DocumentInterface) => dispatchDocuments({type: 'add', data: d})}
                />
                {category === 'invoice' && 
                <InvoiceMakerButton 
                    missionId={missionId} 
                    missionLabel={missionLabel} 
                    handle={(d: DocumentInterface) => dispatchDocuments({type: 'add', data: d})}
                />
                }
                {category === 'report' && 
                <ReportMakerButton 
                    missionId={missionId} 
                    missionLabel={missionLabel} 
                />
                }
            </Group>
            </>}
        </div>
    )
}

export { DocumentsContainer }
import { useContext, useState } from 'react';
import { Badge, Group, Loader, Switch, Text } from '@mantine/core';
import { AlertNotification } from '../AlertNotification';
import AppContext from '../AppContext';

interface PropsInterface {
  action: string,
  defaultValue: boolean,
  label: string,
  isEdition: boolean,
  api: string,
  handle: any,
}

const ToggleContainer = ({action, defaultValue, label, isEdition, api, handle}: PropsInterface) => {

  const myContext = useContext(AppContext);
  const [checked, setChecked] = useState(defaultValue);
  const [isUpdating, setIsUpdating] = useState(false);

  const update = (status: boolean) => {
    if (isEdition) {
      if (api === '') {
        handle(status);
        setChecked(status);
      } else {
        const F = new FormData();
        F.append('action', action);
        F.append('code', action);
        F.append('value', status ? '1' : '0');
        setIsUpdating(true);
        myContext.httpClient.post(api, F).then((res:any) => {
          setIsUpdating(false);
          if (res.data.status === true) {
            if (handle !== null) handle(status);
            setChecked(status);
          } else {
            AlertNotification({message: res.data.message || "Mise à jour impossible"});
          }
        });
      }  
    }
  }

  return (
    <Group>
      {label && <Text color="dimmed" size="xs">{label}</Text>}
      {isUpdating && 
      <Loader size="xs"/>
      }
      {(isEdition && !isUpdating) &&
      <Switch
        checked={checked}
        disabled={!isEdition}
        onChange={(event) => update(event.currentTarget.checked)}
        size="md"
        onLabel='OUI'
        offLabel='NON'
      />
      }
      {(!isEdition && checked) && <Badge color="green">oui</Badge>}
      {(!isEdition && !checked) && <Badge color="red">non</Badge>}
    </Group>
  );

}

export { ToggleContainer }
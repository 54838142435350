import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ActionIcon, Card, createStyles, Group, Loader, Text, NumberInput } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },
    
    input: {
        height: 'auto',
        paddingTop: 18,
        marginTop: 5,
    },
    
    label: {
        position: 'absolute',
        pointerEvents: 'none',
        color: theme.colors.gray[6],
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}))

interface PropsInterface {
    label: string,
    min: number,
    max: number,
    step: number,
    defaultValue: number | null,
    api: string | null,
    handleValue: any,
    withAsterisk: boolean,
}

const NumberInputContainer = ({label, min, max, step, defaultValue, api, handleValue, withAsterisk}: PropsInterface) => {

    const { classes } = useStyles();
    const [ oldValue, setOldValue ] = useState<number>(defaultValue || 0);
    const [ newValue, setNewValue ] = useState<number>(defaultValue || 0);
    const [ isUpdating, setIsUpdating ] = useState(false);
    const myContext = useContext(AppContext);

    const reset = () => {
        setNewValue(oldValue);
    }

    const update = useCallback(() => {
        setIsUpdating(true);
        unsavedFrom.current = null;
        const F = new FormData();
        F.append('value', newValue + '');
        myContext.httpClient.post(api, F).then((result: any) => {
            if (result.data.status === true) {
                setOldValue(newValue);
                if (handleValue !== null) {
                    handleValue(newValue);
                }
            } else {
                setNewValue(oldValue);
                AlertNotification({message: result.data.message || "unknown error from servor"});
            }
            setIsUpdating(false);
        });
    }, [newValue]); // eslint-disable-line

    // --------------------------------------------------------
    // On met en place un mécanisme de sauvegarde automatique
    // après 5 secondes de non saisie suite à une modification.
    const unsavedSeconds = 5;
    const unsavedFrom = useRef<number | null>(null);
    const startAutoSaveTimer = useRef<any>(null);
    const testAutoSaveTimer = useCallback(() => {
        clearTimeout(startAutoSaveTimer.current);
        if (unsavedFrom.current === null) return;
        const t = (new Date()).getTime();
        if (t - unsavedFrom.current > unsavedSeconds * 1000) {
            unsavedFrom.current = null;
            update();
        } else {
            startAutoSaveTimer.current = setTimeout(() => {
                testAutoSaveTimer();
          }, (unsavedSeconds + 1) * 1000);
        }
    }, [update]);
    useEffect(() => {
        if (newValue !== oldValue) {
            unsavedFrom.current = (new Date()).getTime();
            testAutoSaveTimer();
        }
    }, [newValue]); // eslint-disable-line
    // --------------------------------------------------------

    if (api === null) {
        return (
            <Card p="xs">
                <Text size="sm" color="dimmed">
                    {label}
                </Text>
                <Text>
                    {newValue.toLocaleString()}
                </Text>
            </Card>
        );
    } else {
        return (
            <>
            <NumberInput 
                label={label}
                value={newValue}
                min={min}
                max={max}
                step={step}
                onChange={(v: number) => { if (v > max) { setNewValue(max) } else { setNewValue(v) }}}
                classNames={classes} 
                disabled={isUpdating}
                withAsterisk={withAsterisk}
            />
            <Group position="center">
                {(!isUpdating && oldValue !== newValue) && 
                <ActionIcon color="blue" radius="xl" variant="outline" onClick={reset}>
                    <IconX />
                </ActionIcon>}
                {(!isUpdating && oldValue !== newValue) && 
                <ActionIcon color="blue" radius="xl" variant="filled" onClick={update}>
                    <IconCheck />
                </ActionIcon>}
                {isUpdating && <Loader />}
            </Group>
            </>
        );
    }

}

export { NumberInputContainer }
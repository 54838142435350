import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Button, Card, Center, Drawer, Group, LoadingOverlay, Text, Textarea, TextInput } from '@mantine/core';
import { IconMail, IconPencil, IconPhone, IconPlus } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TrashButton } from '../../shared/TrashButton';
import { MessageButton } from './message_button';
import { toFrenchDate } from '../../../services/functions';

interface ContactInterface {
    id: number,
    fullname: string,
    job: string,
    email: string,
    phone: string,
    extra: string,
    last_message: string | null,
}
const contactsReducer = (state:ContactInterface[], payload: any) => {
    switch(payload.type) {
        case 'add':
            return (state.concat([{...payload.data}])).sort((a,b) => { if (a.fullname < b.fullname) return -1; if (a.fullname === b.fullname) return 0; return 1;});
        case 'remove':
            return state.filter((c) => c.id !== payload.id);
        case 'update':
            return state.map((c) => { 
                if (c.id === payload.data.id) {
                    return {...payload.data};
                } else {
                    return {...c};
                }
            });
        case 'set':
            return payload.data;
        default:
            return [];
    }
}

const cleanPhone = (s: string) => {
    let i: number = s.length;
    let r: string = '';
    while (i !== 0) {
        i -= 1;
        if ('0123456789'.indexOf(s.substring(i,i+1)) !== -1) {
            r = s.substring(i,i+1) + r;
        }
    }
    if (s.substring(0,1) === '+') {
        r = '+' + r;
    } else if (r.substring(0,1) === '0') {
        r = '+33' + r.substring(1,r.length);
    }
    return r;
}

interface CompanyInterface {
    id: number,
    letter: string,
    label: string,
}

const ContactBook = (company: CompanyInterface) => {

    const myContext = useContext(AppContext);
    const [isLoaded,setIsLoaded] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [contacts, dispatchContacts] = useReducer(contactsReducer, []);
    const [drawerContact, setDrawerContact] = useState(false);
    const [newContact, setNewContact] = useState<ContactInterface | null>(null);

    useEffect(() => {
        dispatchContacts({type: 'reset'});
        setIsLoaded(false);
    }, [company.id]);

    useEffect(() => {
        if (isLoaded) return;
        if (company.id < 1) {
            dispatchContacts({type: 'reset'});
            setIsLoaded(true);
            return;
        }
        myContext.httpClient.get(`${myContext.apiAddress}/list_contact_book?id=${company.id}`).then((res: any) => {
            setIsLoaded(true);
            if (res.data.status === true) {
                dispatchContacts({type: 'set', data: res.data.data === null ? [] : res.data.data});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [isLoaded]); // eslint-disable-line

    const updateContact = () => {
        if (newContact === null) return;
        if (newContact.fullname.trim() === '') return; 
        const api = `${myContext.apiAddress}/update_contact_book`;
        const F = new FormData();
        F.append('action', newContact.id > 0 ? 'update' : 'add');
        F.append('company_id', company.id + '');
        F.append('id', newContact.id + '');
        F.append('fullname', newContact.fullname.trim());
        F.append('job', newContact.job.trim());
        F.append('email', newContact.email.trim());
        F.append('phone', newContact.phone.trim());
        F.append('extra', newContact.extra.trim());
        setIsUpdating(true);
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsUpdating(false);
            if (res.data.status === true) {
                dispatchContacts({type: (newContact.id < 1) ? 'add' : 'update', data: res.data.data});
                setNewContact(null);
                setDrawerContact(false);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <div>
            <LoadingOverlay visible={!isLoaded} />
            
            <Drawer
                opened={drawerContact}
                onClose={() => { setDrawerContact(false); setNewContact(null)}}
                position="right"
                size="lg"
                padding="md"
            >
                {newContact === null ?
                <Text>aucun contact</Text>
                :
                <>
                <Text>{newContact.id === -1 ? 'Ajouter' : 'Modifier'} un contact</Text>
                <TextInput 
                    label="Prénom NOM"
                    value={newContact.fullname} 
                    disabled={isUpdating}
                    onChange={(event) => setNewContact({...newContact, fullname: event.currentTarget.value})}
                    withAsterisk
                />
                <TextInput 
                    label="Poste occupé"
                    value={newContact.job} 
                    disabled={isUpdating}
                    onChange={(event) => setNewContact({...newContact, job: event.currentTarget.value})}
                />
                <TextInput 
                    label="Email"
                    value={newContact.email} 
                    disabled={isUpdating}
                    onChange={(event) => setNewContact({...newContact, email: event.currentTarget.value})}
                />
                <TextInput 
                    label="Téléphone"
                    value={newContact.phone} 
                    disabled={isUpdating}
                    onChange={(event) => setNewContact({...newContact, phone: event.currentTarget.value})}
                />
                <Textarea 
                    label="Autres informations"
                    value={newContact.extra} 
                    disabled={isUpdating}
                    onChange={(event) => setNewContact({...newContact, extra: event.currentTarget.value})}
                    minRows={3}
                    maxRows={6}
                    autosize
                />
                <Center pt="sm">
                    <Button color="blue" loading={isUpdating}
                        disabled={newContact.fullname.trim() === ''}
                        onClick={() => updateContact() }
                    >
                        {newContact.id > 0 ? 'mettre à jour' : 'sauvegarder'}
                    </Button>
                </Center>
                </>}
            </Drawer>

            {isLoaded && 
            <>
            <Group position="apart">
                <Text>{contacts.length} contact{contacts.length > 1 ? 's' : ''}</Text>
                <Group>
                    <MessageButton 
                        companyId={company.id}
                        companyName={company.label}
                        contactId={0}
                        contactName={''}
                    />
                    <ActionIcon color="blue" variant="outline" 
                        onClick={() => { setDrawerContact(true); setNewContact({ id: -1, fullname: '', job: '', email: '', phone: '', extra: '', last_message: null });}}
                    >
                        <IconPlus size={16} />
                    </ActionIcon>
                </Group>
            </Group>
            {contacts.map((c:ContactInterface,i:number) => 
            <Card key={`contact-${i}`} shadow="xs" m="xs" p="xs" radius="xs" withBorder>
                <Group position="apart">
                    <Text>{c.fullname}</Text>
                    <Group spacing="xs">
                        <TrashButton
                            label={`Supprimer ${c.fullname} ?`}
                            params={`action=remove&company_id=${company.id}&id=${c.id}`}
                            api='update_contact_book'
                            handle={() => dispatchContacts({type: 'remove', id: c.id})}
                        />
                        <ActionIcon color="blue" variant="outline"
                            onClick={() => { setNewContact({...c}); setDrawerContact(true)}}
                        >
                            <IconPencil size={16} />
                        </ActionIcon>
                        <MessageButton
                            companyId={company.id}
                            companyName={company.label}
                            contactId={c.id}
                            contactName={c.fullname}
                        />
                    </Group>
                </Group>
                <Text color="dimmed" size="sm">{c.job}</Text>
                <Group position="apart">
                    {c.phone !== '' &&
                    <Text color="blue" size="sm" component='a' href={`phoneto:${cleanPhone(c.phone)}`}>
                        <IconPhone size={14} /> {c.phone}
                    </Text>
                    }
                    {c.email.indexOf('@') !== -1 && 
                    <Text color="blue" size="sm" component="a" href={`mailto:${c.email}`}>
                        <IconMail size={14} /> {c.email}
                    </Text>
                    }
                </Group>
                {c.extra !== '' && <Text color="dimmed" size="sm" lineClamp={3}>{c.extra}</Text>}
                <Card m="xs" p="xs" radius="xs" withBorder>
                    {c.last_message === null ? 
                    <Text color='dimmed'>aucun message</Text>
                    :
                    <>
                    <Text align='right' color='dimmed'>{toFrenchDate(c.last_message.substring(0,10))}</Text>
                    <Text>{c.last_message.substring(10)}...</Text>
                    </>
                    }
                </Card>
            </Card>
            )}
            </>
            }
        </div>
    )
}

export { ContactBook }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Button, Card, Center, CopyButton, Divider, Grid, Group, LoadingOverlay, Text } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import { TextInputContainer } from '../../shared/TextInputContainer';
import { TextareaContainer } from '../../shared/TextareaContainer';
import { ToggleContainer } from '../../shared/ToggleContainer';
import { AlertNotification } from '../../shared/AlertNotification';
import AppContext from '../../shared/AppContext';

import { HuntInterface } from '../interfaces';

interface PropsInterface {
    huntId: number,
    handle: any,
}

const HeaderHunt = ({huntId, handle}:PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ hunt, setHunt ] = useState<null | HuntInterface>(null);
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        if (loaded) return;
        setHunt(null);
        const api = `${myContext.apiAddress}/get_hunt?id=${huntId}`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                setHunt(res.data.data);
                handle('project_label', res.data.data.label);
                handle('affected_at', res.data.data.affected_at);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoaded(true);
        });
    }, [loaded]); // eslint-disable-line

    return (
        <Center>
            {hunt === null ?
            <LoadingOverlay visible={!loaded} />
            :
            <div style={{minWidth: '500px'}}>
                <Group position='right'>
                    <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                        <IconRefresh size={16} />
                    </ActionIcon>
                </Group>
                <Divider label='Informations générales' labelPosition='left'/>
                <Grid>
                    <Grid.Col xs={12} sm={6}>
                        <TextInputContainer 
                            code='id'
                            label='id'
                            defaultValue={hunt.id+''}
                            isEdition={false}
                            api={''}
                            handle={null}
                            withAsterisk={false}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <Text>{hunt.token}</Text>
                        <CopyButton value={hunt.token}>
                            {({ copied, copy }) => (
                                <Button compact color={copied ? 'teal' : 'blue'} onClick={copy}>
                                {copied ? 'Token copied' : 'Copy token'}
                                </Button>
                            )}
                        </CopyButton>
                    </Grid.Col>
                </Grid>
                <TextInputContainer
                    code='rename_label'
                    label='Libellé'
                    defaultValue={hunt.label}
                    isEdition={!hunt.is_archived}
                    api={`${myContext.apiAddress}/update_hunt?id=${hunt.id}`}
                    handle={(s: string) => { handle(s) }}
                    withAsterisk={true}
                />
                <TextareaContainer
                    code='rename_description'
                    label='Description'
                    defaultValue={hunt.description || ''}
                    isEdition={!hunt.is_archived}
                    api={`${myContext.apiAddress}/update_hunt?id=${hunt.id}`}
                    handle={null}
                />
                <Divider mt='lg' label='Message' labelPosition='left'/>
                <TextInputContainer
                    code='msg_subject'
                    label='Objet du message'
                    defaultValue={hunt.msg_subject || ''}
                    isEdition={!hunt.is_archived}
                    api={`${myContext.apiAddress}/update_hunt?id=${hunt.id}`}
                    handle={null}
                    withAsterisk={false}
                    maxLength={100}
                />
                <TextareaContainer
                    code='msg_body'
                    label='Corps du message'
                    defaultValue={hunt.msg_body || ''}
                    isEdition={!hunt.is_archived}
                    api={`${myContext.apiAddress}/update_hunt?id=${hunt.id}`}
                    handle={null}
                    maxLength={500}
                />
                <Divider mt='lg' label='Autre' labelPosition='left'/>
                <Grid>
                    <Grid.Col xs={12} sm={6}>
                        <Card withBorder>
                            <ToggleContainer
                                action='toggle_open_to'
                                label='Ouvert à la collecte ?'
                                defaultValue={hunt.is_open}
                                isEdition={!hunt.is_archived}
                                api={`${myContext.apiAddress}/update_hunt?id=${hunt.id}`}
                                handle={null}
                            />
                        </Card>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6}>
                        <Card withBorder>
                            <ToggleContainer
                                action='archive'
                                label='Archivé'
                                defaultValue={hunt.is_archived}
                                isEdition={!hunt.is_archived}
                                api={`${myContext.apiAddress}/update_hunt?id=${hunt.id}`}
                                handle={null}
                            />
                        </Card>
                    </Grid.Col>
                </Grid>
            </div>
            }
        </Center>
    )

}

export { HeaderHunt };
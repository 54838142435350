import { useContext, useState } from 'react';
import { ActionIcon, Box, Button, Center, LoadingOverlay, Modal, Text, Textarea } from '@mantine/core';
import { IconEdit } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface PropsInterface {
    needId: number,
    cvId: number,
    name: string,
    nature: 'bm' | 'client',
    value: string | null,
    handle: any,
}

const Comment = ({needId, cvId, name, nature, value, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ val, setVal ] = useState<string>(value || '');
    const [ newVal, setNewVal ] = useState<string>(value || '');
    const [ display, setDisplay ] = useState(false);
    const [ saving, setSaving ] = useState(false);

    const save = () => {
        const s = newVal.trim();
        if (s === val) return;
        setSaving(true);
        const F = new FormData();
        F.append('value', s);
        F.append('nature', nature);
        const api = `${myContext.apiAddress}/update_process_need?need_id=${needId}&cv_id=${cvId}&what=comment&nature=${nature}`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setSaving(false);
            setDisplay(false);
            if (res.data.status === true) {
                setVal(s);
                setNewVal(s);
                handle(s);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <>
        <Modal
            opened={display}
            onClose={() => setDisplay(false)}
            title={name}
        >
            <LoadingOverlay visible={saving} />
            {nature === 'bm' && <Text>Avis Business Manager</Text>}
            {nature === 'client' && <Text>Avis client</Text>}
            <Textarea
                value={newVal}
                onChange={(event) => setNewVal(event.currentTarget.value)}
                autosize
                minRows={4}
                maxRows={8}
            />
            <Center mt="xs" mb="xs">
                <Button color="blue" variant="outline"
                    disabled={newVal.trim() === val}
                    onClick={() => save()}
                >
                    sauvegarder
                </Button>
            </Center>
        </Modal>
        {val === '' ?
        <ActionIcon color="blue" variant="outline" onClick={() => setDisplay(true)}>
            <IconEdit size={16} />
        </ActionIcon>
        :
        <Box>
            {val.split('\n').map((s: string, i: number) => 
            <Text key={`${nature}-row-${i}`}>{s}</Text>
            )}
            <Box>
                <ActionIcon size={14} color="blue" variant="outline" onClick={() => setDisplay(true)}>
                    <IconEdit size={12} />
                </ActionIcon>
            </Box>
        </Box>
        }
        </>
    )

}

export { Comment }
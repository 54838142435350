import { useContext, useEffect, useState } from 'react';
import { Alert, Box, Card, ColorSwatch, Divider, Grid, Group, LoadingOverlay, Text } from '@mantine/core';
import { TextInputContainer } from '../../shared/TextInputContainer';
import { IconAlertCircle } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { LockButton } from './terminate_button';
import { TrashButton } from '../../shared/TrashButton';
import { ToggleContainer } from '../../shared/ToggleContainer';

interface MetadataInterface {
    "client_legal_id": string,
    "client_name": string,
    "client_address_row_1": string,
    "client_address_row_2": string,
    "client_address_row_3": string,
    "client_address_zipcode": string,
    "client_address_town": string,
    "client_address_country": string,
    "client_contact_report_name": string,
    "client_contact_report_job": string,
    "client_contact_report_email": string,
    "client_contact_report_phone": string,
    "client_contact_invoice_name": string,
    "client_contact_invoice_job": string,
    "client_contact_invoice_email": string,
    "client_contact_invoice_phone": string,
    "client_reference": string,
    "supplier_legal_id": string,
    "supplier_name": string,
    "supplier_address_row_1": string,
    "supplier_address_row_2": string,
    "supplier_address_row_3": string,
    "supplier_address_zipcode": string,
    "supplier_address_town": string,
    "supplier_address_country": string,
    "supplier_contact_report_name": string,
    "supplier_contact_report_job": string,
    "supplier_contact_report_email": string,
    "supplier_contact_report_phone": string,
    "supplier_contact_bill_name": string,
    "supplier_contact_bill_job": string,
    "supplier_contact_bill_email": string,
    "supplier_contact_bill_phone": string,
    "supplier_reference": string,
    "supplier_bank_iban": string,
    "supplier_bank_bic": string,
    "finance_client_daily_rate": string,
    "finance_supplier_daily_rate": string,
    "finance_start_date": string,
    "finance_end_date": string,
    "finance_days": string,
    "other_notebook": string,
    "other_report_blocked": boolean,
}

interface PropsInterface {
    missionId: number,
    handleHeader: any,
    handleClose: any,
    handleRemove: any,
    missionOpened: boolean,
}

const MetadataContainer = ({missionId, handleHeader, handleClose, handleRemove, missionOpened}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isInProgress, setIsInProgress ] = useState(missionOpened);
    const [ metadata, setMetadata ] = useState<MetadataInterface | null>(null);
    const [ error, setError ] = useState('');
    const [ api, setApi ] = useState(`${myContext.apiAddress}/update_metadata_mission?id=${missionId}`);

    useEffect(() => {
        if (isLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_metadata_mission?id=${missionId}`).then((res:any) => {
            setIsLoaded(true);
            if (res.data.status === true) {
                setMetadata(res.data.data.metadata);
                handleHeader(res.data.data.header);
                setIsInProgress((res.data.data.header.is_finished || res.data.data.header.is_removed) ? false : true);
            } else {
                setError(res.data.message || 'internal error');
            }
        });
    }, [isLoaded]); // eslint-disable-line

    useEffect(() => {
        setApi(`${myContext.apiAddress}/update_metadata_mission?id=${missionId}`);
        setIsLoaded(false);
    }, [missionId]); // eslint-disable-line

    useEffect(() => {
        if (missionOpened !== isInProgress) {
            setIsInProgress(missionOpened);
        }
    }, [missionOpened]); // eslint-disable-line

    return (
        <>
        <LoadingOverlay
            visible={!isLoaded}
        />
        <Text>Données générales sur la mission</Text>
        {error !== '' && 
        <Alert icon={<IconAlertCircle size={16} />} title="Oups" color="red">
            {error}
        </Alert>
        }
        {metadata === null && <></>}
        {(error === '' && metadata !== null) && 
        <Grid>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Divider label={<><ColorSwatch color="orange" size={12}/><Box style={{paddingLeft: '0.5em'}}>Client</Box></>} labelPosition='center'/>
                <TextInputContainer
                    withAsterisk={false}
                    code='client_legal_id'
                    label='Identifiant légal du client (SIRET)'
                    defaultValue={metadata.client_legal_id}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={true}
                    code='client_name'
                    label='Nom du client'
                    defaultValue={metadata.client_name}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={true}
                    code='client_address_row_1'
                    label='Adresse (rue, ligne 1)'
                    defaultValue={metadata.client_address_row_1}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='client_address_row_2'
                    label='Adresse (complément, ligne 2)'
                    defaultValue={metadata.client_address_row_2}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='client_address_row_3'
                    label='Adresse (complément, ligne 3)'
                    defaultValue={metadata.client_address_row_3}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <Grid>
                    <Grid.Col xs={4}>
                        <TextInputContainer
                            withAsterisk={true}
                            code='client_address_zipcode'
                            label='Code postal'
                            defaultValue={metadata.client_address_zipcode}
                            api={api}
                            isEdition={isInProgress}
                            handle={null}
                        />
                    </Grid.Col>
                    <Grid.Col xs={8}>
                        <TextInputContainer
                            withAsterisk={true}
                            code='client_address_town'
                            label='Ville'
                            defaultValue={metadata.client_address_town}
                            api={api}
                            isEdition={isInProgress}
                            handle={null}
                        />
                    </Grid.Col>
                </Grid>
                <TextInputContainer
                    withAsterisk={false}
                    code='client_address_country'
                    label='Pays'
                    defaultValue={metadata.client_address_country}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Divider label={<><ColorSwatch color="yellow" size={12}/><Box style={{paddingLeft: '0.5em'}}>Fournisseur</Box></>} labelPosition='center'/>
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_legal_id'
                    label='Identifiant légal du supplier (SIRET)'
                    defaultValue={metadata.supplier_legal_id}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_name'
                    label='Nom du fournisseur'
                    defaultValue={metadata.supplier_name}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_address_row_1'
                    label='Adresse (rue, ligne 1)'
                    defaultValue={metadata.supplier_address_row_1}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_address_row_2'
                    label='Adresse (complément, ligne 2)'
                    defaultValue={metadata.supplier_address_row_2}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_address_row_3'
                    label='Adresse (complément, ligne 3)'
                    defaultValue={metadata.supplier_address_row_3}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <Grid>
                    <Grid.Col xs={4}>
                        <TextInputContainer
                            withAsterisk={false}
                            code='supplier_address_zipcode'
                            label='Code postal'
                            defaultValue={metadata.supplier_address_zipcode}
                            api={api}
                            isEdition={isInProgress}
                            handle={null}
                        />
                    </Grid.Col>
                    <Grid.Col xs={8}>
                        <TextInputContainer
                            withAsterisk={false}
                            code='supplier_address_town'
                            label='Ville'
                            defaultValue={metadata.supplier_address_town}
                            api={api}
                            isEdition={isInProgress}
                            handle={null}
                        />
                    </Grid.Col>
                </Grid>
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_address_country'
                    label='Pays'
                    defaultValue={metadata.supplier_address_country}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Divider label={<><ColorSwatch color="green" size={12}/><Box style={{paddingLeft: '0.5em'}}>Caractéristiques du contrat</Box></>} labelPosition='center'/>
                <TextInputContainer
                    withAsterisk={false}
                    code='finance_start_date'
                    label='Date de début au format AAAA-MM-JJ'
                    defaultValue={metadata.finance_start_date}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='finance_end_date'
                    label='Date de fin au format AAAA-MM-JJ'
                    defaultValue={metadata.finance_end_date}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='finance_days'
                    label='Nombre de jours'
                    defaultValue={metadata.finance_days}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='finance_supplier_daily_rate'
                    label='Taux journalier facturé par le manager (EUR/jour), hors frais'
                    defaultValue={metadata.finance_supplier_daily_rate}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={true}
                    code='finance_client_daily_rate'
                    label='Taux journalier facturé au client (EUR/jour), hors frais'
                    defaultValue={metadata.finance_client_daily_rate}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={3}>
                <Divider label={<><ColorSwatch color="orange" size={12}/><Box style={{paddingLeft: '0.5em'}}>Client / Contact CRA</Box></>} labelPosition='center'/>
                <TextInputContainer
                    withAsterisk={false}
                    code='client_contact_report_name'
                    label='Nom'
                    defaultValue={metadata.client_contact_report_name}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='client_contact_report_job'
                    label='Poste'
                    defaultValue={metadata.client_contact_report_job}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='client_contact_report_email'
                    label='Email'
                    defaultValue={metadata.client_contact_report_email}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='client_contact_report_phone'
                    label='Téléphone'
                    defaultValue={metadata.client_contact_report_phone}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <Card withBorder mt={3}>
                    <ToggleContainer
                        action='other_report_blocked'
                        defaultValue={metadata.other_report_blocked}
                        label='Ne pas envoyer de CRA ?'
                        isEdition={isInProgress}
                        api={api}
                        handle={null}
                    />
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={3}>
                <Divider label={<><ColorSwatch color="orange" size={12}/><Box style={{paddingLeft: '0.5em'}}>Client / Contact facturation</Box></>} labelPosition='center'/>
                <TextInputContainer
                    withAsterisk={false}
                    code='client_contact_invoice_name'
                    label='Nom'
                    defaultValue={metadata.client_contact_invoice_name}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='client_contact_invoice_job'
                    label='Poste'
                    defaultValue={metadata.client_contact_invoice_job}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='client_contact_invoice_email'
                    label='Email'
                    defaultValue={metadata.client_contact_invoice_email}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='client_contact_invoice_phone'
                    label='Téléphone'
                    defaultValue={metadata.client_contact_invoice_phone}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={3}>
                <Divider label={<><ColorSwatch color="yellow" size={12}/><Box style={{paddingLeft: '0.5em'}}>Fournisseur / Contact CRA</Box></>} labelPosition='center'/>
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_contact_report_name'
                    label='Nom'
                    defaultValue={metadata.supplier_contact_report_name}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_contact_report_job'
                    label='Poste'
                    defaultValue={metadata.supplier_contact_report_job}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_contact_report_email'
                    label='Email'
                    defaultValue={metadata.supplier_contact_report_email}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_contact_report_phone'
                    label='Téléphone'
                    defaultValue={metadata.supplier_contact_report_phone}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={3}>
                <Divider label={<><ColorSwatch color="yellow" size={12}/><Box style={{paddingLeft: '0.5em'}}>Fournisseur / Contact facturation</Box></>} labelPosition='center'/>
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_contact_bill_name'
                    label='Nom'
                    defaultValue={metadata.supplier_contact_bill_name}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_contact_bill_job'
                    label='Poste'
                    defaultValue={metadata.supplier_contact_bill_job}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_contact_bill_email'
                    label='Email'
                    defaultValue={metadata.supplier_contact_bill_email}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
                <TextInputContainer
                    withAsterisk={false}
                    code='supplier_contact_bill_phone'
                    label='Téléphone'
                    defaultValue={metadata.supplier_contact_bill_phone}
                    api={api}
                    isEdition={isInProgress}
                    handle={null}
                />
            </Grid.Col>
        </Grid>
        }
        {isInProgress &&
        <>
        <Divider label="Verrouiller la mission - Supprimer la mission" labelPosition='center' />
        <Group position='center'>
            <LockButton 
                id={missionId} 
                label={`${metadata?.client_name || 'undefined'}`} 
                handle={() => {
                    setIsInProgress(false);
                    handleClose();
                }}
            />
            <Divider orientation='vertical' />
            <TrashButton
                label={`Supprimer la mission "${missionId}"`}
                api={`remove_mission?id=${missionId}`}
                params={''}
                handle={() => handleRemove()}
            />
        </Group>
        </>
        }
        </>
    )
}

export { MetadataContainer }
import { useState, useContext, useEffect } from 'react';
import { ActionIcon, Alert, Button, Group, LoadingOverlay, Modal, ScrollArea } from '@mantine/core';
import { IconAlertCircle, IconFile } from '@tabler/icons';

import AppContext from '../../shared/AppContext';
import { CVInterface } from '../interfaces';
import { constructCvFromJson } from '../functions';
import { ProfileBox } from './profile_box';

interface PropsInterface {
    hunt_id: number,
    cv_id: number,
}

const ProfileButton = ({hunt_id, cv_id}: PropsInterface) => {

    const myContext = useContext(AppContext);

    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [cv, setCV] = useState<null | CVInterface>(null);
    const [urlPublic, setUrlPublic] = useState('');
    const [urlPrivate, setUrlPrivate] = useState('');

    useEffect(() => {
        if (!open) return;
        if (loaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_process_hunt?hunt_id=${hunt_id}&what=cv&cv_id=${cv_id}`).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                setCV(constructCvFromJson(res.data.data.history));
                setUrlPublic((res.data.data.url !== null) ? res.data.data.url : '');
                setUrlPrivate((res.data.data.token !== null) ? 'https://www.linkedin.com/talent/profile/' + res.data.data.token : '');
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
    }, [open]); // eslint-disable-line

    return (
        <>
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            size={'large'}
        >
            <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                <LoadingOverlay visible={!loaded} />
                {error !== '' &&
                <Alert icon={<IconAlertCircle size="1rem" />} title="Oops!" color="red">
                    {error}
                </Alert>
                }
                { cv !== null &&
                <>
                <Group position="apart" pl='sm' pr='sm'>
                    <Button size='xs' component='a' href={urlPublic} target='_blank' color='blue' variant='outline' disabled={urlPublic === ''}>
                        Open public profile
                    </Button>
                    <Button size='xs' component='a' href={urlPrivate} target='_blank' color='blue' variant='outline' disabled={urlPrivate === ''}>
                        Open talent profile
                    </Button>
                </Group>
                <ProfileBox search={null} profile={cv} />
                </>
                }
            </ScrollArea>
        </Modal>
        <ActionIcon color='blue' variant='outline' onClick={() => setOpen(true)}>
            <IconFile size={16} />
        </ActionIcon>
        </>
    )
}

export { ProfileButton }
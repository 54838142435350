import { useContext, useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Divider, Flex, Group, HoverCard, Modal, Switch, Text, TextInput, ActionIcon, useMantineTheme, ScrollArea } from '@mantine/core';
import { IconDots, IconEraser, IconHelp, IconListSearch, IconSearch, IconArrowRight } from '@tabler/icons';
import AppContext from '../../shared/AppContext';

interface ItemInterface {
    id: number,
    label: string,
    extra: string | null,
}

interface PropsInterface {
    search: {
        searchString: string,
        isValidated: boolean,
    },
    handleFunction: any,
}

const SearchQueryCV = ({search, handleFunction}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const theme = useMantineTheme();
    const [isSectorsLoaded, setIsSectorsLoaded] = useState(false);
    const [isJobsLoaded, setIsJobsLoaded] = useState(false);
    const [isHelper, setIsHelper] = useState(true);
    const [isValidated, setIsValidated] = useState(search.isValidated);
    const [displayList, setDisplayList] = useState<null | 'jobs' | 'sectors'>(null);
    
    const inputRef = useRef<HTMLInputElement>(null);

    const [searchString, setSearchString] = useState(search.searchString);
    const oldSearchString = useRef('');
    const [searchName,setSearchName] = useState('');
    const [searchEmail,setSearchEmail] = useState('');
    const [searchPhone,setSearchPhone] = useState('');
    const [searchJobs,setSearchJobs] = useState('');
    const [searchSectors,setSearchSectors] = useState('');
    const [searchTags,setSearchTags] = useState('');
    const [searchContent,setSearchContent] = useState('');
    const [searchOrders, setSearchOrders] = useState<string[]>([]);
    const searchKeywords: string[] = ['name','email','phone','jobs','sectors','tags','content'];
    
    const [currentPosition, setCurrentPosition] = useState(-1);
    const [currentExamples, setCurrentExamples] = useState<string[]>([]);
    const currentSearchingWords = useRef(false);
    const maxExamples = 30;

    useEffect(() => {
        if (isSectorsLoaded) return;
        if (myContext.sectors !== null) {
            setIsSectorsLoaded(true);
            return;
        }
        myContext.httpClient.get(`${myContext.apiAddress}/get_repository_sector`).then((res: any) => {
            if (res.data.status === true) {
                myContext.setSectors({
                    label: 'Secteurs professionnels',
                    date: (new Date()).getTime(),
                    items: res.data.data,
                });
            }
            setIsSectorsLoaded(true);
        });
    }, [isSectorsLoaded]); // eslint-disable-line

    useEffect(() => {
        if (isJobsLoaded) return;
        if (myContext.jobs !== null) {
            setIsJobsLoaded(true);
            return;
        }
        myContext.httpClient.get(`${myContext.apiAddress}/get_repository_job`).then((res: any) => {
            if (res.data.status === true) {
                myContext.setJobs({
                    label: 'Métiers',
                    date: (new Date()).getTime(),
                    items: res.data.data,
                });
            }
            setIsJobsLoaded(true);
        });
    }, [isJobsLoaded]); // eslint-disable-line
    
    useEffect(() => {
        // lecture des champs
        const s = searchString.trim();
        for (const f of searchKeywords) {
            const c = ':' + f;
            const p0 = s.indexOf(c);
            let v = '';
            if (p0 !== -1) {
                const p1 = s.indexOf(':', p0+1);
                if (p1 === -1) {
                    v = s.substring(p0 + 1 + f.length).trim();
                } else {
                    v = s.substring(p0 + 1 + f.length, p1).trim();
                }
            }
            switch (f) {
                case 'name':
                    if (searchName !== v) setSearchName(v);
                    break;
                case 'email':
                    if (searchEmail !== v) setSearchEmail(v);
                    break;
                case 'phone':
                    if (searchPhone !== v) setSearchPhone(v);
                    break;
                case 'jobs':
                    if (searchJobs !== v) setSearchJobs(v);
                    break;
                case 'sectors':
                    if (searchSectors !== v) setSearchSectors(v);
                    break;
                case 'tags':
                    if (searchTags !== v) setSearchTags(v);
                    break;
                case 'content':
                    if (searchContent !== v) setSearchContent(v);
                    break;
            }
        }
    }, [searchString]); // eslint-disable-line

    const updateSearchString = (s: string) => {
        const L = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
                + 'abcdefghijklmnopqrstuvwxyz'
                + ' +-:*@#"._^¨()\','
                + '0123456789$€£&'
                + 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ'
            ;
        let r: string = '';
        for (const l of s.split('')) {
            if (L.indexOf(l) !== -1) {
                r += l;
            }
        }
        r = r.replace(/[ ]+/, ' ');
        if (r.indexOf(oldSearchString.current) === 0 && r.length === oldSearchString.current.length + 1) {
            // on vient d'ajouter un caractère
            const c = r.substring(r.length - 1);
            if ( c === '(' || c === ')' ) {
                r = r.substring(0, r.length - 1);
                if (r.length - 1 > 0) {
                    if (r.substring(r.length - 1) !== ' ') {
                        r += ' ';
                    }
                }
                r += c + ' ';
            }
        }
        r = r.replace(/[ ]+/, ' ');
        oldSearchString.current = r;
        setSearchString(r);
    }

    const explodeString = (s: string) => {
        if (s.length === 0) return [];
        const res: string[] = [];
        const ww = s.split(' ');
        const n = ww.length;
        let i = 0;
        while (i < n) {
            if (ww[i].substring(0,1) === '"') {
                let j = i;
                while (j < n) {
                    if (ww[j].substring(ww[j].length-1) === '"') {
                        break;
                    }
                    j++;
                }
                if (j < n) {
                    const t = ww.slice(i,j+1).join(' ');
                    res.push(t.substring(1, t.length - 1));
                }
                i = j;
            } else if (ww[i].substring(0,1) === '(') {
                res.push('(');
                if (ww[i].length > 1) {
                    res.push(ww[i].substring(1));
                }
            } else if (ww[i].substring(ww[i].length - 1) === ')') {
                if (ww[i].length > 1) {
                    res.push(ww[i].substring(0, ww[i].length - 1));
                }
                res.push(')');
            } else {
                res.push(ww[i]);
            }
            i++;
        }
        return res;
    }

    // On met à jour la liste des exemples.
    useEffect(() => {
        // on liste les mots clés présents
        const kw: string[] = [];
        let p: number = searchString.indexOf(':');
        while (p !== -1){
            if (p !== 0 && searchString[p-1] !== ' ') {
                break;
            }
            let q = p+1;
            while (q < searchString.length && searchString[q] !== ' ') {
                q += 1;
            }
            if (q > p+1) {
                p += 1;
                const w = searchString.substring(p,q);
                if (searchKeywords.indexOf(w) !== -1) {
                    kw.push(w);
                }
                p = searchString.indexOf(':', q);
            } else {
                break;
            }
        }
        setSearchOrders([...kw]);
        // caractères composant un mot
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
                        + 'abcdefghijklmnopqrstuvwxyz'
                        + 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ';
        // on cherche le mot sur lequel l'utilisateur travaille
        p = inputRef.current === null ? searchString.length : inputRef.current.selectionStart || searchString.length;
        setCurrentPosition(p);
        let reset: boolean = false;
        if (searchString.length === 0) {
            reset = true;
        } else {
            if (
                (p === 0 && searchString[0] === ' ') || 
                (p === searchString.length && searchString[p-1] === ' ')
            ) {
                reset = true;
            }
        }
        if (reset) {
            setCurrentExamples([]);
            return;
        }
        let p2 = p;
        if (p2 < searchString.length) {
            while (p2 < searchString.length && letters.indexOf(searchString[p2]) !== -1) {
                p2 += 1
            }
        }
        let p1 = p2;
        while (p1-1 >=0 && letters.indexOf(searchString[p1-1]) !== -1) {
            p1 -= 1;
        }
        if (p1-1 >= 0 && searchString[p1-1] === ':') {
            p1 -= 1;
        }
        if (p1 > 0 && ' ()"'.indexOf(searchString[p1-1]) === -1) {
            return;
        }
        const w = searchString.substring(p1, p2);
        let currentWord = '';
        let currentTag = '';
        // si ce mot est un mot clé
        if (w[0] === ':') {
            if ((p1 > 0 && searchString[p1-1] === ' ') || (p1 === 0)) {
                currentTag = w;
                currentWord = '';
            }
        } else {
            currentWord = w;
            // on cherche le mot clé le précédent
            while (p1 > 0 && searchString[p1] !== ':') {
                p1 -= 1;
            }
            if (searchString[p1] === ':' && (p1 === 0 || searchString[p1-1] === ' ')) {
                p2 = p1;
                while (p2+1 < searchString.length && letters.indexOf(searchString[p2+1]) !== -1) {
                    p2 += 1;
                }
                p2 += 1;
            }
            currentTag = searchString.substring(p1, p2).toLocaleLowerCase();
        }
        // on définit des exemples
        if (currentWord !== '') {
            let s = currentWord.toLocaleLowerCase();
            for(const c of ['é','è','ê','ë']){
                s = s.replaceAll(c,'('+c+'|e)');
            }
            const re = RegExp(s, 'i');
            if (currentTag === ':jobs') {
                setCurrentExamples(myContext.jobs.items.filter((item: ItemInterface) => re.test(item.label)).map((item: ItemInterface) => { return item.label}).slice(0,maxExamples));
            } else if (currentTag === ':sectors') {
                setCurrentExamples(myContext.sectors.items.filter((item: ItemInterface) => re.test(item.label)).map((item: ItemInterface) => { return item.label}).slice(0,maxExamples));
            } else if (currentTag === ':content' && currentWord.length > 2 && currentSearchingWords.current === false) {
                currentSearchingWords.current = true;
                const F = new FormData();
                F.append('value', currentWord);
                myContext.httpClient.post(`${myContext.apiAddress}/get_repository_word`, F).then((res:any) => {
                    if (res.data.status === true) {
                        setCurrentExamples((res.data.data || []).map((w:string) => { return w;}));
                    }
                    currentSearchingWords.current = false;
                });
            }
        } else if (currentTag !== '') {
            const re = RegExp(currentTag, 'i');
            setCurrentExamples([':jobs', ':sectors', ':tags', ':content', ':name', ':email', ':phone'].filter((s:string) => re.test(s)));
        } else {
            setCurrentExamples([]);
        }
    }, [searchString]); // eslint-disable-line
    
    const runSearch = () => {
        handleFunction({'searchString': searchString, 'isValidated': isValidated});
    }

    const addWordInSearchString = (s:string) => {
        //console.log(s);
        //console.log(currentPosition);
        let p2 = currentPosition;
        let p1 = currentPosition;
        while (p2 < searchString.length && searchString[p2] !== ' ') {
            p2 += 1;
        }
        while (p1-1 >= 0 && searchString[p1-1] !== ' ') {
            p1 -= 1;
        }
        if (s.indexOf(' ') > 0) {
            s = '"' + s + '"';
        }
        if (p1 > 0 && searchString[p1] !== ' ') { s = ' ' + s }
        s = searchString.substring(0,p1) + s;
        if (p2 < searchString.length) {
            if (searchString[p2] !== ' ') { s = s + ' ' }
            s = s + searchString.substring(p2);
        }
        setSearchString(s);
        inputRef.current?.focus();
    }

    return (
        <>
        <Modal
            opened={displayList === null ? false : true}
            onClose={() => { setDisplayList(null);}}
            title={displayList === 'jobs' ? 'Métiers' : (displayList === 'sectors' ? 'Secteurs professionnels' : '')}
        >
            <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                {displayList === 'jobs' && <>
                    {myContext.jobs.items.map((item: ItemInterface, iIdx: number) => (
                        <Text key={`job-${iIdx}`}>{item.label}</Text>
                    ))}
                </>
                }
                {displayList === 'sectors' && <>
                    {myContext.sectors.items.map((item: ItemInterface, iIdx: number) => (
                        <Text key={`sector-${iIdx}`}>{item.label}</Text>
                    ))}
                </>
                }
            </ScrollArea>
        </Modal>
        <Card style={{minHeight: '160px'}}>
            <Group position="right">
                <Group>
                    <Text color="dimmed" size="xs">Métiers :</Text>
                    <ActionIcon variant='subtle' onClick={() => setDisplayList('jobs')}>
                        <IconListSearch/>
                    </ActionIcon>
                    <Text color="dimmed" size="xs">Secteurs :</Text>
                    <ActionIcon variant='subtle' onClick={() => setDisplayList('sectors')}>
                        <IconListSearch/>
                    </ActionIcon>
                    <Text color="dimmed" size="xs">|</Text>
                    <Text color="dimmed" size="xs">Aide :</Text>
                    <Switch 
                        checked={isHelper} 
                        onChange={(event) => setIsHelper(event.currentTarget.checked)}
                        onLabel="oui" offLabel="non"
                    />
                    <Text color="dimmed" size="xs">|</Text>
                    <Text color="dimmed" size="xs">CV validés :</Text>
                    <Switch 
                        checked={isValidated} 
                        onChange={(event) => setIsValidated(event.currentTarget.checked)}
                        onLabel="OK" offLabel="draft"
                    />
                    <Text color="dimmed" size="xs">|</Text>
                    <HoverCard width={500} shadow="md" position="left">
                        <HoverCard.Target>
                            <ActionIcon variant="subtle">
                                <IconHelp/>
                            </ActionIcon>
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                            <Group>
                                <Text color="dimmed" size="sm" weight={700} style={{width: 50, paddingLeft: '10px'}}>:</Text>
                                <Text color="dimmed" size="sm" style={{width: 400}}>Champs cherchables<br/>jobs, sectors, tags, content, name, email, phone</Text>
                            </Group>
                            <Divider/>
                            <Group>
                                <Text color="dimmed" size="sm" weight={700} style={{width: 50, paddingLeft: '10px'}}>et</Text>
                                <Text color="dimmed" size="sm">les deux</Text>
                            </Group>
                            <Group>
                                <Text color="dimmed" size="sm" weight={700} style={{width: 50, paddingLeft: '10px'}}>ou</Text>
                                <Text color="dimmed" size="sm">l'un ou l'autre</Text>
                            </Group>
                            <Divider/>
                            <Group>
                                <Text color="dimmed" size="sm" weight={700} style={{width: 50, paddingLeft: '10px'}}>"&hellip;"</Text>
                                <Text color="dimmed" size="sm">expression</Text>
                            </Group>
                            <Divider/>
                            <Group>
                                <Text color="dimmed" size="sm" weight={700} style={{width: 50, paddingLeft: '10px'}}>*</Text>
                                <Text color="dimmed" size="sm">caractère magique</Text>
                            </Group>
                        </HoverCard.Dropdown>
                    </HoverCard>
                </Group>
            </Group>
            <Group>
                <TextInput
                    ref={inputRef}
                    icon={<IconSearch size={18} stroke={1.5} />}
                    style={{width: 'calc(100% - 50px)'}}
                    radius="xl"
                    size="md"
                    rightSection={
                        <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled"
                            onClick={() => runSearch()}
                        >
                            <IconArrowRight size={18} stroke={1.5} />
                        </ActionIcon>
                    }
                    placeholder="question"
                    rightSectionWidth={42}
                    value={searchString}
                    onChange={(event) => updateSearchString(event.currentTarget.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') runSearch(); }}
                />
                <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled"
                    onClick={() => { setSearchString(''); currentSearchingWords.current = false; }}
                >
                    <IconEraser size={18} stroke={1.5} />
                </ActionIcon>
            </Group>
            {(isHelper) && 
            <Card radius="lg" mt="2px">
                {searchOrders.map((s:string) => (
                <Group key={'search-in-'+s} pt='xs'>
                    {s === 'jobs' && searchJobs !== '' && <>
                    <Badge color="green">:jobs</Badge>{explodeString(searchJobs).map((s,idx) => <Badge color={(s === 'ou' || s === 'et' || s === 'pas') ? 'orange' : ((s === '(' || s === ')') ? 'pink' : 'blue')} key={`jobs-word-${idx}`}>{s}</Badge>)}
                    </>}
                    {s === 'sectors' && searchSectors !== '' && <>
                    <Badge color="green">:sectors</Badge>{explodeString(searchSectors).map((s,idx) => <Badge color={(s === 'ou' || s === 'et' || s === 'pas') ? 'orange' : ((s === '(' || s === ')') ? 'pink' : 'blue')} key={`sectors-word-${idx}`}>{s}</Badge>)}
                    </>}
                    {s === 'content' && searchContent !== '' && <>
                    <Badge color="green">:content</Badge>{explodeString(searchContent).map((s,idx) => <Badge color={(s === 'ou' || s === 'et' || s === 'pas') ? 'orange' : ((s === '(' || s === ')') ? 'pink' : 'blue')} key={`content-word-${idx}`}>{s}</Badge>)}
                    </>}
                    {s === 'name' && searchName !== '' && <>
                    <Badge color="green">:name</Badge>{explodeString(searchName).map((s,idx) => <Badge color={(s === 'ou' || s === 'et' || s === 'pas') ? 'orange' : ((s === '(' || s === ')') ? 'pink' : 'blue')} key={`name-word-${idx}`}>{s}</Badge>)}
                    </>}
                    {s === 'email' && searchEmail !== '' && <>
                    <Badge color="green">:email</Badge>{explodeString(searchEmail).map((s,idx) => <Badge color={(s === 'ou' || s === 'et' || s === 'pas') ? 'orange' : ((s === '(' || s === ')') ? 'pink' : 'blue')} key={`email-word-${idx}`}>{s}</Badge>)}
                    </>}
                    {s === 'phone' && searchPhone !== '' && <>
                    <Badge color="green">:phone</Badge>{explodeString(searchPhone).map((s,idx) => <Badge color={(s === 'ou' || s === 'et' || s === 'pas') ? 'orange' : ((s === '(' || s === ')') ? 'pink' : 'blue')} key={`phone-word-${idx}`}>{s}</Badge>)}
                    </>}
                    {s === 'tags' && searchTags !== '' && <>
                    <Badge color="green">:tags</Badge>{explodeString(searchTags).map((s,idx) => <Badge color={(s === 'ou' || s === 'et' || s === 'pas') ? 'orange' : ((s === '(' || s === ')') ? 'pink' : 'blue')} key={`tags-word-${idx}`}>{s}</Badge>)}
                    </>}
                </Group>))}
            </Card>}
            {currentExamples.length !== 0 &&
            <Card radius="lg" mt="2px">
                <Flex
                    gap="xs"
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                >
                    {currentExamples.map((s, i) => 
                    <Button compact variant='outline' color='teal' key={`${i}-${s}`}
                        onClick={() => addWordInSearchString(s)}
                    >
                        {s}
                    </Button>)}
                    {currentExamples.length === maxExamples && <Text pt='xs'><IconDots size={16} /></Text>}
                </Flex>
            </Card>
            }
        </Card>
        </>
    )
}

export { SearchQueryCV }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Button, Center, Divider, Drawer, Group, LoadingOverlay, SegmentedControl, Text, createStyles, TextInput, ScrollArea, Select } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconFileUpload, IconCloudUpload, IconX, IconDownload } from '@tabler/icons';
import { isDate, isNumber } from '../../../services/functions';
import AppContext from '../AppContext';
import { AlertNotification } from '../AlertNotification';
import { InfoNotification } from '../InfoNotification';

const useStyles = createStyles((theme) => ({
    wrapper: {
      position: 'relative',
      marginBottom: 10,
    },
  
    dropzone: {
      borderWidth: 1,
      paddingBottom: 10,
    },
  
    icon: {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
    },
  
    control: {
      position: 'absolute',
      width: 250,
      left: 'calc(50% - 125px)',
      bottom: -10,
    },
}));

interface OptionInterface {
    code: string,
    label: string,
}
interface MetadataInterface {
    label: string,
    code: string,
    type: 'text' | 'date' | 'number' | 'list', // si liste, cela dépendra du code, sauf si liste fournit dans les options
    options: OptionInterface[] | null,
}
interface PropsInterface {
    title: string,
    api: string,
    metadata: MetadataInterface[],
    handle: any,
}

const FileUploaderButton = ({title, api, metadata, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const { classes, theme } = useStyles();
    const [display, setDisplay] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [values, setValues] = useState<string[]>(metadata.map((m) => ''));
    const [isOk, setIsOk] = useState(false);

    const setValue = (p: number, v: string) => {
        const a: string[] = [...values];
        a[p] = v;
        setValues([...a]);
    }

    useEffect(() => {
        if (file === null) {
            setIsOk(false);
            return;
        }
        let t: boolean = true;
        for (let i=0;i<metadata.length;i++) {
            if (values[i] === '') {
                t = false;
                break;
            }
            if (metadata[i].type === 'number') {
                if (!isNumber(values[i])) {
                    t = false;
                    break;
                }
            } else if (metadata[i].type === 'date') {
                if (!isDate(values[i])) {
                    t = false;
                    break;
                }
            } else if (metadata[i].type === 'text') {
                if (values[i].trim().length === 0) {
                    t = false;
                    break;
                }
            }
        }
        setIsOk(t);
    }, [file, metadata, values]);

    useEffect(() => {
        if (display) return;
        setFile(null);
        setValues(metadata.map((m) => ''));
    }, [display, metadata]);

    const save = () => {
        setIsWorking(true);
        const F = new FormData();
        for(let i=0;i<metadata.length;i++){
            F.append(metadata[i].code, values[i]);
        }
        if (file !== null) {
            F.append('document', file);
        }
        const url = `${myContext.apiAddress}/${api}`;
        myContext.httpClient.post(url, F, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res: any) => {
            if (res.data.status === true) {
                setValues(metadata.map((m) => ''));
                setDisplay(false);
                InfoNotification({message: 'document added'});
                handle(res.data.data);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setIsWorking(false);
        });
    }

    return (
        <>
        <ActionIcon color="blue" variant="outline" onClick={() => setDisplay(true)}>
            <IconFileUpload size={16} />
        </ActionIcon>    
        <Drawer
            opened={display}
            onClose={() => setDisplay(false)}
            title={title}
            padding="xl"
            size="xl"
            position="right"
        >
            <LoadingOverlay visible={isWorking} />
            <ScrollArea style={{height: 'calc(100vh - 100px)'}}>
                <Divider label="1. Fichier" mt="lg" mb="md"/>
                {file === null && <div className={classes.wrapper}>
                    <Dropzone
                        onDrop={(files) => setFile(files[0])}
                        onReject={(files) => console.log('rejected files', files)}
                        className={classes.dropzone}
                        radius="md"
                        maxSize={5 * 1024 ** 2}
                        maxFiles={1}
                        accept={[
                            'application/pdf',
                            'application/vnd.ms-powerpoint',
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        ]}
                        disabled={isWorking}
                    >
                        <div style={{ pointerEvents: 'none' }}>
                            <Group position="center">
                            <Dropzone.Accept>
                                <IconDownload size={30} color={theme.colors[theme.primaryColor][6]} stroke={1.5} />
                            </Dropzone.Accept>
                            <Dropzone.Reject>
                                <IconX size={30} color={theme.colors.red[6]} stroke={1.5} />
                            </Dropzone.Reject>
                            <Dropzone.Idle>
                                <IconCloudUpload
                                    size={20}
                                    color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black}
                                />
                            </Dropzone.Idle>
                            </Group>
                            <Text align="center" weight={700}>
                                <Dropzone.Accept>Déposer le fichier ici</Dropzone.Accept>
                                <Dropzone.Reject>5 Mo max</Dropzone.Reject>
                                <Dropzone.Idle>Déposer un fichier ou cliquer</Dropzone.Idle>
                            </Text>
                            <Text align="center" size="sm" mt="xs" color="dimmed">
                                PDF ou PPT ou PPTX ou DOC ou DOCX, 5 Mo max
                            </Text>
                        </div>
                    </Dropzone>
                </div>
                }
                {file !== null &&
                <Group position="apart">
                    <Text>{file.name}</Text>
                    <Text color="dimmed" size='sm'>{Math.round(10*file.size/(1024 ** 1))/10} ko</Text>
                    <ActionIcon variant='outline' color="blue" onClick={() => setFile(null)} disabled={isWorking}>
                        <IconX size={16} />
                    </ActionIcon>
                </Group>
                }
                <Divider label="2. Informations" mt="lg" mb="md"/>
                {metadata.map((m:MetadataInterface, mIdx: number) => 
                <div key={`metadata-${mIdx}`}>
                    {m.type === 'text' &&
                    <TextInput 
                        label={m.label}
                        placeholder="libellé"
                        value={values[mIdx]}
                        onChange={(event) => setValue(mIdx, event.currentTarget.value)}
                        error={values[mIdx].length > 0 && values[mIdx].trim() === ''}
                        disabled={isWorking}
                        withAsterisk
                    />
                    }
                    {m.type === 'date' &&
                    <TextInput 
                        label={m.label}
                        placeholder="JJ/MM/AAAA"
                        value={values[mIdx]}
                        onChange={(event) => setValue(mIdx, event.currentTarget.value)}
                        error={values[mIdx].length > 0 && !isDate(values[mIdx])}
                        disabled={isWorking}
                        withAsterisk
                    />
                    }
                    {m.type === 'number' &&
                    <TextInput 
                        label={m.label}
                        placeholder="1 234,56"
                        value={values[mIdx]}
                        onChange={(event) => setValue(mIdx, event.currentTarget.value)}
                        error={values[mIdx].length > 0 && !isNumber(values[mIdx])}
                        disabled={isWorking}
                        withAsterisk
                    />
                    }
                    {(m.type === 'list' && m.code === 'status') && 
                    <>
                    <Text size="sm">{m.label} <span style={{color: 'red'}}>*</span></Text>
                    <SegmentedControl
                        value={values[mIdx]}
                        onChange={(value) => setValue(mIdx, value)}
                        data={[
                            { label: 'Brouillon', value: 'draft' },
                            { label: 'Transmis', value: 'sent' },
                            { label: 'Reçu', value: 'received' },
                            { label: 'Signé', value: 'signed' },
                        ]}
                    />
                    </>
                    }
                    {(m.type === 'list' && m.code !== 'status' && m.options !== undefined && m.options !== null && m.options.length > 0) &&
                    <>
                    <Text size="sm">{m.label} <span style={{color: 'red'}}>*</span></Text>
                    <Select 
                        onChange={(s: string) => setValue(mIdx, s)}
                        data={m.options.map((o: OptionInterface) => { return {value: o.code, label: o.label}})}
                    />
                    </>
                    }
                </div>
                )}
                <Divider label="3. Validation" mt="lg" mb="md"/>
                <Center>
                    <Button loading={isWorking} disabled={!isOk} onClick={() => save()}>
                        Sauvegarder
                    </Button>
                </Center>
            </ScrollArea>    
        </Drawer>
        </>
    )
}

export { FileUploaderButton }
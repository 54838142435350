import { showNotification } from '@mantine/notifications';

interface PropsInterface {
    message: string,
}

const AlertNotification = ({message}: PropsInterface) => {

    showNotification({
        message: message,
        color: "red",
        autoClose: 3000,
        styles: (theme) => ({
            root: {
                backgroundColor: theme.colors.red[6],
                borderColor: theme.colors.red[6],
                '&::before': { backgroundColor: theme.white },
            },
            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
                color: theme.white,
                '&:hover': { backgroundColor: theme.colors.red[7] },
            },
        }),
    });

}

export { AlertNotification }
import { useState } from 'react';
import { ActionIcon, Card, Group, TextInput } from '@mantine/core';
import { IconLetterCase, IconTextIncrease, IconPlus, IconExclamationMark } from '@tabler/icons';

import { constructRexpExp } from '../functions';

interface PropsInterface {
    handle: any,
}

const TermInputBox = ({handle}: PropsInterface) => {

    const [value, setValue] = useState('');
    const [caseSensitive, setCaseSensitive] = useState(false);
    const [expand, setExpand] = useState(false);
    const [compulsory, setCompulsory] = useState(false);
    const [inName, setInName] = useState(false);
    const [inHeadline, setInHeadline] = useState(false);
    const [inJob, setInJob] = useState(false);
    const [inExperience, setInExperience] = useState(false);
    const [inFormations, setInFormations] = useState(false);
    const [inDecorations, setInDecorations] = useState(false);
    const [inSector, setInSector] = useState(false);
    const [inLocalisation, setInLocalisation] = useState(false);

    const submit = () => {
        if (value.trim().length === 0) return;
        if (!inName && !inHeadline && !inJob && !inExperience && !inFormations && !inDecorations && !inSector && !inLocalisation) return;
        handle({
            value: value.trim(), 
            caseSensitive: caseSensitive, 
            expand: expand, 
            compulsory: compulsory,
            regexp: constructRexpExp(value, expand, caseSensitive),
            fields: { n: inName, h: inHeadline, j: inJob, e: inExperience, f: inFormations, d: inDecorations, s: inSector, l: inLocalisation },
        });
        setValue('');
        setCaseSensitive(false);
        setExpand(false);
        setCompulsory(false);
        setInName(false);
        setInHeadline(false);
        setInExperience(false);
        setInFormations(false);
        setInDecorations(false);
        setInSector(false);
        setInLocalisation(false);
        setInJob(false);
    }

    return (
        <Card mb='lg'>
            <TextInput 
                value={value} 
                onChange={(event) => setValue(event.currentTarget.value)} 
                mb='xs'
            />
            <Group mb='xs'>
                <ActionIcon color={expand ? 'green' : 'gray'} variant='filled'
                    onClick={() => setExpand(!expand)}
                >
                    <IconTextIncrease size={16} />
                </ActionIcon>
                <ActionIcon color={caseSensitive ? 'green' : 'gray'} variant='filled'
                    onClick={() => setCaseSensitive(!caseSensitive)}
                >
                    <IconLetterCase size={16} />
                </ActionIcon>
                <ActionIcon color={compulsory ? 'green' : 'gray'} variant='filled'
                    onClick={() => setCompulsory(!compulsory)}
                >
                    <IconExclamationMark size={16} />
                </ActionIcon>
            </Group>
            <Group mb='xs'>
                <ActionIcon color={inName ? 'green' : 'gray'} variant='filled'
                    onClick={() => setInName(!inName)}
                >
                    N
                </ActionIcon>
                <ActionIcon color={inHeadline ? 'green' : 'gray'} variant='filled'
                    onClick={() => setInHeadline(!inHeadline)}
                >
                    H
                </ActionIcon>
                <ActionIcon color={inJob ? 'green' : 'gray'} variant='filled'
                    onClick={() => setInJob(!inJob)}
                >
                    J
                </ActionIcon>
                <ActionIcon color={inExperience ? 'green' : 'gray'} variant='filled'
                    onClick={() => setInExperience(!inExperience)}
                >
                    E
                </ActionIcon>
                <ActionIcon color={inFormations ? 'green' : 'gray'} variant='filled'
                    onClick={() => setInFormations(!inFormations)}
                >
                    F
                </ActionIcon>
                <ActionIcon color={inDecorations ? 'green' : 'gray'} variant='filled'
                    onClick={() => setInDecorations(!inDecorations)}
                >
                    D
                </ActionIcon>
                <ActionIcon color={inSector ? 'green' : 'gray'} variant='filled'
                    onClick={() => setInSector(!inSector)}
                >
                    S
                </ActionIcon>
                <ActionIcon color={inLocalisation ? 'green' : 'gray'} variant='filled'
                    onClick={() => setInLocalisation(!inLocalisation)}
                >
                    L
                </ActionIcon>
            </Group>
            <Group position='right'>
                <ActionIcon color='blue' variant='outline' onClick={() => submit()}>
                    <IconPlus size={16} />
                </ActionIcon>
            </Group>
        </Card>
    )
}

export { TermInputBox }
import { useContext, useEffect, useReducer, useState, lazy, Suspense } from 'react';
import { ActionIcon, Badge, Card, Center, Divider, Group, Loader, Progress, Stack, Table, Text } from '@mantine/core';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from "../../shared/TitleContainer"
import { AlertNotification } from "../../shared/AlertNotification";
import { DateInputButton } from '../../shared/DateInputButton';
import { IconRefresh } from '@tabler/icons';
const ChartContainer = lazy(() => import('../../shared/ChartContainer').then(({ChartContainer}) => ({default: ChartContainer})));

interface SerieInProgressInterface {
    name: string,
    p0: number,
    p1: number,
    p2: number,
    o: number,
}
interface ChartInProgressInterface {
    isLoaded: boolean,
    isOk: boolean,
    rows: SerieInProgressInterface[],
    categories: number[],
    total: number[],
}
const chartInProgressReducer = (state: ChartInProgressInterface, payload: any) => {
    switch(payload.type) {
        case 'SET':
            return {
                isLoaded: true,
                isOk: true,
                rows: payload.data.series,
                categories: payload.data.categories,
                total: payload.data.total,
            }
        case 'ERROR':
            return { ...state, isLoaded: true, isOk: false }
        default:
            return { ...state }
    }
}

interface ChartClosedInterface {
    isLoaded: boolean,
    isOk: boolean,
    option: any,
    total: number,
    rate: number | null,
}
const chartClosedReducer = (state: ChartClosedInterface, payload: any) => {
    switch(payload.type){
        case 'ERROR':
            return {
                isLoaded: true, isOk: false, option: null, total: 0, rate: null
            }
        case 'SET':
            if (payload.data.series.length === 0) {
                return { isLoaded: true, isOk: false, option: null, total: 0, rate: null }
            }
            return {
                isLoaded: true,
                isOk: true,
                option: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: payload.data.categories
                    },
                    series: payload.data.series.map((serie:any, i: number) => { return {
                        name: serie.name,
                        type: 'bar',
                        stack: 'total',
                        label: { show: true },
                        emphasis: { focus: 'series' },
                        data: serie.values.map((v: any) => { return v === 0 ? null : v;})
                    }})
                },
                total: payload.data.total,
                rate: payload.data.rate,
            }
        case 'UPDATE':
            return { isLoaded: false, isOk: false, option: null, total: 0, rate: null }
        default:
            return { isLoaded: false, isOk: false, option: null, total: 0, rate: null }
    }
}

interface ChartCreatedInterface {
    isLoaded: boolean,
    isOk: boolean,
    option: any,
}
const chartCreatedReducer = (state: ChartCreatedInterface, payload: any) => {
    switch(payload.type){
        case 'ERROR':
            return {
                isLoaded: true, isOk: false, option: null
            }
        case 'SET':
            if (payload.series.length === 0) {
                return { isLoaded: true, isOk: false, option: null }
            }
            return {
                isLoaded: true,
                isOk: true,
                option: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        data: payload.categories,
                        name: 'Mois',
                        axisLine: { onZero: true },
                        splitLine: { show: false },
                        splitArea: { show: false }
                    },
                    yAxis: {},
                    series: payload.series.map((serie:any, i: number) => { return {
                        name: serie.name,
                        color: (i < 3 ? 'light' : '') + ((i % 3 === 0) ? 'green' : ((i % 3 === 1) ? 'blue' : 'gray')),
                        type: 'bar',
                        stack: serie.stack,
                        label: { show: true },
                        itemStyle: {
                            color: serie.color,
                        },
                        emphasis: { focus: 'series' },
                        data: serie.data.map((v:any) => { return v === 0 ? null : v;})
                    }})
                }
            }
        case 'UPDATE':
            return { isLoaded: false, isOk: false, option: null }
        default:
            return { isLoaded: false, isOk: false, option: null }
    }
}

const DashboardMainNeed = () => {

    const [dates, setDates] = useState<string[] | null>(null);
    const [chartInProgress,dispatchChartInProgress] = useReducer(chartInProgressReducer, {
        isLoaded: false, isOk: false, rows: [], categories: [], total: []
    });
    const [chartClosed,dispatchChartClosed] = useReducer(chartClosedReducer, {
        isLoaded: false, isOk: false, option: null, total: 0, rate: null
    });
    const [chartCreated,dispatchChartCreated] = useReducer(chartCreatedReducer, {
        isLoaded: false, isOk: false, option: null
    });

    const myContext = useContext(AppContext);

    // Chargement des données pour les besoins en cours.
    useEffect(() => {
        if (chartInProgress.isLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_dataset_need?dataset=open`).then((res:any) => {
            if (res.data.status === true) {
                dispatchChartInProgress({type: 'SET', data: res.data.data});
            } else {
                dispatchChartInProgress({type: 'ERROR'});
                AlertNotification({message: res.data.message || 'internal error'});
            }
        });
    }, [chartInProgress.isLoaded]); // eslint-disable-line

    // Chargement des données pour les besoins clos.
    useEffect(() => {
        if (chartClosed.isLoaded) return;
        const api = `${myContext.apiAddress}/get_dataset_need?dataset=closed`;
        const F = new FormData();
        if (dates !== null) {
            F.append('start_date', dates[0]);
            F.append('end_date', dates[1]);
        }
        myContext.httpClient.post(api, F).then((res:any) => {
            if (res.data.status === true) {
                setDates([res.data.data.start_date, res.data.data.end_date]);
                dispatchChartClosed({type: 'SET', data: res.data.data});
            } else {
                dispatchChartClosed({type: 'ERROR'});
                AlertNotification({message: res.data.message || 'internal error'});
            }
        });
    }, [chartClosed.isLoaded]); // eslint-disable-line

    // Chargement des données pour les besoins clos.
    useEffect(() => {
        if (chartCreated.isLoaded) return;
        const api = `${myContext.apiAddress}/get_dataset_need?dataset=created`;
        const F = new FormData();
        if (dates !== null) {
            F.append('start_date', dates[0]);
            F.append('end_date', dates[1]);
        }
        myContext.httpClient.post(api, F).then((res:any) => {
            if (res.data.status === true) {
                setDates([res.data.data.start_date, res.data.data.end_date]);
                dispatchChartCreated({type: 'SET', categories: res.data.data.categories, series: res.data.data.series});
            } else {
                dispatchChartCreated({type: 'ERROR'});
                AlertNotification({message: res.data.message || 'internal error'});
            }
        });
    }, [chartCreated.isLoaded]); // eslint-disable-line

    // Mise à jour de la période pour le graphique des besoins clos.
    const updateWindow = (d1: string, d2: string) => {
        setDates([d1, d2]);
        dispatchChartClosed({type: 'UPDATE'});
        dispatchChartCreated({type: 'UPDATE'});
    }

    // Affichage des éléments.
    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Tableau de bord global sur les besoins
            </TitleContainer>
            <ActionIcon color='blue' variant='outline' onClick={() => {
                dispatchChartClosed({type: 'UPDATE'});
                dispatchChartCreated({type: 'UPDATE'});
            }}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        <Center>
            <Stack style={{minWidth: 'min(700px,100vw)'}}>
                <Card radius="lg" withBorder style={{marginBottom: 10}}>
                    <Group>
                        <Text color="dimmed">
                            Besoins ouverts (hors besoins mis en attente)
                        </Text>
                        <Badge>{chartInProgress.total.p0 + chartInProgress.total.p1 + chartInProgress.total.p2 + chartInProgress.total.o}</Badge>
                    </Group>
                    {!chartInProgress.isLoaded && 
                        <Loader/>
                    }
                    {(chartInProgress.isLoaded && chartInProgress.rows.length === 0) && 
                        <Text>Aucun besoin ouvert.</Text>
                    }
                    {(chartInProgress.isLoaded && chartInProgress.rows.length > 0) && 
                        <Table>
                            <thead>
                                <tr>
                                    <th>Besoin datant de</th>
                                    <th>&hellip;{chartInProgress.categories[0]} jours</th>
                                    <th>{chartInProgress.categories[0]+1} à {chartInProgress.categories[1]} jours</th>
                                    <th>{chartInProgress.categories[1]+1} à {chartInProgress.categories[2]} jours</th>
                                    <th>au-delà</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chartInProgress.rows.map((row:SerieInProgressInterface, iRow: number) => 
                                <tr key={`row-open-${iRow}`}>
                                    <td>{row.name}</td>
                                    <td>
                                        <Group position="apart">
                                            <Text size="xs" color={row.p0 === 0 ? 'dimmed' : 'teal'} weight={700}>
                                                {row.p0 === 0 ? '-' : row.p0}
                                            </Text>
                                        </Group>
                                        <Progress color="teal" 
                                            value={Math.round(row.p0 * 100 / Math.max(1,chartInProgress.total.p0))}
                                        />
                                    </td>
                                    <td>
                                        <Group position="apart">
                                            <Text size="xs" color={row.p1 === 0 ? 'dimmed' : 'blue'} weight={700}>
                                                {row.p1 === 0 ? '-' : row.p1}
                                            </Text>
                                        </Group>
                                        <Progress color="blue" 
                                            value={Math.round(row.p1 * 100 / Math.max(1,chartInProgress.total.p1))}
                                        />
                                    </td>
                                    <td>
                                        <Group position="apart">
                                            <Text size="xs" color={row.p2 === 0 ? 'dimmed' : 'orange'} weight={700}>
                                                {row.p2 === 0 ? '-' : row.p2}
                                            </Text>
                                        </Group>
                                        <Progress color="orange" 
                                            value={Math.round(row.p2 * 100 / Math.max(1,chartInProgress.total.p2))}
                                        />
                                    </td>
                                    <td>
                                        <Group position="apart">
                                            <Text size="xs" color={row.o === 0 ? 'dimmed' : 'red'} weight={700}>
                                                {row.o === 0 ? '-' : row.o}
                                            </Text>
                                        </Group>
                                        <Progress color="red" 
                                            value={Math.round(row.o * 100 / Math.max(1,chartInProgress.total.o))}
                                        />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    }
                </Card>
                <Divider label="Analyse temporelle" labelPosition='center' mt='lg'/>
                <Group position='right'>
                    <Text color='dimmed' size='sm'>Date de création :</Text>
                    <Badge>{`${dates === null ? '' : dates[0]} ... ${dates === null ? '' : dates[1]}`}</Badge>
                    <DateInputButton 
                        label1='Date de début'
                        label2='Date de fin'
                        handle={updateWindow}
                    />
                </Group>
                <Card radius="lg" withBorder style={{marginBottom: 10}}>
                    <Group>
                        <Text color="dimmed">
                            Besoins fermés
                        </Text>
                        <Badge>{chartClosed.total}</Badge>
                        <Badge>{chartClosed.rate === null ? '?' : chartClosed.rate + '%'}</Badge>
                    </Group>
                    {!chartClosed.isLoaded && 
                        <Loader/>
                    }
                    {(chartClosed.isLoaded && chartClosed.option === null) && 
                        <Text>Aucun besoin fermé.</Text>
                    }
                    {(chartClosed.isLoaded && chartClosed.option !== null) &&
                        <Suspense fallback={<Loader/>}>
                            <ChartContainer option={chartClosed.option} />
                        </Suspense>
                    }
                </Card>
                <Card radius="lg" withBorder style={{marginBottom: 10}}>
                    <Text mb="xs" color="dimmed">
                        Besoins créés
                    </Text>
                    {!chartCreated.isLoaded && 
                        <Loader/>
                    }
                    {(chartCreated.isLoaded && chartCreated.option === null) && 
                        <Text>Aucun besoin fermé.</Text>
                    }
                    {(chartCreated.isLoaded && chartCreated.option !== null) &&
                        <Suspense fallback={<Loader/>}>
                            <ChartContainer option={chartCreated.option} />
                        </Suspense>
                    }
                </Card>
            </Stack>
        </Center>
        </>
    )
}

export { DashboardMainNeed }
import { useContext, useEffect, useState } from 'react';
import { Badge, Divider, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import AppContext from '../../shared/AppContext';

import { HistoryButton } from '../../shared/HistoryButton';
import { InfoNotification } from '../../shared/InfoNotification';
import { RestoreButton } from '../../shared/RestoreButton';
import { StatusButton } from '../../shared/StatusButton';
import { ToggleContainer } from '../../shared/ToggleContainer';
import { TrashButton } from '../../shared/TrashButton';
import { UserSelector } from '../../shared/UserSelector';
import { ShortListItemSelector } from '../../shared/ShortListItemSelector';

interface ItemInterface {
    id: number,
    label: string
}

interface PropsInterface {
    needId: number,
    handle: any,
}

const Header = ({needId, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    
    const [ clientName, setClientName ] = useState('');
    const [ createdDate, setCreatedDate ] = useState<string>('');
    const [ affectedAt, setAffectedAt ] = useState<string | null>(null);
    const [ isOpen, setIsOpen ] = useState<boolean | null>(null);
    const [ closedWhy, setClosedWhy ] = useState<string | null>(null);
    const [ isParked, setIsParked ] = useState<boolean | null>(null);
    const [ isRemoved, setIsRemoved ] = useState(false);
    const [ source, setSource ] = useState('?');
    
    const [ loaded, setLoaded ] = useState(false);
    const [ error, setError ] = useState<string | null>(null);

    // Chargement
    useEffect(() => {
        myContext.httpClient.get(`${myContext.apiAddress}/get_need?id=${needId}&what=header`).then((res:any) => {
            if (res.data.status === true) {
                setClientName(res.data.data.client_name);
                setCreatedDate(res.data.data.created_date);
                setAffectedAt(res.data.data.affected_at);
                setIsOpen(res.data.data.is_open);
                if (res.data.data.is_open !== null) {
                    handle(res.data.data.is_open);
                }
                setClosedWhy(res.data.data.closed_why);
                setIsParked(res.data.data.is_parked);
                setSource(res.data.data.source_name);
            } else {
                setError(res.data.message || 'unknown error');
            }
            setLoaded(true);
        });
    }, []); // eslint-disable-line

    // On affecte le besoin à un utilisateur.
    const affectTo = (item: ItemInterface) => {
        const F = new FormData();
        F.append('id', needId + '');
        F.append('user', item.id + '');
        const api = `${myContext.apiAddress}/affect_need`;
        myContext.httpClient.post(api, F).then((res:any) => {
            if (res.data.status === true) {
                setAffectedAt(item.label);
                InfoNotification({message: 'Besoin affecté.'});
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
    }

    if (error !== null) {
        return (
            <Group>
                <Text color="red">{error}</Text>
            </Group>
        )
    }

    if (isRemoved) {
        return (
            <Group>
                <Text color="red">Besoin supprimé</Text>
            </Group>
        )
    }

    return (
        <Group position="apart">
            <LoadingOverlay visible={!loaded}/>
            <Group>
                <Stack spacing={0}>
                    <Badge>Besoin n° {needId}</Badge>
                    <Text size="xs" color="dimmed">Créé le {createdDate.substring(0,10)}</Text>
                </Stack>
                {loaded &&
                <>
                <Divider orientation="vertical" />
                <Text size="xs" color="dimmed">Affecté à</Text>
                {affectedAt === null && <Badge color="orange">non affecté</Badge>}
                {affectedAt !== null && <Badge color="teal">{affectedAt}</Badge>}
                {isOpen && <UserSelector handle={affectTo}/>}
                <Divider orientation="vertical" />
                <Text size="xs" color="dimmed">Statut</Text>
                {isOpen ? 
                <>
                    <Badge color="teal">ouvert</Badge>
                    <StatusButton 
                        items={[
                            { label: 'Mission perdue', value: 'lost' },
                            { label: 'Mission annulée par le client', value: 'canceled' },
                            { label: 'Mission annulée par SNR Partners', value: 'abandonned' },
                            { label: 'Mission gagnée', value: 'won' },
                        ]} 
                        handle={(s: string) => {
                            setClosedWhy(s);
                            handle(false);
                        }} 
                        api={`close_need?id=${needId}`}
                    />
                </>
                :
                <>
                    <Badge color='orange'>{closedWhy}</Badge>
                </>
                }
                <Divider orientation="vertical" />
                <ShortListItemSelector
                    shortTitle='Origine'
                    longTitle='Modifier la source de ce besoin'
                    items={[
                        { code: 'email', value: 'email' },
                        { code: 'website', value: 'site web' },
                        { code: 'phone', value: 'téléphone' },
                    ]}
                    currentValue={source}
                    api={`update_need?id=${needId}&code=source_name`}
                />
                <Divider orientation="vertical" />
                <Group>
                    <Text color="dimmed" size="xs">Mis en attente</Text>
                    <ToggleContainer
                        action={isParked ? 'unpark' : 'park'}
                        defaultValue={isParked === null ? false : isParked}
                        label=""
                        isEdition={isOpen === null ? false : isOpen}
                        api={`${myContext.apiAddress}/update_need?id=${needId}&is_parked`}
                        handle={(b: boolean) => setIsParked(b)}
                    />
                </Group>
                </>}
            </Group>
            <Group>
                {isOpen === true &&
                <>
                <TrashButton
                    label={`Effacer le besoin pour ${clientName} ?`}
                    api={`remove_need`}
                    params={`id=${needId}`}
                    handle={() => setIsRemoved(true)}
                />
                </>
                }
                {isOpen === false &&
                <>
                <Badge color="orange">clos</Badge>
                <RestoreButton
                    label={`Ré ouvrir le besoin "${clientName}"`}
                    api='close_need'
                    params={`id=${needId}&value=restore`}
                    handle={() => {
                        setClosedWhy(null);
                        setIsOpen(true);
                    }}
                />
                </>
                }
                <HistoryButton
                    family='need'
                    id={needId}
                    label={clientName}
                />
            </Group>
        </Group>
    );
}

export { Header }
import { useContext, useState } from 'react';
import { ActionIcon, Group, LoadingOverlay, Stack } from '@mantine/core';
import { IconCheck, IconEdit, IconX } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../AlertNotification';

import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';

interface PropsInterface {
    messageId: number,
    value: string,
    handle: any,
    isEditable: boolean,
    updateUrl: string,
}

const Message = ({messageId, value, handle, isEditable, updateUrl}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const api = `${myContext.apiAddress}/${updateUrl}&message_id=${messageId}`;
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);

    const editor = useEditor({
        extensions: [
          StarterKit,
          Underline,
          Superscript,
          SubScript,
          Highlight,
          TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content: value,
    });

    const save = () => {
        if (editor === null) return;
        const s = editor.getHTML();
        if (value === s) return;
        setSaving(true);
        const F = new FormData();
        F.append('value', s);
        myContext.httpClient.post(api, F).then((res:any) => {
            setSaving(false);
            if (res.data.status === true) {
                if (messageId > 0) {
                    handle(s); // on remonte le contenu du message mis à jour
                } else {
                    handle(res.data.data); // on remonte l'intégralité du message
                    editor.commands.clearContent(); // on efface le message
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    if ((isEditable && editing) || messageId < 0) {
        return (
            <Stack>
                <LoadingOverlay visible={saving} />
                <RichTextEditor editor={editor}>
                    <RichTextEditor.Toolbar sticky stickyOffset={0}>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                    </RichTextEditor.ControlsGroup>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Blockquote />
                        <RichTextEditor.Hr />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.Subscript />
                        <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>
                    <RichTextEditor.Content />
                </RichTextEditor>
                <Group position="right">
                    <ActionIcon color="blue" variant="outline" onClick={() => {
                        if (messageId > 0) {
                            setEditing(false);
                        } else {
                            if (editor !== null) editor.commands.clearContent();
                        }
                    }}>
                        <IconX size={16} />
                    </ActionIcon>
                    <ActionIcon color="blue" variant="outline" onClick={() => save() }>
                        <IconCheck size={16} />
                    </ActionIcon>
                </Group>
            </Stack>
        );
    } else {
        return (
            <Group position="apart">
                <div dangerouslySetInnerHTML={{__html: editor === null ? '' : editor.getHTML() }} />
                {isEditable && 
                <ActionIcon color="blue" variant="outline" onClick={() => setEditing(true)}>
                    <IconEdit size={16} />
                </ActionIcon>
                }
            </Group>
        );
    }

}

export { Message };
import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Badge, Center, Divider, Group, LoadingOverlay, Modal, Table, Text } from '@mantine/core';
import { IconFileDownload, IconPencil, IconRefresh } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../AlertNotification';
import { FileUploaderButton } from '../FileUploaderButton';
import { TrashButton } from '../TrashButton';
import { TextInputContainer } from '../TextInputContainer';
import { DisplayPDF } from "../../shared/DisplayPDF";

interface DocumentInterface {
    id: number,
    category: string | null,
    filename: string,
    extension: string,
    created_date: string,
    created_by: string,
    blocked: boolean,
}

interface OptionInterface {
    code: string,
    label: string,
}
interface MetadataInterface {
    code: string,
    type: 'number' | 'text' | 'list' | 'date',
    label: string,
    options: OptionInterface[],
}

const documentsReducer = (state: DocumentInterface[], payload: any) => {
    switch(payload.type) {
        case 'add':
            return state.concat([{...payload.data}]);
        case 'remove':
            return state.filter((d) => d.id !== payload.id);
        case 'rename':
            return state.map((d) => {
                if (d.id === payload.id) {
                    return {...d, filename: payload.value};
                } else {
                    return {...d};
                }
            });
        case 'set':
            return payload.data || [];
        default:
            return [];
    }
}

interface PropsInterface {
    list_api: string,
    get_api: string,
    update_api: string | null,
    object_id: number,
    metadata: MetadataInterface[],
}

const DocumentsContainer = ({list_api, get_api, update_api, object_id, metadata}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [documents, dispatchDocuments] = useReducer(documentsReducer, []);
    const [loaded, setLoaded] = useState(false);
    const [file, setFile] = useState<DocumentInterface | null>(null); // fichier à visionner
    const [modalAction, setModalAction] = useState<'display' | 'rename' | null>(null);

    useEffect(() => {
        if (loaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/${list_api}?id=${object_id}`).then((res:any) => {
            if (res.data.status === true){
                dispatchDocuments({type: 'set', data: res.data.data});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoaded(true);
        });
    }, [loaded]); // eslint-disable-line

    return (
        <>
        <LoadingOverlay visible={!loaded} />

        <Modal
            opened={modalAction === 'display' && file !== null}
            onClose={() => { setModalAction(null); setFile(null)}}
            title={file === null ? 'undefined' : file.filename}
            fullScreen
        >
            <div style={{width: 'calc(100vw - 50px)', height: 'calc(100vh - 100px)'}}>
                <DisplayPDF fileUrl={`${myContext.apiAddress}/${get_api}?id=${object_id}&file_id=${file?.id}&category=${file?.category}`} />
            </div>
        </Modal>

        <Modal
            opened={modalAction === 'rename' && file !== null}
            onClose={() => { setModalAction(null); setFile(null)}}
            title={file === null ? 'undefined' : file.filename}
        >
            <TextInputContainer
                code='filename'
                label='Renommer le fichier'
                defaultValue={file === null ? 'undefined' : file.filename}
                api={`${myContext.apiAddress}/${update_api}?id=${object_id}&file_id=${file?.id}&action=rename`}
                isEdition={true}
                handle={(v: string) => {
                    dispatchDocuments({type: 'rename', id: file?.id, value: v});
                    setModalAction(null); 
                    setFile(null);
                }}
                withAsterisk={true}
            />
        </Modal>

        <Group pt="xs" position="apart">
            <Text color="dimmed" size="sm">Liste des documents</Text>
            <ActionIcon color="blue" variant="outline"
                onClick={() => setLoaded(false)}
            >
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        {documents.length === 0 ? 
        <Center>
            <Text>aucun document</Text>
        </Center>
        :
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Catégorie</th>
                    <th>Nom</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {documents.map((d: DocumentInterface,i:number) =>
                <tr key={`file-${i}`}>
                    <td>
                        <Badge color="gray">{d.extension}</Badge>
                    </td>
                    <td>
                        {d.category !== null && <Badge color='blue'>{d.category}</Badge>}
                    </td>
                    <td style={{ cursor: 'pointer' }} onClick={() => { setModalAction('display'); setFile({...d})}}>
                        <Text color="blue" style={{textDecoration: 'underline'}}>
                            {d.filename}
                        </Text>
                        <Text color="dimmed" size="xs">Créé le {d.created_date} par {d.created_by}</Text>
                    </td>
                    <td>
                        <Group>
                            <a target='_blank' rel="noreferrer" href={`${myContext.apiAddress}/${get_api}?id=${object_id}&file_id=${d.id}&file_nature=genuine&category=${d.category}`}>
                                <ActionIcon color='blue' variant='outline'>
                                    <IconFileDownload size={16} />
                                </ActionIcon>
                            </a>
                            {d.blocked === false &&
                            <>
                            <ActionIcon color="blue" variant="outline"
                                onClick={() => { setModalAction('rename'); setFile({...d})}}
                            >
                                <IconPencil size={16} />
                            </ActionIcon>
                            {update_api !== null &&
                            <TrashButton 
                                label={`Fichier "${d.filename}"`}
                                api={update_api}
                                params={`id=${object_id}&file_id=${d.id}&action=remove`}
                                handle={() => dispatchDocuments({type: 'remove', id: d.id})}
                            />
                            }
                            </>}
                        </Group>
                    </td>
                </tr>    
            )}
            </tbody>
        </Table>
        }
        {update_api !== null && 
        <>
        <Divider label="Ajouter un fichier" labelPosition='center' mt='lg' />
        <Center>
            <FileUploaderButton 
                title="Ajouter un nouveau d'un document"
                api={`${update_api}?id=${object_id}&action=add`}
                metadata={metadata}
                handle={(d:DocumentInterface) => dispatchDocuments({type:'add',data:d})}
            />
        </Center>
        </>
        }
        </>
    )
}

export { DocumentsContainer }
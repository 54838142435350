import { useContext, useEffect, useState } from 'react';
import { Button, Center, ColorSwatch, CopyButton, Group, LoadingOverlay, Modal, ScrollArea, Stack, Switch, Table, Text, Textarea, TextInput } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import { TitleContainer } from "../../shared/TitleContainer"
import { toEmail } from '../../../services/functions';
import AppContext from '../../shared/AppContext';

import { RichTextInput } from '../../shared/RichTextComponent/rte_input';
import { AlertNotification } from '../../shared/AlertNotification';

interface AnswerInterface {
    email: string,
    sent: boolean,  // Le mail a-t-il été envoyé ?
    saved: number,  // Combien de messages générés ?
}

const MailingBook = () => {

    const myContext = useContext(AppContext);
    const [inputFrom, setInputFrom] = useState('');
    const [inputTo, setInputTo] = useState('');
    const [inputCc, setInputCc] = useState('');
    const [inputBcc, setInputBcc] = useState('');
    const [isInputToOk, setIsInputToOk] = useState(false);
    const [inputSubject, setInputSubject] = useState('');
    const [inputBody, setInputBody] = useState('');
    const [working, setWorking] = useState(false);
    
    const [answers, setAnswers] = useState<AnswerInterface[]>([]);
    const [checked, setChecked] = useState(false);

    const send = () => {
        setWorking(true);
        const F = new FormData();
        F.append('from', inputFrom);
        F.append('to', inputTo);
        F.append('cc', inputCc);
        F.append('bcc', inputBcc);
        F.append('subject', inputSubject);
        F.append('body', inputBody);
        const api = `${myContext.apiAddress}/mailing_book`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                if (res.data.data.length > 0) {
                    setAnswers(res.data.data);
                } else {
                    AlertNotification({message: 'oops, should not be there'});
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    useEffect(() => {
        setIsInputToOk(inputTo.trim().length > 0 && inputTo.split('\n').filter((v: string) => v.trim().length > 0).filter((v:string) => toEmail(v) === null).length === 0);
    }, [inputTo]); // eslint-disable-line

    const toClipboad = () => {
        let s = 'Email;Sent?;Saved?\n';
        for(const a of answers) {
            s += a['email'] + ';' + (a['sent'] ? '1' : '0') + ';' + a['saved'] + '\n';
        }
        return s;
    }

    return (<>
        <Group position='apart'>
            <TitleContainer>Faire un publipostage à partir du serveur <strong>snr-digital.com</strong></TitleContainer>
            <Button variant="outline" compact
                onClick={() => {
                    setAnswers([]);
                    setInputFrom('');
                    setInputTo('');
                    setInputCc('');
                    setInputBcc('');
                    setInputSubject('');
                }}
            >
                reset
            </Button>
        </Group>
        <Modal
            opened={answers.length > 0}
            onClose={() => setAnswers([])}
            title="Mailing results"
            size='xl'
        >
            <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                <Group>
                    <Text><strong>{answers.length}</strong> emails.</Text>
                    <Text><strong>{answers.filter((r) => r.sent).length}</strong> sent.</Text>
                    <Text><strong>{answers.filter((r) => r.saved === 1).length}</strong> actions correctly saved.</Text>
                    <CopyButton value={toClipboad()}>
                    {({ copied, copy }) => (
                        <Button color={copied ? 'teal' : 'blue'} onClick={copy} variant="outline" compact>
                        {copied ? 'Copied data' : 'Copy data'}
                        </Button>
                    )}
                    </CopyButton>
                    <Switch 
                        size='sm'
                        label="only problems"
                        checked={checked} 
                        onChange={(event) => setChecked(event.currentTarget.checked)} 
                    />
                </Group>
                <Table highlightOnHover>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Sent</th>
                            <th>Saved</th>
                        </tr>
                    </thead>
                    <tbody>
                        {answers.filter((r) => !checked || r.sent === false || r.saved !== 1).map((row: AnswerInterface, i) => 
                        <tr key={`email-${i}-${row['email']}`}>
                            <td>{row['email']}</td>
                            <td>
                                {row['sent'] ? 
                                <ColorSwatch color='green'><IconCheck color="white"/></ColorSwatch>
                                :
                                <ColorSwatch color='red'><IconX color="white"/></ColorSwatch>
                                }
                            </td>
                            <td>
                                {row['saved'] === 1 ? 
                                <ColorSwatch color='green'><IconCheck color="white"/></ColorSwatch>
                                :
                                <ColorSwatch color='red'><Text color="white">{row['saved']}</Text></ColorSwatch>
                                }
                            </td>
                        </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>
        </Modal>
        <Center>
            <ScrollArea style={{height: 'calc(100vh - 150px)'}} p="xs">
                <Stack style={{width: 'min(100%, 500px)'}}>
                    <LoadingOverlay visible={working} />
                    <TextInput 
                        label='From'
                        value={inputFrom} 
                        onChange={(event) => setInputFrom(event.currentTarget.value.trim())} 
                        error={inputFrom.trim().length < 1 || toEmail(inputFrom) === null}
                        withAsterisk
                    />
                    <Textarea 
                        label='To (one row, one email)'
                        value={inputTo} 
                        onChange={(event) => setInputTo(event.currentTarget.value.toLocaleLowerCase())} 
                        error={!isInputToOk}
                        autosize
                        minRows={2}
                        maxRows={10}
                        withAsterisk
                    />
                    <TextInput 
                        label='Cc'
                        value={inputCc} 
                        onChange={(event) => setInputCc(event.currentTarget.value.trim())} 
                        error={inputCc.trim().length !== 0 && toEmail(inputCc) === null}
                    />
                    <TextInput 
                        label='Bcc'
                        value={inputBcc} 
                        onChange={(event) => setInputBcc(event.currentTarget.value.trim())} 
                        error={inputBcc.trim().length !== 0 && toEmail(inputBcc) === null}
                    />
                    <TextInput 
                        label='Object'
                        value={inputSubject} 
                        onChange={(event) => setInputSubject(event.currentTarget.value)} 
                        error={inputSubject.trim().length < 1}
                        withAsterisk
                    />
                    <Group>
                        <Text>Body</Text><Text color='red'>*</Text>
                    </Group>
                    <RichTextInput 
                        handle={(v:string) => setInputBody(v)} 
                    />
                    <Group position='center'>
                        <Button color='blue' variant='outline'
                            onClick={() => send()}
                            disabled={
                                inputSubject.trim().length < 1 
                                || inputBody.trim().length < 6 
                                || !isInputToOk 
                                || toEmail(inputFrom) === null
                                || (inputCc !== '' && toEmail(inputCc) === null)
                                || (inputBcc !== '' && toEmail(inputBcc) === null)
                            }
                        >
                            Send emails
                        </Button>
                    </Group>
                </Stack>
            </ScrollArea>
        </Center>
    </>)
}

export { MailingBook }
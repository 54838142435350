import { useState } from 'react';
import { ActionIcon, Indicator, Modal } from '@mantine/core';
import { IconBooks } from '@tabler/icons';
import { DocumentsContainer } from '../../shared/DocumentsContainer';

interface OptionInterface {
    code: string,
    label: string,
}
interface MetadataInterface {
    code: string,
    type: 'number' | 'text' | 'list' | 'date',
    label: string,
    options: OptionInterface[],
}

interface PropsInterface {
    name: string,
    documentId: number,
    nAttachments: number,
}

const Attachements = ({name, documentId, nAttachments}: PropsInterface) => {

    const [opened, setOpened] = useState(false);
    
    const metadataFilename: MetadataInterface = { code: "filename", type: "text", label: "Nom du document", options: [] };
    const metadataCategory: MetadataInterface = { code: 'category', type: 'list', label: 'Catégorie', options: [ 
        {code: 'anonymous', label: 'CV anonyme'}, 
        {code: 'client', label: 'CV client'}, 
        {code: 'other', label: 'Autre'} 
    ]};

    return (
    <>
    <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={`Fichiers attachés à ${name}`}
        fullScreen
    >
        <DocumentsContainer
            list_api='process_attachment_cv_list'
            get_api='process_attachment_cv_get'
            update_api='process_attachment_cv_update'
            object_id={documentId}
            metadata={[metadataFilename, metadataCategory]}
        />
    </Modal>
    {nAttachments === 0 ?
    <ActionIcon color='blue' variant='outline' onClick={() => setOpened(true)}>
        <IconBooks size={16} />
    </ActionIcon>
    :
    <Indicator position='middle-end' color='green'>
        <ActionIcon color='blue' variant='outline' onClick={() => setOpened(true)}>
            <IconBooks size={16} />
        </ActionIcon>
    </Indicator>
    }
    </>
    )

}

export { Attachements }
import { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { useParams } from "react-router-dom";
import { ActionIcon, Button, CopyButton, Group, Loader, LoadingOverlay, ScrollArea, Select, Table, Tabs, Text } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TitleContainer } from '../../shared/TitleContainer';
import { LetteringBadge } from '../../bank/lettering_badge';
import { DocumentButton } from '../ManageMission/document_button';
const ChartContainer = lazy(() => import('../../shared/ChartContainer').then(({ChartContainer}) => ({default: ChartContainer})));

interface FileInterface {
    c: 'bill' | 'invoice', // category
    m: number, // mission_id
    i: number, // file_id
    d: string, // flow_date
    r: string, // reference
    l: number, // lettering
    v: number, // valeur (TVA ou TTC)
}
interface ChartInterface {
    categories: string[],
    total_on_bills: (number | null)[],
    total_on_invoices: (number | null)[],
    total: (number | null)[],
}

const monthList = [
    {value: '1', label: 'Janvier'},
    {value: '2', label: 'Février'},
    {value: '3', label: 'Mars'},
    {value: '4', label: 'Avril'},
    {value: '5', label: 'Mai'},
    {value: '6', label: 'Juin'},
    {value: '7', label: 'Juillet'},
    {value: '8', label: 'Août'},
    {value: '9', label: 'Septembre'},
    {value: '10', label: 'Octobre'},
    {value: '11', label: 'Novembre'},
    {value: '12', label: 'Décembre'},
]

type ParamInterface = {
    paramNature: 'taxes' | 'ttc',
}

const FacturesOnMission = () => {

    const { paramNature } = useParams<ParamInterface>();

    let myTitle: string = '';
    let mySubtitle: string = '';
    let myCategories: string[] = [];
    if (paramNature === 'taxes') {
        myTitle = "TVA collectée et TVA déductible";
        mySubtitle = "La TVA est payée sur les encaissements et décaissements. Les montants sont indiqués en euros.";
        myCategories = ['TVA collectée', 'TVA déductible', 'TVA à payer'];
    } else if (paramNature === 'ttc') {
        myTitle = "TTC facturé";
        mySubtitle = "Les montants sont indiqués en euros.";
        myCategories = ['TTC clients', 'TTC fournisseurs', 'TTC solde'];
    }

    const myContext = useContext(AppContext);
    const [loaded, setLoaded] = useState(false);
    const [chart, setChart] = useState<ChartInterface | null>(null);
    const [files, setFiles] = useState<FileInterface[]>([]);
    const y0 = (new Date()).getFullYear();
    const [year, setYear] = useState<string | null>(y0+'');
    const [month, setMonth] = useState<string | null>(((new Date()).getMonth()+1)+'');
    const [total, setTotal] = useState<number[]>([]);
    const [monthTotal, setMonthTotal] = useState(0);

    useEffect(() => {
        if (loaded) return;
        const url = `${myContext.apiAddress}/taxes_mission?year=${year}&month=${month}&what=data&nature=${paramNature}`;
        myContext.httpClient.get(url).then((result:any) => {
            if (result.data.status === true) {
                setChart(result.data.data.chart);
                setFiles(result.data.data.files);
                const t: number[] = [];
                for(let i=0;i<12;i++){
                    t.push(result.data.data.chart.total[i] + ((i===0) ? 0 : t[i-1]));
                }
                setTotal([...t]);
                let s: number = 0;
                for (const f of result.data.data.files) {
                    s += f.v;
                }
                setMonthTotal(s);
            } else {
                AlertNotification({message: result.data.message || 'unknown error'});
            }
            setLoaded(true);
        });
    }, [loaded]) // eslint-disable-line

    useEffect(() => {
        if (!loaded) return;
        if (paramNature !== 'taxes' && paramNature !== 'ttc') return;
        setLoaded(false);
    }, [paramNature]) // eslint-disable-line

    const exportForClipBoard = () => {
        let s: string = '';
        s += '<table><thead><tr>'
                + '<th>Facture</th>'
                + '<th>Référence</th>'
                + '<th>Date virement</th>'
                + '<th>Montant</th>'
                + '<th>Lettrage</th>'
                + '<th>Fichier</th>'
                + '</tr></thead><tbody>';
        for(const f of files) {
            s += '<tr>'
                + '<td>' + f.c + '</td>'
                + '<td>' + f.r + '</td>'
                + '<td>' + f.d + '</td>'
                + '<td>' + f.v.toLocaleString() + '</td>'
                + '<td>' + f.l + '</td>'
                + '<td>file_' + f.i + '.pdf</td>'
                + '</tr>';
        }
        s += '</tbody></table>';
        return s;
    }

    if (paramNature === 'taxes' || paramNature === 'ttc') {
        return (
            <>
            <Group position="apart">
                <TitleContainer>
                    {myTitle}
                </TitleContainer>
                <Group>
                    <Select 
                        label=""
                        value={year}
                        onChange={setYear}
                        data={[
                            {value: (y0-1)+'', label: (y0-1)+''},
                            {value: y0+'', label: y0+''},
                            {value: (y0+1)+'', label: (y0+1)+''}
                        ]}
                        disabled={!loaded}
                    />
                    <Select 
                        label=""
                        value={month}
                        onChange={setMonth}
                        data={monthList}
                        disabled={!loaded}
                    />
                    <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)} disabled={!loaded}>
                        <IconRefresh size={16} />
                    </ActionIcon>
                </Group>
            </Group>
            <Text>{mySubtitle}</Text>
            <Tabs defaultValue="chart">
                <LoadingOverlay visible={!loaded} />
                <Tabs.List>
                    <Tabs.Tab value="chart">Graphique sur l'année</Tabs.Tab>
                    <Tabs.Tab value="files">Détail sur le mois de {month === null ? '...' : monthList[parseInt(month)-1].label}</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="chart" pt="xs">
                    {chart !== null &&
                    <>
                    <Suspense fallback={<Loader/>}>
                        <ChartContainer option={{
                            legend: {
                                data: myCategories,
                            },
                            toolbox: {
                                feature: {
                                    dataView: {}
                                }
                            },
                            tooltip: {},
                            xAxis: {
                                data: chart.categories,
                                name: 'mois année',
                                axisLine: { onZero: true },
                                splitLine: { show: true },
                                splitArea: { show: true },
                                axisLabel: {
                                    interval: 0,
                                    rotate: 0
                                }
                            },
                            yAxis: {},
                            series: [
                                {
                                    name: myCategories[0],
                                    type: 'bar',
                                    data: chart.total_on_invoices
                                },{
                                    name: myCategories[1],
                                    type: 'bar',
                                    data: chart.total_on_bills
                                },{
                                    name: myCategories[2],
                                    type: 'bar',
                                    data: chart.total
                                }
                            ],
                        }} />
                    </Suspense>
                    <Table style={{fontSize: '0.8em'}}>
                        <thead>
                            <tr>
                                <th>{myCategories[2]}</th>
                                {chart.categories.map((c) => 
                                <th key={`total-header-${c}`}>
                                    <Text align='right'>{c}</Text>
                                </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Text>Sur le mois</Text>
                                </td>
                                {total.map((t,i) => 
                                <td key={`total-body-${i}`}>
                                    <Text align="right">{t === null ? '' : ((i===0) ? t : t - total[i-1]).toLocaleString()}</Text>
                                </td>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    <Text>Cumulé</Text>
                                </td>
                                {total.map((t,i) => 
                                <td key={`total-body-${i}`}>
                                    <Text align="right">{t === null ? '' : t.toLocaleString()}</Text>
                                </td>
                                )}
                            </tr>
                        </tbody>
                    </Table>
                    </>
                    }
                </Tabs.Panel>
                <Tabs.Panel value="files" pt="xs">
                    <ScrollArea style={{height: 'calc(100vh - 180px)'}}>
                    <Group position="apart">
                        <Text color='dimmed'>{files.length} documents.</Text>
                        <Group>
                            <CopyButton value={exportForClipBoard()}>
                                {({ copied, copy }) => (
                                <Button size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                                    {copied ? 'Données copiées' : 'Copier'}
                                </Button>
                                )}
                            </CopyButton>
                            <Button variant='outline' size='xs'
                                component="a" target="_blank" rel="noopener noreferrer"
                                href={`${myContext.apiAddress}/taxes_mission?year=${year}&month=${month}&what=files&nature=${paramNature}`}
                            >
                                Télécharger les fichiers
                            </Button>
                        </Group>
                    </Group>
                    <Table highlightOnHover>
                        <thead>
                            <tr>
                                <th>Facture</th>
                                <th>Référence</th>
                                <th>Date virement</th>
                                <th>Montant</th>
                                <th>Lettrage</th>
                                <th>Document</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <Text>Total</Text>
                                </td>
                                <td>
                                    <Text align='right'>{monthTotal.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                                </td>
                                <td colSpan={2}></td>
                            </tr>
                            {files.map((f) => 
                            <tr key={`file-${f.i}`}>
                                <td>
                                    <Text>{f.c === 'bill' ? 'fournisseur' : 'client'}</Text>
                                </td>
                                <td>
                                    <Text>{f.r}</Text>
                                </td>
                                <td>
                                    <Text>{f.d}</Text>
                                </td>
                                <td>
                                    <Text align='right'>{f.v.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                                </td>
                                <td>
                                    {f.l !== null && <LetteringBadge letter={`L${f.l}`} />}
                                </td>
                                <td>
                                    <DocumentButton
                                        title=""
                                        category={f.c}
                                        missionId={f.m}
                                        fileId={f.i}
                                        missionOpened={false}
                                        handleChange={null}
                                    />
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                </ScrollArea>
                </Tabs.Panel>
            </Tabs>
            </>
        )
    } else {
        return (
            <TitleContainer>
                Erreur 404
            </TitleContainer>
        )
    }

}

export { FacturesOnMission }
import { useContext, useEffect, useReducer, useState, lazy, Suspense } from 'react';
import { ActionIcon, Alert, Divider, Group, Loader, LoadingOverlay, ScrollArea, Text } from '@mantine/core';
import { IconAlertCircle, IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
const ChartContainer = lazy(() => import('../../shared/ChartContainer').then(({ChartContainer}) => ({default: ChartContainer})));

interface SerieInterface {
    name: string,
    type: string,
    data: number[],
}
interface ChartsInterface {
    timeChart: null | {
        categories: string[],
        series: SerieInterface[],
    },
    timeChartCumulated: null | {
        categories: string[],
        series: SerieInterface[],
    },
}

const chartsReducer = (state: ChartsInterface, payload: any) => {
    switch (payload.type) {
        case 'set':
            if (payload.categories === null) {
                return {
                    timeChart: null,
                    timeChartCumulated: null,
                }
            }
            const pts = payload.categories.length;
            // sur un pas mensuel :
            const d: number[][] = [
                ('contract' in payload.series) ? payload.series.contract : new Array(pts).fill(null),
                ('invoice' in payload.series) ? payload.series.invoice : new Array(pts).fill(null),
                ('bill' in payload.series) ? payload.series.bill : new Array(pts).fill(null),
                ('margin' in payload.series) ? payload.series.margin : new Array(pts).fill(null),
            ];
            // et en cumulés :
            const dc: number[][] = [];
            for(let c=0;c<d.length;c++) {
                dc.push(Array(pts).fill(null));
                for(let i=0;i<pts;i++){
                    if (d[c][i] !== null && d[c][i] !== 0) {
                        for(let j=i;j<pts;j++){
                            dc[c][j] += d[c][i];
                        }
                    }
                }
            }
            // zone rouge
            const markArea =(payload.stoppedzone === null) ? null : {
                itemStyle: { color: 'rgba(255, 173, 177, 0.4)' },
                data: [ [ { xAxis: payload.stoppedzone[0] }, { xAxis: payload.stoppedzone[1] } ] ]
            };
            // fin :
            return {
                timeChart: {
                    categories: payload.categories,
                    series: [
                        { color: '#4dabf7', name: 'Budget client prévisionnel', type: 'bar', data: d[0], markArea: markArea },
                        { color: '#fd7e14', name: 'Factures clients', type: 'bar', data: d[1] },
                        { color: '#ffd43b', name: 'Factures fournisseurs', type: 'bar', data: d[2] },
                        { color: '#94d82d', name: 'Marge brute', type: 'bar', data: d[3] },
                    ]
                },
                timeChartCumulated: {
                    categories: payload.categories,
                    series: [
                        { color: '#4dabf7', name: 'Budget client prévisionnel', type: 'bar', data: dc[0], markArea: markArea },
                        { color: '#fd7e14', name: 'Factures clients', type: 'bar', data: dc[1] },
                        { color: '#ffd43b', name: 'Factures fournisseurs', type: 'bar', data: dc[2] },
                        { color: '#94d82d', name: 'Marge brute', type: 'bar', data: dc[3] },
                    ]
                }
            }
        default:
            return {
                timeChart: null,
                timeChartCumulated: null,
            }
    }
}

interface PropsInterface {
    missionId: number,
    missionOpened: boolean,
}

const GraphicsContainer = ({missionId, missionOpened}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState('');
    const [charts, dispatchCharts] = useReducer(chartsReducer, {
        timeChart: null, timeChartCumulated: null,
    });

    useEffect(() => {
        if (isLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_finance_mission?id=${missionId}`).then((res:any) => {
            setIsLoaded(true);
            if (res.data.status === true) {
                dispatchCharts({type: 'set', series: res.data.data.series, categories: res.data.data.categories, stoppedzone: res.data.data.stoppedzone });
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
    }, [isLoaded, missionId, myContext.apiAddress, myContext.httpClient]);

    return (
        <div style={{height: '400px', width: '100%'}}>
            <LoadingOverlay visible={!isLoaded} overlayBlur={2} />
            {error !== '' && 
            <Alert icon={<IconAlertCircle size={16} />} title="Erreur" color="red">
                {error}
            </Alert>
            }
            {charts.timeChart === null &&
                <Text>aucun élément financier associé aux documents de cette mission</Text>
            }
            {charts.timeChart !== null &&
            <Suspense fallback={<Loader/>}>
                <Group position="apart">
                    <Text>Graphiques (montants hors taxes en euros)</Text>
                    {missionOpened && 
                    <ActionIcon color="blue" variant="outline" onClick={() => setIsLoaded(false)}>
                        <IconRefresh size={16} />
                    </ActionIcon>
                    }
                </Group>
                <ScrollArea style={{height: '400px'}}>
                    <Divider label="Trajectoire financière" labelPosition='center'/>
                    <ChartContainer option={{
                        legend: {
                            data: charts.timeChart.series.map((s) => { return s.name}),
                        },
                        toolbox: {
                            feature: {
                                dataView: {}
                            }
                        },
                        tooltip: {},
                        xAxis: {
                            data: charts.timeChart.categories,
                            name: 'mois année',
                            axisLine: { onZero: true },
                            splitLine: { show: true },
                            splitArea: { show: true },
                            axisLabel: {
                                interval: 0,
                                rotate: 30
                            }
                        },
                        yAxis: {},
                        series: charts.timeChart.series,
                    }} />
                    <Divider label="Trajectoire financière en cumulé" labelPosition='center'/>
                    <ChartContainer option={{
                        legend: {
                            data: charts.timeChartCumulated.series.map((s) => { return s.name}),
                        },
                        toolbox: {
                            feature: {
                                dataView: {}
                            }
                        },
                        tooltip: {},
                        xAxis: {
                            data: charts.timeChartCumulated.categories,
                            name: 'mois année',
                            axisLine: { onZero: true },
                            splitLine: { show: true },
                            splitArea: { show: true }
                        },
                        yAxis: {},
                        series: charts.timeChartCumulated.series,
                    }} />
                </ScrollArea>
            </Suspense>
            }
        </div>
    )
}

export { GraphicsContainer }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Alert, Card, Center, Group, Loader, Progress, RingProgress, ScrollArea, Table, useMantineTheme, Text } from '@mantine/core';
import { IconAlertCircle, IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';

interface RowInterface {
    id: string,
    name: string,
    currentStockInserted: number,
    currentStockAffected: number,
    currentStockWaitingForControl: number,
    currentStockWaitingForValidation: number,
    warningStockInserted: number,
    warningStockAffected: number,
    warningStockWaitingForControl: number,
    warningStockWaitingForValidation: number,
    affectedProcessedWeek0: number,
    affectedProcessedWeek1: number,
    affectedProcessedWeek2: number,
    affectedProcessedWeek3: number,
}

// c in [ teal, red ]
// v valeur à afficher
// m valeur maximale
interface MyProgressInterface {
    colorValue: string,
    colorMax: string,
    value: number,
    max: number,
}
const MyProgress = ({colorValue, colorMax, value, max}: MyProgressInterface) => {
    const v1 = Math.round(100 * value / max);
    const v2 = Math.round(100 * (1 - value / max));
    return (
        <>
        <Group position="apart">
            <Text size="xs" color={value === 0 ? 'dimmed' : colorValue} weight={700}>{value === 0 ? '-' : value}</Text>
        </Group>
        <Progress
            sections={[
                {
                    value: v1,
                    color: colorValue,
                },{
                    value: v2,
                    color: colorMax
                }
            ]}
        />
        </>
    )
}

const DashboardInProgressCV = () => {

    const theme = useMantineTheme();
    const colorForNeutral = theme.colors.gray[4];
    const colorForInserted = theme.colors.grape[4];
    const colorForAffected = theme.colors.orange[4];
    const colorForWaitingForControl = theme.colors.cyan[4];
    const colorForWaitingForValidation = theme.colors.green[4];
    const myContext = useContext(AppContext);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ rows, setRows ] = useState<RowInterface[]>([]);
    const [ max, setMax ] = useState<RowInterface>({
        id: 'max',
        name: '',
        currentStockInserted: 0,
        currentStockAffected: 0,
        currentStockWaitingForControl: 0,
        currentStockWaitingForValidation: 0,
        warningStockInserted: 0,
        warningStockAffected: 0,
        warningStockWaitingForControl: 0,
        warningStockWaitingForValidation: 0,
        affectedProcessedWeek0: 0,
        affectedProcessedWeek1: 0,
        affectedProcessedWeek2: 0,
        affectedProcessedWeek3: 0,
    });
    const [ total, setTotal ] = useState<RowInterface>({
        id: 'total',
        name: '',
        currentStockInserted: 0,
        currentStockAffected: 0,
        currentStockWaitingForControl: 0,
        currentStockWaitingForValidation: 0,
        warningStockInserted: 0,
        warningStockAffected: 0,
        warningStockWaitingForControl: 0,
        warningStockWaitingForValidation: 0,
        affectedProcessedWeek0: 0,
        affectedProcessedWeek1: 0,
        affectedProcessedWeek2: 0,
        affectedProcessedWeek3: 0,
    });
    const [ bigTotal, setBigTotal ] = useState(0);
    const [ bigWeekMax, setBigWeekMax ] = useState(0);
    const [ bigWarning, setBigWarning ] = useState(0);
    
    useEffect(() => {
        if (!isLoading) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_dashboard_cv`).then((result: any) => {
            if (result.data.status === true) {
                // Liste des id :
                const ids: string[] = [];
                for (const e of result.data.data.currentStock) {
                    if (ids.includes(e.id) === false) {
                        ids.push(e.id === null ? '?' : e.id);
                    }
                }
                for (const e of result.data.data.affectedProcessed) {
                    if (ids.includes(e.id) === false) {
                        ids.push(e.id === null ? '?' : e.id);
                    }
                }
                // On initialise le tableau :
                const d: RowInterface[] = [];
                const m: RowInterface = {
                    id: 'max',
                    name: '',
                    currentStockInserted: 0,
                    currentStockAffected: 0,
                    currentStockWaitingForControl: 0,
                    currentStockWaitingForValidation: 0,
                    warningStockInserted: 0,
                    warningStockAffected: 0,
                    warningStockWaitingForControl: 0,
                    warningStockWaitingForValidation: 0,
                    affectedProcessedWeek0: 0,
                    affectedProcessedWeek1: 0,
                    affectedProcessedWeek2: 0,
                    affectedProcessedWeek3: 0,
                };
                const t: RowInterface = {
                    id: 'total',
                    name: '',
                    currentStockInserted: 0,
                    currentStockAffected: 0,
                    currentStockWaitingForControl: 0,
                    currentStockWaitingForValidation: 0,
                    warningStockInserted: 0,
                    warningStockAffected: 0,
                    warningStockWaitingForControl: 0,
                    warningStockWaitingForValidation: 0,
                    affectedProcessedWeek0: 0,
                    affectedProcessedWeek1: 0,
                    affectedProcessedWeek2: 0,
                    affectedProcessedWeek3: 0,
                };
                for (const e of ids) {
                    d.push({
                        id: e === null ? '?' : e,
                        name: '',
                        currentStockInserted: 0,
                        currentStockAffected: 0,
                        currentStockWaitingForControl: 0,
                        currentStockWaitingForValidation: 0,
                        warningStockInserted: 0,
                        warningStockAffected: 0,
                        warningStockWaitingForControl: 0,
                        warningStockWaitingForValidation: 0,
                        affectedProcessedWeek0: 0,
                        affectedProcessedWeek1: 0,
                        affectedProcessedWeek2: 0,
                        affectedProcessedWeek3: 0,
                    })
                }
                // On remplit le tableau :
                for (const e of result.data.data.currentStock) {
                    const i: number = e.id === null ? ids.indexOf('?') : ids.indexOf(e.id);
                    if (d[i].name === '') d[i].name = e.name;
                    if (e.status === 'inserted') {
                        const n = parseInt(e.nbr);
                        d[i].currentStockInserted = n;
                        t.currentStockInserted += n;
                        if (n > m.currentStockInserted) m.currentStockInserted = n;
                        const w = parseInt(e.wrn);
                        d[i].warningStockInserted = w;
                        t.warningStockInserted += w;
                        if (w > m.warningStockInserted) m.warningStockInserted = w;
                    } else if (e.status === 'affected') {
                        const n = parseInt(e.nbr);
                        d[i].currentStockAffected = n;
                        t.currentStockAffected += n;
                        if (n > m.currentStockAffected) m.currentStockAffected = n;
                        const w = parseInt(e.wrn);
                        d[i].warningStockAffected = w;
                        t.warningStockAffected += w;
                        if (w > m.warningStockAffected) m.warningStockAffected = w;
                    } else if (e.status === 'waiting-for-control') {
                        const n = parseInt(e.nbr);
                        d[i].currentStockWaitingForControl = n;
                        t.currentStockWaitingForControl += n;
                        if (n > m.currentStockWaitingForControl) m.currentStockWaitingForControl = n;
                        const w = parseInt(e.wrn);
                        d[i].warningStockWaitingForControl = w;
                        t.warningStockWaitingForControl += w;
                        if (w > m.warningStockWaitingForControl) m.warningStockWaitingForControl = w;
                    } else if (e.status === 'waiting-for-validation') {
                        const n = parseInt(e.nbr);
                        d[i].currentStockWaitingForValidation = n;
                        t.currentStockWaitingForValidation += n;
                        if (n > m.currentStockWaitingForValidation) m.currentStockWaitingForValidation = n;
                        const w = parseInt(e.wrn);
                        d[i].warningStockWaitingForValidation = w;
                        t.warningStockWaitingForValidation += w;
                        if (w > m.warningStockWaitingForValidation) m.warningStockWaitingForValidation = w;
                    }
                }
                for (const e of result.data.data.affectedProcessed) {
                    const i: number = e.id === null ? ids.indexOf('?') : ids.indexOf(e.id);
                    if (d[i].name === '') d[i].name = e.name;
                    const v0 = parseInt(e.w0);
                    d[i].affectedProcessedWeek0 = v0;
                    t.affectedProcessedWeek0 += v0;
                    if (v0 > m.affectedProcessedWeek0) m.affectedProcessedWeek0 = v0;
                    const v1 = parseInt(e.w1) - v0;
                    d[i].affectedProcessedWeek1 = v1;
                    t.affectedProcessedWeek1 += v1;
                    if (v1 > m.affectedProcessedWeek1) m.affectedProcessedWeek1 = v1;
                    const v2 = parseInt(e.w2) - v1 - v0;
                    d[i].affectedProcessedWeek2 = v2;
                    t.affectedProcessedWeek2 += v2;
                    if (v2 > m.affectedProcessedWeek2) m.affectedProcessedWeek2 = v2;
                    const v3 = parseInt(e.w3) - v2 - v1 - v0;
                    d[i].affectedProcessedWeek3 = v3;
                    t.affectedProcessedWeek3 += v3;
                    if (v3 > m.affectedProcessedWeek3) m.affectedProcessedWeek3 = v3;
                }
                // on trie
                d.sort((a,b) => b.currentStockAffected - a.currentStockAffected);
                // on sauvegarde
                setRows(d.map((r) => { return {...r}}));
                setTotal({...t});
                setMax({...m});
                setBigTotal(t.currentStockInserted + t.currentStockAffected + t.currentStockWaitingForControl + t.currentStockWaitingForValidation);
                setBigWeekMax(Math.max(m.affectedProcessedWeek0, m.affectedProcessedWeek1, m.affectedProcessedWeek2, m.affectedProcessedWeek3));
                setBigWarning(t.warningStockInserted + t.warningStockAffected + t.warningStockWaitingForControl + t.warningStockWaitingForValidation);
            }
            setIsLoading(false);
        })
    }, [isLoading, myContext])

    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Tableau de bord sur l'ajout de curriculum vitae
            </TitleContainer>
            <ActionIcon color='blue' variant='outline' onClick={() => setIsLoading(true)}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        {isLoading && 
        <Group position="center" align="center">
            <Loader radius="xl"/>
            <Text>Chargement des données en cours...</Text>
        </Group>}
        {(!isLoading && bigTotal === 0) &&
        <Center>
            <Alert icon={<IconAlertCircle size={16} />} title="STOCK" color="red">
                Aucun CV non validé en stock.
            </Alert>
        </Center>
        }
        {(!isLoading && bigTotal > 0) && 
        <Center>
            <Card radius="lg" withBorder style={{marginBottom: 10}}>
                <Text color="dimmed">
                    Charge de travail à date sur les CV non validés.
                </Text>
                <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                    <Table verticalSpacing="xs">
                        <thead>
                            <tr>
                                <th>Qui</th>
                                <th style={{width: 150}}>
                                    <Group position="left" spacing="xs">
                                        <Text align="center" weight={700} color={colorForInserted}>
                                            {total.currentStockInserted}
                                        </Text>
                                        <Text>à affecter</Text>
                                    </Group>
                                </th>
                                <th style={{width: 150}}>
                                    <Group position="left" spacing="xs">
                                        <Text align="center" weight={700} color={colorForAffected}>
                                            {total.currentStockAffected}
                                        </Text>
                                        <Text>à traiter</Text>
                                    </Group>
                                </th>
                                <th style={{width: 150}}>
                                    <Group position="left" spacing="xs">
                                        <Text align="center" weight={700} color={colorForWaitingForControl}>
                                            {total.currentStockWaitingForControl}
                                        </Text>
                                        <Text>à contrôler</Text>
                                    </Group>
                                </th>
                                <th style={{width: 150}}>
                                    <Group position="left" spacing="xs">
                                        <Text align="center" weight={700} color={colorForWaitingForValidation}>
                                            {total.currentStockWaitingForValidation}
                                        </Text>
                                        <Text>à valider</Text>
                                    </Group>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>TOTAL</td>
                                <td>
                                    <Center>
                                        <RingProgress
                                            size={90}
                                            sections={[{
                                                value: Math.round(100 * total.currentStockInserted / bigTotal), 
                                                color: colorForInserted}]}
                                            label={
                                                <Text color={colorForInserted} weight={700} align="center">
                                                    {Math.round(100 * total.currentStockInserted / bigTotal)}%
                                                </Text>
                                            }
                                        />
                                    </Center>
                                </td>
                                <td>
                                    <Center>
                                        <RingProgress
                                            size={90}
                                            sections={[{
                                                    value: Math.round(100 * total.currentStockInserted / bigTotal), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.currentStockAffected / bigTotal), 
                                                    color: colorForAffected
                                                }]}
                                            label={
                                                <Text color={colorForAffected} weight={700} align="center">
                                                    {Math.round(100 * total.currentStockAffected / bigTotal)}%
                                                </Text>
                                            }
                                        />
                                    </Center>
                                </td>
                                <td>
                                    <Center>
                                        <RingProgress
                                            size={90}
                                            sections={[{
                                                    value: Math.round(100 * total.currentStockInserted / bigTotal), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.currentStockAffected / bigTotal), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.currentStockWaitingForControl / bigTotal), 
                                                    color: colorForWaitingForControl
                                                }]}
                                            label={
                                                <Text color={colorForWaitingForControl} weight={700} align="center">
                                                    {Math.round(100 * total.currentStockWaitingForControl / bigTotal)}%
                                                </Text>
                                            }
                                        />
                                    </Center>
                                </td>
                                <td>
                                    <Center>
                                        <RingProgress
                                            size={90}
                                            sections={[{
                                                    value: Math.round(100 * total.currentStockInserted / bigTotal), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.currentStockAffected / bigTotal), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.currentStockWaitingForControl / bigTotal), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.currentStockWaitingForValidation / bigTotal), 
                                                    color: colorForWaitingForValidation
                                                }]}
                                            label={
                                                <Text color={colorForWaitingForValidation} weight={700} align="center">
                                                    {Math.round(100 * total.currentStockWaitingForValidation / bigTotal)}%
                                                </Text>
                                            }
                                        />
                                    </Center>
                                </td>
                            </tr>
                            {rows.map((row) => (
                            <tr key={`row-${row.id}`}>
                                <td>{row.name}</td>
                                <td>
                                    <MyProgress 
                                        colorValue={colorForInserted}
                                        colorMax={colorForNeutral} 
                                        value={row.currentStockInserted} 
                                        max={max.currentStockInserted} 
                                    />
                                </td>
                                <td>
                                    <MyProgress 
                                        colorValue={colorForAffected}
                                        colorMax={colorForNeutral} 
                                        value={row.currentStockAffected} 
                                        max={max.currentStockAffected} 
                                    />
                                </td>
                                <td>
                                    <MyProgress 
                                        colorValue={colorForWaitingForControl}
                                        colorMax={colorForNeutral}
                                        value={row.currentStockWaitingForControl} 
                                        max={max.currentStockWaitingForControl} 
                                    />
                                </td>
                                <td>
                                    <MyProgress 
                                        colorValue={colorForWaitingForValidation}
                                        colorMax={colorForNeutral}
                                        value={row.currentStockWaitingForValidation} 
                                        max={max.currentStockWaitingForValidation} 
                                    />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </ScrollArea>
            </Card>
        </Center>}
        {(!isLoading && bigWeekMax > 0) && 
        <Center>
            <Card radius="lg" withBorder style={{marginBottom: 10}}>
                <Text color="dimmed">
                    CV traités sur les 3 dernières semaines et la semaine en cours.
                </Text>
                <ScrollArea style={{height: 'calc(100vh - 100px)'}}>
                    <Table verticalSpacing="xs">
                        <thead>
                            <tr>
                                <th>Qui</th>
                                <th style={{width: 150}}>
                                    <Text>{total.affectedProcessedWeek3} en S-3</Text>
                                </th>
                                <th style={{width: 150}}>
                                    <Text>{total.affectedProcessedWeek2} en S-2</Text>
                                </th>
                                <th style={{width: 150}}>
                                    <Text>{total.affectedProcessedWeek1} en S-1</Text>
                                </th>
                                <th style={{width: 150}}>
                                    <Text>{total.affectedProcessedWeek0} cette semaine</Text>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.filter((row) => row.id !== '?').map((row: RowInterface) => (
                            <tr key={`row-${row.id}`}>
                                <td>{row.name}</td>
                                <td>
                                    <Progress
                                        size={20}
                                        radius="xl"
                                        sections={[
                                            {
                                                value: Math.round(100 * row.affectedProcessedWeek3 / bigWeekMax),
                                                color: theme.colors.green[4],
                                                label: row.affectedProcessedWeek3 + '',
                                            }
                                        ]}
                                    />
                                </td>
                                <td>
                                    <Progress
                                        size={20}
                                        radius="xl"
                                        sections={[
                                            {
                                                value: Math.round(100 * row.affectedProcessedWeek2 / bigWeekMax),
                                                color: theme.colors.green[4],
                                                label: row.affectedProcessedWeek2 + '',
                                            }
                                        ]}
                                    />
                                </td>
                                <td>
                                    <Progress
                                        size={20}
                                        radius="xl"
                                        sections={[
                                            {
                                                value: Math.round(100 * row.affectedProcessedWeek1 / bigWeekMax),
                                                color: theme.colors.green[4],
                                                label: row.affectedProcessedWeek1 + '',
                                            }
                                        ]}
                                    />
                                </td>
                                <td>
                                    <Progress
                                        size={20}
                                        radius="xl"
                                        sections={[
                                            {
                                                value: Math.round(100 * row.affectedProcessedWeek0 / bigWeekMax),
                                                color: theme.colors.lime[4],
                                                label: row.affectedProcessedWeek0 + '',
                                            }
                                        ]}
                                    />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </ScrollArea>
            </Card>
        </Center>}
        {(!isLoading && bigWarning > 0) && 
        <Center>
            <Card radius="lg" withBorder style={{marginBottom: 10}}>
                <Text color="dimmed">
                    CV non travaillés depuis plus de 14 jours.
                </Text>
                <ScrollArea style={{height: 'calc(100vh - 100px)'}}>
                    <Table verticalSpacing="xs">
                        <thead>
                            <tr>
                                <th>Qui</th>
                                <th style={{width: 150}}>
                                    <Group position="left" spacing="xs">
                                        <Text align="center" weight={700} color="red">
                                            {total.warningStockInserted}
                                        </Text>
                                        <Text>à affecter</Text>
                                    </Group>
                                </th>
                                <th style={{width: 150}}>
                                    <Group position="left" spacing="xs">
                                        <Text align="center" weight={700} color="red">
                                            {total.warningStockAffected}
                                        </Text>
                                        <Text>à traiter</Text>
                                    </Group>
                                </th>
                                <th style={{width: 150}}>
                                    <Group position="left" spacing="xs">
                                        <Text align="center" weight={700} color="red">
                                            {total.warningStockWaitingForControl}
                                        </Text>
                                        <Text>à contrôler</Text>
                                    </Group>
                                </th>
                                <th style={{width: 150}}>
                                    <Group position="left" spacing="xs">
                                        <Text align="center" weight={700} color="red">
                                            {total.warningStockWaitingForValidation}
                                        </Text>
                                        <Text>à valider</Text>
                                    </Group>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>TOTAL</td>
                                <td>
                                    <Center>
                                        <RingProgress
                                            size={90}
                                            sections={[{
                                                value: Math.round(100 * total.warningStockInserted / bigWarning), 
                                                color: "red"}]}
                                            label={
                                                <Text color="red" weight={700} align="center">
                                                    {Math.round(100 * total.warningStockInserted / bigWarning)}%
                                                </Text>
                                            }
                                        />
                                    </Center>
                                </td>
                                <td>
                                    <Center>
                                        <RingProgress
                                            size={90}
                                            sections={[{
                                                    value: Math.round(100 * total.warningStockInserted / bigWarning), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.warningStockAffected / bigWarning), 
                                                    color: "red"
                                                }]}
                                            label={
                                                <Text color="red" weight={700} align="center">
                                                    {Math.round(100 * total.warningStockAffected / bigWarning)}%
                                                </Text>
                                            }
                                        />
                                    </Center>
                                </td>
                                <td>
                                    <Center>
                                        <RingProgress
                                            size={90}
                                            sections={[{
                                                    value: Math.round(100 * total.warningStockInserted / bigWarning), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.warningStockAffected / bigWarning), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.warningStockWaitingForControl / bigWarning), 
                                                    color: "red"
                                                }]}
                                            label={
                                                <Text color="red" weight={700} align="center">
                                                    {Math.round(100 * total.warningStockWaitingForControl / bigWarning)}%
                                                </Text>
                                            }
                                        />
                                    </Center>
                                </td>
                                <td>
                                    <Center>
                                        <RingProgress
                                            size={90}
                                            sections={[{
                                                    value: Math.round(100 * total.warningStockInserted / bigWarning), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.warningStockAffected / bigWarning), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.warningStockWaitingForControl / bigWarning), 
                                                    color: colorForNeutral
                                                },{
                                                    value: Math.round(100 * total.warningStockWaitingForValidation / bigWarning), 
                                                    color: "red"
                                                }]}
                                            label={
                                                <Text color="red" weight={700} align="center">
                                                    {Math.round(100 * total.warningStockWaitingForValidation / bigWarning)}%
                                                </Text>
                                            }
                                        />
                                    </Center>
                                </td>
                            </tr>
                            {rows.map((row) => (
                            <tr key={`row-${row.id}`}>
                                <td>{row.name}</td>
                                <td>
                                    <MyProgress 
                                        colorValue="red"
                                        colorMax={colorForNeutral} 
                                        value={row.warningStockInserted} 
                                        max={max.warningStockInserted} 
                                    />
                                </td>
                                <td>
                                    <MyProgress 
                                        colorValue="red"
                                        colorMax={colorForNeutral} 
                                        value={row.warningStockAffected} 
                                        max={max.warningStockAffected} 
                                    />
                                </td>
                                <td>
                                    <MyProgress 
                                        colorValue="red"
                                        colorMax={colorForNeutral}
                                        value={row.warningStockWaitingForControl} 
                                        max={max.warningStockWaitingForControl} 
                                    />
                                </td>
                                <td>
                                    <MyProgress 
                                        colorValue="red"
                                        colorMax={colorForNeutral}
                                        value={row.warningStockWaitingForValidation} 
                                        max={max.warningStockWaitingForValidation} 
                                    />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </ScrollArea>
            </Card>
        </Center>}
        </>
    )
}

export { DashboardInProgressCV }
function isDate(input) {
    if (isEmpty(input)) return false;
    var validRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
    if (input.match(validRegex)) {
        return (new Date(input.substring(6,4)+'-'+input.substring(3,2)+'-'+input.substring(0,2))).toString() === 'Invalid date' ? false : true;
    } else {
        return false;
    }
}

function isEmpty(input) {
    return (input === undefined || input === null || input === '') ? true : false;
}

function isNumber(input) {
    if (isEmpty(input)) return false;
    return /^-{0,1}[0-9]+([,.]{1}[0-9]+){0,1}$/.test(input.replaceAll(' ','').replaceAll(' ','').replace(',','.'));
}

function slugify(s) {
    return s
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}

function toDomain(input) {
    if (isEmpty(input)) return false;
    var validRegex = /^[a-zA-Z0-9]{1,20}$/;
    if (input.match(validRegex)) {
        return input.toLowerCase();
    } else {
        return null;
    }
}

function toEmail(input) {
    if (isEmpty(input)) return false;
    var validRegex = /^[a-z0-9-._]{1,40}@([a-z0-9-]{1,9}\.){0,1}[a-z0-9-]{1,20}\.[a-z]{2,5}$/;
    if (input.match(validRegex)) {
        return input;
    } else {
        return null;
    }
}

function toFrenchDate(input) {
    const d = (input + '').split('-');
    if (d.length === 3) {
        return [d[2],d[1],d[0]].join('.');
    } else {
        return input;
    }
}

function toLogin(input) {
    if (isEmpty(input)) return false;
    var validRegex = /^[a-z]{2,20}$/;
    if (input.match(validRegex)) {
        return input.toLowerCase();
    } else {
        return null;
    }
}

function toPassword(input) {
    if (isEmpty(input)) return false;
    var validRegex = /^[a-zA-Z0-9@"'!,;:=_-]{8,20}$/;
    if (input.match(validRegex)) {
        return input;
    } else {
        return null;
    }
}

function toVersion(input) {
    if (isEmpty(input)) return false;
    if (input === '+') return input;
    var validRegex = /^[0-9]{1,3}\.[0-9]{1,3}$/;
    if (input.match(validRegex)) {
        return input;
    } else {
        return null;
    }
}

export { isDate, isNumber, slugify, toDomain, toEmail, toFrenchDate, toLogin, toPassword, toVersion };
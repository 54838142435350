import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Center, Group, LoadingOverlay, Modal, ScrollArea, Table, Tabs, Text } from '@mantine/core';
import { IconFileDescription, IconRefresh } from '@tabler/icons';
import { AlertNotification } from '../../shared/AlertNotification';
import { TitleContainer } from '../../shared/TitleContainer';
import { DocumentContainer } from '../DocumentContainer';
import AppContext from '../../shared/AppContext';

interface ItemInterface {
    id: number,
    name: string,
    email: string,
    n: number
}

const AuditDuplicatesCV = () => {

    const myContext = useContext(AppContext);
    const [loaded, setLoaded] = useState(false);
    const [duplicatesOnEmails, setDuplicatesOnEmails] = useState<ItemInterface[]>([]);
    const [duplicatesOnNames, setDuplicatesOnNames] = useState<ItemInterface[]>([]);

    const [docId,setDocId] = useState(-1);
    const [displayDoc, setDisplayDoc] = useState(false);

    useEffect(() => {
        if (loaded) return;
        const api = `${myContext.apiAddress}/duplicates_list_cv`;
        myContext.httpClient.get(api).then((res: any) => {
            setLoaded(true);
            if (res.data.status === true) {
                setDuplicatesOnEmails(res.data.data.emails || []);
                setDuplicatesOnNames(res.data.data.names || []);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [loaded]); // eslint-disable-line

    return (
        <div>
            <Modal
                opened={displayDoc}
                onClose={() => { setDisplayDoc(false); setDocId(-1)}}
                title={`Curriculum vitae ${docId}`}
                fullScreen
            >
                <DocumentContainer
                    documentId={docId}
                    isEdition={false}
                />
            </Modal>
            <LoadingOverlay visible={!loaded} />
            <Group position="apart">
                <TitleContainer>
                    Liste des CV bloqués
                </TitleContainer>
                <ActionIcon color="blue" variant="outline" onClick={() => setLoaded(false)}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
            <Tabs defaultValue='emails'>
                <Tabs.List>
                    <Tabs.Tab value="emails">Doublons sur les emails</Tabs.Tab>
                    <Tabs.Tab value="names">Doublons sur les noms</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="emails" pt="xs">
                    {duplicatesOnEmails.length === 0 ?
                    <Center>
                        <Text color='green'>Aucun doublon sur les emails.</Text>
                    </Center>
                    :
                    <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                        <Table highlightOnHover>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Nom</th>
                                    <th>Id</th>
                                    <th>Lien</th>
                                </tr>
                            </thead>
                            <tbody>
                                {duplicatesOnEmails.map((d) => 
                                <tr key={`row-email-${d.id}`}>
                                    <td>{d.email}</td>
                                    <td>{d.name}</td>
                                    <td>{d.id}</td>
                                    <td>
                                        <ActionIcon color="blue" variant="outline" onClick={() => { setDocId(d.id); setDisplayDoc(true) }}>
                                            <IconFileDescription size={16} />
                                        </ActionIcon>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </ScrollArea>
                    }
                </Tabs.Panel>
                <Tabs.Panel value="names" pt="xs">
                    {duplicatesOnNames.length === 0 ?
                    <Center>
                        <Text color='green'>Aucun doublon sur les noms.</Text>
                    </Center>
                    :
                    <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                        <Table highlightOnHover>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Email</th>
                                    <th>Id</th>
                                    <th>Lien</th>
                                </tr>
                            </thead>
                            <tbody>
                                {duplicatesOnNames.map((d) => 
                                <tr key={`row-email-${d.id}`}>
                                    <td>{d.name}</td>
                                    <td>{d.email}</td>
                                    <td>{d.id}</td>
                                    <td>
                                        <ActionIcon color="blue" variant="outline" onClick={() => { setDocId(d.id); setDisplayDoc(true) }}>
                                            <IconFileDescription size={16} />
                                        </ActionIcon>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </ScrollArea>
                    }
                </Tabs.Panel>
            </Tabs>
        </div>
    )

}

export { AuditDuplicatesCV }
import { useContext, useState } from 'react';
import { Button, Center, Group, LoadingOverlay, Stack, Text, TextInput } from '@mantine/core';
import { TitleContainer } from "../../shared/TitleContainer"
import { toEmail } from '../../../services/functions';
import AppContext from '../../shared/AppContext';

import { RichTextInput } from '../../shared/RichTextComponent/rte_input';
import { AlertNotification } from '../../shared/AlertNotification';
import { InfoNotification } from '../../shared/InfoNotification';

const SendEmail = () => {

    const myContext = useContext(AppContext);
    const [inputTo, setInputTo] = useState('');
    const [inputSubject, setInputSubject] = useState('');
    const [inputBody, setInputBody] = useState('');
    const [working, setWorking] = useState(false);

    const send = () => {
        setWorking(true);
        const F = new FormData();
        F.append('to', inputTo);
        F.append('subject', inputSubject);
        F.append('body', inputBody);
        const api = `${myContext.apiAddress}/send_email`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                setInputTo('');
                InfoNotification({message: 'mail sent'});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (<>
        <TitleContainer>Transmettre un mail à partir de l'application</TitleContainer>
        <Center>
            <Stack style={{width: 'min(100%, 500px)'}}>
                <LoadingOverlay visible={working} />
                <TextInput 
                    label='To'
                    value={inputTo} 
                    onChange={(event) => setInputTo(event.currentTarget.value.trim())} 
                    error={inputTo.trim().length < 1 || (toEmail(inputTo) === null ? true : false)}
                    withAsterisk
                />
                <TextInput 
                    label='Object'
                    value={inputSubject} 
                    onChange={(event) => setInputSubject(event.currentTarget.value)} 
                    error={inputSubject.trim().length < 1}
                    withAsterisk
                />
                <Group>
                    <Text>Body</Text><Text color='red'>*</Text>
                </Group>
                <RichTextInput
                    handle={(v:string) => setInputBody(v)}
                />
                <Group position='center'>
                    <Button color='blue' variant='outline'
                        onClick={() => send()}
                        disabled={inputSubject.trim().length < 1 || inputBody.trim().length < 6 || inputTo === '' || toEmail(inputTo) === null}
                    >
                        Send email
                    </Button>
                </Group>
            </Stack>
        </Center>
    </>)
}

export { SendEmail }
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Badge, Group, LoadingOverlay, ScrollArea, SegmentedControl, Table, Text, TextInput } from '@mantine/core';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from "../../shared/TitleContainer"
import { IconExternalLink, IconSearch } from '@tabler/icons';
import { AlertNotification } from '../../shared/AlertNotification';
import { DocumentButton } from '../ManageMission/document_button';

interface RowInterface {
    client_name: string,
    manager_name: string,
    category: 'bill' | 'invoice',
    reference: string,
    file_id: number,
    mission_id: number,
    lettering: number | null,
    filename: string,
}

const SearchMission = () => {

    const myContext = useContext(AppContext);
    const [inputField, setInputField] = useState('reference');
    const [inputWord, setInputWord] = useState('');
    const [searching, setSearching] = useState(false);
    const [rows, setRows] = useState<RowInterface[]>([]);

    const search = () => {
        setSearching(true);
        setRows([]);
        const F = new FormData();
        F.append('search', ':'+inputField+' '+inputWord);
        const api = `${myContext.apiAddress}/search_mission`;
        myContext.httpClient.post(api, F).then((res:any) => {
            if (res.data.status === true) {
                setRows(res.data.data || []);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setSearching(false);
        });
    }

    return (
        <>
        <TitleContainer>
            Rechercher une facture
        </TitleContainer>
        <Group>
            <LoadingOverlay visible={searching} />
            <Text>Rechercher le mot</Text>
            <TextInput 
                value={inputWord} 
                onChange={(event) => setInputWord(event.currentTarget.value.trim())}
            />
            <Text>dans</Text>
            <SegmentedControl 
                value={inputField}
                onChange={setInputField}
                data={[
                    { label: 'Référence', value: 'reference' },
                    { label: 'Client', value: 'client_name' },
                    { label: 'Manager', value: 'manager_name' },
                  ]}
            />
            <ActionIcon color='blue' variant='outline' onClick={() => search()}
                disabled={inputField === '' || inputWord.length < 3}
            >
                <IconSearch size={16} />
            </ActionIcon>
        </Group>
        {!searching && rows.length !== 0 &&
        <ScrollArea style={{height: 'calc(100vh - 150px)'}}>
            <Table highlightOnHover>
                <thead>
                    <tr>
                        <th>Client final</th>
                        <th>Manager de transition</th>
                        <th>Catégorie</th>
                        <th>Référence</th>
                        <th>Nom du fichier</th>
                        <th>Document</th>
                        <th>Vers la mission</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row) => 
                    <tr key={`${row.mission_id}-${row.file_id}`}>
                        <td><Text>{row.client_name}</Text></td>
                        <td><Text>{row.manager_name}</Text></td>
                        <td><Badge>{row.category === 'bill' ? 'fournisseur' : 'client'}</Badge></td>
                        <td><Text>{row.reference}</Text></td>
                        <td><Text>{row.filename}</Text></td>
                        <td><DocumentButton 
                            title=''
                            category={row.category}
                            missionId={row.mission_id}
                            fileId={row.file_id}
                            handleChange={null}
                            missionOpened={row.lettering === null ? true : false}
                        /></td>
                        <td><ActionIcon color='blue' variant='outline' component={Link} to={`/mission/manage/${row.mission_id}`}>
                            <IconExternalLink size={16} />
                        </ActionIcon></td>
                    </tr>
                    )}
                </tbody>
            </Table>
        </ScrollArea>
        }
        </>
    )
}

export { SearchMission }
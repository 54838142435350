
import { useContext } from "react"
import { ActionIcon, Badge, Card, Center, Group, Mark, Stack, Text } from '@mantine/core';
import AppContext from "../../shared/AppContext"
import { TitleContainer } from "../../shared/TitleContainer"
import { IconCloudDownload } from "@tabler/icons";

interface SectionInterface {
    code: string,
    label: any,
    columns: string[],
}

const ExportDataset = () => {

    const myContext = useContext(AppContext);
    const api = `${myContext.apiAddress}/export_dataset?code=`;

    const sections: SectionInterface[] = [
        {
            code: 'book_all',
            label: <Text>Liste des <Mark>contacts</Mark> dans les entreprises</Text>,
            columns: ['category', 'company', 'fullname', 'job', 'email', 'phone', 'extra'],
        },{
            code: 'cv_all',
            label: <Text>Liste des <Mark>curriculum vitae</Mark> et des méta données associées</Text>,
            columns: ['id', 'name', 'status', 'version', 'email', 'phone', 'address', 'zipcode', 'jobs', 'sectors', 'tags'],
        },{
            code: 'queries',
            label: <Text>Liste des <Mark>recherches</Mark> utilisées pour alimenter les paniers.</Text>,
            columns: ['basket_id', 'basket_label', 'user', 'query'],
        }
    ];
    
    return (
        <>
        <TitleContainer>Exporter un jeu de données</TitleContainer>
        <Center>
            <Stack>
                {sections.map((s,i) => 
                <Card key={`section-${i}`}>
                    <Group position="apart">
                        {s.label}
                        <a href={`${api}${s.code}`} target="_blank" rel="noreferrer">
                            <ActionIcon variant="outline" color="blue">
                                <IconCloudDownload size={16}/>
                            </ActionIcon>
                        </a>
                    </Group>
                    <Text color="dimmed" size="xs">
                        Dans un fichier CSV (séparateur tabulation), on récupère les colonnes suivantes :
                    </Text>
                    <Group>
                        {s.columns.map((c,j) => 
                            <Badge color="gray" key={`section-${i}-column-${j}`}>{c}</Badge>
                        )}
                    </Group>
                </Card>
                )}
            </Stack>
        </Center>
        </>
    )
}

export { ExportDataset }
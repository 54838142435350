import { useState } from 'react';
import { TextInput } from "@mantine/core";

interface PropsInterface {
    handle: any
}

const DateInputContainer = ({handle}: PropsInterface) => {

    const [value,setValue] = useState('');
    const [valueValidated, setValueValidated] = useState('');
    const regex = new RegExp(/^[0-9]{2}\/[0-9]{2}\/[1-2]{1}[0-9]{3}$/);

    const change = (v: string) => {
        setValue(v);
        const s: string = v.trim();
        let d: string = '';
        let t: boolean = false;
        if (regex.test(s)) {
            const a: string[] = s.split('/');
            d = a[2]+'-'+a[1]+'-'+a[0];
            if (!isNaN((new Date(d)).getFullYear())) {
                t = true;
            }
        }
        setValueValidated(t ? d : '');
        handle(t ? d : ''); // on passe la date validée au composant parent
    }

    return (
        <TextInput 
            value={value} 
            onChange={(event) => change(event.target.value)}
            placeholder='JJ/MM/AAAA'
            error={valueValidated === ''}
        />
    )
}

export { DateInputContainer }
import { useContext, useEffect, useState } from 'react';
import { Card, Center, Divider, Grid, Group, LoadingOverlay, Stack, Tabs, Text } from '@mantine/core';
import AppContext from '../../shared/AppContext';

import { SliderContainer } from '../../shared/SliderContainer';
import { TextInputContainer } from '../../shared/TextInputContainer';
import { TextareaContainer } from '../../shared/TextareaContainer';
import { RichTextContainer } from '../../shared/RichTextComponent/rte_container';
import { DatePickerContainer } from '../../shared/DatePickerContainer';
import { NumberInputContainer } from '../../shared/NumberInputContainer';
import { ShortListItemSelector } from '../../shared/ShortListItemSelector';

const MARKS = [
    { value: 0, label: 'non' },
    { value: 25, label: 'peu probable' },
    { value: 50, label: 'pas d\'info' },
    { value: 75, label: 'très probable' },
    { value: 100, label: 'oui' },
];

interface PropsInterface {
    needId: number,
    isOpen: boolean,
    handleLabel: any,
}

const Brief = ({needId, isOpen, handleLabel}: PropsInterface) => {

    const myContext = useContext(AppContext);

    const [ clientName, setClientName ] = useState('');
    const [ clientMission, setClientMission ] = useState('');
    const [ clientDuration, setClientDuration ] = useState('');
    const [ clientLocalisation, setClientLocalisation ] = useState('');
    const [ contactTitle1, setContactTitle1 ] = useState('');
    const [ contactName1, setContactName1 ] = useState('');
    const [ contactEmail1, setContactEmail1 ] = useState('');
    const [ contactPhone1, setContactPhone1 ] = useState('');
    const [ contactTitle2, setContactTitle2 ] = useState('');
    const [ contactName2, setContactName2 ] = useState('');
    const [ contactEmail2, setContactEmail2 ] = useState('');
    const [ contactPhone2, setContactPhone2 ] = useState('');

    const [ isValidator, setIsValidator ] = useState(0);
    const [ isBudget, setIsBudget ] = useState(0);
    const [ isSoon, setIsSoon ] = useState(0);
    const [ clientBudget, setClientBudget ] = useState(0);
    
    const [ specifications, setSpecifications ] = useState('');
    const [ notebook, setNotebook ] = useState('');
    
    const [ missionDateStart, setMissionDateStart ] = useState('');
    const [ missionDateEnd, setMissionDateEnd ] = useState('');
    const [ clientSiret, setClientSiret ] = useState('');
    const [ clientRcs, setClientRcs ] = useState('');
    const [ clientAddress, setClientAddress ] = useState('')
    const [ missionDaysNumber, setMissionDaysNumber ] = useState(0);
    const [ missionDaysDetail, setMissionDaysDetail ] = useState('');
    const [ missionExpenses, setMissionExpenses ] = useState('');
    const [ missionFiche, setMissionFiche ] = useState('');
    const [ supplierName, setSupplierName ] = useState('');
    const [ supplierLegalForm, setSupplierLegalForm ] = useState('');
    const [ supplierCapitalNumber, setSupplierCapitalNumber ] = useState('');
    const [ supplierAddress, setSupplierAddress ] = useState('');
    const [ supplierRcs, setSupplierRcs ] = useState('');
    const [ supplierSiret, setSupplierSiret ] = useState('');
    const [ supplierContactName, setSupplierContactName ] = useState('');
    const [ supplierContactStatus, setSupplierContactStatus ] = useState('');

    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState<string | null>(null);

    // Chargement
    useEffect(() => {
        if (needId < 1) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_need?id=${needId}&what=metadata`).then((res:any) => {
            if (res.data.status === true) {
                setClientName(res.data.data.client_name || '');
                setClientMission(res.data.data.client_mission || '');
                setClientDuration(res.data.data.client_duration || '');
                setClientLocalisation(res.data.data.client_localisation || '');
                setContactTitle1(res.data.data.contact_title_1 || '');
                setContactName1(res.data.data.contact_name_1 || '');
                setContactEmail1(res.data.data.contact_email_1 || '');
                setContactPhone1(res.data.data.contact_phone_1 || '');
                setContactTitle2(res.data.data.contact_title_2 || '');
                setContactName2(res.data.data.contact_name_2 || '');
                setContactEmail2(res.data.data.contact_email_2 || '');
                setContactPhone2(res.data.data.contact_phone_2 || '');
                setIsValidator(res.data.data.is_validator || 0);
                setIsBudget(res.data.data.is_budget || 0);
                setIsSoon(res.data.data.is_soon || 0);
                setClientBudget(res.data.data.client_budget || 0);
                setSpecifications(res.data.data.specifications || '');
                setNotebook(res.data.data.notebook || '');
                setClientSiret(res.data.data.client_siret || '');
                setClientRcs(res.data.data.client_rcs || '');
                setClientAddress(res.data.data.client_address || '');
                setMissionDateStart(res.data.data.mission_date_start || '');
                setMissionDateEnd(res.data.data.mission_date_end || '');
                setMissionDaysNumber(res.data.data.mission_days_number);
                setMissionDaysDetail(res.data.data.mission_days_detail || '');
                setMissionExpenses(res.data.data.mission_expenses || '');
                setMissionFiche(res.data.data.mission_fiche || '');
                setSupplierName(res.data.data.supplier_name || 'à définir');
                setSupplierLegalForm(res.data.data.supplier_legal_form || '');
                setSupplierCapitalNumber(res.data.data.supplier_capital_number || '');
                setSupplierAddress(res.data.data.supplier_address || '');
                setSupplierRcs(res.data.data.supplier_rcs || '');
                setSupplierSiret(res.data.data.supplier_siret || '');
                setSupplierContactName(res.data.data.supplier_contact_name || '');
                setSupplierContactStatus(res.data.data.supplier_contact_status || '');
            } else {
                setError(res.data.message || 'unknown error');
            }
            setLoading(false);
        });
    }, [ needId ]); // eslint-disable-line

    useEffect(() => {
        if (handleLabel === null) return;
        handleLabel(clientName + ' / ' + clientMission);
    }, [clientName, clientMission]); // eslint-disable-line

    if (error !== null) {
        return (
            <Group>
                <Text color="red">{error}</Text>
            </Group>
        )
    }

    if (loading) {
        return (
            <Center>
                <LoadingOverlay visible={true} />
                <Text pt="lg" pb="lg">chargement...</Text>
            </Center>
        )
    }

    return (
        <Center>
            <Tabs defaultValue='default'>
                <Tabs.List>
                    <Tabs.Tab value="default">Description</Tabs.Tab>
                    <Tabs.Tab value="other">Compléments</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="default" pt="xs">

                    <Stack spacing={0} style={{width: 'min(100vw, 660px)'}}>

                        <Divider label="Données d'en-tête (utilisées pour les NDAs)" labelPosition='center' mt="md"/>
                        <TextInputContainer
                            code='client_name'
                            label='Nom du client'
                            defaultValue={clientName}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setClientName(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='client_mission'
                            label='Libellé de la mission'
                            defaultValue={clientMission}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setClientMission(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='client_localisation'
                            label='Localisation de la mission'
                            defaultValue={clientLocalisation}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setClientLocalisation(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='client_duration'
                            label='Durée prévisionnelle de la mission'
                            defaultValue={clientDuration}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setClientDuration(s)}
                            withAsterisk={true}
                        />

                        <Divider label="Contacts" labelPosition='center' mt="md"/>
                        <Grid grow gutter="xs">
                            <Grid.Col span={6}>
                                <TextInputContainer
                                    code='contact_name_1'
                                    label='Prénom NOM'
                                    defaultValue={contactName1}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setContactName1(s)}
                                    withAsterisk={true}
                                />
                                <TextInputContainer
                                    code='contact_title_1'
                                    label='Poste occupé'
                                    defaultValue={contactTitle1}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setContactTitle1(s)}
                                    withAsterisk={true}
                                />
                                <TextInputContainer
                                    code='contact_phone_1'
                                    label='Téléphone'
                                    defaultValue={contactPhone1}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setContactPhone1(s)}
                                    withAsterisk={true}
                                />
                                <TextInputContainer
                                    code='contact_email_1'
                                    label='Email'
                                    defaultValue={contactEmail1}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setContactEmail1(s)}
                                    withAsterisk={true}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <TextInputContainer
                                    code='contact_name_2'
                                    label='Prénom NOM'
                                    defaultValue={contactName2}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setContactName2(s)}
                                    withAsterisk={false}
                                />
                                <TextInputContainer
                                    code='contact_title_2'
                                    label='Poste occupé'
                                    defaultValue={contactTitle2}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setContactTitle2(s)}
                                    withAsterisk={false}
                                />
                                <TextInputContainer
                                    code='contact_phone_2'
                                    label='Téléphone'
                                    defaultValue={contactPhone2}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setContactPhone2(s)}
                                    withAsterisk={false}
                                />
                                <TextInputContainer
                                    code='contact_email_2'
                                    label='Email'
                                    defaultValue={contactEmail2}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setContactEmail2(s)}
                                    withAsterisk={false}
                                />
                            </Grid.Col>
                        </Grid>

                        <Divider label="Priorisation" labelPosition='center' mt="md"/>
                        <Center>
                            <Card style={{width: 'min(100%, 350px)'}}>
                                <Card key='priority-is_validator'>
                                    <Text>Le contact est-il le décideur ?</Text>
                                    <SliderContainer 
                                        marks={MARKS} 
                                        step={25}
                                        defaultValue={isValidator}
                                        api={isOpen ? `${myContext.apiAddress}/update_need?id=${needId}&code=is_validator` : null}
                                        handleValue={(v: number) => setIsValidator(v)}
                                    />
                                </Card>
                                <Card key='priority-is_budget'>
                                    <Text>Le budget est-il validé ?</Text>
                                    <SliderContainer 
                                        marks={MARKS} 
                                        step={25}
                                        defaultValue={isBudget}
                                        api={isOpen ? `${myContext.apiAddress}/update_need?id=${needId}&code=is_budget` : null}
                                        handleValue={(v: number) => setIsBudget(v)}
                                    />
                                </Card>
                                <Card key='priority-is_soon'>
                                    <Text>La mission doit-elle commencer vite ?</Text>
                                    <SliderContainer 
                                        marks={MARKS} 
                                        step={25}
                                        defaultValue={isSoon}
                                        api={isOpen ? `${myContext.apiAddress}/update_need?id=${needId}&code=is_soon` : null}
                                        handleValue={(v: number) => setIsSoon(v)}
                                    />
                                </Card>
                                <Card key='priority-budget'>
                                    <NumberInputContainer
                                        label='Budget prévisionnel de la mission (en k€)'
                                        min={0}
                                        max={999}
                                        step={10}
                                        defaultValue={clientBudget}
                                        api={isOpen ? `${myContext.apiAddress}/update_need?id=${needId}&code=client_budget` : null}
                                        handleValue={(v: number) => setClientBudget(v)}
                                        withAsterisk={true}
                                    />
                                </Card>
                            </Card>
                        </Center>

                        <Divider label='Cahier des charges' labelPosition='center' mt="md"/>
                        {isOpen ?
                        <RichTextContainer
                            code='specifications'
                            value={specifications}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            handle={(s: string) => setSpecifications(s)}
                        />
                        :
                        <Card>
                            <div dangerouslySetInnerHTML={{__html: specifications}} />
                        </Card>
                        }

                        <Divider label='Autres informations' labelPosition='center' mt="md"/>
                        {isOpen ?
                        <RichTextContainer
                            code='notebook'
                            value={notebook}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            handle={(s: string) => setNotebook(s)}
                        />
                        :
                        <Card>
                            <div dangerouslySetInnerHTML={{__html: notebook}} />
                        </Card>
                        }

                    </Stack>

                </Tabs.Panel>
                <Tabs.Panel value="other" pt="xs">

                    <Stack spacing={0} style={{width: 'min(100vw, 660px)'}}>

                        <Divider label='Frais' labelPosition='center' mt="md"/>
                        <Text size='sm' color="dimmed">Exemples :</Text>
                        <Text>Les frais de mission du manager de transition, dont déplacement et hébergement, seront facturés au réel sur la base des justificatifs après accord du CLIENT.</Text>
                        <Text size='sm' color="dimmed">ou</Text>
                        <Text>Les frais de mission du manager de transition, dont déplacement et hébergement, seront facturés sur la base d'un forfait journalier de XXX (EN LETTRES) euros hors taxes, applicable pour chaque jour travaillé.</Text>
                        <TextareaContainer
                            code='mission_expenses'
                            label='Frais au réel ou frais au forfait ?'
                            defaultValue={missionExpenses}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setMissionExpenses(s)}
                        />

                        <Divider label='Autres données pour produire les contrats' labelPosition='center' mt="md"/>
                        <TextInputContainer
                            code='client_siret'
                            label='SIRET du client'
                            defaultValue={clientSiret}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setClientSiret(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='client_rcs'
                            label='RCS du client'
                            defaultValue={clientRcs || 'RCS de ?'}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setClientRcs(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='client_address'
                            label='Adresse du siège du client'
                            defaultValue={clientAddress}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setClientAddress(s)}
                            withAsterisk={true}
                        />
                        <Grid grow gutter="xs">
                            <Grid.Col span={6}>
                                <DatePickerContainer
                                    code='mission_date_start'
                                    label='Date de début de la mission'
                                    defaultValue={missionDateStart}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setMissionDateStart(s)}
                                    withAsterisk={true}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <DatePickerContainer
                                    code='mission_date_end'
                                    label='Date de fin de la mission'
                                    defaultValue={missionDateEnd}
                                    api={`${myContext.apiAddress}/update_need?id=${needId}`}
                                    isEdition={isOpen}
                                    handle={(s: string) => setMissionDateEnd(s)}
                                    withAsterisk={true}
                                />
                            </Grid.Col>
                        </Grid>
                        <NumberInputContainer
                            label='Nombre de jours prévisionnels de la mission'
                            min={0}
                            max={999}
                            step={10}
                            defaultValue={missionDaysNumber}
                            api={isOpen ? `${myContext.apiAddress}/update_need?id=${needId}&code=mission_days_number` : null}
                            handleValue={(v: number) => setMissionDaysNumber(v)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='mission_days_detail'
                            label='Décomposition mensuelle des jours travaillés'
                            defaultValue={missionDaysDetail}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setMissionDaysDetail(s)}
                            withAsterisk={true}
                        />

                        <Divider label='et fiche mission nécessaire aux contrats' labelPosition='center' mt="md"/>
                        {isOpen ?
                        <RichTextContainer
                            code='mission_fiche'
                            value={missionFiche}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            handle={(s: string) => setMissionFiche(s)}
                        />
                        :
                        <Card>
                            <div dangerouslySetInnerHTML={{__html: missionFiche}} />
                        </Card>
                        }

                        <Divider label='si le manager a sa propre société' labelPosition='center' mt="md"/>
                        <TextInputContainer
                            code='supplier_name'
                            label='Nom légal de la société du manager de transition'
                            defaultValue={supplierName}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setSupplierName(s)}
                            withAsterisk={true}
                        />
                        <Card mt={5} mb={3}>
                            <ShortListItemSelector
                                shortTitle='Forme juridique'
                                longTitle='Sélectionner la forme juridique du manager de transition'
                                items={[
                                    { code: 'AUTO', value: 'entrepreneur individuel' },
                                    { code: 'EIRL', value: 'entreprise individuelle à responsabilité limitée' },
                                    { code: 'SARL', value: 'société anonyme à responsabilité limitée' },
                                    { code: 'EURL', value: 'société unipersonnelle à responsabilité limitée' },
                                    { code: 'SA'  , value: 'société anonyme' },
                                    { code: 'SAS' , value: 'société par actions simplifiée' },
                                    { code: 'SASU', value: 'société par actions simplifiée unipersonnelle' },
                                ]}
                                currentValue={supplierLegalForm}
                                api={`update_need?id=${needId}&code=supplier_legal_form`}
                            />
                        </Card>
                        <TextInputContainer
                            code='supplier_capital_number'
                            label='Capital social de la société du manager'
                            defaultValue={supplierCapitalNumber}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setSupplierCapitalNumber(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='supplier_address'
                            label='Adresse du siège social de sa société'
                            defaultValue={supplierAddress}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setSupplierAddress(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='supplier_rcs'
                            label='RCS'
                            defaultValue={supplierRcs}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setSupplierRcs(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='supplier_siret'
                            label='SIRET'
                            defaultValue={supplierSiret}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setSupplierSiret(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='supplier_contact_name'
                            label='Prénom NOM (contact de la société du manager)'
                            defaultValue={supplierContactName}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setSupplierContactName(s)}
                            withAsterisk={true}
                        />
                        <TextInputContainer
                            code='supplier_contact_status'
                            label='Qualité du contact (président, etc.)'
                            defaultValue={supplierContactStatus}
                            api={`${myContext.apiAddress}/update_need?id=${needId}`}
                            isEdition={isOpen}
                            handle={(s: string) => setSupplierContactStatus(s)}
                            withAsterisk={true}
                        />

                    </Stack>

                </Tabs.Panel>
            </Tabs>
        </Center>
    )
}

export { Brief }
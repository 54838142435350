import { useContext, useState } from 'react';
import { ActionIcon, Button, Group, LoadingOverlay, Modal, Text, TextInput } from '@mantine/core';
import { IconLock } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface PropsInterface {
    id: number,
    label: string,
    handle: any,
}

const LockButton = ({id, label, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);

    const [opened, setOpened] = useState(false);
    const [working, setWorking] = useState(false);
    const [word, setWord] = useState('');

    const close = () => {
        setWorking(true);
        myContext.httpClient.get(`${myContext.apiAddress}/close_mission?id=${id}&value=1`).then((res: any) => {
            setWorking(false);
            if (res.data.status === true) {
                handle();
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        })
    }

    return (
        <>
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title="Clôture d'une mission"
        >
            <LoadingOverlay visible={working}/>
            <Text>Merci de confirmer la clôture de la mission :</Text>
            <Text>{label}</Text>
            <Text>en saisissant le mot CLOSE ci-dessous, puis d'appuyer sur "CLOSE"</Text>
            <TextInput value={word} onChange={(event) => setWord(event.target.value)} />
            <Group position="apart" mt="sm" mb="sm">
                <Button color="blue" variant="outline" onClick={() => {setWord(''); setOpened(false)}}>
                    annuler
                </Button>
                <Button color="blue" variant="outline" disabled={word !== 'CLOSE'} onClick={() => close()}>
                    CLOSE
                </Button>
            </Group>
        </Modal>
        <Group position="right">
            <ActionIcon color="blue" variant="outline" onClick={() => setOpened(true)} >
                <IconLock size={16} />
            </ActionIcon>
        </Group>
        </>
    )
}

export { LockButton }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Badge, Card, Center, Divider, Group, LoadingOverlay, Modal, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { IconBuildingFactory2, IconFileDescription, IconRefresh, IconReportSearch, IconTie, IconUser } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { DocumentContainer } from '../../cvtheque/DocumentContainer';
import { TrashButton } from '../../shared/TrashButton';

interface ItemInterface {
    cv_id: number,
    name: string,
    jobs: string[],
    sectors: string[],
    email: string,
    phone: string,
    inserted_date: string,
}

interface QueryInterface {
    entries: number,
    query: string,
    created_date: string,
    created_by: string,
}

interface PropsInterface {
    needId: number,
    isOpen: boolean,
}

const Basket = ({needId, isOpen}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [items, setItems] = useState<ItemInterface[]>([]);
    const [queries, setQueries] = useState<QueryInterface[]>([]);
    const [working, setWorking] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [loaded, setLoaded] = useState(false);

    const [docId,setDocId] = useState(-1);
    const [displayDoc, setDisplayDoc] = useState(false);
    const [displayQueries, setDisplayQueries] = useState(false);
    
    // Chargement
    useEffect(() => {
        if (loaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_process_need?need_id=${needId}&what=basket_items`).then((res:any) => {
            setWorking(false);
            setLoaded(true);
            if (res.data.status === true) {
                setItems(res.data.data || []);
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
        myContext.httpClient.get(`${myContext.apiAddress}/get_process_need?need_id=${needId}&what=basket_queries`).then((res:any) => {
            if (res.data.status === true) {
                setQueries(res.data.data || []);
            } else {
                setError(res.data.message || 'unknown error');
            }
        });
    }, [loaded]); // eslint-disable-line


    if (error !== null) {
        return (
            <Group>
                <Text color="red">{error}</Text>
            </Group>
        )
    }

    return (
        <>
        <Modal
            opened={displayDoc}
            onClose={() => { setDisplayDoc(false); setDocId(-1)}}
            title={`Curriculum vitae ${docId}`}
            fullScreen
        >
            <DocumentContainer
                documentId={docId}
                isEdition={false}
            />
        </Modal>
        <Modal
            opened={displayQueries}
            onClose={() => setDisplayQueries(false)}
            title='Requêtes utilisées pour alimenter ce panier'
        >
            {queries.map((q: QueryInterface, i: number) => 
            <Card key={`query-${i}`}>
                <Group>
                    <Badge>{q.entries}</Badge>
                    <Text>{q.query}</Text>    
                </Group>
                <Group position='apart'>
                    <Text color="dimmed" size='sm'>{q.created_by}</Text>
                    <Text color="dimmed" size='sm'>{q.created_date}</Text>
                </Group>
            </Card>
            )}
        </Modal>
        <Group position="apart" pt="xs">
            <Badge>{items.length} managers</Badge>
            <Group>
                <ActionIcon color="blue" variant="outline" onClick={() => setDisplayQueries(true)}>
                    <IconReportSearch size={16} />
                </ActionIcon>
                <ActionIcon color="blue" variant="outline" onClick={() => { setItems([]); setLoaded(false) }}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
        </Group>
        {(!working && items.length === 0) ?
        <Group pt="lg">
            <Text>Aucun manager de transition rattaché à ce besoin.</Text>
        </Group>
        :
        <Card>
            <LoadingOverlay visible={working} />
            <ScrollArea style={{height: 'calc(100vh - 250px)'}}>
                <Table highlightOnHover>
                    <tbody>
                        {items.map((item, idx: number) => (
                            <tr key={`basket-item-${idx}`}>
                                <td>
                                    <Stack spacing='xs'>
                                        <Group position="apart">
                                            <Group>
                                                <IconUser size={16} />
                                                <Text>{item.name}</Text>
                                                <Text color="dimmed">{item.phone}</Text>
                                                <Text color="dimmed">{item.email}</Text>
                                            </Group>
                                            <Group>
                                                {isOpen && 
                                                <TrashButton 
                                                    label={`Retirer ${item.name} de ce panier ?`}
                                                    api='attach_process_need'
                                                    params={`need_id=${needId}&ids=-${item.cv_id}`}
                                                    handle={() => setItems(items.filter((i) => i.cv_id !== item.cv_id))}
                                                />
                                                }
                                                <ActionIcon color="blue" variant="outline" onClick={() => { setDocId(item.cv_id); setDisplayDoc(true) }}>
                                                    <IconFileDescription size={16} />
                                                </ActionIcon>
                                            </Group>
                                        </Group>
                                        <Group spacing="xs">
                                            <IconTie size={16} />
                                            {item.jobs.map((j,idxj) => (
                                                <Badge key={`item-${idx}-job-${idxj}`}>{j}</Badge>
                                            ))}
                                        </Group>
                                        <Group spacing="xs">
                                            <IconBuildingFactory2 size={16} />
                                            {item.sectors.map((s,idxs) => (
                                                <Badge key={`item-${idx}-sector-${idxs}`}>{s}</Badge>
                                            ))}
                                        </Group>
                                        <Text size='xs' color='dimmed'>Ajouté le {item.inserted_date}</Text>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {isOpen && 
                <>
                <Divider label='Suppression du contenu du panier' labelPosition='center' mt='lg' mb='lg' />
                <Center>
                    <TrashButton 
                        label={`Vider ce panier ?`}
                        api='update_process_need'
                        params={`need_id=${needId}&what=empty_basket`}
                        handle={() => setItems([])}
                    />
                </Center>
                </>
                }
            </ScrollArea>
        </Card>
        }
        </>
    )
}

export { Basket };
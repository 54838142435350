import { useContext, useState } from 'react';
import { LoadingOverlay, Slider } from '@mantine/core';
import AppContext from '../AppContext';
import { AlertNotification } from '../AlertNotification';

interface ItemInterface {
    value: number,
    label: string,
}

interface PropsInterface {
    marks: ItemInterface[],
    step: number,
    defaultValue: number,
    api: string | null,
    handleValue: any,
}

const SliderContainer = ({marks, step, defaultValue, api, handleValue}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [value, setValue] = useState(defaultValue);
    const [working, setWorking] = useState(false);

    const updateValue = (v: number) => {
        if (api === null) return;
        setWorking(true);
        myContext.httpClient.get(`${api}&value=${v}`).then((res: any) => {
            setWorking(false);
            if (res.data.status === true) {
                setValue(v);
                if (handleValue !== null) handleValue(v);
            } else {
                AlertNotification({ message: res.data.message || 'unknown error' });
            }
        });
    }

    return (
        <div>
            <LoadingOverlay visible={working} />
            <Slider
                value={value} 
                onChange={(v: number) => {
                    if (api === null) {
                        setValue(v);
                        if (handleValue !== null) {
                            handleValue(v);
                        }
                    } else {
                        updateValue(v) 
                    }
                }}
                pt="md"
                pb="lg"
                label={(val) => marks.find((mark) => mark.value === val)?.label}
                defaultValue={defaultValue}
                step={step}
                marks={marks}
                labelTransition="fade"
                size={2}
                styles={(theme) => ({
                    track: {
                        backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.blue[1],
                    },
                    mark: {
                        width: 6,
                        height: 6,
                        borderRadius: 6,
                        transform: 'translateX(-3px) translateY(-2px)',
                        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.blue[1],
                    },
                    markFilled: {
                        borderColor: theme.colors.blue[6],
                    },
                    markLabel: { fontSize: theme.fontSizes.xs, marginBottom: 5, marginTop: 0 },
                    thumb: {
                        height: 16,
                        width: 16,
                        backgroundColor: theme.white,
                        borderWidth: 1,
                        boxShadow: theme.shadows.sm,
                    },
                })}
            />
        </div>
    )
}

export { SliderContainer }
import { useState } from 'react';
import { ActionIcon, Button, Card, Center, Group, Modal, NumberInput, Text } from '@mantine/core';
import { IconArrowBarToLeft, IconArrowBarToRight, IconChevronLeft, IconChevronRight } from '@tabler/icons';

interface PropsInterface {
    page: number,    // on compte à partir de 0
    pages: number,   // nombre de pages au total
    change: any,
}

const Pagination = ({page, pages, change}: PropsInterface) => {

    const [opened, setOpened] = useState(false);
    const [value, setValue] = useState<number>(page);

    if (pages === 0) {
        return (
            <Text size='sm'>page 1</Text>
        )
    } else {
        return (
            <>
            <Modal
                opened={opened} 
                onClose={() => { setOpened(false)}}
                title='Set a page number to reach'
            >
                <Card>
                    <NumberInput
                        value={value}
                        onChange={(v) => { if (v !== undefined) {
                            if (v < 1) {
                                setValue(0);
                            } else if (v > pages) {
                                setValue(pages);
                            } else {
                                setValue(v);
                            }
                        }}}
                        min={0} max={pages}
                    />
                    <Center>
                        <Button color='blue' variant='outline' onClick={() => { 
                            setOpened(false);
                            change(value-1);
                        }}>
                            submit
                        </Button>
                    </Center>
                </Card>
            </Modal>
            <Group>
                {page > 0 &&
                <ActionIcon size='sm' color='blue' variant='outline' 
                    onClick={() => { change(0); }}
                >
                    <IconArrowBarToLeft size={12} />    
                </ActionIcon>
                }

                {page > 0 &&
                <ActionIcon size='sm' color='blue' variant='outline' onClick={() => {
                    change(page-1);
                }}>
                    <IconChevronLeft size={12} />
                </ActionIcon>
                }

                <Group spacing={5}>
                    <Text size='sm'>page</Text>
                    <ActionIcon size="sm" color='blue' variant='outline' onClick={() => setOpened(true)}><Text size='sm'>{page + 1}</Text></ActionIcon>
                    <Text size='sm'>/ {pages}</Text>
                </Group>
                
                {page+1 < pages &&
                <ActionIcon size='sm' color='blue' variant='outline' onClick={() => {
                    change(page+1);
                }}>
                    <IconChevronRight size={12} />
                </ActionIcon>
                }

                {page + 1 < pages &&
                <ActionIcon size='sm' color='blue' variant='outline' 
                    onClick={() => { change(pages-1); }}
                >
                    <IconArrowBarToRight size={12} />
                </ActionIcon>
                }
            </Group>
            </>
        )
    }
}

export { Pagination }
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Box, Group, Loader, ScrollArea, Tabs } from '@mantine/core';
import { IconChartBar, IconIdBadge2, IconSearch, IconBasket, IconQuestionMark, IconReport, IconFiles, IconMessages } from '@tabler/icons';

import { AnalyzeCVs } from './analyze_cvs';
import { Basket } from './basket';
import { FormCreator } from '../../shared/FormCreator';
import { HeaderHunt } from './header_hunt';
import { TitleContainer } from '../../shared/TitleContainer';
import { Statistics } from './statistics';
import { Process } from './process';
import { Affect } from './affect';

type ParamInterface = {
    paramId: string,
}

const ManageHunt = () => {

    const { paramId } = useParams<ParamInterface>();
    const huntId: number = parseInt(paramId === undefined ? '0' : paramId);
    const [projectLabel, setProjectLabel] = useState<null | string>(null);
    const [affectedAt,setAffectedAt] = useState<undefined | string | null>(undefined);

    const [activeTab, setActiveTab] = useState<string | null>('header');
    const [isHeaderOpen, setIsHeaderOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isBasketOpen, setIsBasketOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isProcessOpen, setIsProcessOpen] = useState(false);
    const [isDocsOpen, setIsDocsOpen] = useState(false);
    const [isMessagesOpen, setIsMessagesOpen] = useState(false);
    const [isStatisticsOpen, setIsStatisticsOpen] = useState(false);

    useEffect(() => {
        switch(activeTab) {
            case 'header':
                if (!isHeaderOpen) setIsHeaderOpen(true);
                break;
            case 'search':
                if (!isSearchOpen) setIsSearchOpen(true);
                break;
            case 'basket':
                if (!isBasketOpen) setIsBasketOpen(true);
                break;
            case 'form':
                if (!isFormOpen) setIsFormOpen(true);
                break;
            case 'statistics':
                if (!isStatisticsOpen) setIsStatisticsOpen(true);
                break;
            case 'process':
                if (!isProcessOpen) setIsProcessOpen(true);
                break;
            case 'docs':
                if (!isDocsOpen) setIsDocsOpen(true);
                break;
            case 'messages':
                if (!isMessagesOpen) setIsMessagesOpen(true);
                break;
        }
    }, [activeTab]); // eslint-disable-line

    // rendu
    return (
        <Box>
            <Group position='apart'>
                <TitleContainer>
                    {projectLabel === null ? <Loader /> : projectLabel}
                </TitleContainer>
                {affectedAt !== undefined &&
                <Affect 
                    hunt_id={huntId}
                    label={null}
                    is_open={true}
                    value={affectedAt}
                />
                }
            </Group>
            <Tabs radius="xs" value={activeTab} onTabChange={setActiveTab}>
                <Tabs.List>
                    <Tabs.Tab value="header" icon={<IconIdBadge2 size={14} />}>En-tête</Tabs.Tab>
                    <Tabs.Tab value="search" icon={<IconSearch size={14} />}>Recherche</Tabs.Tab>
                    <Tabs.Tab value="basket" icon={<IconBasket size={14} />}>Panier</Tabs.Tab>
                    <Tabs.Tab value="form" icon={<IconQuestionMark size={14} />}>Questionnaire</Tabs.Tab>
                    <Tabs.Tab value="statistics" icon={<IconChartBar size={14} />}>Statistiques</Tabs.Tab>
                    <Tabs.Tab value="process" icon={<IconReport size={14} />}>Analyse</Tabs.Tab>
                    <Tabs.Tab value="docs" icon={<IconFiles size={14} />}>Bibliothèque</Tabs.Tab>
                    <Tabs.Tab value="messages" icon={<IconMessages size={14} />}>Journal</Tabs.Tab>
                </Tabs.List>

                <ScrollArea style={{height: 'calc(100vh - 140px)'}}>
            
                    <Tabs.Panel value="header">
                        {isHeaderOpen &&
                        <HeaderHunt huntId={huntId} handle={(field: string, value: string) => {
                            if (field === 'project_label') {
                                setProjectLabel(value);
                            } else if (field === 'affected_at') {
                                setAffectedAt(value);
                            }
                        }} />
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="search">
                        {isSearchOpen &&
                        <AnalyzeCVs huntId={huntId} />
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="basket">
                        {isBasketOpen &&
                        <Basket huntId={huntId} isOpen={true} />
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="form">
                        {isFormOpen &&
                        <FormCreator 
                            nature='hunt'
                            id={huntId}
                            isOpen={true}
                            initialQuestions={[
                                { position: 1, nature: 'T', required: true, code: '_title', label: 'Poste de ...', param: null },
                                { position: 2, nature: 'P', required: true, code: '_phone', label: 'Votre numéro de téléphone portable est le :', param: null },
                                { position: 3, nature: 'E', required: true, code: '_email', label: 'Votre adresse email est :', param: null },
                                { position: 4, nature: 'N', required: true, code: '_remu' , label: 'A quel niveau de rémunération annuelle brute (fixe et variable) aspireriez-vous ?', param: null}
                            ]}
                        />
                        }
                    </Tabs.Panel>
                    
                    <Tabs.Panel value="statistics">
                        {isStatisticsOpen &&
                        <Statistics id={huntId} />
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="process">
                        {isProcessOpen &&
                        <Process hunt_id={huntId} />
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="docs">
                        {isDocsOpen &&
                        <>??</>
                        }
                    </Tabs.Panel>

                    <Tabs.Panel value="messages">
                        {isMessagesOpen &&
                        <>??</>
                        }
                    </Tabs.Panel>

                </ScrollArea>
            </Tabs>
        </Box>
    )
}

export { ManageHunt }
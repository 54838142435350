import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Center, ColorSwatch, Group, LoadingOverlay, RingProgress, ScrollArea, Table, Text } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconCopy, IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';
import { AlertNotification } from '../../shared/AlertNotification';
import { InfoNotification } from '../../shared/InfoNotification';

interface DayInterface {
    y: number,
    m: number,
    d: number,
    l: number, // 0: Lundi
}
interface MonthInterface {
    y: number,
    m: number, // numéro du mois
    n: number, // nombre de jours
}
interface ValueInterface {
    y: number,
    m: number,
    d: number,
    situation: number,
    validated: boolean,
}
interface UserInterface {
    user_key: string,
    firstname: string,
    lastname: string,
    values: ValueInterface[],
}
interface RowInterface {
    user_key: string,
    firstname: string,
    lastname: string,
    values: number[],
    validated: boolean[],
}

const PlanningTeam = () => {

    const myContext = useContext(AppContext);
    const [ myMonth, setMyMonth ] = useState((new Date()).getMonth() + 1);
    const [ myYear, setMyYear ] = useState((new Date()).getFullYear());
    const [ myCalendar, setMyCalendar ] = useState<RowInterface[] | null>(null);
    const [ myDays, setMyDays ] = useState<DayInterface[]>([]);
    const [ myMonths, setMyMonths ] = useState<MonthInterface[]>([]);
    const [ working, setWorking ] = useState(false);
    
    const dayToLabel = [ 'D', 'L', 'M', 'M', 'J', 'V', 'S' ];
    const monthToLabel = [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre '];

    const resetCalendar = (step: number) => {
        if (step !== -1 && step !== 0 && step !== 1) return;
        setWorking(true);
        let y: number = myYear;
        let m: number = myMonth;
        m += step;
        if (m > 12) {
            y += 1;
            m= 1;
        } else if (m < 1) {
            m = 12;
            y -= 1;
        }
        setMyYear(y);
        setMyMonth(m);
        const F = new FormData();
        F.append('y', y + '');
        F.append('m', m + '');
        F.append('p', '2');
        F.append('action', 'planning');
        const api = `${myContext.apiAddress}/hr_request_account`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                const data: UserInterface[] = res.data.data;
                let d1: Date = new Date(y, m-1, 1);
                d1.setMonth(d1.getMonth() + 2); // premier jour du deuxième mois du calendrier
                const days: number[] = [];
                let d: Date = new Date(y, m-1, 1); // premier jour du calendrier
                const mydays: DayInterface[] = [];
                while (d < d1) {
                    days.push(10000*d.getFullYear()+100*(d.getMonth()+1)+d.getDate());
                    mydays.push({y: d.getFullYear(), m: d.getMonth()+1, d: d.getDate(), l: d.getDay()});
                    d.setDate(d.getDate()+1);
                }
                let i: number = -1;
                let j: number = -1;
                const mymonths: MonthInterface[] = [];
                for (const e of mydays) {
                    if (e.m !== j) {
                        mymonths.push({ y: e.y, m: e.m, n: 0});
                        i++;
                        j = e.m;
                    }
                    mymonths[i].n += 1;
                }
                i = -1;
                const rows: RowInterface[] = [];
                for (const user of data) {
                    i++;
                    rows.push({
                        user_key: user.user_key,
                        firstname: user.firstname,
                        lastname: user.lastname,
                        values: Array(days.length).fill(0),
                        validated: Array(days.length).fill(false),
                    });
                    for (const v of user.values) {
                        const p = days.indexOf(10000 * v.y + 100 * v.m + v.d);
                        if (p !== -1) {
                            rows[i].values[p] = v.situation;
                            rows[i].validated[p] = v.validated;
                        }
                    }
                }
                // on sauvegarde
                setMyMonths(mymonths);
                setMyDays(mydays);
                setMyCalendar(rows);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    useEffect(() => {
        resetCalendar(0);
    }, []); // eslint-disable-line

    const copy = () => {
        setWorking(true);
        const api = `${myContext.apiAddress}/hr_request_account?action=export_day_off&y=${myYear}&m=${myMonth}`;
        myContext.httpClient.get(api).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                    navigator.clipboard.writeText(res.data.data);
                    InfoNotification({message: 'Données copiées dans le presse papier.'});
                } else {
                    AlertNotification({message: 'The clipboard API is not available'});
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <>
        <Group position="apart">
            <TitleContainer>
                Calendrier des absences
            </TitleContainer>
            <Group>
                <ActionIcon color='blue' variant='outline' onClick={() => copy()}>
                    <IconCopy size={16} />
                </ActionIcon>
                <ActionIcon color='blue' variant='outline' onClick={() => resetCalendar(-1)}>
                    <IconArrowLeft size={16} />
                </ActionIcon>
                <ActionIcon color='blue' variant='outline' onClick={() => resetCalendar(0)}>
                    <IconRefresh size={16} />
                </ActionIcon>
                <ActionIcon color='blue' variant='outline' onClick={() => resetCalendar(1)}>
                    <IconArrowRight size={16} />
                </ActionIcon>
            </Group>
        </Group>
        {myCalendar !== null &&
        <Group position="center">
            <ScrollArea style={{height: 'calc(100vh - 150px)'}}>
                <LoadingOverlay visible={working} />
                <Table withColumnBorders highlightOnHover
                    horizontalSpacing={0} verticalSpacing={0}
                    style={{width: `${100+myDays.length*25}px`}}
                >
                    <thead>
                        <tr>
                            <th style={{width: '100px'}}></th>
                            {myMonths.map((m) => 
                            <th key={`th-0-${m.m}`} colSpan={m.n}>
                                {monthToLabel[m.m-1]} {m.y}
                            </th>)}
                        </tr>
                        <tr>
                            <th></th>
                            {myDays.map((d) => 
                            <th key={`th-1-${d.y}-${d.m}-${d.d}`}>
                                <Text align='center'>{d.d}</Text>
                            </th>
                            )}
                        </tr>
                        <tr>
                            <th></th>
                            {myDays.map((d) => 
                            <th key={`th-2-${d.y}-${d.m}-${d.d}`}>
                                <Text align='center'>{dayToLabel[d.l]}</Text>
                            </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {myCalendar.map((u) => 
                        <tr key={`user-${u.user_key}`}>
                            <td
                                style={{position: 'sticky', left: '0'}}
                            >
                                {u.firstname} {u.lastname.substring(0,1)}.
                            </td>
                            {u.values.map((v: number,i: number) => 
                            <td key={`tr-${u.user_key}-${i}`}>
                                {v === 0 ?
                                <>
                                {(myDays[i].l === 0 || myDays[i].l === 6) &&
                                <Center>
                                    <ColorSwatch color='gray' size={12}/>
                                </Center>
                                }
                                </>
                                :
                                <Center>
                                    <RingProgress 
                                        size={20} thickness={5} 
                                        sections={v === 3 ? [{value: 100, color: u.validated[i] ? 'teal' : 'red'}] : 
                                            ((v === 2) ? [{value: 50, color: u.validated[i] ? 'teal' : 'red'}, {value: 50, color:'white'}] 
                                            : [{value: 50, color:'white'}, {value: 50, color: u.validated[i] ? 'teal' : 'red'}])
                                        }
                                    />
                                </Center>
                                }                                
                            </td>
                            )}
                        </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>
        </Group>
        }
        </>
    )

}   

export { PlanningTeam }


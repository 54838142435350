import { useContext, useEffect, useReducer, useRef, useState, lazy, Suspense } from 'react';
import { ActionIcon, Badge, Card, Grid, Group, Loader, LoadingOverlay, NativeSelect, Text } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import { AlertNotification } from '../../shared/AlertNotification';
import { TitleContainer } from '../../shared/TitleContainer';
import AppContext from '../../shared/AppContext';
import { DateInputButton } from '../../shared/DateInputButton';
const ChartContainer = lazy(() => import('../../shared/ChartContainer').then(({ChartContainer}) => ({default: ChartContainer})));

const sumValues = (data: any) => {
    let s: number = 0;
    for (let i=0;i<data.length;i++) {
        s += data[i]['value'];
    }
    return s;
}

const chartsReducer = (state: any, payload: any) => {
    switch(payload.type) {
        case 'setActivity':
            if (payload.data === null) {
                return {
                    ...state,
                    'activity': { isLoaded: true, option: null },
                }
            } else {
                return {
                    ...state,
                    'activity': { 
                        isLoaded: true,
                        filters: payload.data.filters,
                        option: {
                            style: {
                                height: '500px',
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                  type: 'shadow'
                                }
                            },
                            legend: {},
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                                xAxis: {
                                type: 'value'
                            },
                            yAxis: {
                                type: 'category',
                                data: payload.data.categories
                            },
                            series: payload.data.series.map((serie: any) => {
                                return {
                                    name: serie.name,
                                    type: 'bar',
                                    //stack: 'total',
                                    label: {
                                        show: true
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: serie.data
                                }
                            }),
                        }
                    }
                }
            }
        case 'setActivityOverTime':
            if (payload.data === null) {
                return {
                    ...state,
                    'activityOverTime': { isLoaded: true, option: null },
                }
            } else {
                return {
                    ...state,
                    'activityOverTime': { 
                        isLoaded: true,
                        filters: payload.data.filters,
                        option: {
                            style: {
                                height: '490px',
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {},
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: payload.data.categories
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: payload.data.series.map((serie: any) => {
                                return {
                                    name: serie.name,
                                    type: 'line',
                                    //stack: 'total',
                                    label: {
                                        show: true
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: serie.data
                                }
                            }),
                        }
                    }
                }
            }
        case 'setSourceOverTime':
            if (payload.data === null) {
                return {
                    ...state,
                    'sourceOverTime': { isLoaded: true, option: null },
                }
            } else {
                return {
                    ...state,
                    'sourceOverTime': { 
                        isLoaded: true,
                        filters: payload.data.filters,
                        option: {
                            style: {
                                height: '490px',
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {},
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: payload.data.categories
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: payload.data.series.map((serie: any) => {
                                return {
                                    name: serie.name,
                                    type: 'bar',
                                    stack: 'total',
                                    label: {
                                        show: true
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: serie.data
                                }
                            }),
                        }
                    }
                }
            }
        case 'setStockDuplicates':
            if (payload.data === null) {
                return {
                    ...state,
                    'stockDuplicates': { isLoaded: true, option: null },
                }
            } else {
                return {
                    ...state,
                    'stockDuplicates': { 
                        isLoaded: true,
                        option: {
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                top: '5%',
                                left: 'center'
                            },
                            series: [
                                {
                                    name: 'Doublons',
                                    type: 'pie',
                                    radius: ['40%', '70%'],
                                    avoidLabelOverlap: false,
                                    itemStyle: {
                                        borderRadius: 10,
                                        borderColor: '#fff',
                                        borderWidth: 2
                                    },
                                    label: {
                                        formatter: '{c}',
                                        position: 'inside'
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '20',
                                            fontWeight: 'bold'
                                        }
                                    },
                                    labelLine: {
                                        show: false
                                    },
                                    data: payload.data.series // [ {name: ..., value: ...}, ... ]
                                }
                            ]
                        }
                    },
                }
            }
        case 'setStockGlobal':
            if (payload.data === null) {
                return {
                    ...state,
                    'stockGlobal': { isLoaded: true, option: null },
                }
            } else {
                return {
                    ...state,
                    'stockGlobal': { 
                        isLoaded: true,
                        option: {
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                top: '5%',
                                left: 'center'
                            },
                            series: [
                                {
                                    name: 'Stock global',
                                    type: 'pie',
                                    radius: ['40%', '70%'],
                                    avoidLabelOverlap: false,
                                    itemStyle: {
                                        borderRadius: 10,
                                        borderColor: '#fff',
                                        borderWidth: 2
                                    },
                                    label: {
                                        formatter: '{c}',
                                        position: 'inside'
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '20',
                                            fontWeight: 'bold'
                                        }
                                    },
                                    labelLine: {
                                        show: false
                                    },
                                    data: payload.data.series // [ {name: ..., value: ...}, ... ]
                                }
                            ]
                        }
                    },
                }
            }
        case 'setStockIncoming':
            if (payload.data === null) {
                return {
                    ...state,
                    'stockIncoming': { isLoaded: true, option: null },
                }
            } else {
                return {
                    ...state,
                    'stockIncoming': { 
                        isLoaded: true,
                        option: {
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                top: '5%',
                                left: 'center'
                            },
                            series: [
                                {
                                    name: 'Stock global',
                                    type: 'pie',
                                    radius: ['40%', '70%'],
                                    avoidLabelOverlap: false,
                                    itemStyle: {
                                        borderRadius: 10,
                                        borderColor: '#fff',
                                        borderWidth: 2
                                    },
                                    label: {
                                        formatter: '{c}',
                                        position: 'inside'
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '20',
                                            fontWeight: 'bold'
                                        }
                                    },
                                    labelLine: {
                                        show: false
                                    },
                                    data: payload.data.series // [ {name: ..., value: ...}, ... ]
                                }
                            ]
                        }
                    },
                }
            }
        default:
            return {
                'stockDuplicates': { isLoaded: false, option: null},
                'stockGlobal': { isLoaded: false, option: null},
                'stockIncoming': { isLoaded: false, option: null},
                'activity': { isLoaded: false, option: null},
                'activityOverTime': { isLoaded: false, option: null},
            }
    }

}

const DashboardMainCV = () => {

    const myContext = useContext(AppContext);

    const [charts,dispatchCharts] = useReducer(chartsReducer, {
        'stockDuplicates':  { isLoaded: false, option: null},
        'stockGlobal':      { isLoaded: false, option: null},
        'stockIncoming':    { isLoaded: false, option: null},
        'activity':         { isLoaded: false, option: null},
        'activityOverTime': { isLoaded: false, option: null},
        'sourceOverTime':   { isLoaded: false, option: null},
    });
    const [dates, setDates] = useState<string[]>([]);
    const oldDates = useRef('[]');
    const [statusFrom, setStatusFrom] = useState('waiting-for-control');

    // Données sur le stock global des CV :
    useEffect(() => {
        if (charts.stockGlobal.isLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_dataset_cv?dataset=stock_global`).then((res:any) => {
            if (res.data.status === true) {
                dispatchCharts({type: 'setStockGlobal', data: res.data.data});
            } else {
                dispatchCharts({type: 'setStockGlobal', data: null});
                AlertNotification({message: res.data.message || 'error in uploading "stock global"'});
            }
        })
    }, [charts.stockGlobal.isLoaded]); // eslint-disable-line

    // Données pour le graphique les CV entrants :
    useEffect(() => {
        if (charts.stockIncoming.isLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_dataset_cv?dataset=stock_incoming`).then((res:any) => {
            if (res.data.status === true) {
                dispatchCharts({type: 'setStockIncoming', data: res.data.data});
            } else {
                dispatchCharts({type: 'setStockIncoming', data: null});
                AlertNotification({message: res.data.message || 'error in uploading "stock incoming"'});
            }
        })
    }, [charts.stockIncoming.isLoaded]) // eslint-disable-line

    // Données pour le graphique sur les doublons :
    useEffect(() => {
        if (charts.stockDuplicates.isLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_dataset_cv?dataset=stock_duplicates`).then((res:any) => {
            if (res.data.status === true) {
                dispatchCharts({type: 'setStockDuplicates', data: res.data.data});
            } else {
                dispatchCharts({type: 'setStockDuplicates', data: null});
                AlertNotification({message: res.data.message || 'error in uploading "stock duplicates"'});
            }
        })
    }, [charts.stockDuplicates.isLoaded]); // eslint-disable-line

    // Données pour le graphique sur l'activité sur une période :
    useEffect(() => {
        if (charts.activity.isLoaded && JSON.stringify(dates) === oldDates.current) return;
        const F = new FormData();
        if (dates.length === 2) {
            oldDates.current = JSON.stringify(dates);
            F.append('start_date', dates[0]);
            F.append('end_date', dates[1]);
        }
        F.append('status_from', 'waiting-for-control');
        myContext.httpClient.post(`${myContext.apiAddress}/get_dataset_cv?dataset=activity`, F).then((res:any) => {
            if (res.data.status === true) {
                dispatchCharts({type: 'setActivity', data: res.data.data});
            } else {
                dispatchCharts({type: 'setActivity', data: null});
                AlertNotification({message: res.data.message || 'error in uploading "activity"'});
            }
        })
    }, [charts.activity.isLoaded,dates]) // eslint-disable-line

    // Données pour le graphique sur l'activité mensuelle sur une période :
    useEffect(() => {
        const F = new FormData();
        if (dates.length === 2) {
            oldDates.current = JSON.stringify(dates);
            F.append('start_date', dates[0]);
            F.append('end_date', dates[1]);
        }
        F.append('status_from', statusFrom);
        myContext.httpClient.post(`${myContext.apiAddress}/get_dataset_cv?dataset=activity_over_time`, F).then((res:any) => {
            if (res.data.status === true) {
                dispatchCharts({type: 'setActivityOverTime', data: res.data.data});
            } else {
                dispatchCharts({type: 'setActivityOverTime', data: null});
                AlertNotification({message: res.data.message || 'error in uploading "activity_over_time"'});
            }
        })
    }, [charts.activityOverTime.isLoaded,dates,statusFrom]) // eslint-disable-line

    // Données pour le graphique sur l'origine des CV provenant de snr-digital.com :
    useEffect(() => {
        const F = new FormData();
        if (dates.length === 2) {
            oldDates.current = JSON.stringify(dates);
            F.append('start_date', dates[0]);
            F.append('end_date', dates[1]);
        }
        myContext.httpClient.post(`${myContext.apiAddress}/get_dataset_cv?dataset=source_over_time`, F).then((res:any) => {
            if (res.data.status === true) {
                dispatchCharts({type: 'setSourceOverTime', data: res.data.data});
            } else {
                dispatchCharts({type: 'setSourceOverTime', data: null});
                AlertNotification({message: res.data.message || 'error in uploading "source_over_time"'});
            }
        })
    }, [charts.sourceOverTime.isLoaded,dates]) // eslint-disable-line

    // Mise à jour des dates :
    const updateDates = (value1: string, value2: string) => {
        setDates([value1, value2]);
    }

    // Affichage du composant graphique :
    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Tableau de bord global sur le sourcing
            </TitleContainer>
            <ActionIcon color='blue' variant='outline' onClick={() => dispatchCharts({type: 'reset'})}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        <Grid justify="center">
            <Grid.Col xs={12} sm={6} lg={4}>
                <Card withBorder>
                    <LoadingOverlay visible={!charts.stockDuplicates.isLoaded} overlayBlur={2} />
                    {(charts.stockDuplicates.isLoaded && charts.stockDuplicates.option !== null) &&
                    <>
                    <Group position="apart">
                        <Text>Doublons</Text>
                        <Badge color="teal">{sumValues(charts.stockDuplicates.option.series[0].data)}</Badge>
                    </Group>
                    <Text color="dimmed" size="xs">par rapport à l'email ou le nom</Text>
                    <Suspense fallback={<Loader/>}>
                        <ChartContainer option={charts.stockDuplicates.option} />
                    </Suspense>
                    </>}
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Card withBorder>
                    <LoadingOverlay visible={!charts.stockIncoming.isLoaded} overlayBlur={2} />
                    {(charts.stockIncoming.isLoaded && charts.stockIncoming.option !== null) &&
                    <>
                    <Group position="apart">
                        <Text>Curriculum vitae en cours</Text>
                        <Badge color="teal">{sumValues(charts.stockIncoming.option.series[0].data)}</Badge>
                    </Group>
                    <Text color="dimmed" size="xs">avec doublons</Text>
                    <Suspense fallback={<Loader/>}>
                        <ChartContainer option={charts.stockIncoming.option} />
                    </Suspense>
                    </>}
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Card withBorder>
                    <LoadingOverlay visible={!charts.stockGlobal.isLoaded} overlayBlur={2} />
                    {(charts.stockGlobal.isLoaded && charts.stockGlobal.option !== null) &&
                    <>
                    <Group position="apart">
                        <Text>Curriculum vitae validés</Text>
                        <Badge color="teal">{sumValues(charts.stockGlobal.option.series[0].data)}</Badge>
                    </Group>
                    <Text color="dimmed" size="xs">avec doublons</Text>
                    <Suspense fallback={<Loader/>}>
                        <ChartContainer option={charts.stockGlobal.option} />
                    </Suspense>
                    </>}
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} lg={6}>
                <Card withBorder>
                    <LoadingOverlay visible={!charts.activity.isLoaded} overlayBlur={2} />
                    {(charts.activity.isLoaded && charts.activity.option !== null) &&
                    <>
                    <Group position="apart">
                        <Text>Activité sur une période donnée</Text>
                        <Group>
                            <Badge>
                                Du {charts.activity.filters.startDate} au {charts.activity.filters.endDate}
                            </Badge>
                            <DateInputButton 
                                label1='Date de début (comprise)'
                                label2='Date de fin (comprise)'
                                handle={updateDates}
                            />
                        </Group>
                    </Group>
                    <Text color="dimmed" size="xs">avec doublons</Text>
                    <Suspense fallback={<Loader/>}>
                        <ChartContainer option={charts.activity.option} />
                    </Suspense>
                    </>}
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} lg={6}>
                <Card withBorder>
                    <LoadingOverlay visible={!charts.activityOverTime.isLoaded} overlayBlur={2} />
                    {(charts.activityOverTime.isLoaded && charts.activityOverTime.option !== null) &&
                    <>
                    <Group position="apart">
                        <Text>Activité sur un pas mensuel</Text>
                        <NativeSelect
                            value={statusFrom}
                            onChange={(event) => setStatusFrom(event.currentTarget.value)}
                            data={[
                                {value: 'waiting-for-control', label: 'CV traités'},
                                {value: 'waiting-for-validation', label: 'CV contrôlés'},
                                {value: 'validated', label: 'CV validés'},
                            ]}

                        />
                    </Group>
                    <Text color="dimmed" size="xs">avec doublons</Text>
                    <Suspense fallback={<Loader/>}>
                        <ChartContainer option={charts.activityOverTime.option} />
                    </Suspense>
                    </>}
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} lg={6}>
                <Card withBorder>
                    <LoadingOverlay visible={!charts.sourceOverTime.isLoaded} overlayBlur={2} />
                    {(charts.sourceOverTime.isLoaded && charts.sourceOverTime.option !== null) &&
                    <>
                    <Text>Origine des CV en provenance de snr-digital.com</Text>
                    <Text color="dimmed" size="xs">avec doublons</Text>
                    <Suspense fallback={<Loader/>}>
                        <ChartContainer option={charts.sourceOverTime.option} />
                    </Suspense>
                    </>}
                </Card>
            </Grid.Col>
        </Grid>
        </>
    )
}

export { DashboardMainCV }

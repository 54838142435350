import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, TextInput, createStyles, Header, Group, ActionIcon, Text, Drawer } from '@mantine/core';
import { IconBounceRight, IconPhoneIncoming, IconLogout, IconUser } from '@tabler/icons';
import { Link } from 'react-router-dom';
import AppContext from '../AppContext';

import { ColorSchemeToggle } from '../../configuration/ColorSchemeToggle/index';
import { MyAccountContainer } from '../../authentification/MyAccountContainer';

const useStyles = createStyles((theme) => ({
    
    header: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2],
    },
    
}));

export function HeaderContainer() {

    const { classes } = useStyles();

    const myContext = useContext(AppContext);
    const navigate = useNavigate();
    const [isOpened,setIsOpened] = useState(false);
    const [jumped, setJumped] = useState(false);
    const [url, setUrl] = useState('');

    const jump = () => {
        setJumped(false);
        navigate(`/jump-to/${encodeURIComponent(url)}`, { replace: true });
    }

    return (
        <>
        <Drawer
            opened={isOpened}
            onClose={() => setIsOpened(false)}
            position="right"
            title="Mon compte"
            padding="xl"
            size="xl"
        >
            <MyAccountContainer />
        </Drawer>

        <Modal
            opened={jumped}
            onClose={() => setJumped(false)}
            title='Go to'
        >
            <Group>
                <TextInput
                    style={{width: 'calc(100% - 50px)'}}
                    value={url}
                    onChange={(event) => { setUrl(event.currentTarget.value); }}
                    label="Saisir l'URL vers laquelle aller :"
                    onKeyDown={(e) => { if (e.key === 'Enter') jump(); }}
                />
                <ActionIcon color='blue' variant='outline' onClick={() => jump()}>
                    <IconBounceRight size={16} />
                </ActionIcon>
            </Group>
        </Modal>

        <Header height={35} p={3} pl='sm' pr='sm' className={classes.header}>
            <Group position="apart">
                <Group spacing={5}>
                    <Text
                        variant="gradient"
                        size="sm"
                        weight={700}
                        gradient={{ from: 'red', to: 'indigo', deg: 45 }}
                    >
                        {myContext.account.domain} - 5feet
                    </Text>
                    <Text size={8}>{myContext.version}</Text>
                </Group>
                <Group spacing={5}>
                    <ActionIcon variant="outline" color="blue" component={Link} to="/need/add">
                        <IconPhoneIncoming size={16} />
                    </ActionIcon>
                    <ActionIcon variant='outline' color='blue' onClick={() => {
                        const s: string = window.location.href;
                        if (s.indexOf('/app/') !== -1) {
                            const p: number = s.indexOf('/app/');
                            setUrl(s.substring(p + 4));
                            setJumped(true);
                        } else if (s.indexOf('localhost') !== -1) {
                            const p: number = s.indexOf('/', s.indexOf('localhost') + 1);
                            setUrl(s.substring(p));
                            setJumped(true);
                        }
                    }}>
                        <IconBounceRight size={16} />
                    </ActionIcon>
                    <ColorSchemeToggle />
                    <ActionIcon variant="outline" color="blue" onClick={() => { setIsOpened(true)}}>
                        <IconUser size={16} />
                    </ActionIcon>
                    <ActionIcon variant="outline" color="grape" onClick={() => { myContext.logout()}}>
                        <IconLogout size={16} />
                    </ActionIcon>
                </Group>
            </Group>
        </Header>
        </>
    );
}
import { Link } from 'react-router-dom';

import { createStyles, Kbd, Menu, Navbar, Group, ActionIcon, Stack, Text, Tooltip } from '@mantine/core';
import { IconArrowBigRight, IconBuildingBank, IconShoppingCart, IconAffiliate, IconHome, IconUsers, IconLicense, IconSettings, IconAddressBook, IconBrandLinkedin } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    
    navBar: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2],
    },

    subLink: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2],
        color: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[6],
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[8] : theme.colors.blue[0],
        },
        zIndex: 1000,
    }
}));

const menus = [
    {
        label: "CV-thèque",
        icon: <IconUsers size={16} />,
        links: [
            {
                url: "/cvtheque/insert",
                label: "Insérer un ou plusieurs CV",
                shortcut: () => { return (<><Kbd>⌘</Kbd>+<Kbd>I</Kbd></>) },
            },{
                url: "/cvtheque/search",
                label: "Rechercher un CV",
                shortcut: () => { return (<><Kbd>⌘</Kbd>+<Kbd>R</Kbd></>) },
            },{
                url: "/cvtheque/list",
                label: "Voir le stock des CV non validés",
                shortcut: null,
            },{
                url: "/cvtheque/dashboard/in_progress",
                label: "Tableau de bord sur le non validé",
                shortcut: null,
            },{
                url: "/cvtheque/dashboard/main",
                label: "Tableau de bord global",
                shortcut: null,
            },{
                url: "/cvtheque/audit/blocked",
                label: "Lister les CV bloqués",
                shortcut: null,
            },{
                url: "/cvtheque/audit/duplicates",
                label: "Lister les CV doublonnés",
                shortcut: null,
            },{
                url: "/cvtheque/audit/metadata",
                label: "Anlayser les méta données",
                shortcut: null,
            }
        ]
    },{
        label: "Carnets d'adresses",
        icon: <IconAddressBook size={16} />,
        links: [
            {
                url: "/annuaire/add/message",
                label: "Ajouter un évènement",
                shortcut: () => { return (<><Kbd>⌘</Kbd>+<Kbd>E</Kbd></>) },
            },{
                url: "/annuaire/clients/list",
                label: "Afficher l'annuaire des clients",
                shortcut: null,
            },{
                url: "/annuaire/prospects/list",
                label: "Afficher l'annuaire des prospects",
                shortcut: null,
            },{
                url: "/annuaire/references/list",
                label: "Afficher l'annuaire des prises de références",
                shortcut: null,
            },{
                url: "/annuaire/mailing",
                label: "Faire un mailing",
                shortcut: null,
            },{
                url: "/annuaire/dashboard",
                label: "Suivre les actions de prospection",
                shortcut: null,
            }
        ]
    },{
        label: "Besoins",
        icon: <IconShoppingCart size={16} />,
        links: [
            {
                url: "/need/add",
                label: "Ajouter un besoin",
                shortcut: () => { return (<><Kbd>⌘</Kbd>+<Kbd>B</Kbd></>) },
            },{
                url: "/need/list/open",
                label: "Lister les besoins ouverts",
                shortcut: null,
            },{
                url: "/need/list/closed",
                label: "Lister les besoins clos",
                shortcut: null,
            },{
                url: "/need/dashboard/main",
                label: "Tableau de bord global",
                shortcut: null,
            }
        ]
    },{
        label: "Chasses",
        icon: <IconBrandLinkedin size={16} />,
        links: [
            {
                url: "/hunt/add",
                label: "Créer une chasse",
                shortcut: null,
            },{
                url: "/hunt/list",
                label: "Lister les chasses",
                shortcut: null,
            }
        ]
    },{
        label: "Missions",
        icon: <IconLicense size={16} />,
        links: [
            {
                url: "/mission/overview/audit-activities",
                label: "Auditer les CRA des missions",
                shortcut: null,
            },{
                url: "/mission/overview/audit-factures",
                label: "Auditer les factures des missions",
                shortcut: null,
            },{
                url: "/mission/add",
                label: "Créer une mission",
                shortcut: null,
            },{
                url: "/mission/list",
                label: "Lister les missions",
                shortcut: null,
            },{
                url: "/mission/overview/not_paid",
                label: "Lister les factures non payées",
                shortcut: null,
            },{
                url: "/mission/overview/removed-factures",
                label: "Lister les factures supprimées",
                shortcut: null,
            },{
                url: "/mission/search",
                label: "Rechercher une facture",
                shortcut: null,
            },{
                url: "/mission/overview/dashboard",
                label: "Trajectoire financière",
                shortcut: null,
            },{
                url: "/mission/overview/factures/taxes",
                label: "Trajectoire sur la TVA à payer",
                shortcut: null,
            },{
                url: "/mission/overview/factures/ttc",
                label: "Trajectoire sur le TTC",
                shortcut: null,
            }
        ]
    },{
        label: "Banque",
        icon: <IconBuildingBank size={16} />,
        links: [
            {
                url: "/bank/upload",
                label: "Charger un relevé bancaire",
                shortcut: null,
            },{
                url: "/bank/lettering",
                label: "Gérer les rapprochements bancaires",
                shortcut: null,
            },{
                url: "/bank/remove_lettering",
                label: "Supprimer un lettrage",
                shortcut: null,
            }
        ]
    },{
        label: "Configuration",
        icon: <IconSettings size={16} />,
        links: [
            {
                url: "/configuration/account/add",
                label: "Ajouter un utilisateur",
                shortcut: null,
            },{
                url: "/configuration/accounts",
                label: "Gérer les utilisateurs",
                shortcut: null,
            },{
                url: "/configuration/permissions",
                label: "Gérer les permissions",
                shortcut: null,
            },{
                url: "/configuration/affectations",
                label: "Gérer les affectations",
                shortcut: null,
            },{
                url: "/configuration/export",
                label: "Exporter un jeu de données",
                shortcut: null,
            },{
                url: "/configuration/send_email",
                label: "Envoyer un email",
                shortcut: null,
            }
        ]
    },{
        label: "Equipe",
        icon: <IconAffiliate size={16} />,
        links: [
            {
                url: "/team/orga",
                label: "Organigramme",
                shortcut: null,
            },{
                url: "/team/requests",
                label: "Demandes RH",
                shortcut: null,
            },{
                url: "/team/attendance",
                label: "Calendrier des absences",
                shortcut: null,
            }
        ]
    }
];

const NavbarContainer = () => {

    const { classes } = useStyles();

    return (
        <>
        <Navbar width={{ base: 40 }} height='100vh' p={5} className={classes.navBar}>
            <Navbar.Section grow>
                <Stack>
                    <ActionIcon variant="outline" color="blue" component={Link} to={'/home'}>
                        <IconHome size={16}/>
                    </ActionIcon>
                    {menus.map((menu) => (
                    <Menu key={menu.label} trigger="hover" position="right-start" exitTransitionDuration={0}>
                        <Menu.Target>
                            <Tooltip label={menu.label} position="top" withArrow>
                                <ActionIcon variant="outline" color="blue">{menu.icon}</ActionIcon>
                            </Tooltip>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {menu.links.map((link) => (
                                <Menu.Item key={link.url} component={Link} to={link.url} className={classes.subLink}>
                                    <Group style={{width: '350px'}}>
                                        <IconArrowBigRight size={12} />
                                        <Text>{link.label}</Text>
                                        {link.shortcut !== null &&
                                        <Text color="dimmed">{link.shortcut()}</Text>
                                        }
                                    </Group>
                                </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                    </Menu>
                    ))}
                </Stack>
            </Navbar.Section>
        </Navbar>
        </>
    )
}

export { NavbarContainer }
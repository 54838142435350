import { useContext, useState } from 'react';
import { Badge, Group } from '@mantine/core';
import { UserSelector } from '../../shared/UserSelector';
import { InfoNotification } from '../../shared/InfoNotification';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';


interface UserInterface {
    id: number,
    label: string,
}

interface PropsInterface {
    hunt_id: number,
    label: string | null,
    is_open: boolean,
    value: string | null,
}

const Affect = ({hunt_id, label, is_open, value}: PropsInterface) => {

    const myContext = useContext(AppContext);

    const [affectedAt, setAffectedAt] = useState<string | null>(value);

    // On affecte le besoin à un utilisateur.
    const affectTo = (item: UserInterface) => {
        const F = new FormData();
        F.append('id', hunt_id + '');
        F.append('user', item.id + '');
        const api = `${myContext.apiAddress}/affect_hunt`;
        myContext.httpClient.post(api, F).then((res:any) => {
            if (res.data.status === true) {
                setAffectedAt(item.label);
                InfoNotification({message: 'Chasse affectée.'});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    // rendu
    return (
        <Group>
        {affectedAt === null && <Badge color="orange">non affecté</Badge>}
        {affectedAt !== null && <Badge color="teal">{affectedAt}</Badge>}
        {is_open && <UserSelector handle={affectTo}/>}
        </Group>
    );

}

export { Affect }
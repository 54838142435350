import { useContext, useEffect, useState } from 'react';
import { ActionIcon, LoadingOverlay, Modal, ScrollArea, Text } from '@mantine/core';
import { IconPencil } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TextInputContainer } from '../../shared/TextInputContainer';
import { TextareaContainer } from '../../shared/TextareaContainer';

interface TestInterface {
    name: string,
    date: string | null,
    value: string | null,
    comment: string | null,
    filename: string | null,
}

interface PersonnalityInterface {
    f: string,
    l: string,
    ll: string,
}
interface PersonalitiesInterface {
    [key: string]: PersonnalityInterface,
}

interface PropsInterface {
    cv_id: number,
    value: string | null,
}

const MBTI = ({cv_id, value}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ open, setOpen ] = useState(false);
    const [ newValue, setNewValue ] = useState<string>(value || '');
    const [ newComment, setNewComment ] = useState<string>('');
    const [ loaded, setLoaded ] = useState(false);
    const [ test, setTest ] = useState<TestInterface | null>(null);

    useEffect(() => {
        if (!open) return;
        if (loaded) return;
        const api = `${myContext.apiAddress}/get_mbti_cv?id=${cv_id}&what=header`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                setTest({...res.data.data});
                setNewValue(res.data.data.value || '');
                setNewComment(res.data.data.comment || '');
            } else {
                setOpen(false);
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoaded(true);
        });
    }, [open]); // eslint-disable-line

    const personalities: PersonalitiesInterface = {
        'INTJ': { f: 'Analyste', l: 'Architecte', ll: "Penseurs imaginatifs et stratèges, avec un plan pour tout." },
        'INTP': { f: 'Analyste', l: 'Logicien', ll: "Inventeurs innovateurs dotés d’une soif inextinguible de connaissances."},
        'ENTJ': { f: 'Analyste', l: 'Commandant', ll: "Leaders hardis, imaginatifs et dotés d’un fort caractère, qui trouvent toujours un moyen d’arriver à leurs fins, ou le créent."},
        'ENTP': { f: 'Analyste', l: 'Innovateur', ll: "Penseurs astucieux et curieux incapables de résister à un défi intellectuel."},
        'INFJ': { f: 'Diplomate', l :'Avocat', ll: "Idéalistes calmes et mystiques et pourtant très inspirants et infatigables." },
        'INFP': { f: 'Diplomate', l :'Médiateur', ll: "Personnes poétiques, gentilles et altruistes qui sont toujours prêtes à soutenir une bonne cause." },
        'ENFJ': { f: 'Diplomate', l :'Protagoniste', ll: "Leaders charismatiques et inspirants, capables de fasciner leur public." },
        'ENFP': { f: 'Diplomate', l :'Inspirateur', ll: "Esprits libres enthousiastes, créatifs et sociables, qui arrivent toujours à trouver une raison de sourire." },
        'ISTJ': { f: 'Sentinelle', l: 'Logistien', ll: "Individus pragmatiques et intéressés par les faits, dont le sérieux ne saurait être mis en cause." },
        'ISFJ': { f: 'Sentinelle', l: 'Défenseur', ll: "Protecteurs très dévoués et très chaleureux, toujours prêts à défendre ceux qu’ils aiment." },
        'ESTJ': { f: 'Sentinelle', l: 'Directeur', ll: "Excellents gestionnaires, d’une efficacité inégalée quand il s’agit de gérer les choses, ou les gens." },
        'ESFJ': { f: 'Sentinelle', l: 'Consul', ll: "Personnes extraordinairement attentionnées, sociables et populaires, toujours prêtes à aider les autres." },
        'ISTP': { f: 'Explorateur', l: 'Virtuose', ll: "Expérimentateurs hardis et pragmatiques, maîtres de toutes sortes d’outils." },
        'ISFP': { f: 'Explorateur', l: 'Aventurier', ll: "Artistes flexibles et charmants, toujours prêts à explorer et à essayer quelque chose de nouveau." },
        'ESTP': { f: 'Explorateur', l: 'Entrepreneur', ll: "Personnes astucieuses, énergiques et très perspicaces, qui aiment vraiment vivre à la pointe du progrès." },
        'ESFP': { f: 'Explorateur', l: 'Amuseur', ll: "Amuseurs spontanés, énergiques et enthousiastes; avec eux, on ne s’ennuie jamais." },
    };

    return (
        <>
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={loaded ? test?.name : 'loading...'}
        >
            {loaded ?
            <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                {(test !== null && test.date !== null) && <Text color='dimmed'>Last modification {test.date.substring(0,10)}</Text>}
                {(newValue !== '' && personalities.hasOwnProperty(newValue.substring(0,4))) &&
                    <>
                    <Text size='sm'>{personalities[newValue.substring(0,4)].f}</Text>
                    <Text size='sm'>{personalities[newValue.substring(0,4)].l}</Text>
                    <Text size='sm'>{personalities[newValue.substring(0,4)].ll}</Text>
                    </>
                }
                <TextInputContainer 
                    code='value'
                    label='MBTI'
                    defaultValue={newValue}
                    isEdition={true}
                    withAsterisk={true}
                    api={`${myContext.apiAddress}/update_mbti_cv?id=${cv_id}`}
                    handle={(s: string) => setNewValue(s)}
                />
                <Text color='dimmed' size='xs'>Mettre x pour supprimer la valeur du MBTI, sinon, il faut mettre les 4 lettres en majuscules.</Text>
                <TextareaContainer
                    code='comment'
                    label='Informations supplémentaires'
                    defaultValue={newComment}
                    isEdition={true}
                    api={`${myContext.apiAddress}/update_mbti_cv?id=${cv_id}`}
                    handle={(s: string) => setNewComment(s)}
                />
            </ScrollArea>
            :
            <LoadingOverlay visible={!loaded}/>
            }           
        </Modal>
        <Text>{newValue}</Text>
        {(newValue !== '' && personalities.hasOwnProperty(newValue.substring(0,4))) &&
            <>
            <Text size='xs' color='dimmed'>{personalities[newValue.substring(0,4)].f}</Text>
            <Text size='xs' color='dimmed'>{personalities[newValue.substring(0,4)].l}</Text>
            </>
        }
        <ActionIcon color='blue' variant='outline' onClick={() => setOpen(true) } size={14} >
            <IconPencil size={12} />
        </ActionIcon>
        </>
    )
}

export { MBTI }
import { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Center, Group, Text, Loader, Table, Switch, ScrollArea, LoadingOverlay } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons';
import { TitleContainer } from '../../shared/TitleContainer';
import AppContext from '../../shared/AppContext';
import { ToggleContainer } from '../../shared/ToggleContainer';

interface UserInterface {
    user_id: number,
    user_label: string,
    is_frozen: boolean,
    roles: RoleInterface[],
}
interface RoleInterface {
    role_id: number,
    role_label: string,
    is_granted: boolean,
}
interface AffectationsInterface {
    isLoading: boolean,
    isUpdating: boolean,
    error: null,
    data: UserInterface[],
}
const affectationsReducer = (state: AffectationsInterface, payload: any) => {
    switch (payload.type) {
        case 'LOAD':
            return {
                ...state,
                isLoading: false,
                data: payload.data,
            }
        case 'START_UPDATE':
            return {
                ...state,
                isUpdating: true,
            }
        case 'UPDATE':
            const ui = payload.data.user_id;
            const ri = payload.data.role_id;
            const ll = JSON.parse(JSON.stringify(state.data));
            for (let u=0;u<ll.length;u++) {
                for (let r=0;r<ll[u].roles.length;r++) {
                    if (ll[u].user_id === ui && ll[u].roles[r].role_id === ri) {
                        ll[u].roles[r].is_granted = payload.data.value;
                    }
                }
            }
            return {
                ...state,
                isUpdating: false,
                data: ll,
            }
        case 'ERROR':
            return {
                ...state,
                isLoading: false,
                isUpdating: false,
                error: payload.message,
            }
        default:
            return {
                ...state,
                error: 'Oops, unknown local error.',
            }
    }
}

const ManageAffectations = () => {

    const myContext = useContext(AppContext);
    const apiUrlGet: string = myContext.apiAddress + '/get_repository_affectation';
    const apiUrlUpdate: string = myContext.apiAddress + '/update_affectation';
    const [filterOnActif, setFilterOnActif] = useState(true);
    const [affectations, dispatchAffectations] = useReducer(
        affectationsReducer, {
            isLoading: true,
            isUpdating: false,
            error: null,
            data: [],
        }
    );

    const handleChange = (user_id: number, role_id: number, value: boolean) => {
        const myForm = new FormData();
        myForm.append('user_id', user_id + '');
        myForm.append('role_id', role_id + '');
        myForm.append('value', value ? '1' : '0');
        dispatchAffectations({type: 'START_UPDATE'});
        myContext.httpClient.post(apiUrlUpdate, myForm).then((result: any) => {
            if (result.data.status === true) {
                dispatchAffectations({type: 'UPDATE', data: { user_id: user_id, role_id: role_id, value: value} });
            } else if (result.data.status === false) {
                dispatchAffectations({type: 'ERROR', message: result.data.message});
            } else {
                dispatchAffectations({type: 'ERROR', message: 'Strange, the servor returned a bad answer.'});
            }
        });
    }

    useEffect(() => {
        if (!affectations.isLoading) return;
        if (apiUrlGet.startsWith('null')) return;
        myContext.httpClient.get(apiUrlGet).then((result: any) => {
            if (result.data.status === true) {
                dispatchAffectations({type: 'LOAD', data: result.data.data});
            } else if (result.data.status === false) {
                dispatchAffectations({type: 'ERROR', message: result.data.message});
            } else {
                dispatchAffectations({type: 'ERROR', message: 'Strange, the servor returned a bad answer.'});
            }
        });
    }, [affectations.isLoading, apiUrlGet, myContext.httpClient]);

    if (affectations.isLoading) {
        return (
            <Center>
                <Group>
                    <Loader color="blue" size="md" />
                    <Text color="blue">Récupération des affectations en cours&hellip;</Text>
                </Group>
            </Center>
        );
    }

    if (affectations.error !== null) {
        return (
            <Center>
                <Alert icon={<IconAlertCircle size={16} />} title="Gestion des affectations" color="red" radius="md">
                    {affectations.error}
                </Alert>
            </Center>
        )
    }

    if (affectations.data.length === 0) {
        return (
            <Center>
                <Alert icon={<IconAlertCircle size={16} />} title="Gestion des affectations" color="orange" radius="md">
                    Aucune affectation à afficher.
                </Alert>
            </Center>
        )
    }

    return (
        <>
        <Group position="apart">
            <TitleContainer>Gérer les affectations</TitleContainer>
            <ToggleContainer 
                defaultValue={filterOnActif}
                label='Actifs ?'
                isEdition={true}
                handle={(b: boolean) => setFilterOnActif(b)}
                api=''
                action=''
            />
        </Group>
        <ScrollArea style={{height: 'calc(100vh - 120px)'}}>
            <LoadingOverlay visible={affectations.isUpdating} />
            <Center><Text color="blue">{affectations.data.filter((u: UserInterface) => u.is_frozen !== filterOnActif).length} utilisateurs</Text></Center>
            <Table sx={{ minWidth: 1000 }} highlightOnHover>
                <tbody>
                    {affectations.data.filter((u: UserInterface) => u.is_frozen !== filterOnActif).map((u: UserInterface) => (
                        <tr key={'user-' + u.user_id}>
                            <td>{u.user_label}</td>
                            {u.roles.map((r: RoleInterface) => (
                                <td key={u.user_id + '-' + r.role_id}>
                                    <Group>
                                        <Switch size="xs" color="blue" checked={r.is_granted} onChange={(event) => handleChange(u.user_id, r.role_id, event.currentTarget.checked)} />
                                        <Text size="xs">{r.role_label}</Text>
                                    </Group>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </ScrollArea>
        </>
    )
}

export { ManageAffectations }

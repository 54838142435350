import { useState } from 'react';
import { useParams } from "react-router-dom";
import { Badge, Card, Grid, Group, Stack, Text } from '@mantine/core';
import { HeaderContainer } from './header_container';
import { MetadataContainer } from './metadata_container';
import { DocumentsContainer } from './documents_container';
import { GraphicsContainer } from './graphics_container';
import { RestoreButton } from '../../shared/RestoreButton';
import { HistoryButton } from '../../shared/HistoryButton';
import { Messages } from '../../shared/MessagesContainer/messages';
import { FlowsButton } from './flows_button';

interface HeaderInterface {
    id: number,
    client_name: string,
    manager_name: string,
    label: string,
    is_finished: boolean,
    affected_at: string,
    is_removed: boolean,
    stopped_date: string,
    journal: string,
}

type ParamInterface = {
    paramMissionId: string,
}

const ManageMission = () => {

    const { paramMissionId } = useParams<ParamInterface>();
    const missionId: number | null = (paramMissionId === undefined) ? null : parseInt(paramMissionId);
    
    const [ header, setHeader ] = useState<HeaderInterface | null>(null);
    const [ isInProgress, setIsInProgress ] = useState(true);

    const initHeader = (h: HeaderInterface) => {
        setHeader({...h});
        setIsInProgress((h.is_finished || h.is_removed) ? false : true);
    }
    
    if (missionId === null) {
        return (
            <>
            <Text>strange, no mission id</Text>
            </>
        )
    }

    return (
        <>
        <Grid>
            {header !== null && 
            <>
            <Grid.Col xs={11} style={{position: 'sticky', top:'0px', zIndex: 1000}}>
                <Group position='center'>
                    <Text fw={700} style={{backgroundColor: 'white'}}>{header.client_name}</Text>
                    <Text>avec</Text>
                    <Text fw={700} style={{backgroundColor: 'white'}}>{header.manager_name}</Text>
                </Group>
            </Grid.Col>
            <Grid.Col xs={11}>
                <HeaderContainer 
                    header={header} 
                    missionId={missionId} 
                />
            </Grid.Col>
            <Grid.Col xs={1}>
                <Stack align="flex-end">
                    <HistoryButton 
                        family='mission'
                        id={missionId}
                        label={`Historique pour "${header.client_name} / ${header.manager_name}"`}
                    />
                    <FlowsButton
                        missionId={missionId}
                    />
                    {header.is_finished &&
                    <>
                        <Badge color="orange">CLOS</Badge>
                        <RestoreButton 
                            label={`Ré ouvrir la mission ${header.client_name}`}
                            api={`restore_mission`}
                            params={`id=${header.id}`}
                            handle={() => {
                                setIsInProgress(true);
                                setHeader({...header, is_finished: false, is_removed: false});
                            }}
                        />
                    </>
                    }
                    {header.is_removed &&
                        <Badge color="red">removed</Badge>
                    }
                </Stack>
            </Grid.Col>
            </>
            }
            <Grid.Col xs={12}>
                <Card withBorder>
                    <Messages
                        objectCode='mission'
                        objectId={missionId}
                        isOpen={isInProgress}
                        getUrl={`get_messages_mission?id=${missionId}&what=messages`}
                        updateUrl={`update_message_mission?id=${missionId}&what=message`}
                        height='200px'
                    />
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} lg={8}>
                <Card withBorder>
                    <GraphicsContainer
                        missionId={missionId}
                        missionOpened={isInProgress}
                    />
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Card withBorder>
                    <DocumentsContainer 
                        missionId={missionId}
                        missionLabel={header === null ? '...' : header.label}
                        missionOpened={isInProgress}
                        category='contract'
                    />
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Card withBorder>
                    <DocumentsContainer 
                        missionId={missionId}
                        missionLabel={header === null ? '...' : header.label}
                        missionOpened={isInProgress}
                        category='report'
                    />
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Card withBorder>
                    <DocumentsContainer 
                        missionId={missionId}
                        missionLabel={header === null ? '...' : header.label}
                        missionOpened={isInProgress}
                        category='bill'
                    />
                </Card>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
                <Card withBorder>
                    <DocumentsContainer 
                        missionId={missionId}
                        missionLabel={header === null ? '...' : header.label}
                        missionOpened={isInProgress}
                        category='invoice'
                    />
                </Card>
            </Grid.Col>
            <Grid.Col xs={12}>
                <Card withBorder>
                    <MetadataContainer 
                        missionId={missionId}
                        handleHeader={initHeader}
                        handleClose={() => {
                            setIsInProgress(false);
                            if (header !== null) {
                                setHeader({...header, is_finished: true});
                            }
                        }}
                        handleRemove={() => {
                            setIsInProgress(false);
                            if (header !== null) {
                                setHeader({...header, is_removed: true});
                            }
                        }}
                        missionOpened={isInProgress}
                    />
                </Card>
            </Grid.Col>
        </Grid>
        </>
    )

}

export { ManageMission }
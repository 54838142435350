import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Button, Card, Center, Divider, Group, List, Select, Text } from '@mantine/core';
import 'dayjs/locale/fr';
import { DatePicker } from '@mantine/dates';
import { IconSquareToggleHorizontal } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { InfoNotification } from '../../shared/InfoNotification';

const LeaveForm = () => {

    const myContext = useContext(AppContext);
    const [opened, setOpened] = useState(false);
    const [working, setWorking] = useState(false);

    const [inputNature, setInputNature] = useState<string | null>(null);
    const [inputDateStart, setInputDateStart] = useState<Date | null>(null);
    const [inputDateEnd, setInputDateEnd] = useState<Date | null>(null);
    const [inputDayPeriod, setInputDayPeriod] = useState<string | null>(null);

    useEffect(() => {
        if (!opened) return;
        setInputNature(null);
        setInputDateStart(null);
        setInputDateEnd(null);
        setInputDayPeriod('d');
    }, [opened]); // eslint-disable-line

    const dateToString = (D: Date) => {
        const y = D.getFullYear();
        const m = D.getMonth()+1;
        const d = D.getDate();
        return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
    }

    const save = () => {
        if (inputDateStart === null) return;
        if (inputDateEnd === null) return;
        if (inputNature === null) return;
        if (inputDayPeriod === null) return;
        if (inputDateStart > inputDateEnd) return;
        if (inputDateEnd.getTime() - inputDateStart.getTime() > 60 * 24 * 3600 * 1000) return;
        setWorking(true);
        const F = new FormData();
        F.append('action', 'add_day_off');
        F.append('nature', inputNature);
        F.append('day_period', inputDayPeriod);
        F.append('date_start', dateToString(inputDateStart));
        F.append('date_end', dateToString(inputDateEnd));
        const api = `${myContext.apiAddress}/hr_request_account`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                InfoNotification({message: "Demande enregistrée"});
                setOpened(false);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <Card withBorder>
            <Group position='apart'>
                <Text>Formulaire de demande de congé</Text>
                <ActionIcon color='blue' variant='outline' onClick={() => setOpened(!opened)}>
                    <IconSquareToggleHorizontal size={16} />
                </ActionIcon>
            </Group>
            {opened &&
            <Card>
                <Divider mt='sm' mb='sm' />
                <Group grow>
                    <DatePicker
                        locale='fr'
                        placeholder="Début"
                        label="Date de début incluse"
                        value={inputDateStart}
                        onChange={setInputDateStart}
                        disabled={working}
                        withAsterisk
                    />
                    <DatePicker
                        locale='fr'
                        placeholder="Fin"
                        label="Date de fin incluse"
                        value={inputDateEnd}
                        onChange={setInputDateEnd}
                        disabled={working}
                        withAsterisk
                    />
                </Group>

                <Select
                    label="Type d'absence"
                    placeholder="sélectionner une famille d'absence"
                    value={inputNature}
                    onChange={setInputNature}
                    data={[
                        { value: 'a10', group: 'Fréquent', label: 'Congé payé' },
                        { value: 'a12', group: 'Fréquent', label: 'Congé payé par anticipation' },
                        { value: 'a11', group: 'Fréquent', label: 'Congé pour arrêt maladie' },
                        { value: 'a13', group: 'Fréquent', label: 'RTT' },
                        { value: 'b10', group: 'Autre', label: 'Congé enfant malade' },
                        { value: 'b11', group: 'Autre', label: 'Congé maternité' },
                        { value: 'b12', group: 'Autre', label: 'Congé parental' },
                        { value: 'b13', group: 'Autre', label: 'Congé paternité' },
                        { value: 'b14', group: 'Autre', label: 'Congé pour adoption' },
                        { value: 'b15', group: 'Autre', label: 'Congé pour décès' },
                        { value: 'b20', group: 'Autre', label: 'Congé pour examen' },
                        { value: 'b16', group: 'Autre', label: 'Congé pour mariage' },
                        { value: 'b17', group: 'Autre', label: 'Congé pour naissance' },
                        { value: 'b18', group: 'Autre', label: 'Congé pour PACS' },
                        { value: 'b19', group: 'Autre', label: 'Congé sans solde' },
                    ]}
                    disabled={working}
                    withAsterisk
                />

                <Select
                    label="Période de la journée"
                    placeholder="sélectionner une période de la journée"
                    value={inputDayPeriod}
                    onChange={setInputDayPeriod}
                    data={[
                        {value: 'am', label: 'matin'},
                        {value: 'pm', label: 'après-midi'},
                        {value: 'd', label: 'journée'},
                    ]}
                    disabled={working}
                    withAsterisk
                />
                
                <Center pt='lg' pb='lg'>
                    <Button
                        onClick={() => save()} loading={working}
                        disabled={inputDateStart === null || inputDateEnd === null || inputNature === null || inputDayPeriod === null}
                        color='blue' variant='outline'
                    >
                        Sauvegarder
                    </Button>
                </Center>

                <Divider label="Information"/>
                <Text>Les congés suivants nécessitent des justificatifs à transmettre par mail de préférence, sinon par courrier.</Text>
                <List>
                    <List.Item>Congé pour arrêt maladie</List.Item>
                    <List.Item>Congé pour enfant malade</List.Item>
                    <List.Item>Congé relatif à un évènement (adoption, décés, mariage, naissance, PACS)</List.Item>
                </List>
            </Card>
            }
        </Card>
    )
}

export { LeaveForm }
import { useContext, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { SearchCV } from '../../cvtheque/SearchCV';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { InfoNotification } from '../../shared/InfoNotification';

interface PropsInterface {
    needId: number,
    isOpen: boolean,
}

const Search = ({needId, isOpen}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [working, setWorking] = useState(false);

    const attachToNeed = (search: string, ids: number[]) => {
        if (ids.length === 0) return;
        setWorking(true);
        const F = new FormData();
        F.append('need_id', needId + '');
        F.append('ids', ids.join(','));
        F.append('search', search);
        const api = `${myContext.apiAddress}/attach_process_need`;
        myContext.httpClient.post(api, F).then((res: any) => {
            setWorking(false);
            if (res.data.status === true) {
                InfoNotification({message: res.data.message});
            } else {
                AlertNotification({message: res.data.message || 'unknwon error' });
            }
        });
    }

    if (isOpen) {
        return (
            <div>
                <LoadingOverlay visible={working} />
                <SearchCV handleSelection={attachToNeed} />
            </div>
        );
    } else {
        return (<></>);
    }

}

export { Search }
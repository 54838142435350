import { RowHistoryInterface, SearchInterface, TermInterface } from './interfaces';

const constructSearchTermFromString = (s: string): TermInterface | null => {
    const cols: string[] = s.split(';');
    if (cols.length < 2) {
        return null;
    } else if (cols[1] !== '+' && cols[1] !== '-') {
        return null;
    } else if (cols[0].trim().length === 0) {
        return null;
    } else {
        const included = (cols[1] === '+') ? true : false;
        const isExpand = cols.indexOf('A+') > 1 ? true : false;
        const isCase = cols.indexOf('Aa') > 1 ? true : false;
        let isN = (cols.indexOf('N') > 1 || cols.indexOf('n') > 1) ? true : false;
        let isH = (cols.indexOf('H') > 1 || cols.indexOf('h') > 1) ? true : false;
        let isJ = (cols.indexOf('J') > 1 || cols.indexOf('j') > 1) ? true : false;
        let isE = (cols.indexOf('E') > 1 || cols.indexOf('e') > 1) ? true : false;
        let isF = (cols.indexOf('F') > 1 || cols.indexOf('f') > 1) ? true : false;
        let isD = (cols.indexOf('D') > 1 || cols.indexOf('d') > 1) ? true : false;
        let isS = (cols.indexOf('S') > 1 || cols.indexOf('s') > 1) ? true : false;
        let isL = (cols.indexOf('L') > 1 || cols.indexOf('l') > 1) ? true : false;
        if (!isN && !isH && !isJ && !isE && !isF && !isD && !isS && !isL) {
            isN = true;
            isH = true;
            isJ = true;
            isE = true;
            isF = true;
            isD = true;
            isS = true;
            isL = true;
        }
        return {
            'included': included,
            'value': cols[0],
            'Aa': isCase,
            'A+': isExpand,
            '!': (cols.indexOf('!') !== -1) ? true : false,
            'regexp': constructRexpExp(cols[0], isExpand, isCase),
            'weight': 0,
            'fields': {n: isN, h: isH, j: isJ, e: isE, f: isF, d: isD, s: isS, l: isL},
        }
    }
}

const constructRexpExp = (value: string, expand: boolean, caseSensitive: boolean) => {
    let terms = value.replaceAll(' or ', ' ou ').split(' ou ');
    if (!expand) {
        for(let i=0;i<terms.length;i++) {
            terms[i] = "(^|[^\\w])(" + terms[i].trim() + ")([^\\w]|$)";
        }
    }
    const term = (terms.length > 1) ? '(' + terms.join(')|(') + ')' : terms.join('');
    return new RegExp(term, caseSensitive ? 'g' : 'gi');
}

const constructCvFromJson = (p: any) => {
    for (const k of ['fullname','headline','sector','location']) {
        if (k in p === false) {
            p[k] = '';
        }
    }
    for (const k of ['jobs','experiences','formations','decorations']) {
        if (k in p === false) {
            p[k] = [];
        }
    }
    if ('fields' in p === false) {
        p['fields'] = {h: true, e: true, f: true, d: true, s: true, l: true};
    } else {
        let b = true;
        for (const k of ['n','h','j','e','f','d','s','l']) {
            if (k in p['fields']) {
                b = false;
            }
        }
        for (const k of ['n','h','j','e','f','d','s','l']) {
            if (k in p['fields'] === false) {
                p['fields'][k] = b;
            }
        }
    }
    // on sépare en deux les expériences pour avoir les expériences en cours et les expériences passées
    if (p['experiences'].length !== 0 && p['jobs'].length === 0) {
        p['jobs'] = p['experiences'].filter((r: RowHistoryInterface) => r.y1 === null);
        p['experiences'] = p['experiences'].filter((r: RowHistoryInterface) => r.y1 !== null);
    }
    return p;
}

const stringifySearch = (search: SearchInterface) => {
    let ss: string[] = [];
    for(const o of search.terms){
        let s = '';
        s = o['value'] + ';' + (o['included'] ? '+' : '-');
        if (o['Aa']) {
            s += ';Aa';
        }
        if (o['!']) {
            s += ';!';
        }
        if (o['A+']) {
            s += ';A+';
        }
        let b = false;
        for(const f of ['n','h','j','e','f','d','s','l']){
            if (o.fields[f]) {
                s += ';' + f;
                b = true;
            }
        }
        if (b) {
            ss.push(s);
        }
    }
    ss.sort();
    return ss.join('|');
}

export { constructSearchTermFromString, constructRexpExp, constructCvFromJson, stringifySearch }
import { useContext, useState } from 'react';
import { ActionIcon, Center, Group, Loader, Stack, Text } from '@mantine/core';
import { IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TownSelector } from '../../shared/TownSelector';

interface TownInterface {
    id: number,
    zipcode: string,
    label: string,
}
interface PropsInterface {
    needId: number,
    cvId: number,
    field: string,
    value: TownInterface | null,
    handleChange: any,
}

const ValueTown = ({needId, cvId, field, value, handleChange}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [display, setDisplay] = useState(false);
    const [val, setVal] = useState<TownInterface | null>(value === null ? null : {...value});
    const [saving, setSaving] = useState(false);

    const save = (town: TownInterface) => {
        if (val !== null && val.id === town.id) return;
        setSaving(true);
        const F = new FormData();
        F.append('what', field);
        F.append('value', town.id + '');
        const api = `${myContext.apiAddress}/update_process_need?need_id=${needId}&cv_id=${cvId}`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setSaving(false);
            if (res.data.status === true) {
                setVal({...town});
                handleChange({...town});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setDisplay(false);
        });
    }

    if (saving) {
        return (
            <Center>
                <Loader size="sm" />
            </Center>
        )
    } else if (display) {
        return (
            <Group position="center">
                <ActionIcon color='blue' variant='outline' onClick={() => setDisplay(false)}>
                    <IconX size={16} />
                </ActionIcon>
                <TownSelector 
                    handleSelection={(t: TownInterface) => save(t)}
                />
            </Group>
        )
    } else {
        return (
            <Stack spacing={0} onClick={() => setDisplay(true)} style={{cursor: 'pointer'}}>
                {val === null ?
                <Text size="sm" color="dimmed">non définie</Text>
                :
                <>
                <Text>{val.label}</Text>
                <Text size="xs" color="dimmed">{val.zipcode}</Text>
                </>
                }
            </Stack>
        )
    }

}

export { ValueTown }
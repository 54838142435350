import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Badge, ColorSwatch, Divider, Group, LoadingOverlay, ScrollArea, Select, Stack, Table, Text } from '@mantine/core';
import { IconArrowBigRight, IconRefresh, IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';
import { AlertNotification } from '../../shared/AlertNotification';
import { UserSelector } from '../../shared/UserSelector';

interface UserInterface {
    id: number,
    label: string
}
interface ValueInterface {
    bill: {'a': number, 'l': boolean} | null,
    invoice: {'a': number, 'l': boolean} | null,
    contract: boolean,
}
interface MissionInterface {
    id: number,
    affected_at: number,
    client_name: string,
    manager_name: string,
    label: string,
    values: ValueInterface[],
}
interface MonthInterface {
    year: number,
    month: number,
    label: string,
}

const AuditFacturesMission = () => {

    const myContext = useContext(AppContext);
    const d0 = new Date();
    let y0 = d0.getFullYear();
    let m0 = d0.getMonth()+1;
    if (d0.getDate() < 14) {
        m0 -= 1;
        if (m0 === 0) {
            y0 -= 1;
            m0 = 12;
        }
    }
    const [myYear, setMyYear] = useState<string | null>(y0+'');
    const [myMonth, setMyMonth] = useState<string | null>(m0+'');
    const [loaded, setLoaded] = useState(false);
    const [missions, setMissions] = useState<MissionInterface[]>([]);
    const [months, setMonths] = useState<MonthInterface[]>([]);
    const [user, setUser] = useState<UserInterface | null>(null); // {id: myContext.account.id, label: 'moi'}

    useEffect(() => {
        if (loaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/audit_mission?report=factures&year=${myYear}&month=${myMonth}`).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                setMissions(res.data.data);
                const labels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
                const mm: MonthInterface[] = [];
                let y: number = parseInt(myYear || '1');
                let m: number = parseInt(myMonth || '1');
                for (let i=0;i<3;i++) {
                    mm.splice(0,0,{year: y, month: m, label: labels[m-1]});
                    m -= 1;
                    if (m === 0) {
                        m = 12;
                        y -= 1;
                    }
                }
                setMonths(mm);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [loaded]); // eslint-disable-line

    useEffect(() => {
        setLoaded(false);
    }, [myMonth, myYear]); // eslint-disable-line

    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Audit des factures
            </TitleContainer>
            <Group>
                <Select 
                    label="Année"
                    value={myYear}
                    onChange={setMyYear}
                    data={[
                        {value: (y0-1)+'', label: (y0-1)+''},
                        {value: y0+'', label: y0+''},
                        {value: (y0+1)+'', label: (y0+1)+''}
                    ]}
                    disabled={!loaded}
                />
                <Select 
                    label="Mois"
                    value={myMonth}
                    onChange={setMyMonth}
                    data={[
                        {value: '1', label: 'Janvier'},
                        {value: '2', label: 'Février'},
                        {value: '3', label: 'Mars'},
                        {value: '4', label: 'Avril'},
                        {value: '5', label: 'Mai'},
                        {value: '6', label: 'Juin'},
                        {value: '7', label: 'Juillet'},
                        {value: '8', label: 'Août'},
                        {value: '9', label: 'Septembre'},
                        {value: '10', label: 'Octobre'},
                        {value: '11', label: 'Novembre'},
                        {value: '12', label: 'Décembre'},
                    ]}
                    disabled={!loaded}
                />
                <Divider orientation='vertical' />
                {user !== null &&
                <>
                <Text>{user.label}</Text>
                <ActionIcon color='blue' variant='outline' onClick={() => setUser(null)}>
                    <IconX size={16} />
                </ActionIcon>
                </>
                }
                <UserSelector
                    handle={(u: UserInterface) => setUser({...u})}
                />
                {loaded &&
                <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                    <IconRefresh size={16} />
                </ActionIcon>
                }
            </Group>
        </Group>
        <ScrollArea style={{height: 'calc(100vh - 120px)'}}>
            <LoadingOverlay visible={!loaded} />
            <Table highlightOnHover>
                <thead>
                    <tr style={{position: 'sticky', top: '0'}}>
                        <th>Mission</th>
                        {months.map((m) => 
                        <th key={`col-y-${m.year}-m-${m.month}`}>{m.label} {m.year}</th>)}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {missions.filter((m) => (user === null || user.id === m.affected_at)).map((m) => 
                    <tr key={`mission-${m.id}`}>
                        <td>
                            <Text>{m.client_name}</Text>
                            <Text>{m.manager_name}</Text>
                        </td>
                        {m.values.map((v,idx) => 
                        <td key={`mission-${m.id}-col-${idx}`}>
                            <Stack>
                                {(v.invoice !== null || v.bill !== null) ?
                                    <>
                                    {v.invoice === null ?
                                        <Group>
                                            <ColorSwatch size={10} color='red' />
                                            <Badge color='red'>client</Badge>
                                        </Group>
                                        :
                                        <Group>
                                            <ColorSwatch size={10} color={v.invoice.l === false ? 'orange' : 'lime'} />
                                            <Badge color='teal'>client</Badge>
                                        </Group>
                                    }
                                    {v.bill === null ?
                                        <Group>
                                            <ColorSwatch size={10} color='red' />
                                            <Badge color='red'>fournisseur</Badge>
                                        </Group>
                                        :
                                        <Group>
                                            <ColorSwatch size={10} color={v.bill.l === false ? 'orange' : 'lime'} />
                                            <Badge color='teal'>fournisseur</Badge>
                                        </Group>
                                    }
                                    </>
                                :
                                    <>
                                    {v.contract ?
                                        <>
                                        <Group>
                                            <ColorSwatch size={10} color='gray' />
                                            <Badge color='gray'>client</Badge>
                                        </Group>
                                        <Group>
                                            <ColorSwatch size={10} color='gray' />
                                            <Badge color='gray'>fournisseur</Badge>
                                        </Group>
                                        </>
                                    :
                                        <></>
                                    }
                                    </>
                                }
                            </Stack>
                        </td>
                        )}
                        <td>
                            <ActionIcon color="blue" variant="outline"
                                component={Link} to={`/mission/manage/${m.id}`}
                            >
                                <IconArrowBigRight size={16} />
                            </ActionIcon>
                        </td>
                    </tr>
                    )}
                </tbody>
            </Table>
        </ScrollArea>
        </>
    )
}

export { AuditFacturesMission }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Group, LoadingOverlay, Modal, ScrollArea, Table, Text, TextInput } from '@mantine/core';
import { IconClick, IconMapPin } from '@tabler/icons';
import AppContext from '../AppContext';

interface TownInterface {
    id: number,
    zipcode: string,
    label: string,
}

interface PropsInterface {
    handleSelection: any,
}

const TownSelector = ({handleSelection}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [opened, setOpened] = useState(false);
    const [working, setWorking] = useState(false);
    const [zipcode, setZipcode] = useState('');
    const [label, setLabel] = useState('');
    const [towns, setTowns] = useState<TownInterface[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (error !== null) return;
        if (working) return;
        if (zipcode.length !== 5 && label.trim().length < 3) return;
        const F = new FormData();
        F.append('label', label.trim());
        if (zipcode.length === 5) {
            F.append('zipcode', zipcode);
        }
        const api = `${myContext.apiAddress}/search_town`;
        setWorking(true);
        myContext.httpClient.post(api, F).then((res: any) => {
            if (res.data.status === true) {
                if (res.data.data === null) {
                    setTowns([]);
                } else {
                    setTowns(res.data.data);
                }
            } else {
                setError(res.data.message || 'unknown error');
            }
            setWorking(false);
        });
    }, [zipcode, label]); // eslint-disable-line

    return (
        <>
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            size='auto'
        >   
            <Group>
                <TextInput
                    label='Code postal (5 chiffres)'
                    placeholder='75001'
                    value={zipcode}
                    onChange={(event) => {
                        const s = event.currentTarget.value;
                        if (/^[0-9]{1,5}$/.test(s)) {
                            setZipcode(s);
                        } else {
                            setZipcode('');
                        }
                    }}
                />
                <TextInput
                    label='Nom de la ville (1 mot)'
                    placeholder='Paris'
                    value={label}
                    onChange={(event) => setLabel(event?.currentTarget.value.trim().substring(0,20))}
                />
            </Group>
            <ScrollArea style={{height: 'calc(100vh - 300px)'}}>
                <LoadingOverlay visible={working} />
                <Table highlightOnHover>
                    <tbody>
                        {towns.map((t: TownInterface) => 
                        <tr key={`town-${t.id}`}>
                            <td>
                                <ActionIcon color='blue' variant='outline' onClick={() => {
                                    handleSelection({...t});
                                    setOpened(false);
                                }}>
                                    <IconClick size={16} />
                                </ActionIcon>
                            </td>
                            <td>
                                <Text>{t.zipcode} {t.label}</Text>
                            </td>
                        </tr>
                        )}
                    </tbody>
                </Table>
                
                {towns.length === 20 && <Text color="dimmed" size="sm">affiner votre recherche...</Text>}
            </ScrollArea>
        </Modal>
        <ActionIcon color="blue" variant="outline" onClick={() => setOpened(true)}>
            <IconMapPin size={16} />
        </ActionIcon>
        </>
    );

}

export { TownSelector }
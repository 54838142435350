import { useContext, useState } from 'react';
import { ActionIcon, Center, Group, Loader, Modal, Stack, Text } from '@mantine/core';
import { IconCalendar, IconCheck, IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { DatePicker } from '@mantine/dates';

interface PropsInterface {
    needId: number,
    cvId: number,
    value: string | null,
    handleChange: any,
}

const ValueDate = ({needId, cvId, value, handleChange}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [display, setDisplay] = useState(false);
    const [opened, setOpened] = useState(false);
    const [val, setVal] = useState(value);
    const [newVal, setNewVal] = useState<Date | null>(null);
    const [saving, setSaving] = useState(false);

    const mois: string[] = ['jan', 'fév', 'mar', 'avr', 'mai', 'jui', 'juil', 'aoû', 'sep', 'oct', 'nov', 'déc'];
    const formatDate = (s: string) => {
        const t = s.substring(0,10).split('-');
        return parseInt(t[2]) + ' ' + mois[parseInt(t[1])-1] + ' ' + t[0];
    }
    
    const toStringDate = (d: Date | null) => {
        if (d === null) return '';
        return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    }

    const save = () => {
        if (newVal === null) return;
        const s = toStringDate(newVal);
        setOpened(false);
        setDisplay(false);
        setSaving(true);
        const F = new FormData();
        F.append('what', 'form_date');
        F.append('value', s);
        const api = `${myContext.apiAddress}/update_process_need?need_id=${needId}&cv_id=${cvId}`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setSaving(false);
            if (res.data.status === true) {
                setVal(s);
                handleChange(s);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    if (saving) {
        return (
            <Center>
                <Loader size="sm" />
            </Center>
        )
    } else if (display) {
        return (
            <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title={`Date actuelle : ${val === null ? '?' : formatDate(val)}`}
            >
                <DatePicker
                    placeholder='sélectionner une date'
                    value={newVal}
                    onChange={setNewVal}
                />
                <Group position='center' pt='xs'>
                    <ActionIcon color='blue' variant='outline' onClick={() => { setOpened(false); setDisplay(false)}}>
                        <IconX size={16} />
                    </ActionIcon>
                    <ActionIcon color='blue' variant='outline' onClick={() => save()} disabled={newVal === null}>
                        <IconCheck size={16} />
                    </ActionIcon>
                </Group>
            </Modal>
            <Stack spacing={0}>
                <Center>
                    <ActionIcon color='blue' variant='outline' onClick={() => setOpened(true)}>
                        <IconCalendar size={16} />
                    </ActionIcon>
                </Center>
                <Group position="center">
                    <ActionIcon color='blue' variant='outline' size={14} onClick={() => setDisplay(false)}>
                        <IconX size={12} />
                    </ActionIcon>
                </Group>
            </Stack>
            </>
        )
    } else {
        return (
            <Center onClick={() => setDisplay(true)} style={{cursor: 'pointer'}}>
                {val === null ?
                <Text align='center' color='dimmed' size='sm'>non définie</Text>
                :
                <Text align='center'>{formatDate(val)}</Text>
                }
            </Center>
        )
    }

}

export { ValueDate }
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Alert, Badge, Button, Card, Checkbox, Divider, Group, Mark, Modal, Stack, Table, Text, ActionIcon, ScrollArea, LoadingOverlay, Pagination } from '@mantine/core';
import { Prism } from '@mantine/prism';
import { IconAlertCircle, IconBasket, IconCode, IconFileDescription } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { DocumentContainer } from '../DocumentContainer';

interface DocumentInterface {
    id: number,
    version: number,
    points: number,
    jobs: string,
    sectors: string,
    tags: string,
    content: string,
    name: string,
    email: string,
    phone: string,
    created_date: string,
    created_by: string,
    modified_date: string,
    modified_by: string,
    is_blocked: boolean,
}
interface ResultInterface {
    pages: DocumentInterface[][][],  // page => cv => doc
    nResults: number,
    nPages: number,
    query: string,
}

const resultReducer = (state: ResultInterface, payload: any) => {
    switch (payload.type) {
        case 'RESET':
            return {
                pages: [],
                nResults: 0,
                nPages: 0,
                query: '',
            }
        case 'INIT':
            return {
                pages: Array(payload.nPages).fill(null).map((p: any, i: number) => { 
                    if (i === 0) {
                        return payload.data;
                    } else {
                        return [];
                    }
                }),
                nResults: payload.nResults,
                nPages: payload.nPages,
                query: payload.query,
            }
        case 'ADD':
            return {
                ...state,
                pages: state.pages.map((p,i) => {
                    if (i === payload.page) {
                        return payload.data;
                    } else {
                        return p;
                    }
                })
            }
        default:
            return {
                pages: [],
                nResults: 0,
                nPages: 0,
                query: '',
            }
    }
}

interface PropsInterface {
    search: {
        searchString: string,
        isValidated: boolean,
    },
    handleBack: any,
    handleSelection: any,
}
const SearchResultCV = ({search, handleBack, handleSelection}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const scrollWindow = useRef<HTMLDivElement>(null);
    const [isSearching, setIsSearching] = useState(false);
    const [docId,setDocId] = useState(-1);
    const [displayDoc, setDisplayDoc] = useState(false);
    const [displayQuery, setDisplayQuery] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
    const [scrollDisplayed, setScrollDisplayed] = useState(20);
    const [message, setMessage] = useState<string | null>(null);
    
    const [result,dispatchResult] = useReducer(resultReducer, {
        pages: [],
        nResults: 0,
        nPages: 0,
        query: '',
    });

    const [selection, setSelection] = useState<number[]>([]);
    const toggleSelectionRow = (id: number) => setSelection((current) =>
        current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );
    const toggleSelectionAll = () => setSelection(
        (current) => (current.length === result.pages[activePage-1].length ? [] : result.pages[activePage-1].map((items: DocumentInterface[]) => items[0].id))
    );

    const attachToBasket = () => {
        if (handleSelection === null) return;
        if (selection.length === 0) return;
        handleSelection(search.searchString, [...selection]);
    };

    // Lorsqu'on change de page, on supprime la sélection éventuelle en cours.
    useEffect(() => {
        setSelection([]);
    }, [activePage]); // eslint-disable-line

    // On lance la recherche.
    useEffect(() => {
        if (search.searchString === '') {
            dispatchResult({type: 'RESET'});
            return;
        }
        if (isSearching) return;
        setIsSearching(true);
        dispatchResult({type: 'RESET'});
        const F = new FormData();
        F.append('search', search.searchString);
        F.append('page', '0');
        F.append('is_validated', search.isValidated ? '1' : '0');
        myContext.httpClient.post(`${myContext.apiAddress}/search_cv`, F).then((result: any) => {
            if (result.data.status === true) {
                setScrollDisplayed(20);
                dispatchResult({ type: 'INIT', data: result.data.data.data, nPages: result.data.data.nPages, nResults: result.data.data.nResults, query: result.data.data.query });
                setMessage(null);
            } else {
                setMessage(result.data.message || null);
            }
            setIsSearching(false);
        });
    }, [search]); // eslint-disable-line

    const addPage = (page: number) => {
        setIsSearching(true);
        const F = new FormData();
        F.append('search', search.searchString);
        F.append('page', page + '');
        F.append('is_validated', search.isValidated ? '1' : '0');
        myContext.httpClient.post(`${myContext.apiAddress}/search_cv`, F).then((result: any) => {
            if (result.data.status === true) {
                dispatchResult({ type: 'ADD', page: page, data: result.data.data.data });
                setMessage(null);
            } else {
                setMessage(result.data.message || null);
            }
            setIsSearching(false);
        });
    }

    const highlight = (s: string) => {
        const terms: string[][] = [];
        let p: number = 0;
        let b0: number = s.indexOf('{{', p);
        let b1: number = -1;
        while (b0 !== -1) {
            b1 = s.indexOf('}}', b0);
            if (b1 === -1) {
                break;
            }
            terms.push([ '0' , s.substring(p, b0)]);
            terms.push([ '1' , s.substring(b0+2, b1)]);
            p = b1 + 2;
            b0 = s.indexOf('{{', p);
        }
        if (p < s.length) {
            terms.push([ '0' , s.substring(p)]);
        }
        return (
            <>
            {terms.map((t, tIdx) => (
            <span key={`term-${tIdx}`}>
                {t[0] === '0' && <>{t[1]}</>}
                {t[0] === '1' && <><Mark>{t[1]}</Mark></>}
            </span>
            ))}
            </>
        )
    }

    useEffect(() => {
        if (scrollWindow.current !== undefined && scrollWindow.current !== null) {
            if (Math.abs(scrollWindow.current.scrollHeight - scrollWindow.current.scrollTop - scrollWindow.current.clientHeight) < 9) {
                if (scrollDisplayed < result.pages[activePage-1].length) {
                    setScrollDisplayed(scrollDisplayed + 20);
                }
            }
        }
    }, [scrollPosition.y]); // eslint-disable-line

    return (
        <>
        <Modal
            opened={displayDoc}
            onClose={() => { setDisplayDoc(false); setDocId(-1)}}
            title={`Curriculum vitae ${docId}`}
            fullScreen
        >
            <DocumentContainer
                documentId={docId}
                isEdition={false}
            />
        </Modal>
        <Modal
            opened={displayQuery}
            onClose={() => setDisplayQuery(false)}
            title="Requête SQL"
            fullScreen
        >
            <ScrollArea style={{height: '80vh'}}>
            <Prism language="sql">{result.query}</Prism>
            </ScrollArea>
        </Modal>
        <Card style={{ minHeight: '100px' }}>
            {/* ---------------------------------- 
                        partie affichage 
                ---------------------------------- */}
            <LoadingOverlay visible={isSearching} />
            
            {(!isSearching && search.searchString !== '' && result.nResults === 0) &&
                <Group position="apart">
                    <Alert icon={<IconAlertCircle size={16} />} title="Aucun résultat" color={message === null ? 'blue' : 'red'}>
                        <Text>La recherche &laquo; {search.searchString} &raquo; n'a fourni aucun résultat.</Text>
                        {message && <Text style={{ fontWeight: '700'}} pt='xs' pb='xs'>{message}</Text>}
                    </Alert>
                    <Button color="blue" variant="outline" onClick={() => handleBack({...search}) }>
                        Retour arrière
                    </Button>
                    <ActionIcon variant="outline" color="blue" onClick={() => setDisplayQuery(true)}>
                        <IconCode size={16} />
                    </ActionIcon>
                </Group>
            }

            {result.pages.length > 0 && <>
            
                {(result.pages[activePage - 1].length > 0) &&
                <Group position="apart" mr={5} pt={5}>
                    <Group>
                        <Checkbox
                            onChange={toggleSelectionAll}
                            checked={selection.length === result.pages[activePage-1].length}
                            indeterminate={selection.length > 0 && selection.length !== result.pages[activePage-1].length}
                            transitionDuration={0}
                        />
                        <Text>sélectionner toute la page</Text>
                        {(selection.length > 0) &&
                        <ActionIcon color="blue" variant="outline"
                            onClick={() => attachToBasket()}
                        >
                            <IconBasket size={16} />
                        </ActionIcon>
                        }
                    </Group>
                    <Group>
                        <Pagination page={activePage} onChange={(p: number) => {
                                if (p !== activePage) {
                                    if (result.pages[p-1].length === 0) {
                                        addPage(p-1);
                                    }
                                }
                                setActivePage(p);
                            }} 
                            total={result.nPages} initialPage={1}
                        />
                        <ActionIcon variant="outline" color="blue" onClick={() => setDisplayQuery(true)}>
                            <IconCode size={16} />
                        </ActionIcon>
                    </Group>
                    <Button color="blue" variant="outline" onClick={() => handleBack({...search}) }>
                        Retour arrière
                    </Button>
                </Group>
                }
                
                <Divider
                    labelPosition="center"
                    label={
                        <>
                        <Text color="dimmed" size="sm">{result.nResults} CV trouvés,</Text><Badge color="teal">{result.pages[activePage-1].length}</Badge><Text color="dimmed">affichés</Text>
                        </>
                    }
                />
                <ScrollArea style={{height: 'calc(100vh - 150px)'}} viewportRef={scrollWindow} onScrollPositionChange={onScrollPositionChange}>
                <Table highlightOnHover><tbody>
                {result.pages[activePage-1].filter((dd: DocumentInterface[], dIdx:number) => dIdx < scrollDisplayed).map((dd: DocumentInterface[], dIdx: number) => (
                    <tr key={`document-${dd[0].id}`}>
                        <td>
                            {!dd[0].is_blocked &&
                            <Checkbox
                                checked={selection.includes(dd[0].id)}
                                onChange={() => toggleSelectionRow(dd[0].id)}
                                transitionDuration={0}
                            />
                            }
                        </td>
                        <td>
                            <Stack spacing={2} mb='xs' style={{width: '100%'}}>
                                <Group position="apart" style={{width: '100%'}}>
                                    <Text color="blue" weight={700}>{highlight(dd[0].name)}</Text>
                                    {dd[0].email.indexOf('{{') !== -1 && <Text>{highlight(dd[0].email)}</Text>}
                                    {dd[0].phone.indexOf('{{') !== -1 && <Text>{highlight(dd[0].phone)}</Text>}
                                    <ActionIcon variant="outline" color="blue" mr="md"
                                        onClick={() => { setDocId(dd[0].id); setDisplayDoc(true) }}
                                    >
                                        <IconFileDescription size={16} />
                                    </ActionIcon>
                                </Group>
                                <Group spacing="xs">
                                    {('sectors' in dd[0] && dd[0].sectors.length > 0) && dd[0].sectors.split(';').map((s:string,idx:number) => (
                                        <Badge key={`doc-${dd[0].id}-sector-${idx}`} style={{textTransform: 'none'}}>{highlight(s)}</Badge>
                                    ))}
                                    {('jobs' in dd[0] && dd[0].jobs.length > 0) && dd[0].jobs.split(';').map((s:string,idx:number) => (
                                        <Badge key={`doc-${dd[0].id}-job-${idx}`} style={{textTransform: 'none'}}>{highlight(s)}</Badge>
                                    ))}
                                    {('tags' in dd[0] && dd[0].tags.length > 0) && dd[0].tags.split(';').map((s:string,idx:number) => (
                                        <Badge key={`doc-${dd[0].id}-tag-${idx}`} style={{textTransform: 'none'}}>{highlight(s)}</Badge>
                                    ))}
                                </Group>
                                {'content' in dd[0] && <Text size="sm">{highlight(dd[0].content)}</Text>}
                                {dd.length > 1 && 
                                <Group>
                                    <Text>Autres versions</Text>
                                    {dd.filter((d, i) => i !== 0).map((d,i) => 
                                    <Button compact color="blue" variant="outline" key={`other-version-${dd[0].id}-${i}`}
                                        onClick={() => { setDocId(d.id); setDisplayDoc(true) }}
                                    >
                                        {d.version}
                                    </Button>
                                    )}
                                </Group>}
                            </Stack>
                        </td>
                    </tr>
                ))}
                </tbody></Table>
                </ScrollArea>
            
            </>}

        </Card>
        </>
    )
}

export { SearchResultCV }
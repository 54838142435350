import { useContext, useState } from 'react';
import { TextInput, PasswordInput, Anchor, Paper, Stack, Text, Title, Container, Group, Button, Loader, Center, Drawer } from '@mantine/core';
import { useForm } from '@mantine/form';

import AppContext from '../../shared/AppContext';
import { RecoveryContainer } from './recovery';
import { AlertNotification } from '../../shared/AlertNotification';

const LoginContainer = () => {

    const myContext = useContext(AppContext);

    // Les images vont de 000 à 048
    const imageNumber = '002'; // ((1000 + (Math.floor((Math.floor(Date.now()/1000))/(24*3600)) % 49))+'').substring(1);
    const backgroundImage = `url(/assets/${imageNumber}.jpeg)`;
    
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isRecoveryMode, setIsRecoveryMode ] = useState(false);

    const form = useForm({
        initialValues: {
            domain: localStorage.getItem('domain') || '',
            nickname: '',
            password: '',
            terms: true,
        },
        validate: {
            domain: (val) => (/^[A-Z]{3,10}$/.test(val) ? null : 'Domaine invalide'),
            nickname: (val) => (/^[a-zA-Z0-9]{2,20}$/.test(val) ? null : 'Identifiant invalide'),
            password: (val) => (val.length < 8 ? 'Le mot de passe doit au moins contenir 8 caractères' : null),
        },
    });

    const login = () => {
        setIsLoading(true);
        const myForm = new FormData();
        myForm.append('dom', form.values.domain);
        myForm.append('usr', form.values.nickname);
        myForm.append('pwd', form.values.password);
        myContext.httpClient.post(myContext.apiAddress + '/login', myForm).then((result: any) => {
            setIsLoading(false);
            if (result.data.status === true) {
                const A = {
                    domain: form.values.domain,
                    id: result.data.data.id,
                    nickname: result.data.data.nickname,
                    firstname: result.data.data.firstname,
                    lastname: result.data.data.lastname,
                    email: result.data.data.email,
                    avatar: result.data.data.avatar,
                    lastHttRequestTime: (new Date()).getTime(),
                    maxItemsPerPage: parseInt(result.data.data.maxItemsPerPage),
                    maxUploadSize: parseInt(result.data.data.maxUploadSize),
                    maxInactivityTime: parseInt(result.data.data.maxInactivityTime),
                    acceptedFiles: result.data.data.acceptedFiles,
                };
                myContext.setAccount({...A});
                localStorage.setItem('account', JSON.stringify(A));
                localStorage.setItem('domain', form.values.domain);
                console.log(`User log in at ${new Date()}`);
            } else {
                AlertNotification({message: result.data.message || "accès refusé" });
            }
        })
    }

    return (
        <>
        <Drawer
            opened={isRecoveryMode}
            onClose={() => setIsRecoveryMode(false)}
            title="Mot de passe perdu"
            padding="xl"
            size="xl"
            position="right"
        >
            <RecoveryContainer />
        </Drawer>
        <div style={{width: '100vw', height: '100vh', margin: '0', backgroundImage: backgroundImage, backgroundSize: "cover"}}>
            <Container size={420} py={80} >
                <Title
                    align="center"
                    sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900, color: 'white', textShadow: '1px 1px 10px violet' })}
                >
                    5feet
                </Title>
                <Text align='center' color='white' style={{textShadow: '1px 1px 10px violet'}}>
                    v{myContext.version}
                </Text>
                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                    {(isLoading) ? (
                    <>
                        <Center>
                            <Loader size="lg" />        
                        </Center>
                        <Center>
                            Demande de connexion en cours...
                        </Center>
                    </>
                    ):(
                    <form onSubmit={form.onSubmit(() => { login() })}>
                        <Stack>
                            <TextInput
                                required
                                label="Domaine"
                                placeholder="Votre nom de domaine"
                                value={form.values.domain}
                                onChange={(event) => form.setFieldValue('domain', event.currentTarget.value.toLocaleUpperCase().trim())}
                                error={form.errors.domain && 'Domaine invalide'}
                            />
                            <TextInput
                                required
                                label="Identifiant"
                                placeholder="Votre identifiant de connexion"
                                value={form.values.nickname}
                                onChange={(event) => form.setFieldValue('nickname', event.currentTarget.value)}
                                error={form.errors.nickname && 'Identifiant invalide'}
                            />
                            <PasswordInput
                                required
                                label="Mot de passe"
                                placeholder="Your password"
                                value={form.values.password}
                                onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                                error={form.errors.password && 'Le mot de passe doit au moins contenir 8 caractères'}
                            />
                        </Stack>
                        <Group position="apart" mt="xl">
                            <Button type="submit">Se connecter</Button>
                            <Anchor
                                component="button"
                                type="button"
                                color="dimmed"
                                onClick={() => setIsRecoveryMode(true)}
                                size="xs"
                            >
                                Mot de passe oublié ?
                            </Anchor>
                        </Group>
                    </form>
                    )}
                </Paper>
            </Container>
        </div>
        </>
    );
}

export { LoginContainer }

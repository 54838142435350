import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Alert, Badge, Card, Center, Divider, Group, Loader, RingProgress, ScrollArea, Text, TextInput } from '@mantine/core';
import { IconAlertCircle, IconCalendar, IconCurrencyEuro, IconMilitaryAward, IconRefresh, IconUserOff, IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';
import { AlertNotification } from '../../shared/AlertNotification';
import { UserSelector } from '../../shared/UserSelector';
import { toFrenchDate } from '../../../services/functions';

interface UserInterface {
    id: number,
    label: string
}

interface NeedInterface {
    id: number,
    client_name: string,
    client_mission: string,
    client_localisation: string,
    client_duration: string,
    client_budget: number,
    created_date: string,
    created_by: string,
    affected_date: string | null,
    affected_id: number | null,
    affected_at: string | null,
    is_validator: number,
    is_budget: number,
    is_soon: number,
    closed_why: string | null,
    closed_date: string,
    is_parked: boolean,
    source_name: string,
    search: string,
    notifications: number,
}

interface NeedsInterface {
    size: number,
    parked: NeedInterface[],
    unparked: NeedInterface[],
}

interface PropsInterface {
    isOpen: boolean,
}

const ListNeed = ({isOpen}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [needs, setNeeds] = useState<NeedsInterface | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<UserInterface | null>(null); // {id: myContext.account.id, label: myContext.account.firstname} // si on veut prendre l'utilisateur connecté par défaut
    const [search, setSearch] = useState('');

    useEffect(() => {
        setIsLoading(true);
    }, [isOpen]);
    
    useEffect(() => {
        if (!isLoading) return;
        myContext.httpClient.get(`${myContext.apiAddress}/list_need?open=${isOpen ? 1 : 0}`).then((res:any) => {
            setIsLoading(false);
            if (res.data.status === true) {
                setNeeds({
                    size: res.data.data.length,
                    parked: res.data.data.filter((n:NeedInterface) => n.is_parked),
                    unparked: res.data.data.filter((n:NeedInterface) => !n.is_parked),
                });
            } else {
                AlertNotification({message: res.data.message || 'Sorry, unable to list the current needs.'});
            }
        });
    }, [isLoading]); // eslint-disable-line

    return (
        <>
        <Group position='apart'>
            <Group>
                <TitleContainer>
                    Liste des besoins {isOpen ? 'ouverts' : 'fermés'}
                </TitleContainer>
                <Badge>{needs?.unparked.length}</Badge>
            </Group>
            <TextInput
                placeholder='mot recherché'
                value={search}
                onChange={(event) => setSearch(event.currentTarget.value.trim().toLocaleLowerCase())}
            />
            <Group>
                {user === null ?
                <IconUserOff size={16} />
                :
                <>
                <Badge>{user.label}</Badge>
                <ActionIcon color="blue" variant="outline" onClick={() => setUser(null)}>
                    <IconX size={16} />
                </ActionIcon>
                </>
                }
                <UserSelector 
                    handle={(u: UserInterface) => setUser({...u})}
                />
                <ActionIcon color='blue' variant='outline' onClick={() => setIsLoading(true)}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
        </Group>
        <Center>
        {isLoading && 
            <Group>
                <Loader/>
                <Text>Chargement en cours...</Text>
            </Group>
        }
        {(!isLoading && needs !== null && needs.size === 0) && 
            <Alert icon={<IconAlertCircle size={16} />} title="Besoins" color="red">
                Aucun besoin {isOpen ? 'ouvert' : 'fermé'}.
            </Alert>
        }
        {(!isLoading && needs !== null && needs.size > 0) && 
            <ScrollArea style={{height: 'calc(100vh - 120px)'}}>
            {needs.unparked.filter((need: NeedInterface) => ((user === null || need.affected_id === user.id) && (search === '' || need.search.includes(search)))).map((need: NeedInterface, nIdx: number) => (
                <Card key={`need-${nIdx}`}
                    component={Link} 
                    to={`/need/process/${need.id}`}
                    m='xs'
                >
                    <Text color={need.affected_at === null ? 'orange' : 'teal'}>
                        {need.notifications > 0 && 
                        <Loader color="red" variant="dots" />
                        }
                        {(isOpen && (new Date()).getTime() - (new Date(need.created_date)).getTime() < 1000 * 3600 * 24 * 8) &&
                        <Badge color="yellow">new</Badge>
                        }
                        {need.client_name} / {need.client_mission} / {need.client_duration} / {need.client_localisation}
                    </Text>
                    <Text color="dimmed" size="sm">
                        Besoin n° {need.id}, créé le {need.created_date} par {need.created_by}, origine : {need.source_name}
                        {!isOpen && <span>, fermé le {toFrenchDate(need.closed_date)}</span>}
                    </Text>
                    <Group>
                        <Text align='right' size='xs'>
                            {need.client_budget.toLocaleString()} k€
                        </Text>
                        <RingProgress size={30} thickness={9} 
                            label={<Text align="center"><IconMilitaryAward size={16}/></Text>}
                            sections={[{value: need.is_validator, color: 'teal'}]}
                        />
                        <RingProgress size={30} thickness={9} 
                            label={<Text align="center"><IconCurrencyEuro size={16}/></Text>}
                            sections={[{value: need.is_budget, color: 'teal'}]}
                        />
                        <RingProgress size={30} thickness={9} 
                            label={<Text align="center"><IconCalendar size={16}/></Text>}
                            sections={[{value: need.is_soon, color: 'teal'}]}
                        />
                        {need.affected_at === null ?
                        <Badge color="orange">non affecté</Badge>
                        :
                        <Badge color="teal">{need.affected_at}</Badge>
                        }
                        {!isOpen && <>
                            {need.closed_why === 'won' ? <Badge color="teal">Gagné</Badge> :
                            (need.closed_why === 'lost' ? <Badge color="orange">Perdu</Badge> : <Badge>{need.closed_why}</Badge>)
                            }
                        </>}
                    </Group>
                </Card>
            ))}
                <Divider label='Besoins mis en attente' labelPosition="center"/>
            {needs.parked.filter((need: NeedInterface) => ((user === null || need.affected_id === user.id) && (search === '' || need.search.includes(search)))).map((need: NeedInterface, nIdx: number) => (
                <Card key={`need-${nIdx}`}
                component={Link} 
                to={`/need/process/${need.id}`}
                m='xs'
            >
                <Text color={need.affected_at === null ? 'orange' : 'teal'}>
                    {need.client_name} / {need.client_mission} / {need.client_duration} / {need.client_localisation}
                </Text>
                <Text color="dimmed" size="sm">
                    Besoin n° {need.id}, créé le {need.created_date} par {need.created_by}, origine : {need.source_name}
                    {!isOpen && <span>, fermé le {toFrenchDate(need.closed_date)}</span>}
                </Text>
            </Card>
            ))}
            </ScrollArea>
        }
        </Center>
        </>
    )
}

export { ListNeed }
import { useState } from 'react';
import { ActionIcon, Badge, Box, Group, Stack, Text, TextInput } from '@mantine/core';
import { IconCheck, IconLetterCase, IconTextIncrease, IconPencil, IconX, IconExclamationMark } from '@tabler/icons';

import { TermInterface } from '../interfaces';

interface PropsInterface {
    color: 'teal' | 'red',
    term: TermInterface,
    close: any,
    toggle: any,
    update: any,
}

const TermBox = ({ color, term, close, toggle, update }: PropsInterface) => {

    const [edition, setEdition] = useState(false);
    const [value, setValue] = useState(term.value);
    const [newValue, setNewValue] = useState(term.value);
    const fields: string[] = ['n','h','j','e','f','d','s','l'];

    if (edition) {
        return (
            <Box>
                <Badge
                    size="lg" color="blue"
                    style={{textTransform: 'none'}}
                    rightSection={
                        <Group spacing={0}>
                            <ActionIcon size="xs" radius="xl" variant="transparent"
                                onClick={() => setEdition(false) }
                            >
                                <IconX size={16} />
                            </ActionIcon>
                            <ActionIcon size="xs" radius="xl" variant="transparent"
                                onClick={() => {
                                    setValue(newValue);
                                    setEdition(false);
                                    update(newValue);
                                }}
                            >
                                <IconCheck size={16} />
                            </ActionIcon>
                        </Group>
                    }
                >
                    <TextInput 
                        value={newValue} 
                        onChange={(event) => setNewValue(event.currentTarget.value)}
                    />
                </Badge>
            </Box>
        )
    } else {
        return (
            <Box mb='xs'>
                <Stack spacing={0}>
                    <Text color={color} lineClamp={4}>
                        <Badge color={color}>
                            {term.weight}
                        </Badge>
                        {value}
                    </Text>
                    <Group spacing={3}>
                        <ActionIcon size='xs' radius='xl' variant='transparent'
                            onClick={() => setEdition(true)}
                        >
                            <IconPencil size={16} />
                        </ActionIcon>
                        <ActionIcon size="xs" radius="xl" variant={term['A+'] ? 'outline' : 'transparent'}
                            color={term['A+'] ? 'dark' : 'gray'}
                            onClick={() => toggle('expand')}
                        >
                            <IconTextIncrease size={term['A+'] ? 12 : 16} />
                        </ActionIcon>
                        <ActionIcon size="xs" radius="xl" variant={term['Aa'] ? 'outline' : 'transparent'}
                            color={term['Aa'] ? 'dark' : 'gray'}
                            onClick={() => toggle('caseSensitive')}
                        >
                            <IconLetterCase size={term['Aa'] ? 12 : 16} />
                        </ActionIcon>
                        <ActionIcon size="xs" radius="xl" variant={term['!'] ? 'outline' : 'transparent'}
                            color={term['!'] ? 'dark' : 'gray'}
                            onClick={() => toggle('compulsory')}
                        >
                            <IconExclamationMark size={term['!'] ? 12 : 16} />
                        </ActionIcon>

                        {fields.map((f: string) =>
                            <ActionIcon size="xs" radius="xl" variant={term.fields[f] ? 'outline' : 'transparent'}
                                color={term.fields[f] ? 'dark' : 'gray'}
                                onClick={() => toggle(f)}
                                key={`field-${f}`}
                            >
                                <Text size={term.fields[f] ? 'xs' : 'md'}>{f.toUpperCase()}</Text>
                            </ActionIcon>
                        )}

                        <ActionIcon size="xs" radius="xl" variant='transparent'
                            onClick={() => close() }
                        >
                            <IconX size={16} />
                        </ActionIcon>

                    </Group>
                </Stack>
            </Box>
        )
    }

}

export { TermBox }
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Group, Loader, ScrollArea, SimpleGrid, Table, Text } from '@mantine/core';
import { TitleContainer } from '../../shared/TitleContainer';
import AppContext from '../../shared/AppContext';

interface DocumentInterface {
    id: number,
    label: string,
    category: string,
}

interface IndicatorsInterface {
    cols: string[],
    rows: string[],
    values: number[],
}

interface SourcingInterface {
    message: string | null,
    indicators: IndicatorsInterface | null,
    documents: DocumentInterface[],
}

interface MissionInterface {
    id: number,
    client_name: string,
    manager_name: string,
}
interface MissionsInterface {
    message: string | null,
    list: MissionInterface[],
}

interface NeedInterface {
    id: number,
    client_name: string,
    client_mission: string,
    highlight: number,
}

interface NeedsInterface {
    message: string | null,
    indicators: IndicatorsInterface | null,
    needs: NeedInterface[],
}

interface HuntInterface {
    id: number,
    name: string,
    highlight: number,
}
interface HuntsInterface {
    message: string | null,
    list: HuntInterface[],
}

const HomeContainer = () => {

    const myContext = useContext(AppContext);
    
    const [isSourcingLoaded, setIsSourcingLoaded] = useState(false);
    const [sourcing, setSourcing] = useState<null | SourcingInterface>(null);

    const [isNeedsLoaded, setIsNeedsLoaded] = useState(false);
    const [needs, setNeeds] = useState<null | NeedsInterface>(null);

    const [isMissionsLoaded, setIsMissionsLoaded] = useState(false);
    const [missions, setMissions] = useState<null | MissionsInterface>(null);

    const [isHuntsLoaded, setIsHuntsLoaded] = useState(false);
    const [hunts, setHunts] = useState<null | HuntsInterface>(null);

    useEffect(() => {
        if (isSourcingLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_tasks?family=sourcing`).then((res: any) => {
            setIsSourcingLoaded(true);
            if (res.data.status === true) {
                setSourcing({
                    message: null,
                    indicators: res.data.data.indicators,
                    documents: res.data.data.documents,
                });
            } else {
                setSourcing({
                    message: res.data.message || 'unable to retrieve data',
                    indicators: null,
                    documents: [],
                });
            }
        })
    }, [isSourcingLoaded]); // eslint-disable-line

    useEffect(() => {
        if (isMissionsLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_tasks?family=mission`).then((res: any) => {
            setIsMissionsLoaded(true);
            if (res.data.status === true) {
                setMissions({
                    message: null,
                    list: res.data.data.missions || [],
                });
            } else {
                setMissions({
                    message: res.data.message || 'unable to retrieve data',
                    list: [],
                });
            }
        })
    }, [isMissionsLoaded]); // eslint-disable-line

    useEffect(() => {
        if (isNeedsLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_tasks?family=need`).then((res: any) => {
            setIsNeedsLoaded(true);
            if (res.data.status === true) {
                setNeeds({
                    message: null,
                    indicators: res.data.data.indicators,
                    needs: res.data.data.needs === null ? [] : res.data.data.needs,
                });
            } else {
                setNeeds({
                    message: res.data.message || 'unable to retrieve data',
                    indicators: null,
                    needs: [],
                });
            }
        })
    }, [isNeedsLoaded]); // eslint-disable-line

    useEffect(() => {
        if (isHuntsLoaded) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_tasks?family=hunt`).then((res: any) => {
            setIsHuntsLoaded(true);
            if (res.data.status === true) {
                setHunts({
                    message: null,
                    list: res.data.data === null ? [] : res.data.data,
                });
            } else {
                setHunts({
                    message: res.data.message || 'unable to retrieve data',
                    list: [],
                });
            }
        })
    }, [isHuntsLoaded]); // eslint-disable-line

    return (
        <>
        <TitleContainer>Bonjour {myContext.account.firstname}</TitleContainer>

        <SimpleGrid cols={2}>

            <Card withBorder>
                <Text color="teal">CV-thèque / Mes actions à réaliser</Text>
                {!isSourcingLoaded && 
                <Group position="center">
                    <Loader size="sm"/>
                    <Text>chargement en cours</Text>
                </Group>
                }
                {(isSourcingLoaded && sourcing !== null && sourcing.documents.length === 0) && 
                <Text>aucune action à réaliser</Text>
                }
                {(isSourcingLoaded && sourcing !== null && sourcing.documents.length > 0) &&
                <>
                <Group>
                    <Badge color="green">{sourcing.documents.length}</Badge>
                    <Text>CV attend{sourcing.documents.length === 1 ? '' : 'ent'} une action de votre part :</Text>
                </Group>
                <ScrollArea style={{height: '200px'}}>
                    {sourcing.documents.map((d: DocumentInterface, idx: number) => (
                    <Group key={`sourcing-document-${idx}`}>
                        <Button
                            variant="subtle"
                            component={Link} to={`/cvtheque/document/${d.id}`}
                        >
                            <Badge size="xs">{d.category}</Badge>
                            <Text ml="xs">{d.label}</Text>
                        </Button>
                    </Group>
                    ))}
                </ScrollArea>
                </>
                }
            </Card>
            <Card withBorder>
                <Text color="teal">CV-thèque / Mes indicateurs</Text>
                {!isSourcingLoaded && 
                <Group position="center">
                    <Loader size="sm"/>
                    <Text>chargement en cours</Text>
                </Group>
                }
                {(isSourcingLoaded && sourcing !== null && sourcing.indicators === null) && 
                <Text>aucun indicateur</Text>
                }
                {(isSourcingLoaded && sourcing !== null && sourcing.indicators !== null) && 
                <>
                <Text>Mois en cours, et les deux mois précédents :</Text>
                <Table highlightOnHover withBorder withColumnBorders>
                    <tbody>
                        <tr>
                            <td></td>
                            {sourcing.indicators.cols.map((c: string, idx) => (
                                <td key={`sourcing-indicator-0-${idx}`} style={{paddingRight: '1em'}}>
                                    <Text color="dimmed" size="sm">{c}</Text>
                                </td>
                            ))}
                        </tr>
                        {sourcing.indicators.rows.map((r: string, rIdx: number) => (
                        <tr key={`sourcing-indicator-row-${rIdx}`}>
                            <td>
                                <Text color="dimmed" size="sm">{r}</Text>
                            </td>
                            {sourcing.indicators?.cols.map((c: string, cIdx) => (
                                <td key={`sourcing-indicator-${rIdx}-${cIdx}`}>
                                    <Text color="teal" weight="bold" align="center">
                                        {sourcing.indicators?.values[rIdx * sourcing.indicators.rows.length + cIdx]}
                                    </Text>
                                </td>
                            ))}
                        </tr>
                        ))}
                    </tbody>
                </Table>
                </>
                }
            </Card>

            <Card withBorder>
                <Text color="teal">Besoins en cours</Text>
                {!isNeedsLoaded && 
                <Group position="center">
                    <Loader size="sm"/>
                    <Text>chargement en cours</Text>
                </Group>
                }
                {(isNeedsLoaded && needs !== null && needs.needs.length === 0) && 
                <Text>aucune action à réaliser</Text>
                }
                {(isNeedsLoaded && needs !== null && needs.needs.length > 0) &&
                <>
                <Group>
                    <Badge color="green">{needs.needs.length}</Badge>
                    <Text>Besoin{needs.needs.length > 1 ? 's' : ''} à votre charge :</Text>
                </Group>
                <ScrollArea style={{height: '200px'}}>
                    {needs.needs.map((d: NeedInterface, idx: number) => (
                    <Group key={`needs-need-${idx}`}>
                        <Button
                            variant="subtle"
                            component={Link} to={`/need/process/${d.id}`}
                        >
                            {d.highlight > 0 && 
                            <Loader color="red" variant="dots" />
                            }
                            <Text>{d.client_name} / {d.client_mission}</Text>
                        </Button>
                    </Group>
                    ))}
                </ScrollArea>
                </>
                }
            </Card>
            <Card withBorder>
                <Text color="teal">Besoins / Mes indicateurs</Text>
                {!isNeedsLoaded && 
                <Group position="center">
                    <Loader size="sm"/>
                    <Text>chargement en cours</Text>
                </Group>
                }
                {(isNeedsLoaded && needs !== null && needs.indicators === null) && 
                <Text>aucun indicateur</Text>
                }
                {(isNeedsLoaded && needs !== null && needs.indicators !== null) && 
                <>
                <Text>Mois en cours, et les deux mois précédents :</Text>
                <Table highlightOnHover withBorder withColumnBorders>
                    <tbody>
                        <tr>
                            <td></td>
                            {needs.indicators.cols.map((c: string, idx) => (
                                <td key={`needs-indicator-0-${idx}`} style={{paddingRight: '1em'}}>
                                    <Text color="dimmed" size="sm">{c}</Text>
                                </td>
                            ))}
                        </tr>
                        {needs.indicators.rows.map((r: string, rIdx: number) => (
                        <tr key={`needs-indicator-row-${rIdx}`}>
                            <td>
                                <Text color="dimmed" size="sm">{r}</Text>
                            </td>
                            {needs.indicators?.cols.map((c: string, cIdx) => (
                                <td key={`needs-indicator-${rIdx}-${cIdx}`}>
                                    <Text color="teal" weight="bold" align="center">
                                        {needs.indicators?.values[rIdx * needs.indicators.rows.length + cIdx]}
                                    </Text>
                                </td>
                            ))}
                        </tr>
                        ))}
                    </tbody>
                </Table>
                </>
                }
            </Card>
            
            <Card withBorder>
                <Text color="teal">Missions en cours</Text>
                {!isMissionsLoaded && 
                <Group position="center">
                    <Loader size="sm"/>
                    <Text>chargement en cours</Text>
                </Group>
                }
                {(isMissionsLoaded && missions !== null && missions.list.length === 0) && 
                <Text>aucune mission suivie</Text>
                }
                {(isMissionsLoaded && missions !== null && missions.list.length > 0) &&
                <>
                <Group>
                    <Badge color="green">{missions.list.length}</Badge>
                    <Text>Mission{missions.list.length > 1 ? 's' : ''} à votre charge :</Text>
                </Group>
                <ScrollArea style={{height: '200px'}}>
                    {missions.list.map((m: MissionInterface, idx: number) => (
                    <Group key={`mission-${m.id}`}>
                        <Button
                            variant="subtle"
                            component={Link} to={`/mission/manage/${m.id}`}
                        >
                            <Text>{m.client_name}</Text>
                            <Text>&nbsp;</Text>
                            <Text color="dimmed">{m.manager_name}</Text>
                        </Button>
                    </Group>
                    ))}
                </ScrollArea>
                </>
                }
            </Card>

            <Card withBorder>
                <Text color="teal">Chasses en cours</Text>
                {!isHuntsLoaded && 
                <Group position="center">
                    <Loader size="sm"/>
                    <Text>chargement en cours</Text>
                </Group>
                }
                {(isHuntsLoaded && hunts !== null && hunts.list.length === 0) && 
                <Text>aucune action à réaliser</Text>
                }
                {(isHuntsLoaded && hunts !== null && hunts.list.length > 0) &&
                <>
                <Group>
                    <Badge color="green">{hunts.list.length}</Badge>
                    <Text>Chasse{hunts.list.length > 1 ? 's' : ''} à votre charge :</Text>
                </Group>
                <ScrollArea style={{height: '200px'}}>
                    {hunts.list.map((d: HuntInterface, idx: number) => (
                    <Group key={`hunts-hunt-${idx}`}>
                        <Button
                            variant="subtle"
                            component={Link} to={`/hunt/manage/${d.id}`}
                        >
                            {d.highlight > 0 && 
                            <Loader color="red" variant="dots" />
                            }
                            <Text>{d.name}</Text>
                        </Button>
                    </Group>
                    ))}
                </ScrollArea>
                </>
                }
            </Card>

        </SimpleGrid>
        </>
    )
}

export { HomeContainer }
import { useContext, useState } from 'react';
import { ActionIcon, Card, ColorSwatch, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface PropsInterface {
    hunt_id: number,
    cv_id: number,
    value: -2 | -1 | 0 | 1 | null, // undefined, non, peut-être, oui, n/a
    handle: any,
}

const Choice = ({hunt_id, cv_id, value, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [val, setVal] = useState(value);
    const colors: string[] = ['gray', 'red', 'yellow', 'green'];
    const [display, setDisplay] = useState(false);
    const [working, setWorking] = useState(false);

    const save = (v: -2 | -1 | 0 | 1) => {
        if (v === val) return;
        setWorking(true);
        myContext.httpClient.get(`${myContext.apiAddress}/update_process_hunt?hunt_id=${hunt_id}&cv_id=${cv_id}&what=metadata&code=bm_score&value=${v}`).then((res:any) => {
            setWorking(false);
            setDisplay(false);
            if (res.data.status === true) {
                setVal(v);
                handle(v);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <>
        {display ?
        <>
        <Card withBorder>
            <LoadingOverlay visible={working} />
            <Group>
                <Text>Nouvelle position :</Text>
                <Stack>
                    <Group>
                        <ActionIcon color="blue" variant="outline" onClick={() => save(-2)}>
                            <ColorSwatch size={12} color={colors[0]} />
                        </ActionIcon>
                        <Text>non défini</Text>
                    </Group>
                    <Group>
                        <ActionIcon color="blue" variant="outline" onClick={() => save(-1)}>
                            <ColorSwatch size={12} color={colors[1]} />
                        </ActionIcon>
                        <Text>refus</Text>
                    </Group>
                    <Group>
                        <ActionIcon color="blue" variant="outline" onClick={() => save(0)}>
                            <ColorSwatch size={12} color={colors[2]} />
                        </ActionIcon>
                        <Text>peut-être</Text>
                    </Group>
                    <Group>
                        <ActionIcon color="blue" variant="outline" onClick={() => save(1)}>
                            <ColorSwatch size={12} color={colors[3]} />
                        </ActionIcon>
                        <Text>retenu</Text>
                    </Group>
                </Stack>
                <ActionIcon color="blue" variant="outline" onClick={() => setDisplay(false)}>
                    <IconX size={16} />
                </ActionIcon>
            </Group>
        </Card>
        </>
        :
        <Group>
            <Text>Position actuelle :</Text>
            <ActionIcon color="blue" variant="outline" onClick={() => setDisplay(true)}>
                <ColorSwatch
                    size={12}
                    color={val === null ? '' : colors[val + 2]}
                />
            </ActionIcon>
        </Group>
        }
        </>
    )
}

export { Choice }
import { useContext, useState } from 'react';
import { ActionIcon, Center, ColorSwatch, Group, Loader, Stack, Text } from '@mantine/core';
import { IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { ListItemSelector } from '../../shared/ListItemSelector';

interface itemInterface {
    id: number,
    value: string,
}

interface PropsInterface {
    needId: number,
    cvId: number,
    name: string,
    value: string | null,
    handleChange: any,
}

const ValuePortage = ({needId, cvId, name, value, handleChange}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [display, setDisplay] = useState(false);
    const [val, setVal] = useState<string | null>(value);
    const [saving, setSaving] = useState(false);

    const save = (s: string) => {
        setSaving(true);
        const F = new FormData();
        F.append('what', 'form_portage');
        F.append('value', s);
        const api = `${myContext.apiAddress}/update_process_need?need_id=${needId}&cv_id=${cvId}`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setSaving(false);
            if (res.data.status === true) {
                setVal(s);
                handleChange(s);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setDisplay(false);
        });
    }

    if (saving) {
        return (
            <Center>
                <Loader size="sm" />
            </Center>
        )
    } else if (display) {
        return (
            <Group position="center">
                <ActionIcon color='blue' variant='outline' onClick={() => setDisplay(false)}>
                    <IconX size={16} />
                </ActionIcon>
                <ListItemSelector
                    title={`Véhicule juridique pour ${name}`}
                    items={[
                        {id: 0, value: 'AUTO'},
                        {id: 1, value: 'EURL'},
                        {id: 2, value: 'SARL'},
                        {id: 3, value: 'SAS'},
                        {id: 4, value: 'SASU'},
                        {id: 5, value: 'HURA portage'},
                        {id: 6, value: 'autre portage'},
                        {id: 7, value: 'non défini'},
                    ]}
                    handleSelection={(item: itemInterface) => {
                        save(item.value);
                    }}
                    multi={false}
                />
            </Group>
        )
    } else {
        return (
            <Stack spacing={0} onClick={() => setDisplay(true)} style={{cursor: 'pointer'}}>
                {val === null ?
                <Text size="sm" color="dimmed">non défini</Text>
                :
                <Stack align="center" spacing={0}>
                    <ColorSwatch color={(val === 'autre portage' || val === 'non défini') ? 'red' : 'green'} size={12} />
                    <Text size="xs" lineClamp={1}>{val}</Text>
                </Stack>
                }
            </Stack>
        )
    }

}

export { ValuePortage }
import { useContext, useState } from 'react';
import { ActionIcon, ColorSwatch, Group, LoadingOverlay, Modal, Stack, Text } from '@mantine/core';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface PropsInterface {
    needId: number,
    cvId: number,
    nature: 'bm' | 'client',
    value: -2 | -1 | 0 | 1 | null, // undefined, non, peut-être, oui, n/a
    handle: any,
}

const Choice = ({needId, cvId, nature, value, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [val, setVal] = useState(value);
    const colors: string[] = ['gray', 'red', 'yellow', 'green'];
    const [display, setDisplay] = useState(false);
    const [working, setWorking] = useState(false);

    const save = (v: -2 | -1 | 0 | 1) => {
        if (v === val) return;
        setWorking(true);
        myContext.httpClient.get(`${myContext.apiAddress}/update_process_need?need_id=${needId}&cv_id=${cvId}&what=choice&nature=${nature}&value=${v}`).then((res:any) => {
            setWorking(false);
            setDisplay(false);
            if (res.data.status === true) {
                setVal(v);
                handle(v);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <>
        <Modal
            opened={display}
            onClose={() => setDisplay(false)}
            title='Sélectionner une couleur'
        >
            <LoadingOverlay visible={working} />
            <Stack>
                <Group>
                    <ActionIcon color="blue" variant="outline" onClick={() => save(-2)}>
                        <ColorSwatch size={12} color={colors[0]} />
                    </ActionIcon>
                    <Text>non défini</Text>
                </Group>
                <Group>
                    <ActionIcon color="blue" variant="outline" onClick={() => save(-1)}>
                        <ColorSwatch size={12} color={colors[1]} />
                    </ActionIcon>
                    <Text>refus</Text>
                </Group>
                <Group>
                    <ActionIcon color="blue" variant="outline" onClick={() => save(0)}>
                        <ColorSwatch size={12} color={colors[2]} />
                    </ActionIcon>
                    <Text>peut-être</Text>
                </Group>
                <Group>
                    <ActionIcon color="blue" variant="outline" onClick={() => save(1)}>
                        <ColorSwatch size={12} color={colors[3]} />
                    </ActionIcon>
                    <Text>retenu</Text>
                </Group>
            </Stack>
        </Modal>
        <ActionIcon color="blue" variant="outline" onClick={() => setDisplay(true)}>
            <ColorSwatch
                size={12}
                color={val === null ? '' : colors[val + 2]}
            />
        </ActionIcon>
        </>
    )
}

export { Choice }
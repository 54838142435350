import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Card, Center, TextInput } from '@mantine/core';
import { IconCheck } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { TitleContainer } from '../../shared/TitleContainer';

const AddMission = () => {

    const [client, setClient] = useState('');
    const [manager, setManager] = useState('');
    const [label, setLabel] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const myContext = useContext(AppContext);
    const navigate = useNavigate();

    const save = () => {
        const c = client.trim();
        const m = manager.trim();
        const l = label.trim();
        if (c.length === 0 || m.length === 0 || l.length === 0) return;
        const F = new FormData();
        F.append('client', c);
        F.append('manager', m);
        F.append('label', l);
        setIsSaving(true);
        myContext.httpClient.post(`${myContext.apiAddress}/add_mission`, F).then((res:any) => {
            if (res.data.status === true) {
                navigate(`/mission/manage/${res.data.data.id}`, { replace: true })
            } else {
                AlertNotification({message: res.data.message || "unknown error" });
            }
            setIsSaving(false);
        });
    }

    return (
        <>
        <TitleContainer>
            Ajouter une nouvelle mission
        </TitleContainer>
        <Center>
            <Card withBorder radius='lg'
                style={{minWidth: '400px'}}
            >
                <TextInput 
                    required
                    label="Client"
                    placeholder='Nom du client'
                    value={client} 
                    onChange={(event) => setClient(event.currentTarget.value)}
                    disabled={isSaving}
                />
                <TextInput 
                    required
                    label="Manager de transition"
                    placeholder='Prénom NOM'
                    value={manager} 
                    onChange={(event) => setManager(event.currentTarget.value)}
                    disabled={isSaving}
                />
                <TextInput 
                    required
                    label="Libellé"
                    placeholder='Libellé de la mission'
                    value={label} 
                    onChange={(event) => setLabel(event.currentTarget.value)}
                    disabled={isSaving}
                />
                <Center pt='sm'>
                    <Button 
                        leftIcon={<IconCheck size={14} />} 
                        loading={isSaving}
                        disabled={label.trim().length < 2}
                        onClick={() => save()}
                    >
                        Sauvegarder
                    </Button>
                </Center>
            </Card>
        </Center>
        </>
    )
}

export { AddMission }

import { useContext, useState } from 'react';
import { Group } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconFileCertificate } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { Document } from './document';
import { TrashButton } from '../../shared/TrashButton';

interface PropsInterface {
    needId: number,
    cvId: number,
    name: string,
    nature: 'nda' | 'ccv',
    fileId: number | null,
    handleAdd: any,
    handleRemove: any,
}

const File = ({needId, cvId, name, nature, fileId, handleAdd, handleRemove}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ fid, setFid ] = useState<null | number>(fileId);
    const [working, setWorking] = useState(false);

    const saveFile = (inputFiles: File[]) => {
        setWorking(true);
        const F = new FormData();
        F.append('need_id', needId + '');
        F.append('what', 'file_add');
        F.append('nature', nature);
        F.append('cv_id', cvId + '');
        let n = inputFiles[0].name.normalize();
        F.append('document', inputFiles[0], n);
        F.append('filename', n);
        const api = `${myContext.apiAddress}/update_process_need`;
        myContext.httpClient.post(api, F, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                handleAdd(res.data.data.id, res.data.data.created_date);
                setFid(res.data.data.id);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        })
    }

    if (fid === null) {
        return <Dropzone
            onDrop={(files) => saveFile(files)}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={1 * 1024 ** 2}
            maxFiles={1}
            accept={[ 'application/pdf' ]}
            loading={working}
            padding="xs"
        >
            <Dropzone.Idle>
                <Group position="center" spacing={0} style={{ height: '1em', pointerEvents: 'none' }}>
                    <IconFileCertificate size={16} />
                </Group>
            </Dropzone.Idle>
        </Dropzone>
    } else {
        return (
            <Group spacing={5}>
                <Document 
                    needId={needId} 
                    fileId={fid} 
                />
                <TrashButton 
                    label={`Suppression du ${nature === 'nda' ? 'NDA' : 'CV client'} pour ${name}`}
                    api='update_process_need'
                    params={`need_id=${needId}&cv_id=${cvId}&what=file_remove&nature=${nature}`}
                    handle={() => { setFid(null); handleRemove()}}
                />
            </Group>
        )
    }

}

export { File }
import { useContext, useState } from 'react';
import { ActionIcon, Card, createStyles, Group, Loader, Text, Textarea } from '@mantine/core';
import { IconX, IconCheck } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface InputInterface {
    code: string,
    label: string,
    defaultValue: string,
    api: string,
    isEdition: boolean,
    handle: any,
    maxLength?: number,
}

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },
    
    input: {
        height: 'auto',
        paddingTop: 18,
        marginTop: 5,
    },
    
    label: {
        position: 'absolute',
        pointerEvents: 'none',
        color: theme.colors.gray[6],
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}))

const TextareaContainer = ({code, label, defaultValue, api, isEdition, handle, maxLength = -1}: InputInterface) => {

    const { classes } = useStyles();
    const [ oldValue, setOldValue ] = useState(defaultValue || '');
    const [ newValue, setNewValue ] = useState(defaultValue || '');
    const [ isUpdating, setIsUpdating ] = useState(false);
    const myContext = useContext(AppContext);

    const reset = () => {
        setNewValue(oldValue);
    }

    const update = () => {
        setIsUpdating(true);
        const F = new FormData();
        F.append('action', code);
        F.append('code', code);
        F.append('value', newValue);
        myContext.httpClient.post(api, F).then((result: any) => {
            if (result.data.status === true) {
                const v: string = result.data.message === 'done' ? newValue : result.data.message;
                setOldValue(v);
                setNewValue(v);
                if (handle !== null) {
                    handle(v);
                }
            } else {
                setNewValue(oldValue);
                AlertNotification({message: result.data.message || 'unknown error from servor'});
            }
            setIsUpdating(false);
        });
    }

    if (isEdition) {
        return (
            <>
            <Textarea 
                label={label}
                placeholder="à compléter"
                value={newValue}
                onChange={(event) => setNewValue(event.currentTarget.value)}
                classNames={classes} 
                disabled={isUpdating}
                autosize
                minRows={2}
                maxRows={9}
            />
            <Group position="center">
                {(!isUpdating && oldValue !== newValue) && 
                <ActionIcon color="blue" radius="xl" variant="outline" onClick={reset}>
                    <IconX />
                </ActionIcon>}
                {(!isUpdating && oldValue !== newValue && (maxLength < 0 || newValue.length <= maxLength) ) && 
                <ActionIcon color="blue" radius="xl" variant="filled" onClick={update}>
                    <IconCheck />
                </ActionIcon>}
                {isUpdating && <Loader />}
            </Group>
            {maxLength > -1 &&
            <Text size="xs">{newValue.length} / {maxLength} characters</Text>
            }
            </>
        );
    } else {
        return (
            <Card p="xs">
                <Text size="sm" color="dimmed">
                    {label}
                </Text>
                {newValue === '' && <Text>-</Text>}
                {newValue.split('\n').map((s:string, sIdx: number) => (
                    <Text key={`row-${sIdx}`}>{s}</Text>
                ))}
            </Card>
        );
    }

}

export { TextareaContainer }

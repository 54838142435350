import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Badge, Button, ColorSwatch, Divider, Grid, Group, LoadingOverlay, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconDownload, IconRefresh, IconX, IconCloudUpload } from '@tabler/icons';
import { TitleContainer } from "../shared/TitleContainer";
import { AlertNotification } from '../shared/AlertNotification';
import AppContext from '../shared/AppContext';
import { toFrenchDate } from '../../services/functions';

interface ItemInterface {
    user: string,
    when: string,
    nEntries: number,
    dMin: string,
    dMax: string,
    tDebit: number,
    tCredit: number,
}

const BankUpload = () => {

    const myContext = useContext(AppContext);
    const [file, setFile] = useState<File | null>(null);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [uploads, setUploads] = useState<ItemInterface[]>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) return;
        const url = `${myContext.apiAddress}/bank_list`;
        myContext.httpClient.get(url).then((res:any) => {
            if (res.data.status === true) { 
                const uu: ItemInterface[] = res.data.data || [];
                setUploads(uu);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoaded(true);
        });
    }, [loaded]); // eslint-disable-line

    const upload = () => {
        if (file === null) return;
        setUploadingFile(true);
        const F = new FormData();
        F.append('document', file);
        const url = `${myContext.apiAddress}/bank_upload`;
        myContext.httpClient.post(url, F, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res: any) => {
            if (res.data.status === true) {
                setUploads([{...res.data.data}].concat(uploads.map((item: ItemInterface) => {return {...item}})));
                setFile(null);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setUploadingFile(false);
        });
    }

    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Chargement des relevés bancaires
            </TitleContainer>
            <ActionIcon color='blue' variant='outline' onClick={() => { 
                setFile(null); 
                setUploads([]); 
                setLoaded(false); 
            }}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        <Grid>
            <Grid.Col sm={12} md={3}>
                <Divider label='Ajouter un fichier' labelPosition='center' />
                <Dropzone
                    onDrop={(files) => setFile(files[0])}
                    onReject={(files) => console.log('rejected files', files)}
                    radius="md"
                    maxSize={1 * 1024 ** 2}
                    maxFiles={1}
                    accept={[ 'text/csv', ]}
                    loading={uploadingFile}
                >
                    <div style={{ pointerEvents: 'none' }}>
                        <Group position="center">
                        <Dropzone.Accept>
                            <IconDownload size={30} color='blue' stroke={1.5} />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <IconX size={30} color='red' stroke={1.5} />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <IconCloudUpload size={40} stroke={1.5} />
                        </Dropzone.Idle>
                        </Group>
                        <Text align="center" weight={700}>
                            <Dropzone.Accept>Déposer le fichier ici</Dropzone.Accept>
                            <Dropzone.Reject>1 Mo max</Dropzone.Reject>
                            <Dropzone.Idle>Déposer un fichier ou cliquer</Dropzone.Idle>
                        </Text>
                        <Text align="center" size="sm" mt="xs" color="dimmed">
                            CSV only
                        </Text>
                    </div>
                </Dropzone>
                {file !== null &&
                <Stack>
                    <Group>
                        <Text>{file.name}</Text>
                        {!uploadingFile &&
                        <ActionIcon color='blue' variant='outline' onClick={() => setFile(null)}>
                            <IconX size={16} />
                        </ActionIcon>
                        }
                    </Group>
                    <Button color='blue' variant='outline' loading={uploadingFile} onClick={() => upload()}>
                        Transmettre
                    </Button>
                </Stack>
                }
            </Grid.Col>
            
            <Grid.Col sm={0} md={1}>

            </Grid.Col>

            <Grid.Col sm={12} md={7}>
                <Divider label='Liste des fichiers chargés' labelPosition='center' />
                <ScrollArea style={{height: 'calc(100vh - 150px)'}}>
                    <LoadingOverlay visible={!loaded} />
                    <Table highlightOnHover><tbody>
                    {uploads.map((item: ItemInterface) => 
                    <tr key={`upload-${item.dMin}-${item.dMax}`}>
                        <td>
                            <Text>Du <b>{toFrenchDate(item.dMin)}</b> au <b>{toFrenchDate(item.dMax)}</b></Text>
                            {item.user === 'me' ?
                            <ColorSwatch color='yellow' size={20} /> 
                            :
                            <Text color="dimmed">{item.user}, le {toFrenchDate(item.when)}</Text>
                            }
                        </td>
                       <td>
                            <Text align='right'>
                                <Badge>{item.nEntries}</Badge>
                            </Text>
                        </td>
                        <td>
                            <Text align='right'>
                                <Badge color='red'>{item.tDebit.toLocaleString(undefined, {minimumFractionDigits: 2})}</Badge>
                            </Text>
                        </td>
                        <td>
                            <Text align='right'>
                                <Badge color='green'>{item.tCredit.toLocaleString(undefined, {minimumFractionDigits: 2})}</Badge>
                            </Text>
                        </td>
                    </tr>
                    )}
                    </tbody></Table>
                </ScrollArea>
            </Grid.Col>

        </Grid>
        </>
    )
}

export { BankUpload }
import { useContext, useEffect, useState } from 'react';
import { Group, Loader, Text } from '@mantine/core';
import AppContext from '../AppContext';
import { ListItemSelector } from '../../shared/ListItemSelector';

interface ItemInterface {
    id: number,
    value: string
}

interface PropsInterface {
    handle: any
}

const UserSelector = ({handle}: PropsInterface) => {
    
    const myContext = useContext(AppContext);
    const [isLoaded,setIsLoaded] = useState(myContext.users === null ? false : true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (isLoaded) return;
        if (isError) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_repository_user`).then((res: any) => {
            if (res.data.status === true) {
                myContext.setUsers({
                    label: 'Utilisateurs',
                    date: (new Date()).getTime(),
                    items: res.data.data,
                });
                setIsLoaded(true);
            } else {
                setIsError(true);
            }
        });
    }); // eslint-disable-line

    const handleSelection = (item: ItemInterface) => {
        handle({id: item.id, label: item.value });
    }

    if (!isLoaded) {
        return (
            <Group>
                <Loader size="sm" />
                <Text size="xs">Chargement des utilisateurs</Text>
            </Group>
        )
    } else if (myContext.users === null) {
        return (
            <Group>
                <Text color="red" weight={700}>aucun utilisateur chargé</Text>
            </Group>
        )
    } else {
        return (
            <ListItemSelector
                title="Sélectionner un utilisateur"
                items={myContext.users.items.map((i: any) => { return { id: i.id, value: i.label} })}
                handleSelection={handleSelection}
                multi={false}
            />
        )
    }
}

export { UserSelector }
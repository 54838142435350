import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Button, CopyButton, Group, LoadingOverlay, Modal, ScrollArea, Table, Text } from '@mantine/core';
import { IconCoinEuro, IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';
import { AlertNotification } from '../../shared/AlertNotification';

interface RowInterface {
    internal_category: string,
    internal_id: number,
    label: string,
    reference: string,
    date: string,
    debit: number,
    credit: number,
    lettering: string,
}

interface PropsInterface {
    missionId: number,
}

const FlowsButton = ({missionId}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ opened, setOpened ] = useState(false);
    const [ loaded, setLoaded ] = useState(false);
    const [ rows, setRows ] = useState<RowInterface[]>([]);

    useEffect(() => {
        if (!opened) return;
        if (loaded)return;
        const api = `${myContext.apiAddress}/get_flow_mission?id=${missionId}`;
        myContext.httpClient.get(api).then((res: any) => {
            if (res.data.status === true) {
                setRows(res.data.data);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoaded(true);
        });
    }, [opened, loaded]); // eslint-disable-line

    const exportForClipBoard = () => {
        let s = '<table><thead><tr><td>Nature</td><td>Libellé</td><td>Référence</td><td>Date</td><td>Débit</td><td>Crédit</td><td>Lettrage</td></tr></thead><tbody>';
        for(const row of rows) {
            s += '<tr><td>'+row.internal_category+'</td><td>'+row.label+'</td><td>'+row.reference+'</td><td>'+row.date+'</td><td>'+row.debit+'</td><td>'+row.credit+'</td><td>'+row.lettering+'</td></tr>';
        }     
        s += '</tbody></table>';
        return s;
    }

    return (
        <>
        <ActionIcon color='blue' variant='outline' onClick={() => setOpened(true)}>
            <IconCoinEuro size={16} />
        </ActionIcon>
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            fullScreen
        >
            <LoadingOverlay visible={!loaded} />
            <Group position='apart'>
                <TitleContainer>
                    Liste des flux relatifs à la banque et aux factures
                </TitleContainer>
                <Group>
                    <CopyButton value={exportForClipBoard()}>
                        {({ copied, copy }) => (
                        <Button size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                            {copied ? 'Data copied' : 'Copy'}
                        </Button>
                        )}
                    </CopyButton>
                    <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                        <IconRefresh size={16} />
                    </ActionIcon>
                </Group>
            </Group>
            <ScrollArea style={{height: 'calc(100vh - 150px)'}}>
                <Table highlightOnHover>
                    <thead>
                        <tr>
                            <th style={{width: '70px'}}>Nature</th>
                            <th>Libellé</th>
                            <th style={{width: '120px'}}>Date</th>
                            <th style={{width: '100px'}}>Débit</th>
                            <th style={{width: '100px'}}>Crédit</th>
                            <th style={{width: '100px'}}>Lettrage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row: RowInterface) => 
                        <tr key={row.internal_category + '-' + row.internal_id}>
                            <td>
                                <Text size='xs'>{row.internal_category}</Text>
                            </td>
                            <td>
                                <Text>{row.label}</Text>
                                {row.reference && <Text color='dimmed'>Référence : {row.reference}</Text>}
                            </td>
                            <td>
                                <Text>{row.date}</Text>
                            </td>
                            <td>
                                <Text align='right'>{row.debit.toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                            </td>
                            <td>
                                <Text align='right'>{row.credit.toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                            </td>
                            <td>
                                {row.lettering && <Text color="dimmed">L{row.lettering}</Text>}
                            </td>
                        </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>
        </Modal>
        </>
    )
}

export { FlowsButton }
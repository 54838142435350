import { useContext, useState } from 'react';
import { ActionIcon, Center, Group, Loader, NumberInput, Stack, Text } from '@mantine/core';
import { IconCheck, IconEdit, IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface PropsInterface {
    needId: number,
    cvId: number,
    field: string,
    value: number | null,
    handleChange: any,
    fnOnValue: any,
}

const ValueNumber = ({needId, cvId, field, value, handleChange, fnOnValue}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [display, setDisplay] = useState(false);
    const [val, setVal] = useState(value);
    const [newVal, setNewVal] = useState<number>(value || 0);
    const [saving, setSaving] = useState(false);

    const save = () => {
        if (val === newVal) return;
        setSaving(true);
        const F = new FormData();
        F.append('what', field);
        F.append('value', newVal + '');
        const api = `${myContext.apiAddress}/update_process_need?need_id=${needId}&cv_id=${cvId}`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setSaving(false);
            if (res.data.status === true) {
                const v: number = parseInt(res.data.data.value);
                setVal(v);
                setNewVal(v);
                handleChange(v);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setDisplay(false);
        });
    }

    if (saving) {
        return (
            <Center>
                <Loader size="sm" />
            </Center>
        )
    } else if (display) {
        return (
            <Stack spacing={0}>
                <NumberInput 
                    size='xs'
                    value={newVal}
                    onChange={(v: number) => setNewVal(v)}
                    hideControls
                />
                <Group position="center">
                    <ActionIcon color='blue' variant='outline' size={14} onClick={() => setDisplay(false)}>
                        <IconX size={12} />
                    </ActionIcon>
                    <ActionIcon color='blue' variant='outline' size={14} onClick={() => save()}>
                        <IconCheck size={12} />
                    </ActionIcon>
                </Group>
            </Stack>
        )
    } else {
        if (val === null) {
            return (
                <Center>
                    <ActionIcon color="blue" variant="outline"onClick={() => setDisplay(true)} style={{cursor: 'pointer'}} size={14}>
                        <IconEdit size={12} />
                    </ActionIcon>
                </Center>
            )
        } else {
            return (
                <Stack spacing={0} onClick={() => setDisplay(true)} style={{cursor: 'pointer'}}>
                    <Text align="right">{val.toLocaleString()}</Text>
                    {newVal > 0 && 
                    <Text align="right" color="dimmed">
                        {fnOnValue === null ? newVal.toLocaleString() : fnOnValue(newVal)}
                    </Text>
                    }
                </Stack>
            )
        }
    }

}

export { ValueNumber }
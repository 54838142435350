import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Divider, Grid, LoadingOverlay, Modal, ScrollArea } from '@mantine/core';
import { IconFile } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TextInputContainer } from '../../shared/TextInputContainer';
import { FinancialFlowEditor } from '../../shared/FinancialFlowEditor';
import { AlertNotification } from '../../shared/AlertNotification';
import { SegmentInputContainer } from '../../shared/SegmentInputContainer';
import { DisplayPDF } from "../../shared/DisplayPDF";

interface RowInterface {
    id: number,
    label: string,
    date: string,
    quantity: number | null,
    price: number | null,
    amount: number,
    vat_rate: number | null,
}

interface DocumentInterface {
    loaded: boolean,
    filename: string,
    status: string,
    reference: string,
    rows: RowInterface[],
}

const documentReducer = (state: DocumentInterface, payload: any) => {
    switch(payload.type) {
        case 'set':
            return {
                loaded: true,
                filename: payload.data.header.filename,
                reference: payload.data.header.reference || '',
                status: payload.data.header.status,
                rows: payload.data.flows
            }
        default:
            return {
                loaded: false,
                filename: '',
                reference: '',
                status: '',
                rows: []
            }
    }
}

interface PropsInterface {
    title: string,
    category: string,
    missionId: number,
    missionOpened: boolean,
    fileId: number,
    handleChange: any,
}

const DocumentButton = ({title, category, missionId, missionOpened, fileId, handleChange}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [document, dispatchDocument] = useReducer(documentReducer, {
        loaded: false, filename: '', reference: '', status: '', rows: []
    });
    const getMetadataApi = `${myContext.apiAddress}/get_${category}_mission?mission=${missionId}&file=${fileId}&what=metadata`;
    const updateMetadataApi = `${myContext.apiAddress}/update_${category}_mission?id=${missionId}&file_id=${fileId}`;
    const [ isOpened, setIsOpened ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    
    useEffect(() => {
        if (!isOpened) return;
        if (isLoaded) return;
        myContext.httpClient.get(getMetadataApi).then((res: any) => {
            if (res.data.status === true) {
                dispatchDocument({type: 'set', data: res.data.data});
            } else {
                AlertNotification({message: res.data.message || 'internal error'});
            }
            setIsLoaded(true);
        });
    }, [isOpened, isLoaded, myContext.httpClient, getMetadataApi ]);

    useEffect(() => {
        if (isOpened) return;
        setIsLoaded(false);
        dispatchDocument({type: 'reset'});
    }, [isOpened]);

    return (
        <>
        <Modal
            opened={isOpened}
            onClose={() => setIsOpened(false)}
            title={title}
            fullScreen
        >
            <LoadingOverlay visible={!document.loaded} />
            {document.loaded &&
            <Grid>
                <Grid.Col sm={12} lg={9} style={{ height: 'max(50vh, 600px)' }}>
                    <DisplayPDF fileUrl={`${myContext.apiAddress}/get_${category}_mission?mission=${missionId}&file=${fileId}&what=file`} />
                </Grid.Col>
                <Grid.Col sm={12} lg={3}>
                    <TextInputContainer
                        code='filename'
                        label='Libellé du document'
                        defaultValue={document.filename}
                        api={`${updateMetadataApi}&action=rename`}
                        isEdition={missionOpened}
                        handle={(v: string) => { if (handleChange !== null) handleChange({id: fileId, field: 'filename', value: v})}}
                        withAsterisk={true}
                    />
                    <TextInputContainer
                        code='reference'
                        label='Référence du document'
                        defaultValue={document.reference}
                        api={`${updateMetadataApi}&action=reference`}
                        isEdition={missionOpened}
                        handle={(v: string) => { if (handleChange !== null) handleChange({id: fileId, field: 'reference', value: v})}}
                        withAsterisk={true}
                    />
                    <SegmentInputContainer
                        defaultValue={document.status}
                        api={missionOpened ? `${updateMetadataApi}&action=toggle` : ''}
                        options={[
                            { label: 'Brouillon', value: 'draft' },
                            { label: 'Transmis', value: 'sent' },
                            { label: 'Reçu', value: 'received' },
                            { label: 'Signé', value: 'signed' },
                        ]}
                        handle={(v: string) => { if (handleChange !== null) handleChange({id: fileId, field: 'status', value: v})}}
                    />
                </Grid.Col>
                <Grid.Col sm={12} style={{ height: 'max(50vh, 600px)' }}>
                    <Divider label="Eléments financiers" labelPosition='center' />
                    <ScrollArea style={{height: '100%'}}>
                        <FinancialFlowEditor
                            updateApi={missionOpened ? `${myContext.apiAddress}/update_${category}_mission?id=${missionId}&file_id=${fileId}&action=flow` : ''}
                            data={document.rows}
                            push={null}
                            isEditable={missionOpened}
                        />
                    </ScrollArea>
                </Grid.Col>
            </Grid>
            }
        </Modal>
        <ActionIcon color="blue" variant="outline" onClick={() => setIsOpened(true)}>
            <IconFile size={16} />
        </ActionIcon>
        </>
    )
}

export { DocumentButton }
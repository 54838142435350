import { Card, Spoiler, Text } from '@mantine/core';

import { SearchInterface, CVInterface, RowHistoryInterface } from '../interfaces';

interface SpanInterface {
    s: string,        // texte à afficher
    c: string | null, // couleur éventuelle
}

interface PropsInterface {
    search: SearchInterface | null,
    profile: CVInterface,
}

const ProfileBox = ({profile, search}: PropsInterface) => {

    const highlightString = (field: string, str: string): SpanInterface[] => {
        let s: string = str.replace('|', ';');
        const wi: string[] = [];
        const wo: string[] = [];
        if (search !== null) {
            const N: number = search.terms.length;
            if (N !== 0) {
                for(let i=0;i<N;i++) {
                    const term = search.terms[i];
                    if (!term.fields[field]) continue;
                    const mm = [...str.matchAll(term.regexp)];
                    if (mm.length !== 0) {
                        if (term.included) {
                            for (const m of mm) {
                                if (wi.includes(m[0]) === false) {
                                    wi.push(m[0]);
                                }
                            }
                        } else {
                            for (const m of mm) {
                                if (wo.includes(m[0]) === false) {
                                    wo.push(m[0]);
                                }
                            }
                        }
                    }
                }
            }
        }
        if (wi.length === 0 && wo.length === 0) {
            return [ {s: str, c: null}, ];
        }
        for (let i=0;i<wi.length;i++) {
            s = s.replaceAll(wi[i], ' |+' + wi[i] + '+| ' );
        }
        for (let i=0;i<wo.length;i++) {
            s = s.replaceAll(wo[i], ' |-' + wo[i] + '-| ' );
        }
        return s.split('|').map((t,i) => {
            const l = t.length-1;
            if (t[0] === '+' && t[l] === '+') {
                return {s: t.substring(1,l), c: '#63e6be'};
            } else if (t[0] === '-' && t[l] === '-') {
                return {s: t.substring(1,l), c: '#ffa8a8'};
            } else {
                return {s: t, c: null};
            }
        })
    }

    const DisplayHighlight = ({k, s}: {k: string, s: SpanInterface[]}) => {
        return <>
        {s.map((r: SpanInterface, i: number) => 
            <span key={`${k}-span-${i}`} style={(r.c === null) ? {} : { 'backgroundColor': r.c}}>{r.s}</span>
        )}
        </>
    }

    return (
        <Card withBorder m='xs'>
            <Spoiler maxHeight={150} showLabel="Show more" hideLabel="Hide">
                {profile.fullname !== '' && <Text size="sm"  key={`profile-${profile.id}-fullname`}>
                    <DisplayHighlight k={profile.id+'-fullname'} s={highlightString('n', profile.fullname)} />
                </Text>}
                {profile.headline !== '' && <Text size="sm"  key={`profile-${profile.id}-headline`}>
                    <DisplayHighlight k={profile.id+'-headline'} s={highlightString('h', profile.headline)} />
                </Text>}
                {profile.sector !== '' && <Text size="sm"  key={`profile-${profile.id}-sector`}>
                    <DisplayHighlight k={profile.id+'-sector'} s={highlightString('s', profile.sector)} />
                </Text>}
                {profile.location !== '' && <Text size="sm"  key={`profile-${profile.id}-location`}>
                    <DisplayHighlight k={profile.id+'-location'} s={highlightString('l', profile.location)} />
                </Text>}
            </Spoiler>
            <Spoiler maxHeight={300} showLabel="Show more" hideLabel="Hide">
                <Text size="sm" color="dimmed">Jobs en cours</Text>
                {profile.jobs.map((row: RowHistoryInterface, i: number) =>
                <Text size="sm" key={`profile-${profile.id}-job-${i}`}>
                    • {`${row.y0} - ${row.y1} : `}
                    <DisplayHighlight k={profile.id+'-job'} s={highlightString('j', row.la)} /></Text>
                )}
                <Text size="sm" color="dimmed">Expériences passées</Text>
                {profile.experiences.map((row: RowHistoryInterface, i: number) =>
                <Text size="sm" key={`profile-${profile.id}-experience-${i}`}>
                    • {`${row.y0} - ${row.y1} : `}
                    <DisplayHighlight k={profile.id+'-experience'} s={highlightString('e', row.la)} /></Text>
                )}
                <Text size="sm" color="dimmed">Formations</Text>
                {profile.formations.map((row: RowHistoryInterface, i: number) =>
                <Text size="sm" key={`profile-${profile.id}-formation-${i}`}>
                    • {`${row.y0} - ${row.y1} : `}
                    <DisplayHighlight k={profile.id+'-formation'} s={highlightString('f', row.la)} /></Text>
                )}
                <Text size="sm" color="dimmed">Spotlights</Text>
                {profile.decorations.map((row: string, i: number) =>
                <Text size="sm" key={`profile-${profile.id}-decoration-${i}`}>
                    • <DisplayHighlight k={profile.id+'-decoration'} s={highlightString('d', row)} /></Text>
                )}
            </Spoiler>
        </Card>
    )
}

export { ProfileBox }
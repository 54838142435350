import { useContext, useEffect, useState } from 'react';
import { Group, Loader, Text } from '@mantine/core';
import AppContext from '../AppContext';
import { ListItemSelector } from '../../shared/ListItemSelector';

interface ItemInterface {
    id: number,
    value: string
}

interface PropsInterface {
    handle: any
}

const SectorSelector = ({handle}: PropsInterface) => {
    
    const myContext = useContext(AppContext);
    const [isLoaded,setIsLoaded] = useState(myContext.sectors === null ? false : true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (isLoaded) return;
        if (isError) return;
        myContext.httpClient.get(`${myContext.apiAddress}/get_repository_sector`).then((res: any) => {
            if (res.data.status === true) {
                myContext.setSectors({
                    label: 'Secteurs professionnels',
                    date: (new Date()).getTime(),
                    items: res.data.data,
                });
                setIsLoaded(true);
            } else {
                setIsError(true);
            }
        });
    }); // eslint-disable-line

    if (!isLoaded || myContext.sectors === null) {
        return (
            <Group>
                <Loader />
                <Text>Chargement des secteurs professionnels</Text>
            </Group>
        )
    }
    
    const handleSelection = (items: ItemInterface[]) => {
        handle(items);
    }

    return (
        <ListItemSelector
            title="Sélectionner un secteur professionnel"
            items={myContext.sectors.items.map((i: any) => { return { id: i.id, value: i.label} })}
            handleSelection={handleSelection}
            multi={true}
        />
    )
}

export { SectorSelector }
import { useContext, useEffect, useState } from 'react';
import { Badge, Card, Group, LoadingOverlay, Modal, Text } from '@mantine/core';
import AppContext from '../shared/AppContext';

interface RowInterface {
    category: string,
    date: string,
    description: string,
    debit: number,
    credit: number,
    currency: string,
}
interface PropsInterface {
    letter: string,
}

const LetteringBadge = ({letter}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [opened, setOpened] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<RowInterface[]>([]);

    useEffect(() => {
        if (!opened) return;
        if (loaded) return;
        setLoading(true);
        const id: number = parseInt(letter.substring(1));
        const api = `${myContext.apiAddress}/bank_letter?id=${id}`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                setRows(res.data.data);
            }
            setLoading(false);
            setLoaded(true);
        });
    }, [opened]); // eslint-disable-line

    return (
        <>
        <Modal 
            opened={opened}
            onClose={() => setOpened(false)}
            title={`Ecritures bancaires liées au lettrage ${letter}`}
            size='lg'
        >
            <LoadingOverlay visible={loading} />
            {rows.length === 0 ?
            <Text>strange nothing</Text>
            :
            <>
            {rows.map((row, i: number) => 
            <Card key={`letter-${letter}-${i}`}>
                <Group position='apart'>
                    <Badge>{row.category}</Badge>
                    <Text size='sm'>{row.date}</Text>
                    <Group>
                        <Badge>{row.debit.toLocaleString()}</Badge>
                        <Badge>{row.credit.toLocaleString()}</Badge>
                        <Badge>{row.currency}</Badge>
                    </Group>
                </Group>
                <Text>{row.description}</Text>
            </Card>
            )}
            </>
            }
        </Modal>
        <Badge color='yellow' onClick={() => setOpened(true)} style={{cursor: 'pointer'}}>{letter}</Badge>
        </>
    )
}

export { LetteringBadge }
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { ActionIcon, Badge, Card, Center, Divider, Group, LoadingOverlay, ScrollArea, Text } from '@mantine/core';
import { IconRefresh, IconLayoutNavbarCollapse, IconLayoutNavbarExpand } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { Message } from './message';

interface ItemInterface {
    id: number,
    content: string,
    created_date: string,
    created_by: string,
    mine: boolean,
}
const itemsReducer = (state: ItemInterface[], payload: any) => {
    switch(payload.type) {
        case 'set':
            return payload.data;
        case 'add':
            return state.concat([{...payload.item}]);
        case 'update':
            return state.map((item: ItemInterface) => {
                if (item.id === payload.id) {
                    return { ...item, content: payload.value };
                } else {
                    return item;
                }
            })
        default:
            return [];
    }
}

interface PropsInterface {
    objectCode: 'need' | 'mission',
    objectId: number,
    isOpen: boolean,
    getUrl: string,
    updateUrl: string,
    height: string,
}

const Messages = ({objectCode, objectId, isOpen, getUrl, updateUrl, height}: PropsInterface) => {
    
    const myContext = useContext(AppContext);
    const [working, setWorking] = useState(true);
    const [items, dispatchItems] = useReducer(itemsReducer, []);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const t0: string = (new Date()).toISOString().substring(0,10);
    const [display, setDisplay] = useState(false);

    const viewport = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (loaded) return;
        setWorking(true);
        const api = `${myContext.apiAddress}/${getUrl}`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                if (res.data.data !== null) {
                    dispatchItems({type: 'set', data: res.data.data});
                    setTimeout(() => {
                        if (viewport.current !== null) {
                            viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
                        }
                    }, 1000);
                }
            } else {
                setError(res.data.message || 'unknown error');
            }
            setWorking(false);
            setLoaded(true);
        })
    }, [loaded]); // eslint-disable-line

    if (error !== null) {
        return (
            <Center>
                <Text color="red">{error}</Text>
            </Center>
        );
    }

    return (
        <>
        <Group position="apart" pt="xs" pr="xs">
            <Badge>{items.length}</Badge>
            <ActionIcon color="blue" variant="outline" onClick={() => setLoaded(false)}>
                <IconRefresh size={16} />
            </ActionIcon>
        </Group>
        <Center>
            <ScrollArea pr="sm" style={{height: height, width: 'min(100vw, 700px)'}} viewportRef={viewport} >
                <LoadingOverlay visible={working} />
                {items.map((item: ItemInterface, idx: number) => (
                <Group key={`message-${item.id}`} position={item.mine ? 'right' : 'left'}>
                    <Card p="xs" mb="sm">
                        <Card.Section>
                            <Message 
                                messageId={item.id} 
                                value={item.content} 
                                handle={(v: string) => dispatchItems({type: 'update', id: item.id, value: v})} 
                                isEditable={(isOpen && item.created_date.substring(0,10) === t0 && item.mine) ? true : false}
                                updateUrl={updateUrl}
                            />
                        </Card.Section>
                        <Group position="apart">
                            <Text color="dimmed" size="xs">#{item.id}</Text>
                            <Text color="dimmed" size="xs">{item.created_date}</Text>
                            <Text color="dimmed" size="xs">{item.created_by}</Text>
                        </Group>
                    </Card>
                </Group>
                ))}
                {isOpen &&
                <>
                <Group>
                    <Divider pt="xs" pb="xs" label="Ajouter un message" labelPosition='center' style={{width: 'calc(100% - 60px)'}} />
                    <Group style={{width: '35px'}}>
                    {display ?
                    <ActionIcon color='blue' variant='outline' onClick={() => setDisplay(false)}>
                        <IconLayoutNavbarCollapse size={16} />
                    </ActionIcon>
                    :
                    <ActionIcon color='blue' variant='outline' onClick={() => setDisplay(true)}>
                        <IconLayoutNavbarExpand size={16} />
                    </ActionIcon>
                    }
                    </Group>
                </Group>
                {display &&
                <Group position="right">
                    <Message 
                        messageId={-1}
                        value=''
                        handle={(item: ItemInterface) => dispatchItems({type: 'add', item: item})}
                        isEditable={true}
                        updateUrl={updateUrl}
                    />
                </Group>
                }
                </>}
            </ScrollArea>
        </Center>
        </>
    )
}

export { Messages }
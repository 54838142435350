import { Avatar, Group, Text } from '@mantine/core';

interface propsInterface {
    avatar: string | null,
    firstname: string,
    lastname: string,
    isFull: boolean,
}

const AvatarIcon = ({avatar, firstname, lastname, isFull}: propsInterface) => {

    if (isFull) {
        if (avatar) {
            return (
                <Group noWrap>
                    <Avatar 
                        src={`data:image/png;base64,${avatar}`} 
                        radius="xl"
                    />
                    <Text>{`${firstname} ${lastname}`}</Text>
                </Group>
            )
        } else {
            return (
                <Group noWrap>
                    <Avatar
                        color="cyan"
                        radius="xl"
                    >
                        {`${firstname.substring(0,2)}`}
                    </Avatar>
                    <Text>{`${firstname} ${lastname}`}</Text>
                </Group>
            )
        };
    } else {
        if (avatar) {
            return (
                <Avatar 
                    src={`data:image/png;base64,${avatar}`} 
                    radius="xl" 
                    alt={`${firstname} ${lastname}`} 
                />
            )
        } else {
            return (
                <Avatar
                    color="cyan"
                    radius="xl"
                >
                    {`${firstname.substring(0,2)}`}
                </Avatar>
            )
        };
    }

}

export { AvatarIcon }
import { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Loader, Modal, Stack, Text, TextInput } from '@mantine/core';
import { IconCheck, IconTrash, IconX } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../AlertNotification';

interface PropsInterface {
    label: string,
    api: string,
    params: string,
    handle: any,
}

const TrashButton = ({label, api, params, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [isOpened, setIsOpened] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [word, setWord] = useState('');
    
    const remove = () => {
        setIsRemoving(true);
        myContext.httpClient.get(`${myContext.apiAddress}/${api}${params === '' ? '' : '?'}${params}`).then((res:any) => {
            setIsRemoving(false);
            setIsOpened(false);
            if (res.data.status === true) {
                handle(); // La suite est traitée par le composant parent.
            } else {
                AlertNotification({message: (res.data.message || `Error in ${label}`)});
            }
        });
    }

    return  (
        <>
        <Modal
            opened={isOpened}
            onClose={() => { setIsOpened(false)}}
            title={label}
        >
            {isRemoving && <Group position="center" align="center">
                <Loader/>
                <Text>Suppression en cours...</Text>
            </Group>}
            {!isRemoving && <Stack>
                <Text>
                    Afin de confirmer la suppression, merci de saisir le mot REMOVE ci-après, puis de valider.
                </Text>
                <TextInput
                    value={word}
                    onChange={(event) => setWord(event.target.value.trim())}
                    error={word !== 'REMOVE'}
                />
                <Group position="apart">
                    <Button leftIcon={<IconX/>} color="blue" onClick={() => setIsOpened(false)}>
                        annuler
                    </Button>
                    <Button leftIcon={<IconCheck/>} color="blue" onClick={() => remove()}
                        disabled={word !== 'REMOVE'}
                    >
                        supprimer
                    </Button>
                </Group>
            </Stack>}
        </Modal>
        <ActionIcon variant="outline" color="blue" 
            onClick={() => { setIsOpened(true); setWord('');}}
        >
            <IconTrash size={16}/>
        </ActionIcon>
        </>
    )
}

export { TrashButton }
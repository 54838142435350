import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Badge, Button, Center, ColorSwatch, CopyButton, Group, LoadingOverlay, ScrollArea, Select, Stack, Table, Text, TextInput } from '@mantine/core';
import { IconArrowBigRight, IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from '../../shared/TitleContainer';
import { AlertNotification } from '../../shared/AlertNotification';
import { Files } from './audit_activities_files';

interface FileInterface {
    id: number,
    label: string,
}
interface MissionInterface {
    id: number,
    affected_at: string | null,
    mission_client: string,
    mission_label: string,
    client_name: string | null,
    client_email: string | null,
    manager_name: string | null,
    manager_email: string | null,
    manager_phone: string | null,
    report_blocked: boolean,
    files: FileInterface[],
}

const AuditActivitiesMission = () => {

    const myContext = useContext(AppContext);
    const d0 = new Date();
    let y0 = d0.getFullYear();
    let m0 = d0.getMonth()+1;
    if (d0.getDate() < 14) {
        m0 -= 1;
        if (m0 === 0) {
            y0 -= 1;
            m0 = 12;
        }
    }
    const [myYear, setMyYear] = useState<string | null>(y0+'');
    const [myMonth, setMyMonth] = useState<string | null>(m0+'');
    const [loaded, setLoaded] = useState(false);
    const [missions, setMissions] = useState<MissionInterface[]>([]);
    const [search, setSearch] = useState('');
    
    const monthLabels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    
    useEffect(() => {
        if (loaded) return;
        setMissions([]);
        myContext.httpClient.get(`${myContext.apiAddress}/audit_mission?report=activities&year=${myYear}&month=${myMonth}`).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                setMissions(res.data.data);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [loaded]); // eslint-disable-line

    useEffect(() => {
        setLoaded(false);
    }, [myMonth, myYear]); // eslint-disable-line

    const exportForClipBoard = () => {
        const p: string = (myMonth === null) ? '' : monthLabels[parseInt(myMonth)-1] + ' de ' + myYear;
        const uName: string = myContext.account.firstname + ' ' + myContext.account.lastname;
        const uEmail: string = myContext.account.email;
        let s: string = '';
        s += '<table><thead><tr>'
                + '<th>MT::param_client</th>'
                + '<th>MT::param_poste</th>'
                + '<th>MT::param_manager</th>'
                + '<th>MT::param_period</th>'
                + '<th>MT::param_days</th>'
                + '<th>MT::Name</th>'
                + '<th>MT::Email</th>'
                + '<th>CLI::Name</th>'
                + '<th>CLI::Email</th>'
                + '<th>SNR::Name</th>'
                + '<th>SNR::Email</th>'
                + '<th>SNR::param_reference</th>'
                + '</tr></thead><tbody>';
        for(const m of missions) {
            if (m.report_blocked) continue;
            s += '<tr>'
                + '<td>' + m.mission_client + '</td>'
                + '<td>' + m.mission_label + '</td>'
                + '<td>' + m.manager_name + '</td>'
                + '<td>' + p + '</td>'
                + '<td></td>'
                + '<td>' + m.manager_name + '</td>'
                + '<td>' + m.manager_email + '</td>'
                + '<td>' + m.client_name + '</td>'
                + '<td>' + m.client_email + '</td>'
                + '<td>' + uName + '</td>'
                + '<td>' + uEmail + '</td>'
                + '<td>CRA-' + m.id + '-' + myYear + '-' + myMonth + '</td>'
                + '</tr>';
        }
        s += '</tbody></table>';
        return s;
    }

    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Audit des CRA
            </TitleContainer>
            <Group>
                <Select 
                    label="Année"
                    value={myYear}
                    onChange={setMyYear}
                    data={[
                        {value: (y0-1)+'', label: (y0-1)+''},
                        {value: y0+'', label: y0+''},
                        {value: (y0+1)+'', label: (y0+1)+''}
                    ]}
                    disabled={!loaded}
                />
                <Select 
                    label="Mois"
                    value={myMonth}
                    onChange={setMyMonth}
                    data={[
                        {value: '1', label: 'Janvier'},
                        {value: '2', label: 'Février'},
                        {value: '3', label: 'Mars'},
                        {value: '4', label: 'Avril'},
                        {value: '5', label: 'Mai'},
                        {value: '6', label: 'Juin'},
                        {value: '7', label: 'Juillet'},
                        {value: '8', label: 'Août'},
                        {value: '9', label: 'Septembre'},
                        {value: '10', label: 'Octobre'},
                        {value: '11', label: 'Novembre'},
                        {value: '12', label: 'Décembre'},
                    ]}
                    disabled={!loaded}
                />
                <TextInput 
                    label='Mot à chercher'
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value.trim().toLocaleLowerCase())}
                />
                <CopyButton value={exportForClipBoard()}>
                    {({ copied, copy }) => (
                    <Button size='xs' variant='outline' color={copied ? 'teal' : 'blue'} onClick={copy}>
                        {copied ? 'Données copiées' : 'Copier'}
                    </Button>
                    )}
                </CopyButton>
                <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
        </Group>
        <ScrollArea style={{height: 'calc(100vh - 130px)'}}>
            <LoadingOverlay visible={!loaded} />
            <Table highlightOnHover>
                <thead>
                    <tr style={{position: 'sticky', top: '0'}}>
                        <th>Bloqué ?</th>
                        <th>Mission</th>
                        <th>Client CRA</th>
                        <th>Fournisseur CRA</th>
                        <th>{myMonth !== null && `${monthLabels[parseInt(myMonth)-1]} ${myYear}`}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {missions.filter((m) => (search === '' || (m.mission_label + ' ' + m.client_name + ' ' + m.manager_name + ' ' + m.affected_at).toLocaleLowerCase().indexOf(search) !== -1)).map((m) => 
                    <tr key={`mission-${m.id}`}>
                        <td>
                            {m.report_blocked && <Center><ColorSwatch color='red' /></Center>}
                        </td>
                        <td>
                            <Stack spacing={0}>
                                <Text>{m.mission_client}</Text>
                                <Text>{m.mission_label}</Text>
                                <Text color='dimmed'>{m.affected_at}</Text>
                            </Stack>
                        </td>
                        <td>
                            <Stack spacing={0}>
                                {m.client_name === null ? <Badge color='red'>Nom</Badge> : <Text>{m.client_name}</Text>}
                                {m.client_email === null ? <Badge color='red'>Email</Badge> : <Text color='dimmed'>{m.client_email}</Text>}
                            </Stack>
                        </td>
                        <td>
                            <Stack spacing={0}>
                                {m.manager_name === null ? <Badge color='red'>Nom</Badge> : <Text>{m.manager_name}</Text>}
                                {m.manager_email === null ? <Badge color='red'>Email</Badge> : <Text color='dimmed'>{m.manager_email}</Text>}
                                {m.manager_phone === null ? <Badge color='red'>Téléphone</Badge> : <Text color='dimmed'>{m.manager_phone}</Text>}
                            </Stack>
                        </td>
                        <td>
                            <Files
                                missionId={m.id}
                                year={myYear + ''}
                                month={myMonth + ''}
                                files={m.files}
                            />
                        </td>
                        <td>
                            <ActionIcon color="blue" variant="outline"
                                component={Link} to={`/mission/manage/${m.id}`}
                            >
                                <IconArrowBigRight size={16} />
                            </ActionIcon>
                        </td>
                    </tr>
                    )}
                </tbody>
            </Table>
        </ScrollArea>
        </>
    )
}

export { AuditActivitiesMission }
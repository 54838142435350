import { useState } from 'react';
import { ActionIcon, Center, Group, Table, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { FinancialRowEditor } from '../FinancialRowEditor';

interface RowInterface {
    id: number | null,
    label: string | null,
    date: string | null,
    quantity: number | null,
    price: number | null,
    amount: number | null,
    vat_rate: number | null,
}
interface PropsInterface {
    updateApi: string,
    data: RowInterface[],
    push: any,
    isEditable: boolean,
}

const FinancialFlowEditor = ({updateApi, data, push, isEditable}: PropsInterface) => {

    const [ rows, setRows ] = useState<RowInterface[]>(data.map((row) => { return {...row}}));
    const [ totalHT, setTotalHT ] = useState(0);
    const [ totalTTC, setTotalTTC ] = useState(0);

    const addRow = () => {
        const r: RowInterface[] = rows.map((row) => { return {...row}});
        r.push({id: null, label: null, date: null, quantity: null, price: null, amount: null, vat_rate: 0.2});
        setRows(r);
        computeTotal(r);
    }

    const removeRow = (i: number) => {
        const r: RowInterface[] = rows.map((row) => { return {...row}});
        r.splice(i, 1);
        setRows(r);
        if (push !== null) {
            push(r);
        }
        computeTotal(r);
    }

    const updateRow = (index: number, row: RowInterface) => {
        const r: RowInterface[] = rows.map((o: RowInterface, i: number) => { 
            if (index === i) {
                return {...row}
            } else {
                return {...o}
            }
        });
        setRows(r);
        if (push !== null) {
            push(r);
        }
        computeTotal(r);
    }

    const computeTotal = (data: RowInterface[]) => {
        let ht: number = 0; // montant hors taxe
        let ttc: number = 0; // taxes
        for(const r of data) {
            if (r.amount !== null) {
                ht += r.amount;
                ttc += r.amount * (1 + (r.vat_rate || 0));
            }
        }
        setTotalHT(ht);
        setTotalTTC(ttc);
    }

    return (
        <>
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th>
                        <Text color="dimmed">#</Text>
                    </th>
                    <th>
                        <Group><Text>Libellé</Text><Text color="red">*</Text></Group>
                    </th>
                    <th style={{width: '130px'}}>
                        <Group position='right'><Text>Date</Text><Text color="red">*</Text></Group>
                    </th>
                    <th style={{textAlign: 'right', width: '130px'}}>
                        Quantité
                    </th>
                    <th style={{textAlign: 'right', width: '130px'}}>
                        Prix unitaire
                    </th>
                    <th style={{width: '130px'}}>
                        <Group position='right'><Text>Total HT</Text><Text color="red">*</Text></Group>
                    </th>
                    <th style={{textAlign: 'right', width: '130px'}}>
                        Taux de TVA
                    </th>
                    <th style={{textAlign: 'right', width: '130px'}}>
                        Total TTC
                    </th>
                    <th>

                    </th>
                </tr>
            </thead>
            <tbody>
                {rows.map((o: RowInterface, idx: number) => (
                <FinancialRowEditor
                    key={`row-editor-${idx}`}
                    api={updateApi}
                    idx={idx}
                    row={{...o}}
                    remove={removeRow}
                    push={(r: RowInterface) => { updateRow(idx, {...r}) }}
                    isEditing={(o.label === null && o.date === null && o.amount === null) ? true : false}
                    isEditable={isEditable}
                />
                ))}
                {rows.length > 1 && 
                <tr>
                    <td></td>
                    <td>
                        <Text>Total</Text>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <Text align="right">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalHT)}</Text>
                    </td>
                    <td></td>
                    <td>
                        <Text align="right">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalTTC)}</Text>
                    </td>
                    <td></td>
                </tr>
                }
            </tbody>
        </Table>
        {isEditable &&
        <Center style={{paddingBottom: '1em'}}>
            <ActionIcon color="blue" variant="outline" onClick={() => addRow()}>
                <IconPlus size={16} />
            </ActionIcon>
        </Center>
        }
        </>
    );

}

export { FinancialFlowEditor }
import { useContext, useReducer, useState } from 'react';
import { Button, LoadingOverlay, Modal, ScrollArea, Text } from '@mantine/core';
import AppContext from '../../shared/AppContext';

interface PropsInterface {
    hunt_id: number,
    search_id: number,
    get_ids: any,
}

interface TaskInterface {
    ids: number[],
    res: null | boolean,
    msg: string,
}

const tasksReducer = (state: TaskInterface[], payload: any) => {
    if (payload.type === 'set') {
        return payload.data;
    } else if (payload.type === 'update') {
        return state.map((task: TaskInterface, idx: number) => {
            if (idx === payload.idx) {
                return { ...task, res: payload.res, msg: payload.msg };
            } else {
                return { ...task };
            }
        });
    } else {
        return [];
    }
}

const BuyButton = ({hunt_id, search_id, get_ids}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [isWorking, setIsWorking] = useState(false);
    const [tasks, dispatchTasks] = useReducer(tasksReducer, []);
    const [opened, setOpened] = useState(false);

    const saveCvIds = () => {
        if (get_ids === null) return;
        setIsWorking(true);
        const tasks: TaskInterface[] = [];
        const ids: number[] = get_ids();
        if (ids.length === 0) return;
        let t: number = 0;
        let r: number = 0;
        tasks.push({'ids': [], 'res': null, 'msg': 'unprocessed' });
        for (let i=0;i<ids.length;i++){
            if (r === 500) {
                r = 0;
                t += 1;
                tasks.push({ 'ids': [], 'res': null, 'msg': 'unprocessed' });
            }
            tasks[t]['ids'].push(ids[i]);
            r += 1;
        }
        dispatchTasks({type: 'set', data: tasks});
        const sendTasks = tasks.map(async (task: TaskInterface, idx: number) => {
            const F = new FormData();
            F.append('hunt_id', hunt_id+'');
            F.append('search_id', search_id+'');
            F.append('cv_ids', task.ids.join(','));
            const api = `${myContext.apiAddress}/attach_process_hunt?call=${idx}`;
            myContext.httpClient.post(api, F).then((result:any) => {
                if (result.data.status === true) {
                    dispatchTasks({type: 'update', idx: idx, res: true, msg: result.data.message});
                } else {
                    dispatchTasks({type: 'update', idx: idx, res: false, msg: result.data.message || 'unknown error from server'});
                }
            });
        });
        Promise.all(sendTasks).then(() => {
            setIsWorking(false);
            setOpened(true);
        });
    }

    return (
        <>
        <Modal
            opened={opened}
            onClose={() => { setOpened(false);dispatchTasks({type:'reset'}) }}
            title="Bilan des ajouts au panier"
            size='lg'
        >
            <LoadingOverlay visible={isWorking}/>
            <ScrollArea style={{height: 'min(80vh, 300px)'}}>
                {tasks.map((task:TaskInterface, idx: number) => 
                <Text key={`result-attach-${idx}`} color={task.res === true ? 'green' : 'red'}>
                    Call {idx} : {task.msg}
                </Text>
                )}
            </ScrollArea>
        </Modal>
        <Button loading={isWorking} variant='outline' size='xs' compact onClick={() => saveCvIds()}>
            Save to basket
        </Button>
        </>
    )
}

export { BuyButton }
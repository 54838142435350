import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AppShell, Box, MantineProvider, ColorScheme } from '@mantine/core';
import { useHotkeys, useLocalStorage } from '@mantine/hooks';
import { NotificationsProvider } from '@mantine/notifications';
import { ErrorBoundary } from './components/configuration/ErrorBoundary';

import AppContext from './components/shared/AppContext';
import { AvatarIcon } from './components/shared/AvatarIcon';

//import logo from './logo.svg';
import './App.css';

import { Routes, Route } from "react-router-dom";

import { LoginContainer } from './components/authentification/LoginContainer';
import { HeaderContainer } from './components/shared/HeaderContainer';
import { NavbarContainer } from './components/shared/NavbarContainer';
import { HomeContainer } from './components/authentification/HomeContainer';
// configuration
import { AddAccount } from './components/configuration/AddAccount';
import { ManageAccounts } from './components/configuration/ManageAccounts';
import { ManagePermissions } from './components/configuration/ManagePermissions';
import { ManageAffectations } from './components/configuration/ManageAffectations';
import { ExportDataset } from './components/configuration/ExportDataset';
import { SendEmail } from './components/configuration/SendEmail';
// cvthèque
import { AuditBlockedCV } from './components/cvtheque/AuditCV/blocked';
import { AuditDuplicatesCV } from './components/cvtheque/AuditCV/duplicates';
import { AuditMetadataCV } from './components/cvtheque/AuditCV/metadata';
import { DashboardInProgressCV } from './components/cvtheque/DashboardInProgressCV';
import { DashboardMainCV } from './components/cvtheque/DashboardMainCV';
import { DocumentCV } from './components/cvtheque/DocumentCV';
import { InsertCV } from './components/cvtheque/InsertCV';
import { ListCV } from './components/cvtheque/ListCV';
import { SearchCV } from './components/cvtheque/SearchCV';
// annuaire des clients
import { CompanyBook } from './components/annuaire/CompanyBook';
import { CompanyFiche } from './components/annuaire/CompanyFiche';
import { DashboardBook } from './components/annuaire/DashboardBook';
import { CreateMessageBook } from './components/annuaire/CreateMessageBook';
import { MailingBook } from './components/annuaire/MailingBook';
// banque
import { BankUpload } from './components/bank/upload';
import { BankLettering } from './components/bank/lettering';
// besoins
import { BankLetteringRemove } from './components/bank/lettering_remove';
import { AddNeed } from './components/need/AddNeed';
import { ListNeed } from './components/need/ListNeed';
import { ProcessNeed } from './components/need/ProcessNeed';
import { DashboardMainNeed } from './components/need/DashboardMainNeed';
// linkedin
import { AddHunt } from './components/hunt/AddHunt/add_hunt';
import { ListHunts } from './components/hunt/ListHunts/list_hunts';
import { ManageHunt } from './components/hunt/ManageHunt';
// missions
import { AddMission } from './components/mission/AddMission';
import { ListMission } from './components/mission/ListMission';
import { ManageMission } from './components/mission/ManageMission';
import { DashboardMission } from './components/mission/OverviewMission/dashboard';
import { AuditFacturesMission } from './components/mission/OverviewMission/audit_factures';
import { AuditActivitiesMission } from './components/mission/OverviewMission/audit_activities';
import { NotPaidMission } from './components/mission/OverviewMission/not_paid';
import { RemovedFactures } from './components/mission/OverviewMission/removed_factures';
import { SearchMission } from './components/mission/OverviewMission/search';
import { FacturesOnMission } from './components/mission/OverviewMission/factures';
// gestion de l'équipe
import { OrgaTeam } from './components/team/OrgaTeam';
import { PlanningTeam } from './components/team/PlanningTeam';
import { RequestsTeam } from './components/team/RequestsTeam';
// divers
import { JumpTo } from './components/shared/HeaderContainer/jump-to';
// 404
import { PageNotFound } from './components/shared/PageNotFound';

interface itemInterface {
  id: number,
  label: string,
  extra: string | null,
}
interface listInterface {
  label: string,
  date: number,
  items: itemInterface[],
}
interface accountInterface {
  domain: string,
  id: number,
  nickname: string,
  firstname: string,
  lastname: string,
  email: string,
  avatar: string,
  lastHttpRequestTime: number,
  maxItemsPerPage: number,
  maxUploadSize: number,
  maxInactivityTime: number,
  acceptedFiles: string,
}

function App() {

  const initiateAccount = () => {
    const s: string | null = localStorage.getItem('account');
    if (s === null) return null;
    const a: accountInterface = JSON.parse(s);
    const t = (new Date()).getTime();
    if (t - a.lastHttpRequestTime > 22 * 60 * 1000) return null; // PHP cookie session max lifetime: 24 minutes
    return a;
  }

  // We define the global variables to share inside the app via context component.
  // --> informations sur le compte
  const [account,setAccount] = useState<accountInterface | null>(initiateAccount());
  // --> api address
  const apiAddress = 'https://5feet.eu/api';
  //const apiAddress = 'http://localhost/5feet/api';
  // --> color scheme
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
  });
  // --> http client
  const httpClient = axios.create();
  httpClient.interceptors.response.use((response) => {
    if (account !== null && response.request.responseURL.endsWith('/logout') === false) {
      const t = (new Date()).getTime();
      setAccount({...account, lastHttpRequestTime: t});
      localStorage.setItem('account', JSON.stringify({...account, lastHttpRequestTime: t}));
    }
    if (response.data.status === false) {
      if (response.data.message !== undefined) {
        if (response.data.message.includes('disconnected') || response.data.message.includes('log in first')) {
          setAccount(null);
        }
      }
    }
    return response;
  }, (error) => {
      let m = '';
      if (error.response) {
          // Request made and server responded
          m = 'Axios, server error';
      } else if (error.request) {
          // The request was made but no response was received
          m = 'Axios, no server response';
      } else {
          // Something happened in setting up the request that triggered an Error
          m = 'Axios, unknown error';
      }
      return { data: { status: false, message: m } };
  });
  // --> jobs (liste des métiers)
  const [jobs,setJobs] = useState<listInterface | null>(null);
  // --> log out
  const logout = () => {
    console.log(`User has requested a log out at ${(new Date())}.`);
    httpClient.get(`${apiAddress}/logout`).then((result) => {
      setAccount(null);
      localStorage.removeItem('account');
      console.log('Answer form server: ' + result.data.message);
    });
  }
  // --> on retourne un avatar en partant de son identifiant
  const returnAvatar = (id: number, isFull: boolean) => {
    if (users !== null) {
      for(let i=0;i<users.items.length;i++) {
        if (users.items[i].id === id) {
          return (
            <AvatarIcon 
              avatar={users.items[i].extra}
              firstname={users.items[i].label}
              lastname={''}
              isFull={isFull}
            />
          );
        }
      };
    }
    return (<>{id}</>);
  }
  // --> on retourne un nom d'utilisateur en partant de son identifiant
  const returnUsername = (id: number) => {
    if (users !== null) {
      for(let i=0;i<users.items.length;i++) {
        if (users.items[i].id === id) {
          return <Box>{users.items[i].label}</Box>;
        }
      };
    }
    return (<>{id}</>);
  }
  // --> sectors (liste des secteurs professionnels)
  const [sectors,setSectors] = useState<listInterface | null>(null);
  // --> users (liste des utilisateurs)
  const [users,setUsers] = useState<listInterface | null>(null);
  // --> version de l'application
  const version = '204';

  // Create an object to hold the global variables and functions to update them.
  const userSettings = {
    // --> account
    account: account,
    setAccount,
    // --> api address
    apiAddress: apiAddress,
    // --> color scheme
    colorScheme: colorScheme,
    setColorScheme,
    // --> http client
    httpClient,
    // --> jobs
    jobs: jobs,
    setJobs,
    // --> log out
    logout,
    // --> affichage d'un avatar à partir de son id
    returnAvatar,
    // --> affichage du nom d'utilisateur à partir de son id
    returnUsername,
    // --> sectors
    sectors: sectors,
    setSectors,
    // --> utilisateurs
    users: users,
    setUsers,
    // --> version de l'application
    version,
  }

  // On vide certaines variables lorsque la personne se déconnecte.
  useEffect(() => {
    if (account === null) {
      setJobs(null);
      setSectors(null);
      setUsers(null);
    }
  }, [account])

  // Système pour remettre à zéro le décompte d'inactivité.
  const secondsSinceLastActivity = useRef(0);
  const secondsSinceLastHello = useRef(0);
  const sayHello = () => {
    if (secondsSinceLastHello.current < 30) return;
    secondsSinceLastHello.current = 0;
    httpClient.get(`${apiAddress}/user_extra_time`).then((result) => {
      if (result.data.status === true) {
        console.log(`Successfull hello to server at ${(new Date())}`);
      } else {
        clearTimeout(startActivityTimer.current);
        setAccount(null);
        localStorage.removeItem('account');
        console.log('Logout after a hello failure, answer form server: ' + result.data.message);
      }
    });
  }
  const resetActivity = useCallback(() => {
    secondsSinceLastActivity.current = 0;
  }, []);
  const activityEvents = [
    'mousedown', 'mousemove', 'keydown',
    'scroll', 'touchstart'
  ];
  activityEvents.forEach(function(eventName) {
    document.addEventListener(eventName, resetActivity, true);
  });
  const startActivityTimer = useRef<any>(null);
  const testActivityTimer = useCallback(() => {
    clearTimeout(startActivityTimer.current);
    if (account === null) return;
    secondsSinceLastActivity.current += 5;
    secondsSinceLastHello.current += 5;
    const t = ((new Date()).getTime() - account.lastHttpRequestTime) / 1000;
    if (t > 120 && secondsSinceLastActivity.current < 15) {
      // Cela fait 120 secondes sans échange avec le serveur,
      // alors que la dernière action de l'utilisateur date de moins de 15 secondes,
      // donc, on prévient le serveur que l'utilisateur est toujours là.
      sayHello();
    }
    startActivityTimer.current = setTimeout(() => {
      testActivityTimer();
    }, 5000); // 5 secondes
  },[account]); // eslint-disable-line
  startActivityTimer.current = setTimeout(() => {
    testActivityTimer();
  }, 5000); // 5 secondes

  // Système de déconnexion automatique (avant que le serveur ne déconnecte l'utilisateur) :
  const startAutoLogoutTimer = useRef<any>(null);
  const testAutoLogoutTimer = useCallback(() => {
    clearTimeout(startAutoLogoutTimer.current);
    if (account === null) return;
    const t = (new Date()).getTime();
    if (t - account.lastHttpRequestTime > 22 * 60 * 1000) { // PHP session max 24 minutes
      console.log(`Auto log out at ${(new Date())} after ${(t-account.lastHttpRequestTime)/(60*1000)} minutes`);
      logout();
    } else {
      startAutoLogoutTimer.current = setTimeout(() => {
        testAutoLogoutTimer();
      }, 10 * 1000); // 10 secondes
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  // Initialisation :
  startAutoLogoutTimer.current = setTimeout(() => {
    testAutoLogoutTimer();
  }, 10 * 1000); // 10 secondes

  // Gestion des combinaisons de touche
  const navigate = useNavigate();
  useHotkeys([
    ['mod+I', () => navigate("/cvtheque/insert", { replace: true })],
    ['mod+R', () => navigate("/cvtheque/search", { replace: true })],
    ['mod+B', () => navigate("/need/add", { replace: true })],
    ['mod+E', () => navigate("/annuaire/add/message", { replace: true })]
  ]);

  return (
      <AppContext.Provider value={userSettings}>
        <MantineProvider theme={{ colorScheme: userSettings.colorScheme }} withGlobalStyles withNormalizeCSS>
          <NotificationsProvider position="top-right">
            {(account === null) ? (
              <LoginContainer />
            ):(
              <AppShell 
                header={<HeaderContainer/>}
                navbar={<NavbarContainer/>}
              >
                <ErrorBoundary>
                  <Routes>
                    <Route path="/" element={<HomeContainer/>} />
                    <Route path="/home" element={<HomeContainer/>} />
                    <Route path="/annuaire/add/message" element={<CreateMessageBook/>} />
                    <Route path="/annuaire/clients/list" element={<CompanyBook category='c'/>} />
                    <Route path="/annuaire/dashboard" element={<DashboardBook/>} />
                    <Route path="/annuaire/fiche/:paramCompanyId/:paramCompanyName" element={<CompanyFiche />} />
                    <Route path="/annuaire/mailing" element={<MailingBook/>} />
                    <Route path="/annuaire/prospects/list" element={<CompanyBook category='p'/>} />
                    <Route path="/annuaire/references/list" element={<CompanyBook category='r'/>} />
                    <Route path="/bank/lettering" element={<BankLettering/>} />
                    <Route path="/bank/remove_lettering" element={<BankLetteringRemove/>} />
                    <Route path="/bank/upload" element={<BankUpload/>} />
                    <Route path="/configuration/account/add" element={<AddAccount/>} />
                    <Route path="/configuration/accounts" element={<ManageAccounts/>} />
                    <Route path="/configuration/permissions" element={<ManagePermissions/>} />
                    <Route path="/configuration/affectations" element={<ManageAffectations/>} />
                    <Route path="/configuration/export" element={<ExportDataset/>} />
                    <Route path="/configuration/send_email" element={<SendEmail/>} />
                    <Route path="/cvtheque/audit/blocked" element={<AuditBlockedCV/>} />
                    <Route path="/cvtheque/audit/duplicates" element={<AuditDuplicatesCV/>} />
                    <Route path="/cvtheque/audit/metadata" element={<AuditMetadataCV/>} />
                    <Route path="/cvtheque/dashboard/in_progress" element={<DashboardInProgressCV/>} />
                    <Route path="/cvtheque/dashboard/main" element={<DashboardMainCV/>} /> 
                    <Route path="/cvtheque/document/:paramDocumentId" element={<DocumentCV/>} />
                    <Route path="/cvtheque/insert" element={<InsertCV/>} />
                    <Route path="/cvtheque/list" element={<ListCV/>} />
                    <Route path="/cvtheque/search" element={<SearchCV handleSelection={null} />} />
                    <Route path="/hunt/add" element={<AddHunt/>} />
                    <Route path="/hunt/list" element={<ListHunts/>} />
                    <Route path="/hunt/manage/:paramId" element={<ManageHunt/>} />
                    <Route path="/mission/add" element={<AddMission/>} />
                    <Route path="/mission/list" element={<ListMission/>} />
                    <Route path="/mission/manage/:paramMissionId" element={<ManageMission/>} />
                    <Route path="/mission/overview/audit-activities" element={<AuditActivitiesMission/>} />
                    <Route path="/mission/overview/audit-factures" element={<AuditFacturesMission/>} />
                    <Route path="/mission/overview/dashboard" element={<DashboardMission/>} />
                    <Route path="/mission/overview/not_paid" element={<NotPaidMission/>} />
                    <Route path="/mission/overview/removed-factures" element={<RemovedFactures/>} />
                    <Route path="/mission/overview/factures/:paramNature" element={<FacturesOnMission/>} />
                    <Route path="/mission/search" element={<SearchMission/>} />
                    <Route path="/need/add" element={<AddNeed/>} />
                    <Route path="/need/list/open" element={<ListNeed isOpen={true}/>} />
                    <Route path="/need/list/closed" element={<ListNeed isOpen={false}/>} />
                    <Route path="/need/process/:paramNeedId" element={<ProcessNeed/>} />
                    <Route path="/need/dashboard/main" element={<DashboardMainNeed/>} />
                    <Route path="/team/orga" element={<OrgaTeam/>} />
                    <Route path="/team/attendance" element={<PlanningTeam/>} />
                    <Route path="/team/requests" element={<RequestsTeam/>} />
                    <Route path="/jump-to/:paramUrl" element={<JumpTo/>} />
                    <Route path="*" element={<PageNotFound/>} />
                  </Routes>
                </ErrorBoundary>
              </AppShell>
            )}
          </NotificationsProvider>
        </MantineProvider>
      </AppContext.Provider>
  );
}

export default App;

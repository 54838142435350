import { useEffect, useState } from 'react';
import { ActionIcon, Badge, Group, Modal, LoadingOverlay, NumberInput, ScrollArea, SegmentedControl } from '@mantine/core';
import { IconBulb } from '@tabler/icons';

import { CVInterface, RowHistoryInterface } from '../interfaces';

import enStopWords from './stop_words_english.json';
import frStopWords from './stop_words_french.json';

const sentence2vec = (s: string): [string[], number[]] => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ0123456789';
    const words: string[] = [];
    const tfs: number[] = [];
    let t: number = 0;
    let word: string = '';
    for (let l of s + ' ') {
        if (letters.includes(l)) {
            word += l;
        } else {
            if (word !== '') {
                if ('0123456789'.includes(word[0]) === false) { 
                    const p = words.indexOf(word);
                    if (p === -1) {
                        if (frStopWords.indexOf(word.toLowerCase()) === -1 
                        && enStopWords.indexOf(word.toLocaleLowerCase()) === -1) {
                            words.push(word);
                            tfs.push(1);
                        }
                    } else {
                        tfs[p] += 1;
                    }
                    t += 1;
                }
                word = '';
            }
        }
    }
    if (t === 0) {
        return [ [] , [] ];
    } else {
        for (let i=0;i<tfs.length;i++) {
            tfs[i] = Math.round(1000 * tfs[i] / t);
        }
        return [ words, tfs ];
    }
}
interface WordInterface {
    value: string,
    idf: number,
}

interface PropsInterface {
    profiles: CVInterface[];
}

const AnalyzeFields = ({ profiles }: PropsInterface) => {

    const fields = [ 'jobs', 'experiences', 'formations', 'decorations', 'headline', 'location', 'sector' ];
    const [ display, setDisplay ] = useState(false);
    const [ field, setField ] = useState<string | undefined>('sector');
    const [ prepared, setPrepared ] = useState(true);
    const [ dico, setDico ] = useState<WordInterface[]>([]);
    const [ seuilMin, setSeuilMin ] = useState(10);
    const [ seuilMax, setSeuilMax ] = useState(90);

    const analyze = () => {
        if (field === undefined || profiles.length === 0) {
            setDico([]);
            return;
        };
        setPrepared(false);
        const words: string[] = [];
        const idfs: number[] = [];
        for (const p of profiles) {
            let s: string = '';
            if (field === 'jobs'){
                s = p.jobs.map((r: RowHistoryInterface) => { return r.la }).join(' ');
            } else if (field === 'experiences'){
                s = p.experiences.map((r: RowHistoryInterface) => { return r.la }).join(' ');
            } else if (field === 'formations'){
                s = p.formations.map((r: RowHistoryInterface) => { return r.la }).join(' ');
            } else if (field === 'decorations'){
                s = p.decorations.join(' ');
            } else if (field === 'sector'){
                s = p.sector;
            } else if (field === 'location'){
                s = p.location;
            } else if (field === 'headline'){
                s = p.headline;
            }
            const res = sentence2vec(s + ' ');
            const ww = res[0];
            for (const w of ww) {
                const j: number = words.indexOf(w);
                if (j === -1) {
                    words.push(w);
                    idfs.push(1);
                } else {
                    idfs[j] += 1;
                }
            }
        }
        let n = profiles.length;
        if (n !== 0) {
            n = Math.log(n);
            for(let i=0;i<idfs.length;i++){
                idfs[i] = Math.round(100 * (1 - Math.log(idfs[i]) / n));
            }
        }
        setDico(words.map((s,i) => { return { value: s, idf: idfs[i]} }).sort((a,b) => a.idf - b.idf));
        setPrepared(true);
    }

    useEffect(() => {
        analyze();
    }, [field]); // eslint-disable-line

    return (
        <>
        <Modal
            opened={display}
            onClose={() => setDisplay(false)}
            size='large'
        >
            <LoadingOverlay visible={!prepared} />
            <SegmentedControl
                value={field}
                onChange={setField}
                data={fields.map((f) => { return { 'label': f, 'value': f }})}
            />
            <Group>
                <NumberInput
                    value={seuilMin} onChange={(v) => { setSeuilMin(v || 20) }}
                    defaultValue={20}
                    placeholder="seuil minimal"
                    label="seuil minimal"
                    withAsterisk
                />
                <NumberInput
                    value={seuilMax} onChange={(v) => { setSeuilMax(v || 80) }}
                    defaultValue={80}
                    placeholder="seuil maximal"
                    label="seuil maximal"
                    withAsterisk
                />
            </Group>
            <ScrollArea style={{height: 'calc(100vh - 290px)'}}>
                {dico.filter((w) => (w.idf >= seuilMin && w.idf <= seuilMax)).map((w,i) => 
                    <Badge key={`w-${i}`}
                        style={{textTransform: 'none'}}
                        rightSection={
                            <>{(100-w.idf)/10}</>
                        }
                    >
                        {w.value}
                    </Badge>
                )}
            </ScrollArea>
        </Modal>
        <ActionIcon color='blue' variant='outline' onClick={() => { setDisplay(true); }}
        >
            <IconBulb size={16} />
        </ActionIcon>
        </>
    )
}

export { AnalyzeFields }
import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Badge, Button, Center, Divider, Drawer, Grid, Group, Indicator, LoadingOverlay, Mark, Modal, ScrollArea, SegmentedControl, Table, Text, Textarea, TextInput } from '@mantine/core';
import { IconArrowBigRight, IconCodePlus, IconLetterA, IconLetterB, IconLetterC, IconLetterD, IconLetterE, IconLetterF, IconLetterG, IconLetterH, IconLetterI, IconLetterJ, IconLetterK, IconLetterL, IconLetterM, IconLetterN, IconLetterO, IconLetterP, IconLetterQ, IconLetterR, IconLetterS, IconLetterT, IconLetterU, IconLetterV, IconLetterW, IconLetterX, IconLetterY, IconLetterZ, IconNumber0, IconPlus, IconPencil, IconUser } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from "../../shared/TitleContainer"
import { AlertNotification } from '../../shared/AlertNotification';
import { InfoNotification } from '../../shared/InfoNotification';
import { ContactBook } from '../ContactBook';
import { TrashButton } from '../../shared/TrashButton';

interface CompanyInterface {
    id: number,
    letter: string,
    label: string,
    tag: string | null,
    indicator: number,
    n_contacts: number,
}
interface BookInterface {
    letter: string,
    total: number,
    indicator: number,
    companies: CompanyInterface[],
}

const listOfLetters = [
    '0', 
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
    'Y', 'Z',
];

const listOfIcons = [
    <IconNumber0 size={14} />,
    <IconLetterA size={14} />,
    <IconLetterB size={14} />,
    <IconLetterC size={14} />,
    <IconLetterD size={14} />,
    <IconLetterE size={14} />,
    <IconLetterF size={14} />,
    <IconLetterG size={14} />,
    <IconLetterH size={14} />,
    <IconLetterI size={14} />,
    <IconLetterJ size={14} />,
    <IconLetterK size={14} />,
    <IconLetterL size={14} />,
    <IconLetterM size={14} />,
    <IconLetterN size={14} />,
    <IconLetterO size={14} />,
    <IconLetterP size={14} />,
    <IconLetterQ size={14} />,
    <IconLetterR size={14} />,
    <IconLetterS size={14} />,
    <IconLetterT size={14} />,
    <IconLetterU size={14} />,
    <IconLetterV size={14} />,
    <IconLetterW size={14} />,
    <IconLetterX size={14} />,
    <IconLetterY size={14} />,
    <IconLetterZ size={14} />,
];

const bookReducer = (state: BookInterface[], payload: any) => {
    switch(payload.type) {
        case 'add':
            const i0 = listOfLetters.indexOf(payload.data.letter);
            return state.map((B,i) => {
                if (i0 === i) {
                    return {
                        ...B,
                        total: B.total + 1,
                        companies: (B.companies.concat([{...payload.data}])).sort((a,b) => { if (a<b) return -1; if (a===b) return 0; return 1;}),
                    };
                } else {
                    return {...B};
                }
            });
        case 'update_label':
            const o1 = listOfLetters.indexOf(payload.oldLetter);
            const i1 = listOfLetters.indexOf(payload.data.letter);
            if (o1 === i1) {
                return state.map((B,i) => {
                    if (i1 === i) {
                        return {
                            ...B,
                            companies: (B.companies.map((c) => { if (c.id === payload.data.id) { return {...payload.data}; } else { return {...c}; } })).sort((a,b) => { if (a<b) return -1; if (a===b) return 0; return 1;}),
                        };
                    } else {
                        return {...B};
                    }
                });
            } else {
                return state.map((B,i) => {
                    if (i1 === i) {
                        return {
                            ...B,
                            total: B.total + 1,
                            companies: (B.companies.concat([{...payload.data}])).sort((a,b) => { if (a<b) return -1; if (a===b) return 0; return 1;}),
                        };
                    } else if (o1 === i) {
                        return {
                            ...B,
                            total: B.total - 1,
                            companies: (B.companies.filter((c) => c.id !== payload.data.id)).sort((a,b) => { if (a<b) return -1; if (a===b) return 0; return 1;}),
                        };
                    } else {
                        return {...B};
                    }
                });
            }
        case 'update_tag':
            const i2 = listOfLetters.indexOf(payload.company.letter);
            return state.map((B,i) => {
                if (i2 === i) {
                    return {
                        ...B,
                        companies: B.companies.map((c) => {
                            if (c.id === payload.company.id) {
                                return {...c, tag: payload.company.tag}
                            } else {
                                return {...c};
                            }
                        }),
                    };
                } else {
                    return {...B};
                }
            });
        case 'remove':
            return state.map((B,i) => {
                if (B.letter === payload.company.letter) {
                    return {
                        ...B,
                        total: B.total - 1,
                        companies: B.companies.filter((c) => c.id !== payload.company.id),
                    };
                } else {
                    return B;
                }
            });
        case 'set':
            const B: BookInterface[] = [
                {letter: '0', total: 0, companies: [], indicator: 0},
                {letter: 'A', total: 0, companies: [], indicator: 0},
                {letter: 'B', total: 0, companies: [], indicator: 0},
                {letter: 'C', total: 0, companies: [], indicator: 0},
                {letter: 'D', total: 0, companies: [], indicator: 0},
                {letter: 'E', total: 0, companies: [], indicator: 0},
                {letter: 'F', total: 0, companies: [], indicator: 0},
                {letter: 'G', total: 0, companies: [], indicator: 0},
                {letter: 'H', total: 0, companies: [], indicator: 0},
                {letter: 'I', total: 0, companies: [], indicator: 0},
                {letter: 'J', total: 0, companies: [], indicator: 0},
                {letter: 'K', total: 0, companies: [], indicator: 0},
                {letter: 'L', total: 0, companies: [], indicator: 0},
                {letter: 'M', total: 0, companies: [], indicator: 0},
                {letter: 'N', total: 0, companies: [], indicator: 0},
                {letter: 'O', total: 0, companies: [], indicator: 0},
                {letter: 'P', total: 0, companies: [], indicator: 0},
                {letter: 'Q', total: 0, companies: [], indicator: 0},
                {letter: 'R', total: 0, companies: [], indicator: 0},
                {letter: 'S', total: 0, companies: [], indicator: 0},
                {letter: 'T', total: 0, companies: [], indicator: 0},
                {letter: 'U', total: 0, companies: [], indicator: 0},
                {letter: 'V', total: 0, companies: [], indicator: 0},
                {letter: 'W', total: 0, companies: [], indicator: 0},
                {letter: 'X', total: 0, companies: [], indicator: 0},
                {letter: 'Y', total: 0, companies: [], indicator: 0},
                {letter: 'Z', total: 0, companies: [], indicator: 0},
            ];
            if (payload.data === null) {
                return B;
            }
            for (const c of payload.data) {
                const i: number = listOfLetters.indexOf(c.letter);
                B[i].total += 1;
                B[i].companies.push({...c});
                if (c.indicator > 3) {
                    B[i].indicator += 1;
                }
            }
            return B;
        default:
            return [];
    }
}

interface PropsInterface {
    category: 'c' | 'p' | 'r',
}

const CompanyBook = ({category}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [book, dispatchBook] = useReducer(bookReducer, []);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [letter, setLetter] = useState(-1);
    
    const [drawerCompany, setDrawerCompany] = useState(false);
    const emptyCompany: CompanyInterface = {id: -1, letter: '', label: '', tag: '', indicator: 0, n_contacts: 0 };
    const [company, setCompany] = useState<CompanyInterface>({...emptyCompany});
    const [newCompany, setNewCompany] = useState<CompanyInterface>({...emptyCompany});
    const [newCategory, setNewCategory] = useState<string>(category);
    const [newTag, setNewTag] = useState<string>('');

    const [drawerCompanies, setDrawerCompanies] = useState(false);
    const [newCompanies, setNewCompanies] = useState('');

    const [modalContacts, setModalContacts] = useState(false);
    const [newContacts, setNewContacts] = useState('');
    
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        setCompany({...newCompany});
        setNewCompany({...emptyCompany});
        setIsLoaded(false);
    }, [category]); // eslint-disable-line

    useEffect(() => {
        if (isLoaded) return;
        const api = `${myContext.apiAddress}/list_company_book?category=${category}`;
        myContext.httpClient.get(api).then((res: any) => {
            setIsLoaded(true);
            if (res.data.status === true) {
                dispatchBook({type: 'set', data: res.data.data});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [isLoaded]); // eslint-disable-line

    const createCompanies = async () => {
        setIsWorking(true);
        const api = `${myContext.apiAddress}/update_company_book`;
        setNewCompany({...emptyCompany});
        const companies = newCompanies.split('\n');
        let e: string[] = [];
        for(const C of companies){
            if (C.trim().length > 0) {
                const F = new FormData();
                F.append('action', 'add');
                F.append('category', category);
                F.append('id', '');
                F.append('label', C.trim());
                let b: any = await _update(api, F);
                if (b.status === true) {
                    dispatchBook({type: 'add', data: b.data});
                } else {
                    AlertNotification({message: b.data || 'unknown error'});
                    e.push(C.trim());
                    if (e.length > 10) {
                        break;
                    }
                }
            }
        }
        setNewCompanies(e.join('\n'));
        setIsWorking(false);
        if (e.length === 0) {
            setDrawerCompanies(false);
        }
    }

    const createContacts = async () => {
        // contrôle des données
        let b: boolean = true;
        let ok = 0;
        const contacts: string[] = newContacts.split('\n');
        for(let i=0;i<contacts.length;i++){
            if (contacts[i].trim().length !== 0) {
                if (/^[a-z0-9-]{1,100};[^;]{1,100};[^;]{1,70}@[^;]{1,30};[^;]{0,100};[^;]{0,50}$/.test(contacts[i].trim()) !== true) {
                    b = false;
                    AlertNotification({message: 'error in line ' + (i+1)});
                    break;
                } else {
                    ok += 1;
                }
            }
        }
        if (!b) return;
        if (ok === 0) return;
        // on y va
        setIsWorking(true);
        let e: string[] = [];
        const api = `${myContext.apiAddress}/update_contact_book`;
        for(let i=0;i<contacts.length;i++){
            if (contacts[i].trim().length !== 0) {
                const cols: string[] = contacts[i].trim().split(';');
                const F = new FormData();
                F.append('action', 'add');
                F.append('company_slug', cols[0]);
                F.append('company_category', category);
                F.append('fullname', cols[1]);
                if (cols[2].trim().length > 0) F.append('email', cols[2].trim());
                if (cols[3].trim().length > 0) F.append('job', cols[3].trim());
                if (cols[4].trim().length > 0) F.append('phone', cols[4].trim());
                let b: any = await _update(api, F);
                if (b.status !== true) {
                    AlertNotification({message: b.data || 'unknown error'});
                    e.push(contacts[i].trim());
                    if (e.length > 10) {
                        break;
                    }
                }
            }
        }
        setNewContacts(e.join('\n'));
        setIsWorking(false);
        if (e.length === 0) {
            setModalContacts(false);
        }
    }

    const UpdateCompany = async () => {
        setIsWorking(true);
        const api = `${myContext.apiAddress}/update_company_book`;
        const F = new FormData();
        F.append('action', newCompany.id === -1 ? 'add' : 'update_label');
        F.append('category', category);
        F.append('id', newCompany.id + '');
        F.append('label', newCompany.label.trim());
        let b: any = await _update(api, F);
        setIsWorking(false);
        if (b.status === true) {
            dispatchBook({type: newCompany.id === -1 ? 'add' : 'update_label', oldLetter: newCompany.letter, data: b.data});
            setNewCompany({...emptyCompany});
            setDrawerCompany(false);
        } else {
            AlertNotification({message: b.data || 'unknown error'});
        }
    }

    const _update = (api: string, F: FormData) => {
        return new Promise((resolve) => {
            myContext.httpClient.post(api, F).then((res:any) => {
                let b: any;
                if (res.data.status === true) {
                    b = { status: true, data: res.data.data };
                } else {
                    b = { status: false, data: res.data.message || 'unknown error' };
                }
                resolve(b);
            });
        })
    }

    const UpdateCategory = () => {
        if (newCompany.id === -1) return;
        if (newCategory === category) return;
        setIsWorking(true);
        const F = new FormData();
        F.append('action', 'update');
        F.append('category', newCategory);
        F.append('id', newCompany.id + '');
        const api = `${myContext.apiAddress}/update_company_book`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsWorking(false);
            if (res.data.status === true) {
                dispatchBook({type: 'remove', company: {...newCompany}});
                setNewCompany({...emptyCompany});
                setCompany({...emptyCompany});
                setDrawerCompany(false);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    const UpdateTag = () => {
        if (newCompany.id === -1) return;
        if (newTag === newCompany.tag) return;
        setIsWorking(true);
        const F = new FormData();
        F.append('action', 'update');
        F.append('category', category);
        F.append('tag', newTag);
        F.append('id', newCompany.id + '');
        const api = `${myContext.apiAddress}/update_company_book`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsWorking(false);
            if (res.data.status === true) {
                dispatchBook({type: 'update_tag', company: {...newCompany, tag: newTag === 'null' ? null : newTag}});
                setNewCompany({...emptyCompany});
                setCompany({...emptyCompany});
                setDrawerCompany(false);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });

    }

    const computeTotal = () => {
        let t: number = 0;
        for (const b of book) {
            for (const c of b.companies) {
                t += c.n_contacts || 0;
            }
        }
        return t;
    }

    const copyContacts = () => {
        setExporting(true);
        const api = `${myContext.apiAddress}/export_contacts_book?category=${category}`;
        myContext.httpClient.get(api).then((res:any) => {
            setExporting(false);
            if (res.data.status === true) {
                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                    navigator.clipboard.writeText(res.data.data);
                    InfoNotification({message: 'Données copiées dans le presse papier.'});
                } else {
                    AlertNotification({message: 'The clipboard API is not available'});
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <>
        <Group position="apart">
            <TitleContainer>
                {category === 'c' && <>Annuaire des clients</>}
                {category === 'p' && <>Annuaire des prospects</>}
                {category === 'r' && <>Annuaire des prises de références</>}
            </TitleContainer>
            <Group>
                <Badge>
                    {computeTotal()} <IconUser size={10} />
                </Badge>
                <Button color="blue" variant="outline" size='xs' compact
                    onClick={() => copyContacts()} loading={exporting}
                >
                    Exporter
                </Button>
                <ActionIcon color="blue" variant="outline" onClick={() => {
                        setCompany({...emptyCompany});
                        setNewCompany({...emptyCompany});
                        setDrawerCompany(true);
                    }}
                >
                    <IconPlus size={14} />
                </ActionIcon>
                <ActionIcon color="blue" variant="outline" onClick={() => {
                        setNewCompanies('');
                        setDrawerCompanies(true);
                    }}
                >
                    <IconCodePlus size={16} />
                </ActionIcon>
                <ActionIcon color="blue" variant="outline" onClick={() => {
                        setNewContacts('');
                        setModalContacts(true);
                    }}
                >
                    <IconCodePlus size={16} />
                </ActionIcon>
            </Group>
        </Group>
        <Drawer
            opened={drawerCompany}
            onClose={() => setDrawerCompany(false)}
            position='right'
            padding='xl'
            size="xl"
        >
            <LoadingOverlay visible={isWorking} />

            <Text>
                {newCompany.id === -1 ? 'Ajouter une nouvelle société' : 'Renommer une société'}
            </Text>
            <Text><strong>
                {category === 'c' && <>client</>}
                {category === 'p' && <>prospect</>}
                {category === 'r' && <>référence</>}    
            </strong></Text>
            <Group position="apart" style={{alignItems: 'bottom'}}>
                <TextInput
                    pt='lg'
                    label='Nom de la société'
                    value={newCompany.label} 
                    onChange={(event) => setNewCompany({...newCompany, label: event.currentTarget.value})}
                    withAsterisk
                />
                <Button color="blue" disabled={newCompany.label.trim() === '' || newCompany.label === company.label} 
                    onClick={() => UpdateCompany()}
                >
                    {newCompany.id === -1 ? 'sauvegarder' : 'mettre à jour'}
                </Button>
            </Group>

            {newCompany.id !== -1 &&
            <>
            <Divider mt="lg" mb="lg" />
            <Text>
                Changer le tag de la société
            </Text>
            <Group position="apart">
                <SegmentedControl
                    mt='lg'
                    value={newTag}
                    onChange={setNewTag}
                    data={[
                        {label: 'aucun', value: 'null'},
                        {label: 'nouveau', value: 'nouveau'},
                        {label: 'bronze', value: 'bronze'},
                        {label: 'argent', value: 'argent'},
                        {label: 'or', value: 'or'}
                    ]}
                />
                <Button color="blue" disabled={newTag === (newCompany.tag || 'null')}
                    onClick={() => UpdateTag()}
                >
                    mettre à jour
                </Button>
            </Group>
            <Divider mt="lg" mb="lg" />
            <Text>
                Changer la société d'annuaire
            </Text>
            <Group position="apart">
                <SegmentedControl
                    mt='lg'
                    value={newCategory}
                    onChange={setNewCategory}
                    data={[
                        {label: 'clients', value: 'c'},
                        {label: 'prospects', value: 'p'},
                        {label: 'références', value: 'r'}
                    ]}
                />
                <Button color="blue" disabled={newCategory === category} 
                    onClick={() => UpdateCategory()}
                >
                    mettre à jour
                </Button>
            </Group>
            <Divider mt="lg" mb="lg" />
            <Group position="apart">
                <Text>Supprimer la société (si aucun contact actif)</Text>
                <TrashButton
                    label={`Supprimer la société ${company.label} ?`}
                    api={`update_company_book`}
                    params={`action=remove&id=${company.id}&category=${category}`}
                    handle={() => {
                        dispatchBook({type: 'remove', company: {...company}});
                        setCompany({...emptyCompany});
                        setNewCompany({...emptyCompany});
                        setDrawerCompany(false);
                    }}
                />
            </Group>
            </>
            }

        </Drawer>
        
        <Drawer
            opened={drawerCompanies}
            onClose={() => setDrawerCompanies(false)}
            position='right'
            padding='xl'
            size="xl"
        >
            <LoadingOverlay visible={isWorking} />
            <Text>Créer en une seule fois plusieurs sociétés de</Text>
            <Text><strong>
                {category === 'c' && <>clients</>}
                {category === 'p' && <>prospects</>}
                {category === 'r' && <>références</>}    
            </strong></Text>
            <Textarea 
                placeholder='1 new company to add per line'
                value={newCompanies} 
                onChange={(event) => setNewCompanies(event.currentTarget.value)}
                minRows={5}
                maxRows={10}
                autosize
            />
            <Center p="xs">
                <Button color='blue' variant='outline' loading={isWorking}
                    disabled={newCompanies.trim().length < 1}
                    onClick={() => createCompanies()}
                >
                    submit
                </Button>
            </Center>
        </Drawer>

        <Modal
            opened={modalContacts}
            onClose={() => setModalContacts(false)}
            padding='xl'
            fullScreen
        >
            <LoadingOverlay visible={isWorking} />
            <Text>Créer en une seule fois plusieurs contacts de</Text>
            <Text><strong>
                {category === 'c' && <>clients</>}
                {category === 'p' && <>prospects</>}
                {category === 'r' && <>références</>}    
            </strong></Text>
            <Textarea 
                placeholder='company_slug;fullname;email;job;phone'
                value={newContacts} 
                onChange={(event) => setNewContacts(event.currentTarget.value)}
                minRows={5}
                maxRows={10}
                autosize
            />
            <Center p="xs">
                <Button color='blue' variant='outline' loading={isWorking}
                    onClick={() => createContacts()}
                >
                    submit
                </Button>
            </Center>
        </Modal>

        <div>
            <LoadingOverlay visible={!isLoaded} />
            {book.length !== 0 &&
            <Group>
                {listOfIcons.map((node: any, j: number) => 
                <ActionIcon key={`letter-${j}`} color="blue" variant="outline" disabled={book[j].total === 0}
                    onClick={() => { setLetter(j); setCompany({...emptyCompany});}}
                >
                    {node}
                </ActionIcon>
                )}
            </Group>
            }
            {letter > -1 && 
            <Grid pt="xs">
                <Grid.Col lg={4} md={5} sm={5} xs={12}>
                    <Divider label="Sociétés" labelPosition="left" />
                    <ScrollArea style={{height: 'calc(100vh - 180px)'}}>
                    <Table highlightOnHover><tbody>
                    {book[letter].companies.map((c: CompanyInterface, i: number) => 
                        <tr key={`company-${letter}-${c.id}`}>
                            <td>
                                <Indicator color="red" disabled={c.indicator === 0} size={7} zIndex={10}>
                                    <Group>
                                        <Text color="grape" size="xs">
                                            {c.tag === 'or' && <>▮▮▮</> }
                                            {c.tag === 'argent' && <>▮▮▯</> }
                                            {c.tag === 'bronze' && <>▮▯▯</> }
                                            {c.tag === 'nouveau' && <>new</> }
                                            {c.tag === null && <>▯▯▯</>}
                                        </Text>
                                        <Text onClick={() => setCompany({...c})}
                                            style={{cursor: 'pointer'}}
                                        >
                                            <IconArrowBigRight size={12} /> {(company !== null && c.id === company.id) ? <Mark>{c.label}</Mark> : <>{c.label}</>}
                                        </Text>
                                    </Group>
                                </Indicator>
                            </td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <ActionIcon color="blue" variant="outline"
                                        onClick={() => { 
                                            setCompany({...c}); 
                                            setNewCompany({...c}); 
                                            setNewCategory(category);
                                            setNewTag(c.tag || '');
                                            setDrawerCompany(true)}
                                        }
                                    >
                                        <IconPencil size={16} />
                                    </ActionIcon>
                                    &nbsp;
                                    <ActionIcon color='blue' variant="outline" onClick={() => setCompany({...c})}>
                                        <IconArrowBigRight size={16} />
                                    </ActionIcon>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody></Table>
                    </ScrollArea>
                </Grid.Col>
                <Grid.Col lg={1} md={1} sm={1} xs={12}>
                    <Center style={{height: '100%'}}>
                        <Divider orientation="vertical" />
                    </Center>
                </Grid.Col>
                <Grid.Col lg={7} md={6} sm={6} xs={12}>
                    <Divider label={`Contacts dans la société${company === null ? '' : ' '+ company.label}`} labelPosition="left" />                        
                    <ScrollArea style={{height: 'calc(100vh - 180px)'}}>
                        {company.id !== -1 && 
                        <ContactBook
                            id={company.id}
                            letter={company.letter}
                            label={company.label}
                        />
                        }
                    </ScrollArea>
                </Grid.Col>
            </Grid>
            }
        </div>
        </>
    )
}

export { CompanyBook }
import { useContext, useState } from 'react';
import { ActionIcon, Center, ColorSwatch, Group, Loader, Stack, Switch } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface PropsInterface {
    needId: number,
    cvId: number,
    field: string,
    value: boolean | null,
    handleChange: any,
}

const ValueBoolean = ({needId, cvId, field, value, handleChange}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [display, setDisplay] = useState(false);
    const [val, setVal] = useState(value);
    const [newVal, setNewVal] = useState(value === null ? false : value);
    const [saving, setSaving] = useState(false);

    const save = () => {
        if (val === newVal) return;
        setSaving(true);
        const F = new FormData();
        F.append('what', field);
        F.append('value', newVal + '');
        const api = `${myContext.apiAddress}/update_process_need?need_id=${needId}&cv_id=${cvId}`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setSaving(false);
            if (res.data.status === true) {
                setVal(newVal);
                handleChange(newVal);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setDisplay(false);
        });
    }

    if (saving) {
        return (
            <Center>
                <Loader size="sm" />
            </Center>
        )
    } else if (display) {
        return (
            <Stack spacing={0}>
                <Center pb="xs">
                    <Switch size="xs" checked={newVal} onChange={(event) => setNewVal(event.currentTarget.checked)} />
                </Center>
                <Group position="center">
                    <ActionIcon color='blue' variant='outline' size={14} onClick={() => setDisplay(false)}>
                        <IconX size={12} />
                    </ActionIcon>
                    <ActionIcon color='blue' variant='outline' size={14} onClick={() => save()}>
                        <IconCheck size={12} />
                    </ActionIcon>
                </Group>
            </Stack>
        )
    } else {
        return (
            <Center onClick={() => setDisplay(true)} pt="xs" style={{cursor: 'pointer'}}>
                {val === null ? 
                <ColorSwatch color='gray' size={12}/>
                :
                <ColorSwatch color={val ? 'green' : 'red'} size={12}/>
                }
            </Center>
        )
    }

}

export { ValueBoolean }
import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Center, Stack, TextInput } from "@mantine/core";
import { TitleContainer } from "../../shared/TitleContainer";
import { toEmail, toLogin } from '../../../services/functions';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

const AddAccount = () => {

    const myContext = useContext(AppContext);
    const navigate = useNavigate();

    const [ working, setWorking ] = useState(false);
    const [ inputFirstname, setInputFirstname ] = useState('');
    const [ inputLastname, setInputLastname ] = useState('');
    const [ inputLogin, setInputLogin ] = useState('');
    const [ inputEmail, setInputEmail ] = useState('');

    const save = () => {
        setWorking(true);
        const F = new FormData();
        F.append('login', inputLogin.trim());
        F.append('email', inputEmail.trim());
        F.append('firstname', inputFirstname.trim());
        F.append('lastname', inputLastname.trim());
        const api = `${myContext.apiAddress}/add_account`;
        myContext.httpClient.post(api, F).then((res: any) => {
            if (res.data.status === true) {
                navigate(`/configuration/accounts`, { replace: true });
            } else {
                setWorking(false);
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <Center>
            <Stack style={{width: 'min(100%, 400px)'}}>
                <TitleContainer>
                    Ajouter un utilisateur
                </TitleContainer>
                <TextInput
                    label='Prénom'
                    value={inputFirstname}
                    onChange={(event) => setInputFirstname(event.currentTarget.value)}
                    disabled={working}
                    withAsterisk
                />
                <TextInput
                    label='Nom'
                    value={inputLastname}
                    onChange={(event) => setInputLastname(event.currentTarget.value.toLocaleUpperCase())}
                    disabled={working}
                    withAsterisk
                />
                <TextInput
                    label='Login'
                    value={inputLogin}
                    onChange={(event) => setInputLogin(event.currentTarget.value.trim())}
                    disabled={working}
                    withAsterisk
                />
                <TextInput
                    label='Email'
                    value={inputEmail}
                    onChange={(event) => setInputEmail(event.currentTarget.value.trim())}
                    disabled={working}
                    withAsterisk
                />
                <Button color='blue' variant='outline' loading={working}
                    disabled={[null, false].includes(toEmail(inputEmail)) || [null, false].includes(toLogin(inputLogin)) || inputFirstname.trim().length < 2 || inputLastname.trim().length < 2}
                    onClick={() => save()}
                >
                    Ajouter ce nouvel utilisateur
                </Button>
            </Stack>
        </Center>
    )
}


export { AddAccount }
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ActionIcon, Card, createStyles, Group, Loader, Text, TextInput } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../../shared/AlertNotification';

interface PropsInterface {
    code: string,
    label: string,
    defaultValue: string,
    api: string,
    isEdition: boolean,
    handle: any,
    withAsterisk: boolean,
    maxLength?: number,
}

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },
    
    input: {
        height: 'auto',
        paddingTop: 18,
        marginTop: 5,
    },
    
    label: {
        position: 'absolute',
        pointerEvents: 'none',
        color: theme.colors.gray[6],
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}))

const TextInputContainer = ({code, label, defaultValue, api, isEdition, handle, withAsterisk, maxLength = -1}: PropsInterface) => {

    const { classes } = useStyles();
    const [ oldValue, setOldValue ] = useState(defaultValue);
    const [ newValue, setNewValue ] = useState(defaultValue);
    const [ isUpdating, setIsUpdating ] = useState(false);
    const myContext = useContext(AppContext);

    const update = useCallback(() => {
        setIsUpdating(true);
        unsavedFrom.current = null;
        const F = new FormData();
        F.append('action', code);
        F.append('code', code);
        F.append('value', newValue);
        myContext.httpClient.post(api, F).then((result: any) => {
            if (result.data.status === true) {
                const v: string = result.data.message === 'done' ? newValue : result.data.message;
                setOldValue(v);
                setNewValue(v);
                if (handle !== null) {
                    handle(newValue);
                }
            } else {
                setNewValue(oldValue);
                AlertNotification({message: result.data.message || "unknown error from servor"});
            }
            setIsUpdating(false);
        });
    }, [newValue]); // eslint-disable-line

    // --------------------------------------------------------
    // On met en place un mécanisme de sauvegarde automatique
    // après 5 secondes de non saisie suite à une modification.
    const unsavedSeconds = 5;
    const unsavedFrom = useRef<number | null>(null);
    const startAutoSaveTimer = useRef<any>(null);
    const testAutoSaveTimer = useCallback(() => {
        clearTimeout(startAutoSaveTimer.current);
        if (unsavedFrom.current === null) return;
        const t = (new Date()).getTime();
        if (t - unsavedFrom.current > unsavedSeconds * 1000) {
            unsavedFrom.current = null;
            update();
        } else {
            startAutoSaveTimer.current = setTimeout(() => {
                testAutoSaveTimer();
          }, (unsavedSeconds + 1) * 1000);
        }
    }, [update]);
    useEffect(() => {
        if (newValue !== oldValue) {
            unsavedFrom.current = (new Date()).getTime();
            testAutoSaveTimer();
        }
    }, [newValue]); // eslint-disable-line
    // --------------------------------------------------------

    const reset = () => {
        setNewValue(oldValue);
        unsavedFrom.current = null;
    }

    if (isEdition) {
        return (
            <>
            <TextInput 
                label={label}
                placeholder="-"
                value={newValue}
                onChange={(event) => setNewValue(event.currentTarget.value)}
                classNames={classes} 
                disabled={isUpdating}
                withAsterisk={withAsterisk}
            />
            <Group position="center">
                {(!isUpdating && oldValue !== newValue && (maxLength < 0 || newValue.length <= maxLength) ) && 
                <ActionIcon color="blue" radius="xl" variant="outline" onClick={reset}>
                    <IconX />
                </ActionIcon>}
                {(!isUpdating && oldValue !== newValue) && 
                <ActionIcon color="blue" radius="xl" variant="filled" onClick={update}>
                    <IconCheck />
                </ActionIcon>}
                {isUpdating && <Loader />}
            </Group>
            {maxLength > -1 &&
            <Text size="xs">{newValue.length} / {maxLength} characters</Text>
            }
            </>
        );
    } else {
        return (
            <Card p="xs">
                <Text size="sm" color="dimmed">
                    {label}
                </Text>
                <Text>
                    {newValue || '-'}
                </Text>
            </Card>
        );
    }

}

export { TextInputContainer }
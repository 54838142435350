import { lazy, Suspense, useContext, useState } from 'react';
import { Badge, Card, Grid, Group, Indicator, Loader, Text } from '@mantine/core';
import { IconTag } from '@tabler/icons';
import { TextInputContainer } from '../../shared/TextInputContainer';
import { UserSelector } from '../../shared/UserSelector';
import { AlertNotification } from '../../shared/AlertNotification';
import AppContext from '../../shared/AppContext';
import { DatePickerContainer } from '../../shared/DatePickerContainer';

const RichTextContainer = lazy(() => import('../../shared/RichTextComponent/rte_container').then(({RichTextContainer}) => ({default: RichTextContainer})));

interface UserInterface {
    id: number,
    label: string
}

interface HeaderInterface {
    id: number,
    client_name: string,
    manager_name: string,
    label: string,
    is_finished: boolean,
    affected_at: string | null,
    is_removed: boolean,
    stopped_date: string | null,
    journal: string,
}

interface PropsInterface {
    missionId: number,
    header: HeaderInterface,
}

const HeaderContainer = ({missionId, header}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const apiUpdateHeader = `${myContext.apiAddress}/update_header_mission?id=${missionId}`;
    const apiUpdateMetadata = `${myContext.apiAddress}/update_metadata_mission?id=${missionId}`;
    const [affectedAt, setAffectedAt] = useState(header.affected_at);
    const isInProgress = (header.is_finished || header.is_removed) ? false : true;

    const handleUser = ({id, label}: UserInterface) => {
        if (!isInProgress) return;
        const F = new FormData();
        F.append('code', 'affected_at');
        F.append('value', id + '');
        myContext.httpClient.post(apiUpdateHeader, F).then((res:any) => {
            if (res.data.status === true) {
                setAffectedAt(label);
            } else {
                AlertNotification({message: res.data.message || 'internal error'});
            }
        });
    }

    return (
        <Grid>
            <Grid.Col xs={12} md={6} lg={4}>
                <Group position="apart">
                    <Indicator label={`#${missionId}`} color="gray">
                        <IconTag size={12}/>
                    </Indicator>
                    <Group position="right">
                        <Badge color={affectedAt === null ? 'red' : 'green'}>{affectedAt || 'non affecté'}</Badge>
                        {isInProgress &&
                        <UserSelector
                            handle={handleUser}
                        />
                        }
                    </Group>
                </Group>
                <TextInputContainer
                    code='client_name'
                    label='Nom du client'
                    defaultValue={header.client_name}
                    api={apiUpdateHeader}
                    isEdition={isInProgress}
                    handle={null}
                    withAsterisk={true}
                />
                <TextInputContainer
                    code='manager_name'
                    label='Nom du manager de transition'
                    defaultValue={header.manager_name}
                    api={apiUpdateHeader}
                    isEdition={isInProgress}
                    handle={null}
                    withAsterisk={true}
                />
                <TextInputContainer
                    code='label'
                    label='Libellé de la mission'
                    defaultValue={header.label}
                    api={apiUpdateHeader}
                    isEdition={isInProgress}
                    handle={null}
                    withAsterisk={true}
                />
                <DatePickerContainer 
                    code='stopped_date'
                    label='Date de fin anticipée'
                    defaultValue={header.stopped_date || ''}
                    api={apiUpdateHeader}
                    isEdition={isInProgress}
                    handle={null}
                    withAsterisk={false}
                />
            </Grid.Col>
            <Grid.Col xs={12} md={6} lg={8}>
                <Text>Notes</Text>
                <Card withBorder>
                    <Suspense fallback={<Loader/>}>
                        <RichTextContainer
                            code='other_notebook'
                            value={header.journal}
                            api={apiUpdateMetadata}
                            handle={null}
                        />
                    </Suspense>
                </Card>
            </Grid.Col>
        </Grid>
    )
}

export { HeaderContainer }
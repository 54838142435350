import { useContext, useState } from 'react';
import { ActionIcon, Button, Center, Loader, Modal, Text } from '@mantine/core';
import { IconArrowMerge } from '@tabler/icons';
import AppContext from '../../shared/AppContext';

interface PropsInterface {
    id: number,
    handle: any,
}

const DuplicateButton = ({id, handle} : PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ working, setWorking ] = useState(false);
    const [ working2, setWorking2 ] = useState(false);
    const [ opened, setOpened ] = useState(false);
    const [ message, setMessage ] = useState<string | null>('');

    const merge = (force: boolean) => {
        setWorking(true);
        const api = `${myContext.apiAddress}/process_duplicated_cv?id=${id}${force ? '&force=true' : ''}`;
        myContext.httpClient.get(api).then((res: any) => {
            if (res.data.status === true) {
                handle();
                setWorking(false);
            } else {
                setOpened(true);
                setMessage(res.data.message || null);
            }
        });
    }

    if (working) {
        return (
            <>
            <Modal
                opened={opened}
                onClose={() => { setOpened(false); setWorking(false); }}
            >
                <Text>{message}</Text>
                <Center>
                    <Button loading={working2} color='blue' variant='outline' onClick={() => { merge(true); setWorking2(true); } }>
                        forcer le remplacement
                    </Button>
                </Center>
            </Modal>
            <Loader size="xs" />
            </>
        );
    } else {
        return (
            <ActionIcon color='blue' variant='outline' onClick={() => merge(false) }>
                <IconArrowMerge size={16} />
            </ActionIcon>
        )
    }

}

export { DuplicateButton }
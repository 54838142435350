import { useContext, useState } from 'react';
import { ActionIcon, Badge, Button, Card, Center, Group, LoadingOverlay, NumberInput, ScrollArea, Text } from "@mantine/core"
import { IconCheck, IconX } from '@tabler/icons';
import { TitleContainer } from "../shared/TitleContainer"
import AppContext from '../shared/AppContext';
import { AlertNotification } from '../shared/AlertNotification';
import { InfoNotification } from '../shared/InfoNotification';

interface RowInterface {
    category: string,
    date: string,
    description: string,
    debit: number,
    credit: number,
    currency: string,
}

const BankLetteringRemove = () => {

    const myContext = useContext(AppContext);
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<RowInterface[]>([]);

    const load = () => {
        if (id === 0) return;
        setLoading(true);
        setRows([]);
        const api = `${myContext.apiAddress}/bank_letter?id=${id}`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                setRows(res.data.data === null ? [] : res.data.data);
            }
            setLoading(false);
        });
    }

    const remove = () => {
        if (id === 0) return;
        setLoading(true);
        const api = `${myContext.apiAddress}/bank_remove_letter?id=${id}`;
        myContext.httpClient.get(api).then((res:any) => {
            setId(0);
            setRows([]);
            if (res.data.status === true) {
                InfoNotification({message: 'Lettering removed.'});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoading(false);
        });
    }

    return (<>
    <TitleContainer>Supprimer un lettrage</TitleContainer>
    <Center>
        <ScrollArea style={{height: 'calc(100vh - 200px)', width: 'min(100%, 600px)'}}>
            <LoadingOverlay visible={loading} />
            <Group>
                <NumberInput 
                    label='Numéro du lettrage'
                    value={id}
                    onChange={(v) => (v === undefined) ? setId(0) : setId(v)}
                    min={0}
                    max={999999}
                    disabled={rows.length !== 0}
                />
                {rows.length === 0 ?
                <ActionIcon color='blue' variant='outline' onClick={() => load()}>
                    <IconCheck size={16} />
                </ActionIcon>
                :
                <ActionIcon color='blue' variant='outline' onClick={() => { setRows([]); setId(0)}}>
                    <IconX size={16} />
                </ActionIcon>
                }
            </Group>
            {rows.length === 0 ?
            <Text>strange nothing</Text>
            :
            <>
            {rows.map((row, i: number) => 
            <Card key={`letter-${id}-${i}`} withBorder mt='xs'>
                <Group position='apart'>
                    <Badge>{row.category}</Badge>
                    <Text size='sm'>{row.date}</Text>
                    <Group>
                        <Badge>{row.debit.toLocaleString()}</Badge>
                        <Badge>{row.credit.toLocaleString()}</Badge>
                        <Badge>{row.currency}</Badge>
                    </Group>
                </Group>
                {row.description.split('\n').map((s,j) => 
                <Text size='sm' key={`text-${id}-${i}-${j}`}>{s}</Text>
                )}
            </Card>
            )}
            <Center mt='xs'>
                <Button color='blue' variant='outline' onClick={() => remove()}>
                    Supprimer le lettrage L{id}
                </Button>
            </Center>
            </>
            }
        </ScrollArea>
    </Center>
    </>)
}

export { BankLetteringRemove }
import { useContext, useEffect, useReducer, useState } from 'react';
import { ActionIcon, Badge, Button, Card, Group, Modal, ScrollArea, Spoiler, Stack, Text } from '@mantine/core';
import { IconSearch, IconRefresh, IconX } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
import { slugify } from '../../../services/functions';

import { SearchInterface, TermInterface } from '../interfaces';

import { constructSearchTermFromString, stringifySearch } from '../functions';

const searchesReducer = (state: SearchInterface[], payload: any) => {
    switch(payload.type) {
        case 'set':
            return payload.data.map((s:any) => {
                return {
                    id: s.id,
                    refreshed: false,
                    terms: s.search.split('|').map((i: string) => { return constructSearchTermFromString(i) }).filter((o: TermInterface | null) => o !== null),
                }
            });
        case 'add':
            return [ {
                id: payload.id,
                refreshed: false,
                terms: payload.search.split('|').map((i: string) => { return constructSearchTermFromString(i) }).filter((o: TermInterface | null) => o !== null),
            }, ].concat(state);
        case 'update':
            return state.map((s) => {
                return (s.id === payload.id) ? {
                    id: s.id,
                    refreshed: false,
                    terms: payload.search.split('|').map((i: string) => { return constructSearchTermFromString(i) }).filter((o: TermInterface | null) => o !== null),
                } : s;
            })
        default:
            return [];
    }
}

interface PropsInterface {
    huntId: number,   // id de la chasse
    getSearch: any,   // fonction retournant la recherche actuelle
    setSearch: any,   // fonction retournant la recherche séletionnée
    resetSearch: any, // fonction supprimant la recherche avec laquelle on travaille
    runSave: number,
}

const RepoSearches = ({huntId, getSearch, setSearch, resetSearch, runSave}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [ loaded, setLoaded ] = useState(false);
    const [ display, setDisplay ] = useState(false);
    const [ searches, dispatchSearches] = useReducer(searchesReducer, []);
    const [ currentSearchId, setCurrentSearchId ] = useState<null | number>(null);
    const [ working, setWorking ] = useState(false);

    useEffect(() => {
        if (loaded) return;
        const api = `${myContext.apiAddress}/manage_search_hunt?id=${huntId}&action=list`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                dispatchSearches({type: 'set', data: res.data.data || []});
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
            setLoaded(true); 
        });
    }, [loaded]); // eslint-disable-line

    const save = () => {
        const search: SearchInterface = getSearch();
        if (search.terms.length === 0) return;
        const s = stringifySearch(search);
        if (search.id !== null) {
            // on vérifie qu'il y a bien modification
            for (const S of searches) {
                if (S.id === search.id && s === stringifySearch(S)) {
                    return;
                }
            }
        }
        setWorking(true);
        const api = `${myContext.apiAddress}/manage_search_hunt?id=${huntId}&action=${currentSearchId === null ? 'add' : 'update'}`;
        const form = new FormData();
        form.append('search', s);
        if (currentSearchId !== null) form.append('search_id', currentSearchId + '');
        myContext.httpClient.post(api, form).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                if (currentSearchId === null) {
                    dispatchSearches({type: 'add', id: res.data.data.id, search: s});
                    setCurrentSearchId(res.data.data.id);
                } else {
                    dispatchSearches({type: 'update', id: currentSearchId, search: s});
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }
    
    useEffect(() => {
        if (currentSearchId !== null) {
            save();
        }
    }, [runSave]); // eslint-disable-line

    return (
        <>
        <Modal
            opened={display}
            onClose={() => setDisplay(false)}
            size='lg'
        >
            <Stack>
                <Group position='apart'>
                    <Text>{searches.length} recherches sauvegardées</Text>
                    <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                        <IconRefresh size={16} />
                    </ActionIcon>
                </Group>
                {currentSearchId !== null && 
                <Group position='apart'>
                    <Text>Vous travaillez actuellement avec la recherche {currentSearchId}.</Text>
                    <ActionIcon color='blue' variant='outline' onClick={() => setCurrentSearchId(null)}>
                        <IconX size={16} />
                    </ActionIcon>
                </Group>
                }
            </Stack>
            <ScrollArea style={{height: 'calc(100vh - 250px)'}}>
                {searches.map((s: SearchInterface) => 
                <Card key={`search-${s.id}`} withBorder mt='xs'>
                    <Group position='apart'>
                        <Badge>#{s.id}</Badge>
                        {currentSearchId !== s.id &&
                        <Button compact color='blue' variant='outline'
                            onClick={() => {
                                setCurrentSearchId(s.id);
                                setSearch({...s, refreshed: true});
                                setDisplay(false);
                            }}
                        >
                            select
                        </Button>
                        }
                    </Group>
                    <Spoiler maxHeight={60} showLabel="Show more" hideLabel="Hide">
                        <Group spacing={2}>
                            {s.terms.filter((o) => o.included).map((t,i) => 
                            <Text 
                                key={`search-${s.id}-in-${i}-${slugify(t.value)}`}
                                color='teal'
                            >
                                {`${t.value}`}
                            </Text>
                            )}
                        </Group>
                        <Group spacing={2}>
                            {s.terms.filter((o) => !o.included).map((t,i) => 
                            <Text
                                key={`search-${s.id}-out-${i}-${slugify(t.value)}`}
                                color='red'
                                style={{textTransform: 'none'}}
                            >
                                {`${t.value}`}
                            </Text>
                            )}
                        </Group>
                    </Spoiler>
                </Card>
                )}
            </ScrollArea>
        </Modal>
        <Group position='right'>
            {currentSearchId === null ?
            <Button size='xs' color='blue' variant='outline' compact
                onClick={() => save() } loading={working}
            >
                Save search as new one
            </Button>
            :
            <>
            <Button size='xs' compact color='blue' variant='outline' onClick={() => {
                setCurrentSearchId(null);
                resetSearch();
            }}>
                reset
            </Button>
            <Badge>#{currentSearchId}</Badge>
            </>
            }
            <ActionIcon color='blue' variant='outline' onClick={() => setDisplay(true)}>
                <IconSearch size={16} />
            </ActionIcon>
        </Group>
        </>
    )
}

export { RepoSearches }
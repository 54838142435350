import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Center, ColorSwatch, Group, LoadingOverlay, ScrollArea, Table, Text } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import { AlertNotification } from '../../shared/AlertNotification';
import { TitleContainer } from '../../shared/TitleContainer';
import { ToggleContainer } from '../../shared/ToggleContainer';
import AppContext from '../../shared/AppContext';

interface ItemInterface {
    id: number,
    name: string,
    version: number,
    is_blocked: boolean,
    email: string,
}

const AuditBlockedCV = () => {

    const myContext = useContext(AppContext);
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState<ItemInterface[][]>([]);

    useEffect(() => {
        if (loaded) return;
        const api = `${myContext.apiAddress}/block_list_cv`;
        myContext.httpClient.get(api).then((res: any) => {
            setLoaded(true);
            if (res.data.status === true) {
                setItems(res.data.data || []);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [loaded]); // eslint-disable-line

    return (
        <div>
            <LoadingOverlay visible={!loaded} />
            <Group position="apart">
                <TitleContainer>
                    Liste des CV bloqués
                </TitleContainer>
                <ActionIcon color="blue" variant="outline" onClick={() => setLoaded(false)}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
            {(loaded && items.length === 0) &&
            <Center>
                <p>aucun CV bloqué</p>
            </Center>
            }
            {(loaded && items.length !== 0) &&
            <Center>
                <ScrollArea style={{height: 'calc(100vh - 200px)'}}>
                    <Table highlightOnHover>
                        <tbody>
                            {items.map((cvs: ItemInterface[], i: number) => 
                            <tr key={`cvs-${i}`}>
                                <td>
                                    <Text>{cvs[0].email}</Text>
                                </td>
                                <td>
                                    {cvs.map((cv: ItemInterface) => 
                                    <Group key={`cv-${cv.id}`} position="apart" pt='xs' pb='xs'>
                                        <ColorSwatch color={cv.is_blocked ? 'red' : 'green'} size={16} />
                                        <Text>{cv.name}</Text>
                                        <ToggleContainer
                                            action='block'
                                            defaultValue={!cv.is_blocked}
                                            label='Utilisable'
                                            isEdition={true}
                                            api={`${myContext.apiAddress}/block_cv?id=${cv.id}`}
                                            handle={() => setLoaded(false)}
                                        />
                                    </Group>
                                    )}
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                </ScrollArea>
            </Center>
            }
        </div>
    )

}

export { AuditBlockedCV }
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { ActionIcon, Alert, Blockquote, Box, Button, Card, Center, Flex, Group, LoadingOverlay, Modal, NumberInput, ScrollArea, SegmentedControl, Select, Slider, Stack, Switch, Text, Textarea, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { IconChevronUp, IconChevronDown, IconChevronsUp, IconChevronsDown, IconCheck, IconX, IconPencil, IconPlus, IconRefresh, IconAsterisk, IconAlertTriangle } from '@tabler/icons';
import AppContext from '../AppContext';
import { AlertNotification } from '../AlertNotification';
import { InfoNotification } from '../InfoNotification';

import { NatureQuestionType, QuestionInterface } from './interfaces';

const patternParamSlider = /^\[(\{"value":[0-9]{1,3},"label":"[^"]{1,20}"\})(,(\{"value":[0-9]{1,3},"label":"[^"]{1,20}"\})){0,9}\]$/;

const convertStringQuestion = (row: string): null | QuestionInterface => {
    const cols = row.trim().split(';');
    if (cols.length === 0) {
        return null;
    } else if (cols.length !== 5) {
        console.log(`Uncorrect number of cols in ${row}`); //DEBUG
        return null;
    } else {
        // On teste les colonnes, si ok, on les insère.
        let nature: NatureQuestionType | null = null;
        switch (cols[0]) {
            case 'D': nature = 'D'; break;
            case 'E': nature = 'E'; break;
            case 'I': nature = 'I'; break;
            case 'N': nature = 'N'; break;
            case 'P': nature = 'P'; break;
            case 'R': nature = 'R'; break;
            case 'S': nature = 'S'; break;
            case 'T': nature = 'T'; break;
            case 'Z': nature = 'Z'; break;
        }
        const required = (['1','0'].indexOf(cols[1]) !== -1) ? (cols[1] === '1' ? true : false) : null;
        const code = /^[a-zA-Z0-9_]{2,10}$/.test(cols[2]) ? cols[2] : null;
        const label = (cols[3].trim().length > 5) ? cols[3].trim() : null;
        const param = (nature === 'S' && patternParamSlider.test(cols[4])) ? cols[4] : null;
        if (nature === null || required === null || code === null || label === null) {
            console.log(`Uncorrect values for ${row}`); //DEBUG
            return null;
        }
        if (nature === 'S' && param === null) {
            console.log(`Uncorrect param for slider in row ${row}`);
            return null;
        }
        return {
            position: -1,
            nature: nature,
            required: required,
            code: code,
            label: label,
            param: (param === null) ? null : JSON.parse(param),
        };
    }
}

const questionsReducer = (state: QuestionInterface[], payload: any) => {
    switch(payload.type) {
        // initialisation
        case 'set':
            return payload.data.map((r: QuestionInterface) => { return {...r}});
        // ajout d'une ligne
        case 'add':
            return state.concat([ {...payload.question} ]);
        // mode expert
        case 'expert':
            let E: QuestionInterface[] = [];
            for (const row of payload.rows.split('\n')) {
                const q = convertStringQuestion(row);
                if (q !== null) {
                    E.push({
                        ...q,
                        position: E.length,
                    });
                }
            }
            return E;
        // déplacement d'une ligne
        case 'move':
            const i0: number = payload.index + payload.step;
            const i1: number = payload.index;
            if (i0 < 0 || i0 >= state.length || i1 < 0 || i1 >= state.length) {
                return {...state}
            }
            const r0: QuestionInterface = {...state[i0]};
            let N: QuestionInterface[] = state.map((r) => { return {...r}});
            N[i0] = {...N[i1], position: i0};
            N[i1] = {...r0, position: i1};
            return N;
        // suppression d'une ligne
        case 'remove':
            return state.filter((r,i) => i !== payload.index);
        // mise à jour d'une ligne
        case 'update':
            return state.map((r,i) => { 
                if (i === payload.index) { 
                    return {...r, label: payload.label, nature: payload.nature, required: payload.required, param: payload.param } 
                } else { 
                    return {...r}
                }
            });
        // défaut
        default:
            return [];
    }
}

interface PropsInterface {
    nature: 'need' | 'hunt',
    id: number,
    isOpen: boolean,
    initialQuestions: QuestionInterface[],
}

const FormCreator = ({nature, id, isOpen, initialQuestions}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [working, setWorking] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [questions, dispatchQuestions] = useReducer(questionsReducer, []);
    const previousQuestions = useRef('');

    const [questionIndex, setQuestionIndex] = useState<number | null>(null);
    const [newLabel, setNewLabel] = useState('');
    const [newNature, setNewNature] = useState<string | null>(null);
    const [newRequired, setNewRequired] = useState(false);
    const [newParam, setNewParam] = useState('');
    const [isUpdateQuestion, setIsUpdateQuestion] = useState(false);
    
    const [isExpertMode, setIsExpertMode] = useState(false);
    const [newQuestions, setNewQuestions] = useState('');
    const [newQuestionsError, setNewQuestionsError] = useState<string>('');
    
    interface OptionInterfaceItem {
        label: string,
        paramWith: boolean,
        paramPattern: RegExp | null,
    }
    interface OptionsInterface {
        [key: string]: OptionInterfaceItem;
    }
    const options: OptionsInterface = {
        'D': { label: 'Date'         , paramWith: false, paramPattern: null},
        'E': { label: 'Email'        , paramWith: false, paramPattern: null},
        'I': { label: 'Free answer'  , paramWith: false, paramPattern: null},
        'N': { label: 'Integer'      , paramWith: false, paramPattern: null},
        'P': { label: 'Phone number' , paramWith: false, paramPattern: null},
        'R': { label: 'Boolean'      , paramWith: false, paramPattern: null},
        'S': { label: 'Slider'       , paramWith: true , paramPattern: patternParamSlider },
        'T': { label: 'Readonly text', paramWith: false, paramPattern: null},
        'Z': { label: 'Zipcode'      , paramWith: false, paramPattern: null},
    };

    useEffect(() => {
        if (loaded) return;
        setWorking(true);
        let api = `${myContext.apiAddress}/get_process_${nature}?${nature}_id=${id}&what=form`;
        myContext.httpClient.get(api).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                if (res.data.data === null) {
                    const F = new FormData();
                    F.append('questions', JSON.stringify(initialQuestions));
                    api = `${myContext.apiAddress}/update_process_${nature}?${nature}_id=${id}&what=form`;
                    myContext.httpClient.post(api, F).then((res:any) => {
                        setWorking(false);
                        if (res.data.status === true) {
                            dispatchQuestions({type: 'set', data: initialQuestions});
                            previousQuestions.current = JSON.stringify(initialQuestions);
                        } else {
                            setError(res.data.message || 'unknown error');
                        }
                    });
                } else {
                    dispatchQuestions({type: 'set', data: res.data.data});
                    previousQuestions.current = JSON.stringify(res.data.data);
                    setWorking(false);
                }
            } else {
                setWorking(false);
                setError(res.data.message || 'unknown error');
            }
        });
    }, [loaded]); // eslint-disable-line

    useEffect(() => {
        setNewQuestionsError(
            newQuestions.trim().split('\n')
            .map((row: string, idx: number) => { return convertStringQuestion(row) === null ? idx+1 : null})
            .filter((v) => v !== null)
            .join(' , ')
        );
    }, [newQuestions]); // eslint-disable-line

    const addQuestion = () => {
        dispatchQuestions({
            type: 'add', 
            question: {
                position: questions.length + 1,
                code: 'q' + (questions.length + 1),
                nature: newNature, 
                label: newLabel, 
                required: newNature === 'T' ? false : newRequired,
                param: (newNature !== null && options[newNature].paramWith) ? JSON.parse(newParam) : null,
            }
        });
    }

    const updateQuestion = () => {
        dispatchQuestions({
            type: 'update', 
            index: questionIndex,
            nature: newNature, 
            label: newLabel, 
            required: newNature === 'T' ? false : newRequired,
            param: (newNature !== null && options[newNature].paramWith) ? JSON.parse(newParam) : null,
        });
    }

    const save = () => {
        setWorking(true);
        const F = new FormData();
        F.append('questions', JSON.stringify(questions));
        const api = `${myContext.apiAddress}/update_process_${nature}?${nature}_id=${id}&what=form`;
        myContext.httpClient.post(api, F).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                previousQuestions.current = JSON.stringify(questions);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
                dispatchQuestions({type: 'set', data: JSON.parse(previousQuestions.current)});
            }
        });
    }

    const exportURLs = () => {
        setWorking(true);
        const api = `${myContext.apiAddress}/get_process_${nature}?${nature}_id=${id}&what=export_urls`;
        myContext.httpClient.get(api).then((res:any) => {
            setWorking(false);
            if (res.data.status === true) {
                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                    navigator.clipboard.writeText(res.data.data);
                    InfoNotification({message: 'Données copiées dans le presse papier.'});
                } else {
                    AlertNotification({message: 'The clipboard API is not available'});
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }

    return (
        <div>
            <Modal
                opened={isExpertMode}
                onClose={() => setIsExpertMode(false)}
                title='Expert mode'
                fullScreen
            >
                <Text color="dimmed">Each row contains the following data: nature;required;code;label;param</Text>
                <Textarea
                    placeholder="Your comment"
                    value={newQuestions}
                    onChange={(event) => setNewQuestions(event.currentTarget.value)}
                    autosize
                    withAsterisk
                />
                {newQuestionsError !== '' &&
                <Alert color='red'>
                    Rows with errors: {newQuestionsError}
                </Alert>
                }
                <Center mt='lg' mb='lg'>
                    <Button color='blue' variant='outline' 
                        onClick={() => {
                            dispatchQuestions({type:'expert',rows:newQuestions});
                            setIsExpertMode(false);
                        }}
                        disabled={newQuestionsError !== ''}
                    >
                        validate
                    </Button>
                </Center>
            </Modal>
            <Modal
                opened={isUpdateQuestion}
                onClose={() => setIsUpdateQuestion(false)}
                title={questionIndex === null ? "Ajouter une ligne" : "Modifier une ligne"}
            >
                <ScrollArea style={{height: 'min(90vh, 400px)'}}>
                    <Stack>
                        <Select 
                            label="Nature de la ligne :"
                            placeholder='choisir'
                            value={newNature} onChange={setNewNature}
                            data={ [ { value: '', label: '-' }, ].concat(Object.keys(options).map((k) => { return { 'value': k, label: options[k].label } })) }
                        />
                        {newNature !== 'T' &&
                        <Switch 
                            labelPosition="left"
                            label="Champ obligatoire ?"
                            checked={newRequired} onChange={(event) => setNewRequired(event.currentTarget.checked)} 
                        />}
                        {(newNature !== null && newNature in options && options[newNature].paramWith) && 
                        <Textarea
                            label="Param:"
                            placeholder={options[newNature].paramPattern?.toString()}
                            value={newParam} onChange={(event) => setNewParam(event.currentTarget.value)}
                            autosize
                            minRows={2}
                            maxRows={4}
                            error={!options[newNature].paramPattern?.test(newParam)}
                        />
                        }
                        {(newNature !== null && newNature in options) && 
                        <Textarea
                            label="Texte à afficher :"
                            placeholder="saisir"
                            value={newLabel} onChange={(event) => setNewLabel(event.currentTarget.value)}
                            autosize
                            minRows={2}
                            maxRows={4}
                        />
                        }
                        <Center>
                            <ActionIcon color="blue" variant="filled" 
                                style={{marginTop: '1.5em'}}
                                disabled={newLabel.trim() === '' || newNature?.length !== 1 || (options[newNature].paramWith && options[newNature].paramPattern?.test(newParam) !== true)} 
                                onClick={() => { 
                                    setIsUpdateQuestion(false); 
                                    if (questionIndex === null) {
                                        addQuestion(); 
                                    } else {
                                        updateQuestion();
                                    }
                                }}
                            >
                                {questionIndex === null && <IconPlus size={16} />}
                                {questionIndex !== null && <IconCheck size={16} />}
                            </ActionIcon>
                        </Center>
                    </Stack>
                </ScrollArea>
            </Modal>
            
            <LoadingOverlay visible={working}/>
            
            <Group position="right" pt="xs">
                <Button color="blue" variant="outline" size='xs' onClick={() => exportURLs()}>
                    Data for mailing
                </Button>
                <Button color='blue' variant='outline' size='xs' onClick={() => {
                    setNewQuestions(questions.map((q: QuestionInterface) => { return `${q.nature};${q.required ? '1' : '0'};${q.code};${q.label};${q.param === null ? '' : JSON.stringify(q.param)}` }).join('\n'));
                    setIsExpertMode(true);
                }}>
                    Expert mode
                </Button>
                <ActionIcon color="blue" variant="outline" onClick={() => setLoaded(false)}>
                    <IconRefresh size={16} />
                </ActionIcon>
            </Group>
            
            {error !== null && <Text color="red">{error}</Text>}
            <Center pt="xs">
                <Group>
                    <ScrollArea style={{height: 'calc(100vh - 220px)'}} p='sm'>
                        <Stack style={{maxWidth: '700px'}}>
                            {questions.map((r: QuestionInterface, i: number) => 
                            <Card key={`form-question-${i}`} style={{overflow: 'visible'}} withBorder>
                                {isOpen && 
                                <Group position='right' spacing="xs" pb='xs'>
                                    <ActionIcon color="blue" variant="outline" onClick={() => dispatchQuestions({type: 'move', index: i, step: -i})}>
                                        <IconChevronsUp size={16} />
                                    </ActionIcon>
                                    <ActionIcon color="blue" variant="outline" onClick={() => dispatchQuestions({type: 'move', index: i, step: -1})}>
                                        <IconChevronUp size={16} />
                                    </ActionIcon>
                                    <ActionIcon color="blue" variant="outline" onClick={() => dispatchQuestions({type: 'move', index: i, step: 1})}>
                                        <IconChevronDown size={16} />
                                    </ActionIcon>
                                    <ActionIcon color="blue" variant="outline" onClick={() => dispatchQuestions({type: 'move', index: i, step: questions.length - 1 - i})}>
                                        <IconChevronsDown size={16} />
                                    </ActionIcon>
                                    <ActionIcon color="blue" variant="outline" onClick={() => { 
                                        setQuestionIndex(i); 
                                        setNewLabel(r.label);
                                        setNewNature(r.nature);
                                        setNewRequired(r.required);
                                        setIsUpdateQuestion(true);
                                        setNewParam(r.param === null ? '' : JSON.stringify(r.param));
                                    }}>
                                        <IconPencil size={16} />
                                    </ActionIcon>
                                    {r.code.substring(0,1) !== '_' && 
                                    <ActionIcon color="blue" variant="outline" onClick={() => dispatchQuestions({type: 'remove', index: i})}>
                                        <IconX size={16} />
                                    </ActionIcon>}
                                </Group>
                                }
                                <Flex 
                                    justify="flex-start"
                                    align="flex-start"
                                    direction="row"
                                    wrap="nowrap"
                                >
                                    <Box style={{width: '20px'}}>
                                        {r.required && <IconAsterisk size={16} color="red" />}
                                    </Box>
                                    <Group position="apart" style={{width: 'calc(100% - 40px)'}}>
                                        {r.nature === 'D' && 
                                        <DatePicker label={r.label} />
                                        }
                                        {r.nature === 'E' &&
                                        <TextInput label={r.label} placeholder='user@domain.com' />
                                        }
                                        {r.nature === 'I' && 
                                        <Textarea label={r.label} minRows={3} maxRows={10} autosize style={{width: 'min(500px,50vw)'}}/>
                                        }
                                        {r.nature === 'N' && 
                                        <NumberInput label={r.label} min={0} max={500000} hideControls />
                                        }
                                        {r.nature === 'P' &&
                                        <TextInput label={r.label} placeholder='06 01 02 03 04' />
                                        }
                                        {r.nature === 'R' && 
                                        <Group position="apart">
                                            <Text>{r.label}</Text>
                                            <SegmentedControl color="blue" data={['-', 'oui', 'non']}/>
                                        </Group>
                                        }
                                        {r.nature === 'S' &&
                                        <Stack spacing={2} pb={24} style={{width: '100%'}}>
                                            <Text>{r.label}</Text>
                                            <Center>
                                                <Slider 
                                                    min={0}
                                                    max={100}
                                                    step={10}
                                                    precision={1}
                                                    style={{minWidth: '200px'}}
                                                    marks={r.param}
                                                />
                                            </Center>
                                        </Stack>
                                        }
                                        {r.nature === 'T' && 
                                        <Blockquote>{r.label}</Blockquote>
                                        }
                                        {r.nature === 'Z' &&
                                        <Group>
                                            <Text>{r.label}</Text>
                                            <TextInput label="Code postal" placeholder='75001' />
                                            <TextInput label="Ville" placeholder='Paris' />
                                        </Group>
                                        }
                                    </Group>
                                    <Box style={{width: '20px'}}>
                                        <IconCheck style={{backgroundColor: 'lightgray'}} color='white' size={16} />
                                    </Box>
                                </Flex>
                            </Card>
                            )}
                        </Stack>
                        {isOpen && 
                        <Center p='lg'>
                            <Button color="blue" variant="outline" onClick={() => { 
                                setQuestionIndex(null);
                                setNewLabel('');
                                setNewNature('-');
                                setNewRequired(false);
                                setIsUpdateQuestion(true);
                            }}>
                                Ajouter une ligne
                            </Button>
                        </Center>
                    }
                    </ScrollArea>
                    {isOpen && 
                    <Stack>
                        {questions.filter((r: QuestionInterface) => r.label.endsWith('...')).length !== 0 && 
                        <Center>
                            <IconAlertTriangle color='red' size={32} />
                        </Center>
                        }
                        <Button color="red" 
                            disabled={working || questions.length === 0 || previousQuestions.current === JSON.stringify(questions)}
                            onClick={() => save()}
                        >
                            Sauvegarder
                        </Button>
                    </Stack>
                    }
                </Group>
            </Center>
        </div>
    )

}

export { FormCreator }
import { useContext, useState } from 'react';
import { ActionIcon, Button, Card, Center, Group, Modal, NumberInput, Text, Textarea } from '@mantine/core';
import { IconFilePencil } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { DisplayPDF } from "../../shared/DisplayPDF";

interface PropsInterface {
    missionId: number,
    missionLabel: string,
}

const ReportMakerButton = ({missionId, missionLabel}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [isOpened, setIsOpened ] = useState(false);
    const [isGo, setIsGo] = useState(false);
    const [month, setMonth] = useState((new Date()).getMonth()+1);
    const [year, setYear] = useState((new Date()).getFullYear());
    const [complement, setComplement] = useState('');
    const months: String[] = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    return (
        <>
        <ActionIcon color="blue" variant="outline" onClick={() => setIsOpened(true)} >
            <IconFilePencil size={16} />
        </ActionIcon>
        <Modal
            title={`Rapport d'activité vierge pour "${missionLabel}" [${missionId}]`}
            opened={isOpened}
            onClose={() => setIsOpened(false)}
            fullScreen
        >
            <Center>
                <Card>
                    <NumberInput
                        label='Année'
                        value={year}
                        min={2022} max={2032} step={1}
                        onChange={(y) => { 
                            if (y !== undefined) { setYear(y)}
                        }}
                        disabled={isGo}
                    />
                    <Group>
                        <NumberInput
                            label='Mois'
                            value={month}
                            min={1} max={12} step={1}
                            onChange={(m) => { 
                                if (m !== undefined) { setMonth(m)}
                            }}
                            disabled={isGo}
                        />
                        <Text mt="md">{months[month-1]}</Text>
                    </Group>
                    <Textarea 
                        label='Informations complémentaires à insérer'
                        value={complement}
                        onChange={(event) => setComplement(event.currentTarget.value)}
                        minRows={3}
                        maxRows={3}
                        disabled={isGo}
                    />
                    <Group position="apart" mt="sm">
                        <Button color="blue" variant="outline" disabled={isGo} onClick={() => setIsGo(true)}>
                            Générer le PDF
                        </Button>
                        <Button color="blue" variant="outline" disabled={!isGo} onClick={() => setIsGo(false)}>
                            Recommencer
                        </Button>
                    </Group>
                </Card>
            </Center>
            {isGo &&
            <DisplayPDF fileUrl={`${myContext.apiAddress}/create_report_mission?id=${missionId}&year=${year}&month=${month}&complement=${encodeURIComponent(complement)}`} />
            }
        </Modal>
        </>
    )
}

export { ReportMakerButton }
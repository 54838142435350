import { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { Button, Card, Center, Divider, Group, Loader, LoadingOverlay, ScrollArea, SegmentedControl, Spoiler, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppContext from '../../shared/AppContext';
import { AlertNotification } from '../../shared/AlertNotification';
const ChartContainer = lazy(() => import('../../shared/ChartContainer').then(({ChartContainer}) => ({default: ChartContainer})));

interface NatureInterface {
    id: number,
    label: string,
}

interface CompanyInterface {
    id: number,
    label: string,
    delay: number,
}

interface PropsInterface {
    category: 'c' | 'p' | 'r',
}

const DashboardContainer = ({category}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [isLoaded,setIsLoaded] = useState(false);
    const [dataset1Companies, setDataset1Companies] = useState<CompanyInterface[][]>([]);
    const dataset1categories: string[] = ['jamais','> 12 mois','> 11 mois', '> 10 mois','> 9 mois','> 8 mois', '> 7 mois', '> 6 mois', '> 5 mois', '> 4 mois', '> 3 mois', '> 2 mois', '> 1 mois', 'récemment'];
    const [dataset2Option, setDataset2Option] = useState<any>(null);
    
    const [natures, setNatures] = useState<NatureInterface[]>([]);
    const [nature, setNature] = useState('-1');
    
    useEffect(() => {
        if (isLoaded) return;
        const api = `${myContext.apiAddress}/get_dashboard_book?category=${category}&nature=${nature}`;
        myContext.httpClient.get(api).then((res:any) => {
            setIsLoaded(true);
            if (res.data.status === true) {
                let A: CompanyInterface[][] = []
                for (let i=0;i<14;i++){ A.push([])}
                if (res.data.data !== null) {
                    let r: CompanyInterface;
                    for(r of res.data.data.dataset_1){
                        A[r.delay].push({...r});
                    }
                    setDataset1Companies(A);
                    setDataset2Option({
                        legend: {
                            data: res.data.data.dataset_2.series.map((s:any) => { return s.name}),
                        },
                        toolbox: {
                            feature: {
                                dataView: {}
                            }
                        },
                        tooltip: {},
                        xAxis: {
                            data: res.data.data.dataset_2.categories,
                            name: 'mois',
                            axisLine: { onZero: true },
                            splitLine: { show: false },
                            splitArea: { show: false }
                        },
                        yAxis: {},
                        series: res.data.data.dataset_2.series.map((s:any) => { return {
                            name: s.name,
                            type: 'bar',
                            stack: 'one',
                            data: s.data
                        }}),
                    });
                }
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        })
    }, [isLoaded]); // eslint-disable-line

    useEffect(() => {
        setIsLoaded(false);
    }, [nature]);

    useEffect(() => {
        if (natures.length !== 0) return;
        const api = `${myContext.apiAddress}/get_repository_nature_book`;
        myContext.httpClient.get(api).then((res:any) => {
            if (res.data.status === true) {
                setNatures([{id: -1, label: 'all'},{id: 0, label: 'undefined'}].concat(res.data.data));
            } else {
                setNatures([{id: -1, label: 'all'}]);
            }
        });
    }, []); // eslint-disable-line

    return (
        <>
        {natures.length > 1 &&
        <Center pt='xs'>
            <SegmentedControl value={nature} onChange={setNature} size='xs'
                data={natures.map((n) => { return {label: n.label, value: n.id + ''} })}
            />
        </Center>
        }
        <Divider label="Mesure de l'activité sur les derniers mois" labelPosition='center' py="xs"/>
        {isLoaded && dataset2Option !== null &&
        <Center>
            <Card style={{width: 'max(70vw, 600px)'}}>
                <Suspense fallback={<Loader/>}>
                    <ChartContainer 
                        option={dataset2Option} 
                    />
                </Suspense>
            </Card>
        </Center>
        }
        <Divider label="Regroupement des entreprises en fonction du dernier échange" labelPosition='center' py="xs"/>
        <Center>
            <ScrollArea style={{height: 'calc(100vh - 200px)', width: '80%'}}>
                <LoadingOverlay visible={!isLoaded} />
                {isLoaded && <>
                {dataset1categories.map((c:string, ic: number) => 
                <Spoiler 
                    key={`category-${ic}`}
                    showLabel="Show more" hideLabel="Hide"
                    maxHeight={50}
                >
                    <Divider label={`${c}`} labelPosition='left' />
                    {dataset1Companies[ic].length === 0 ? <Text color="dimmed" size="xs">aucune société</Text> : 
                    <Group>
                        {dataset1Companies[ic].map((s:CompanyInterface,is: number) =>
                        <Button 
                            color='blue' variant='outline'
                            key={`category-${ic}-company-${is}`}
                            component={Link}
                            to={`/annuaire/fiche/${s.id}/${encodeURIComponent(s.label)}`}
                            compact
                        >{s.label}</Button>
                        )}
                    </Group>
                    }
                </Spoiler>
                )}
                </>
                }
            </ScrollArea>
        </Center>
        </>
    )
}

export { DashboardContainer }
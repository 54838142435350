import { useContext, useState } from 'react';
import { Badge, Button, Card, Center, Divider, Grid, Group, List, LoadingOverlay, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import { AlertNotification } from '../../shared/AlertNotification';
import AppContext from '../../shared/AppContext';
import { Brief } from '../ProcessNeed/brief';

const AddNeed = () => {

    const myContext = useContext(AppContext);
    
    const [clientName, setClientName] = useState('');
    const [clientMission, setClientMission] = useState('');
    const [clientDuration, setClientDuration] = useState('');
    const [clientLocalisation, setClientLocalisation] = useState('');
    const [needId, setNeedId] = useState<number | null>(null);
    const [isWaiting, setIsWaiting] = useState(false);
    
    const createNeed = () => {
        if (clientName.trim() === '') return;
        if (clientMission.trim() === '') return;
        setIsWaiting(true);
        const F = new FormData();
        F.append('client_name', clientName.trim());
        F.append('client_mission', clientMission.trim());
        if (clientDuration.trim() !== '') {
            F.append('client_duration', clientDuration.trim());
        }
        if (clientLocalisation.trim() !== '') {
            F.append('client_localisation', clientLocalisation.trim());
        }
        const api = myContext.apiAddress + '/create_need';
        myContext.httpClient.post(api, F).then((res:any) => {
            if (res.data.status === true) {
                setNeedId(res.data.data.id);
            } else {
                AlertNotification({message: res.data.message || 'unknown error in saving'})
            }
            setIsWaiting(false);
        });
    }

    return (
        <Grid>
            <Grid.Col md={12} lg={7}>
            {needId === null ?
                <Center>
                    <Card>
                        <LoadingOverlay visible={isWaiting} />
                        <TextInput
                            label="Nom du client"
                            value={clientName} 
                            onChange={(event) => setClientName(event.currentTarget.value)}
                            withAsterisk
                        />
                        <TextInput
                            label="Libellé du poste de la mission"
                            value={clientMission} 
                            onChange={(event) => setClientMission(event.currentTarget.value)}
                            withAsterisk
                        />
                        <TextInput
                            label="Durée prévisionnelle de la mission"
                            value={clientDuration} 
                            onChange={(event) => setClientDuration(event.currentTarget.value)}
                        />
                        <TextInput
                            label="Lieu de la mission (ville et code postal)"
                            value={clientLocalisation} 
                            onChange={(event) => setClientLocalisation(event.currentTarget.value)}
                        />
                        <Center mt="xs">
                            <Button color="blue" variant="outline" onClick={() => createNeed()}
                                disabled={clientName.trim() === '' || clientMission.trim() === ''}
                            >
                                Créer ce besoin
                            </Button>
                        </Center>
                    </Card>    
                </Center>
            :
                <ScrollArea style={{height: 'calc(100vh - 90px)'}} pb='lg'>
                    <Group>
                        <Text>Création de la mission</Text><Badge>{needId}</Badge>
                    </Group>
                    <Brief needId={needId} isOpen={true} handleLabel={null} />
                </ScrollArea>
            }
            </Grid.Col>
            <Grid.Col md={12} lg={5}>
                <ScrollArea style={{height: 'calc(100vh - 90px)'}}>
                    <Stack>
                        <Divider label="Qui sommes nous ?" labelPosition='center'/>
                        <List style={{fontSize: '0.8em', width: '95%'}}>
                            <List.Item>Nous sommes un cabinet implanté sur le marché du Management de Transition depuis 2009.</List.Item>
                            <List.Item>Notre vivier de Managers est nourri de plusieurs milliers de personnes couvrant un large panel de fonctions du Top et Middle Management.</List.Item>
                            <List.Item>Les fonctions corporate et support (Finance, RH, SI), opérations (DU, DT, HSE, Dprod) et les fonctions managériales (DG, DBU, Dir Agence).</List.Item>
                            <List.Item>Nous travaillons avec tous les secteurs : industrie, retail, services (adapter le discours en fonction de l’activité de l’entreprise).</List.Item>
                            <List.Item>Actuellement, nous avons à peu près 150 Managers de Transition en mission.</List.Item>
                        </List>
                        <Divider label="Contexte" labelPosition='center'/>
                        <List style={{fontSize: '0.8em', width: '95%'}}>
                            <List.Item>Activité de l’entreprise</List.Item>
                            <List.Item>Fonction du contact de l’entreprise</List.Item>
                            <List.Item>Chiffre d’Affaires</List.Item>
                            <List.Item>Taille de l’entreprise (obtenir si possible des éléments chiffrés)</List.Item>
                            <List.Item>Localisation</List.Item>
                        </List>
                        <Divider label="Mission" labelPosition='center'/>
                        <List style={{fontSize: '0.8em', width: '95%'}}>
                            <List.Item>Fonction du Manager ?</List.Item>
                            <List.Item>Cadre de la mission ? Conduite de changement, management-relais, gestion de crise, gestion de projet, retournement, amélioration de la performance…</List.Item>
                            <List.Item>Missions clés du Manager, actions prédominantes le temps de la mission</List.Item>
                            <List.Item>Y a-t-il une équipe à manager ? Si oui, combien de personnes? Comment est-elle structurée (fonctions des collaborateurs, organigramme) ?</List.Item>
                            <List.Item>A qui le Manager est-il rattaché le temps de sa mission ?</List.Item>
                            <List.Item>Y a-t-il une fiche de poste de formalisée ? </List.Item>
                            <List.Item>Date de démarrage ?</List.Item>
                            <List.Item>Durée prévisionnelle ? Il est plus facile d’attirer les talents pour des missions de 6 mois</List.Item>
                            <List.Item>Y a-t-il du télétravail ?</List.Item>
                            <List.Item>Si entreprise multisites, y a-t-il des déplacements entre les sites prévus pour le Manager ?</List.Item>
                        </List>
                        <Divider label="Manager" labelPosition='center'/>
                        <List style={{fontSize: '0.8em', width: '95%'}}>
                            <List.Item>Quels softskills / qualités relationnelles sont déterminants pour cette mission ?</List.Item>
                            <List.Item>Doit-il venir du même secteur d’activité que l’entreprise cliente ?</List.Item>
                            <List.Item>Outils spécifique à maîtriser ?</List.Item>
                            <List.Item>Langue à maîtriser?</List.Item>
                        </List>
                    </Stack>
                </ScrollArea>
            </Grid.Col>
        </Grid>
    )

}

export { AddNeed }
import { useContext, useState } from 'react';
import { Button, Card, Center, Group, Loader, PasswordInput, Text, Tabs, TextInput } from '@mantine/core';
import { IconIdBadge2, IconPhoto, IconKey, IconBooks } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { AvatarIcon } from '../../shared/AvatarIcon';
import { AlertNotification } from '../../shared/AlertNotification';
import { InfoNotification } from '../../shared/InfoNotification';

const MyAccountContainer = () => {

    const myContext = useContext(AppContext);
    
    const [isWorking,setIsWorking] = useState(false);
    const [pwd0,setPwd0] = useState('');
    const [pwd1,setPwd1] = useState('');
    const [pwd2,setPwd2] = useState('');

    const updatePassword = () => {
        setIsWorking(true);
        const f = new FormData();
        f.append('pwd0', pwd0);
        f.append('pwd1', pwd1);
        myContext.httpClient.post(`${myContext.apiAddress}/update_password`, f).then((result: any) => {
            setPwd0('');
            setPwd1('');
            setPwd2('');
            if (result.data.status === true) {
                InfoNotification({message: "Votre mot de passe a été modifié."});
            } else {
                AlertNotification({message: result.data.message || 'Oops, something wrong, sorry.'});
            }
            setIsWorking(false);
        });
    }

    return (
        <Tabs defaultValue="avatar">
            <Tabs.List>
                <Tabs.Tab value="badge" icon={<IconIdBadge2 size={14} />}>Identifiants</Tabs.Tab>
                <Tabs.Tab value="avatar" icon={<IconPhoto size={14} />}>Avatar</Tabs.Tab>
                <Tabs.Tab value="password" icon={<IconKey size={14} />}>Mot de passe</Tabs.Tab>
                <Tabs.Tab value="repositories" icon={<IconBooks size={14} />}>Référentiels</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="badge" pt="xs">
                <TextInput
                    label="Identifiant de connexion"
                    placeholder={myContext.account.nickname}
                    disabled
                />
                <TextInput
                    label="Prénom"
                    placeholder={myContext.account.firstname}
                    disabled
                />
                <TextInput
                    label="Nom de famille"
                    placeholder={myContext.account.lastname}
                    disabled
                />
                <TextInput
                    label="Email"
                    placeholder={myContext.account.email}
                    disabled
                />
            </Tabs.Panel>
            <Tabs.Panel value="avatar" pt="xs">
                <Group>
                    <Text>Avatar actuel :</Text>
                    <AvatarIcon 
                        avatar={myContext.account.avatar}
                        firstname={myContext.account.firstname}
                        lastname={myContext.account.lastname}
                        isFull={false}
                    />
                </Group>
            </Tabs.Panel>
            <Tabs.Panel value="password" pt="xs">
                {(isWorking) ? (
                <>
                    <Center>
                        <Loader size="lg" />        
                    </Center>
                    <Center>
                        Modification du mot de passe en cours...
                    </Center>
                </>
                ):(
                    <>
                    <PasswordInput
                        required
                        label="Votre mot de passe actuel"
                        value={pwd0}
                        onChange={(event) => setPwd0(event.currentTarget.value)}
                    />
                    <PasswordInput
                        required
                        label="Nouveau mot de passe"
                        value={pwd1}
                        onChange={(event) => setPwd1(event.currentTarget.value)}
                    />
                    { /^[a-zA-Z0-9]+$/.test(pwd1) === false && <Text color="red" size="sm">Que des lettres et des chiffres.</Text> }
                    { pwd1.length < 8 && <Text color="red" size="sm">Au moins 8 caractères.</Text> }
                    { (pwd1.length - pwd1.replaceAll(/[a-zA-Z]/g,'').length < 2) && <Text color="red" size="sm">Au moins 2 lettres.</Text> }
                    { (pwd1.length - pwd1.replaceAll(/[0-9]/g,'').length < 2) && <Text color="red" size="sm">Au moins 2 chiffres.</Text> }
                    <PasswordInput
                        required
                        label="Resaisie du nouveau mot de passe"
                        value={pwd2}
                        onChange={(event) => setPwd2(event.currentTarget.value)}
                    />
                    { (pwd2.length > 0 && pwd1 !== pwd2) && <Text color="red" size="sm">La resaisie n'est pas identique au nouveau mot de passe.</Text> }
                    <Button
                        disabled={/^[a-zA-Z0-9]+$/.test(pwd1) === false || pwd1.length < 8 || pwd1.length - pwd1.replaceAll(/[a-zA-Z]/g,'').length < 2 || pwd1.length - pwd1.replaceAll(/[0-9]/g,'').length < 2}
                        onClick={() => updatePassword()}
                        style={{marginTop: '1em'}}
                    >
                        Modifier
                    </Button>
                    </>
                )}
            </Tabs.Panel>
            <Tabs.Panel value="repositories" pt="xs">
                <Card>
                    <Group position="apart">
                        <Text>Métiers chargés</Text>
                        <Text color="dimmed">{myContext.jobs === null ? 0 : myContext.jobs.items.length}</Text>
                    </Group>
                </Card>
                <Card>
                    <Group position="apart">
                        <Text>Secteurs chargés</Text>
                        <Text color="dimmed">{myContext.sectors === null ? 0 : myContext.sectors.items.length}</Text>
                    </Group>
                </Card>
                <Card>
                    <Group position="apart">
                        <Text>Utilisateurs chargés</Text>
                        <Text color="dimmed">{myContext.users === null ? 0 : myContext.users.items.length}</Text>
                    </Group>
                </Card>
            </Tabs.Panel>
        </Tabs>
    )
}

export { MyAccountContainer }

import { Center, ScrollArea, Stack } from '@mantine/core';
import { TitleContainer } from "../../shared/TitleContainer"

import { LeaveForm } from "./leave-form"
import { LeaveList } from './leave-list';
import { LeaveValidateList } from './leave-validate-list';

const RequestsTeam = () => {

    return (<>
        <TitleContainer>
            Requêtes RH
        </TitleContainer>
        <Center>
            <ScrollArea style={{width: 'min(100vw, 700px)', height: 'calc(100vh - 120px)'}}>
                <Stack>
                    <LeaveForm />
                    <LeaveList />
                    <LeaveValidateList />
                </Stack>
            </ScrollArea>
        </Center>
    </>)
}

export { RequestsTeam }
import { useContext, useState } from 'react';
import { ActionIcon, Modal } from '@mantine/core';
import { IconFileDescription } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { DisplayPDF } from "../../shared/DisplayPDF";

interface PropsInterface {
    huntId: number,
    cvId: number,
    fileId: number,
}

const Document = ({huntId, cvId, fileId}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [display, setDisplay] = useState(false);

    return (
        <>
        <Modal
            opened={display}
            onClose={() => setDisplay(false)}
            fullScreen
        >
            <div style={{width: 'calc(100vw - 50px)', height: 'calc(100vh - 100px)'}}>
                <DisplayPDF fileUrl={`${myContext.apiAddress}/get_process_hunt?hunt_id=${huntId}&cv_id=${cvId}&&what=file&file_id=${fileId}`} />
            </div>
        </Modal>
        <ActionIcon color="blue" variant="outline" onClick={() => setDisplay(true)}>
            <IconFileDescription size={16} />
        </ActionIcon>
        </>
    )
}

export { Document }
import { useContext, useState } from 'react';
import { LoadingOverlay, Select } from '@mantine/core';
import { AlertNotification } from '../../shared/AlertNotification';
import AppContext from '../AppContext';

interface ItemInterface {
    label: string,
    value: string,
}
interface PropsInterface {
    defaultValue: string,
    options: ItemInterface[],
    api: string,
    handle: any,
}

const SegmentInputContainer = ({defaultValue, options, api, handle}: PropsInterface) => {

    const myContext = useContext(AppContext);
    const [value, setValue] = useState(defaultValue);
    const [isSaving, setIsSaving] = useState(false);

    const update = (v: string) => {
        if (api === '') return;
        if (value === v) return;
        const F = new FormData();
        F.append('code', 'status');
        F.append('value', v);
        setIsSaving(true);
        myContext.httpClient.post(api, F).then((res:any) => {
            setIsSaving(false);
            if (res.data.status === true) {
                setValue(v);
                if (handle !== null) {
                    handle(v);
                }
            } else {
                AlertNotification({message: res.data.message || 'internal error'});
            }
        });
    }

    return (
        <div>
            <LoadingOverlay visible={isSaving}/>
            <Select
                label='Statut'
                value={value}
                onChange={update}
                data={options}
                disabled={api === ''}
            />
        </div>
    )
}

export { SegmentInputContainer }
import { useContext, useEffect, useState } from 'react';
import { ActionIcon, Button, CopyButton, Group, LoadingOverlay, ScrollArea, Table, Text } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import AppContext from '../../shared/AppContext';
import { TitleContainer } from "../../shared/TitleContainer"
import { AlertNotification } from '../../shared/AlertNotification';

interface RowInterface {
    reference: string,
    path: string,
    date: string,
}

const RemovedFactures = () => {

    const myContext = useContext(AppContext);
    const [ loaded, setLoaded ] = useState(false);
    const [ rows, setRows ] = useState<RowInterface[]>([]);

    useEffect(() => {
        if (loaded) return;
        const api = `${myContext.apiAddress}/audit_mission?report=removed`;
        myContext.httpClient.get(api).then((res:any) => {
            setLoaded(true);
            if (res.data.status === true) {
                setRows(res.data.data);
            } else {
                AlertNotification({message: res.data.message || 'unknown error'});
            }
        });
    }, [loaded]); // eslint-disable-line

    const rowsToString = () => {
        let s = '<table><tr><th>reference</th><th>date de suppression</th></tr>';
        for(const row of rows){
            s += `<tr><td>${row.reference}</td><td>${row.date.substring(0,10)}</td></tr>`;
        }
        return s;
    }

    return (
        <>
        <Group position='apart'>
            <TitleContainer>
                Liste des factures supprimées
            </TitleContainer>
            <Group>
            <CopyButton value={rowsToString()}>
                {({ copied, copy }) => (
                <Button color={copied ? 'teal' : 'blue'} onClick={copy} variant='outline' compact>
                    {copied ? 'Copied' : 'Copy'}
                </Button>
                )}
            </CopyButton>
                <ActionIcon color='blue' variant='outline' onClick={() => setLoaded(false)}>
                    <IconRefresh size={(16)} />
                </ActionIcon>
            </Group>
        </Group>
        <LoadingOverlay visible={!loaded} />
        {rows.length !== 0 &&
        <ScrollArea style={{height: 'calc(100vh - 120px)'}}>
            <Table>
                <thead>
                    <tr>
                        <th>Référence</th>
                        <th>Fichier local</th>
                        <th>Date de suppression</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((r: RowInterface) => 
                    <tr key={`${r['reference']}`}>
                        <td><Text>{r['reference']}</Text></td>
                        <td><Text>{r['path']}</Text></td>
                        <td><Text>{r['date']}</Text></td>
                    </tr>)}
                </tbody>
            </Table>
        </ScrollArea>
        }
        </>
    )

}

export { RemovedFactures }